<template>
  <component
    :is="component"
    :progress="progress"
    :color="progressColor"
    empty-color="transparent"
    :size="progressSize"
    :legend="true"
    :legendValue="progress"
    :legendFormatter="
      ({ currentValue }) => ` <small class='d-block'>${currentValue}%</small>`
    "
    legendClass="legend-custom-style"
    :thickness="3"
    empty-thickness="1"
    lineMode="out 2"
    animation="rs 700 1000"
    fontSize="1.2rem"
  >
  </component>
</template>

<script>
export default {
  props: ['progress', 'progressColor', 'progressSize'],
  data() {
    return {};
  },

  computed: {
    component() {
      return this.test ? 'vue-ellipse-progress-test' : 'vue-ellipse-progress';
    },
  },
  methods: {},
};
</script>
