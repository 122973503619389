import objectPath from "object-path";
import Vue from 'vue';
import {endpoints} from "@/core/services/Endpoints";
import moment from 'moment';
import 'moment/locale/tr'

class Global{
    constructor(){
        this.app = null;
        this.vm = Vue.prototype;

        this.initialize();
    }

    setApp(app) {
        this.app = app;
    }

    initialize() {
        Vue.directive('init', {
            beforeMount(el, binding, vnode) {
                if (binding.arg) {
                    binding.instance[binding.arg] = binding.value;
                }
            }
        });

        this.vm.sprintf = (pattern, params) => {
            var i = 0;
            return pattern.replace(/%(s|d|0\d+d)/g, function (x, type) {
                var value = params[i++];
                switch (type) {
                    case 's':
                        return value;
                    case 'd':
                        return parseInt(value, 10);
                    default:
                        value = String(parseInt(value, 10));
                        var n = Number(type.slice(1, -1));
                        return '0'.repeat(n).slice(value.length) + value;
                }
            });
        };

        this.vm.arrayColumn = (array, column) => {
            return array.map(x => x[column])
        };

        this.vm.objectPath = objectPath;
        this.vm.endpoints = endpoints;
        this.vm.moment = moment;

        this.vm.onResponse = (response, successCallback, callback, showSuccessNotify = true) => {
            if (!response.status) {
                return  this.onResponseFailure(response, callback);
            }

            if (showSuccessNotify) {
                this.app.$notify({
                    type: 'success',
                    title: this.app.$t("messages.success"),
                    message: this.app.$t("messages.actionOk"),
                });
            }

            if (successCallback) {
                successCallback();
            }

            if (callback) {
                callback();
            }
        };

        this.vm.onResponseFailure = (response, callback) => {
             let notifyObj = {
                 type: 'error',
                 title: this.app.$t("messages.error"),
                 //message:  this.app.$t('responseErrors.' + response.message, response.message),
             };

             if (response.message == 'validate_error') {
                 //notifyObj.dangerouslyUseHTMLString = true
                 notifyObj.title =  this.app.$t("responseErrors.validate_error");
                // notifyObj.message = Object.values(response.errors).join('</br>');
             }

             this.app.$notify(notifyObj);

             if (callback) {
                 callback();
             }
        };

        this.vm.resolveDatum = (data, value, key = 'id', defaultValue = {}) => {
            if (!data || !value) {
                return defaultValue;
            }

            let datum = defaultValue;

            for (let i = 0; i < data.length; i++) {
                if (this.vm.objectPath.get(data[i], key) == value) {
                    datum = data[i];
                    break;
                }
            }

            return datum;
        }

        this.vm.cloneData = (data) => {
            return JSON.parse(JSON.stringify(data));
        };

        this.vm.ucFirst = (string) => {
            string = string.toLowerCase(string);
            return string[0].toUpperCase() + string.slice(1);
        };

        this.vm.uniqueID = (length = 16) => {
            return parseInt(Math.ceil(Math.random() * Date.now()).toPrecision(length).toString().replace(".", ""));
        };

        this.vm.trustedWindowOpen = (url, target) => {
            let a = document.createElement("a");
            document.body.appendChild(a);

            a.style = "display: none";
            a.href = url;

            if(target) {
                a.target = target;
            }

            a.click();
            document.body.removeChild(a);
        }
    }
}

export default new Global();
