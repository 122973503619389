import {createTableState} from '@/core/services/Helper'

const state = () => ({
    table: createTableState()
})

// getters
const getters = {}

// actions
const actions = {
    // get records
    async get({state, commit}, {filterData}) {
        commit('setLoading', true)

        state.filterData = filterData;

        let params = {};

        if(state.filterData){
            params = {...params, ...state.filterData};
        }

        const res = await this._vm.axios.get(this._vm.endpoints['notification'], {
            params: params
        })

        const {data} = res.data
        commit('updateTableData', data)

        setTimeout(() => {
            commit('setLoading', false)
        }, 250);

        return data;
    },

    // refresh records
    refresh({state, dispatch}) {
        dispatch('get', {
            filterData: state.filterData
        })
    }
}

// mutations
const mutations = {
    setLoading(state, val) {
        state.table.loading = val
    },
    updateTableData(state, data) {
        state.table.data = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
