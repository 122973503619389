export const endpoints = {
    localization_language: "/localization/language",
    localization_currency: "/localization/currency",
    ecommerce_order: "/ecommerce/order",
    ecommerce_order_shipping_detail: "/ecommerce/order/shipping-details",
    module_paragraph: "/module/paragraph",
    module_paragraph_question_session: "/module/paragraph/question/session",
    module_paragraph_question_answer: "/module/paragraph/question/answer",
    module_paragraph_question_session_finish: "/module/paragraph/question/session/finish",
    module_zoom_meeting_link: "/module/zoom/meeting/private-link",
    module_popup_check: "/module/popup/check",
    module_source: "/module/sources",
    module_source_category: "/module/sources/category",
    lesson_stream_sync_duration: "/lesson/lecture/stream/sync-duration",
    notification: "/notification"
}
