<template>
  <div>
    <highcharts
      class="hc"
      :options="chartOption"
      ref="chart"
    ></highcharts>
  </div>
</template>

<script>
export default {
  props: ['chartOption'], 
};
</script>
<style scoped>
.hc {
  min-height: 400px;
}
</style>
