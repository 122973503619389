<template>
  <Layout>
    <div class="cs-modal" v-if="pauseModalIsOpen">
      <div class="cs-modal__body">
        <div class="text-center mt-40">
          <p class="h4  text-primary mb-30">Denemeye Kısa Bir Ara</p>
          <p class="text-muted w-75 mx-auto">
            Anlaşılan kısa bir araya ihtiyacın vardı. Sen geri dönene kadar her
            şey olduğu gibi seni bekleyecek.
          </p>
          <hr class="my-30 w-75 mx-auto" />
          <p class="text-gray-soft h5 mb-40">
            Denemelerini mümkün olduğu kadar ara vermeden çöz!
          </p>
          <button class="btn btn-primary px-30 py-3" @click="continueQuiz">
            Denemeye Devam Et
          </button>
        </div>
      </div>
    </div>
    <div class="cs-modal" v-if="finishModalIsOpen">
      <div class="cs-modal__body">
        <div class="text-center mt-40">
          <p class="h4  text-primary mb-30">Sınavı Tamamlıyoruz</p>

          <p class="text-gray-soft mb-10">
            Henüz cevaplamadığın
            <span class="fw-bold">{{
              questions.length - answersArr.filter((_) => !undefined).length
            }}</span>
            soru var.
          </p>

          <p class="text-gray-soft ">
            Kullanabileceğin <span class="fw-bold">{{ timerFormat }}</span> dk.
            süren var!
          </p>

          <hr class="my-30 w-75 mx-auto" />

          <button class="btn btn-primary px-30 py-15" @click="closeFinishModal">
            Sınava Devam Et
          </button>
          <button class="btn btn-danger px-30 py-15 ml-5" @click="completeQuiz">
            Sınavı Bitir
          </button>
        </div>
      </div>
    </div>
    <div class="cs-modal" v-if="resultModalIsOpen">
      <div class="cs-modal__body py-60">
        <div class="text-center ">
          <p class="h4  text-primary mb-30">Sınav Tamamlandı.</p>
          <p class="text-gray-soft h6 fw-500 mb-10">
            Sonuç sayfasına yönlendiriliyorsunuz...
          </p>
        </div>
      </div>
    </div>
    <QuizCard class="mb-3">
      <template slot="headerRight">
        <div class="fw-500">
          <span class="h6 d-flex align-items-center"
            ><span class="text-muted mr-10">Kalan Süre:</span
            ><span class="timeformat">{{ timerFormat }}</span> dk</span
          >
        </div>
      </template>
      <template slot="footer">
        <ul class="quiz-process-tab mb-3">
          <li
            class="tab-item"
            @click.prevent="setActive('home')"
            :class="{ active: isActive('home') }"
          >
            Anlık Durum
          </li>
          <li
            class="tab-item"
            @click.prevent="setActive('review')"
            :class="{ active: isActive('review') }"
          >
            Gözden Geçir
          </li>
        </ul>
        <div class="quiz-process-tab-content" v-if="activeItem != ''">
          <div class="tab-content" :class="{ 'active show': isActive('home') }">
            <div class="quiz-results-grid">
              <div class="result-item">
                Toplam Soru <span class="text d-block">80</span>
              </div>
              <div class="result-item">
                Cevaplanan Soru <span class="text d-block">12</span>
              </div>
              <div class="result-item">
                Kalan Soru <span class="text d-block">68</span>
              </div>
              <div class="result-item">
                Geçen Süre<span class="text d-block">12.30 dk</span>
              </div>
              <div class="result-item">
                Geçen Süre<span class="text d-block">177.30 dk</span>
              </div>
              <div class="result-item">
                Soru Başına Ort Süre<span class="text d-block">0.00 dk</span>
              </div>
            </div>
          </div>
          <div
            class="tab-content"
            :class="{ 'active show': isActive('review') }"
          >
            <div class="questions-review-grid mb-50">
              <div class="left">
                <div class="text-gray-soft fw-500 text-center mb-30">
                  Lütfen Görüntülemek İstediğiniz Soruyu Seçiniz.
                </div>
                <div class="all-questions-grid">
                  <span
                    class="question-number"
                    v-for="(question, ind) in questions"
                    @click.prevent="reviewIndex = ind"
                    :key="ind"
                    :class="{
                      active: reviewIndex == ind,
                      filled:
                        answersArr[ind] != undefined && answersArr[ind] != '',
                    }"
                    >{{ ind + 1 }}</span
                  >
                </div>
                <ul class="review-descriptions mt-30">
                  <li class="active">Aktif Soru</li>
                  <li class="filled">İşaretlediklerim</li>
                  <li class="empty">Boş Bıraktıklarım</li>
                </ul>
              </div>
              <div class="right">
                <div class="question-subdesc mb-3">
                  <p class="mb-0">
                    1 – 16: For these questions, choose the best word(s) or
                    expression(s) to fill the space(s).
                  </p>
                </div>
                <div class="question-detail-card mb-3">
                  <div
                    class="detail-card__header d-flex align-items-center justify-content-between mb-1"
                  >
                    <span class="question-number primary">{{
                      reviewIndex + 1
                    }}</span>
                  </div>
                  <div>
                    <p>
                      {{ questions[reviewIndex].text }}
                    </p>
                  </div>
                </div>

                <div class="">
                  <div class="row">
                    <div
                      class="col-md-12"
                      v-for="(answer, key) in questions[reviewIndex]['answers']"
                      :key="key"
                    >
                      <label
                        class="wq-option d-flex align-items-center"
                        :for="key"
                        :class="{
                          'hover-option': selectedAnswer == '',
                          selected: answersArr[reviewIndex] == key,
                        }"
                      >
                        <span class="q-number primary position-relative">{{
                          key
                        }}</span>
                        <input
                          type="radio"
                          :id="key"
                          class="hidden"
                          :value="key"
                          @change="answered($event)"
                          v-model="selectedAnswer"
                          :disabled="selectedAnswer != ''"
                        />
                        <span class="flex-grow-1">{{ answer }}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="mt-3" />

        <vue-scroll class="question-numbers my-3" :ops="ops">
          <li
            class="question-number"
            v-for="(question, ind) in questions"
            @click.prevent="index = ind"
            :class="{
              active: index == ind,
              filled: answersArr[ind] != undefined && answersArr[ind] != '',
            }"
            :key="ind"
          >
            {{ ind + 1 }}
          </li>
        </vue-scroll>
        <hr class="my-3" />

        <div class="w-75 mx-auto">
          <div class="question-subdesc mb-3">
            <p class="mb-0">
              1 – 16: For these questions, choose the best word(s) or
              expression(s) to fill the space(s).
            </p>
          </div>
          <div class="question-detail-card mb-3">
            <div
              class="detail-card__header d-flex align-items-center justify-content-between mb-1"
            >
              <span class="question-number primary">{{ index + 1 }}</span>
            </div>
            <div>
              <p>
                {{ questions[index].text }}
              </p>
            </div>
          </div>

          <div class="">
            <div class="row">
              <div
                class="col-md-12"
                v-for="(answer, key) in questions[index]['answers']"
                :key="key"
              >
                <label
                  class="wq-option d-flex align-items-center"
                  :for="key"
                  :class="{
                    'hover-option': selectedAnswer == '',
                    selected: answersArr[index] == key,
                  }"
                >
                  <span class="q-number primary position-relative">{{
                    key
                  }}</span>
                  <input
                    type="radio"
                    :id="key"
                    class="hidden"
                    :value="key"
                    @change="answered($event)"
                    v-model="selectedAnswer"
                    :disabled="selectedAnswer != ''"
                  />
                  <span class="flex-grow-1">{{ answer }}</span>
                </label>
              </div>
            </div>

            <div class="quiz-control-buttons mt-20">
              <button
                class="control-btn prev"
                :class="index == 0 ? 'disabled' : ''"
                :disabled="index == 0"
                @click.prevent="prevQuestion()"
              >
                Önceki Soru
                <span class="ico ml-10">
                  <i class="icon-chevron-down "></i>
                </span>
              </button>
              <button class="pause mx-20" @click="pauseQuiz">
                <svg
                  width="17"
                  height="19"
                  viewBox="0 0 17 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="6" height="19" fill="white" />
                  <rect x="11" width="6" height="19" fill="white" />
                </svg>
              </button>
              <button
                class="control-btn next"
                :disabled="index + 1 == questions.length"
                :class="index + 1 == questions.length ? 'disabled' : ''"
                @click="nextQuestion()"
              >
                <span class="ico mr-10">
                  <i class="icon-chevron-down"></i>
                </span>
                Sonraki Soru
              </button>
            </div>
          </div>
        </div>
      </template>
      <template slot="quizCardRight">
        <button
          class="btn btn-primary shadow-sm w-100 text-white"
          @click="openFinishModal"
        >
          Sınavı Bitir
        </button>
      </template>
    </QuizCard>
  </Layout>
</template>

<script>
import Layout from '../components/Layout.vue';
import QuizCard from '../components/QuizCard.vue';
export default {
  data() {
    return {
      index: 0,
      reviewIndex: 0,
      activeItem: '',
      selectedOption: '',
      timerEnabled: true,
      timerCount: 600,
      pauseModalIsOpen: false,
      finishModalIsOpen: false,
      resultModalIsOpen: false,
      timerFormat: '',
      selectedAnswer: '',

      answersArr: [],

      questions: [
        {
          text: 'Ipsum is a local problem which does not affect distant areas.',
          answers: { a: 'true', b: 'false', c: 'bla', d: 'zula' },
          correctAnswer: 'b',
        },
        {
          text:
            'Desertification is a local problem which does not affect distant areas.',
          answers: { a: 'balkon', b: 'bahce', c: 'ev', d: 'yeşil' },
          correctAnswer: 'b',
        },
        {
          text: 'Lorem is a local problem which does not affect distant areas.',
          answers: { a: 'ev', b: 'araba', c: 'kalem', d: 'test' },
          correctAnswer: 'a',
        },
        {
          text: 'Dolor is a local problem which does not affect distant areas.',
          answers: { a: 'ev', b: 'araba', c: 'kalem', d: 'hedef' },
          correctAnswer: 'd',
        },
        {
          text: 'Dolor is a local problem which does not affect distant areas.',
          answers: { a: 'is', b: 'was', c: 'does', d: 'did' },
          correctAnswer: 'a',
        },
      ],
      ops: {
        rail: {
          enable: false,
        },
        bar: {
          enable: false,
        },
        scrollButton: {
          enable: false,
        },
        scrollPanel: {
          easing: 'easeInQuad',
          speed: 800,
        },
        vuescroll: {
          wheelScrollDuration: 0,
          wheelDirectionReverse: true,
        },
      },
    };
  },
  components: {
    Layout,
    QuizCard,
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount--;
        }, 1000);
      }
    },

    timerCount: {
      handler(value) {
        var minutes = Math.floor(parseInt(value, 10) / 60);
        var seconds = parseInt(value, 10) - minutes * 60;

        this.timerFormat = minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
        if (value > 0 && this.timerEnabled) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else if (value == 0) {
          this.timerEnabled;
          this.completeQuiz();
        }
      },
      immediate: true,
    },
  },
  methods: {
    answered(e) {
      this.selectedAnswer = e.target.value;
      this.answersArr[this.index] = e.target.value;
      setTimeout(() => {
        this.selectedAnswer = '';
        this.nextQuestion();
      }, 500);
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      if (this.activeItem == menuItem) {
        this.activeItem = '';
      } else {
        this.activeItem = menuItem;
      }
    },

    nextQuestion() {
      if (this.index < this.questions.length - 1) {
        this.index++;
      }
      this.selectedAnswer = '';
    },
    prevQuestion() {
      if (this.index > 0) {
        this.index--;
      }
      this.selectedAnswer = '';
    },

    play() {
      this.timerEnabled = true;
    },
    pause() {
      this.timerEnabled = false;
    },

    continueQuiz() {
      this.play();
      this.pauseModalIsOpen = false;
    },
    pauseQuiz() {
      this.pause();
      this.pauseModalIsOpen = true;
    },

    openFinishModal() {
      this.pause();
      this.finishModalIsOpen = true;
    },
    closeFinishModal() {
      this.play();
      this.finishModalIsOpen = false;
    },

    completeQuiz() {
      //do something
      this.finishModalIsOpen = false;
      this.resultModalIsOpen = true;
      setTimeout(() => {
        this.$router.push('sinavlar');
      }, 2000);
    },
  },
};
</script>
<style>
.timeformat {
  min-width: 50px;
  text-align: center;
}
</style>
