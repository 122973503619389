<template>
  <Layout>
    <div
      class="quiz-overlay"
      :class="{ active: liveStatusIsOpen || reviewIsOpen }"
      @click="closeLeftMenus"
    ></div>
    <div class="live-status" :class="{ active: liveStatusIsOpen }">
      <div
        class="live-status-header d-flex align-items-center justify-content-between mb-3"
      >
        <span class="h4 mb-0">ANLIK DURUM</span>
        <button
          class="btn btn-danger rounded shadow-sm px-3"
          @click="liveStatusIsOpen = false"
        >
          KAPAT
        </button>
      </div>
      <div class="live-status-content">
        <div class="row text-center live-status-table mx-0 mb-30">
          <div class="col-12 px-0 head ">Test Bilgileri</div>
          <div class="col-3 px-0 head ">Test Adı</div>
          <div class="col-3 px-0 head ">Toplam S. Sayısı</div>
          <div class="col-3 px-0 head ">Cevaplanmış S. Sayısı</div>
          <div class="col-3 px-0 head ">Kalan S. Sayısı</div>

          <div class="col-3 px-0 cell big-font">İNGİLİZCE</div>
          <div class="col-3 px-0 cell big-font">{{ questions.length }}</div>
          <div class="col-3 px-0 cell big-font">
            {{ answersArr.filter((_) => !undefined).length }}
          </div>
          <div class="col-3 px-0 cell big-font">
            {{ questions.length - answersArr.filter((_) => !undefined).length }}
          </div>
        </div>

        <div class="row text-center live-status-table mx-0 mb-30">
          <div class="col-12 px-0 head">Anlık Süre Bilgileri</div>
          <div class="col-4 px-0 head">Başlama Saati</div>
          <div class="col-4 px-0 head">Bitiş Saati</div>
          <div class="col-4 px-0 head">Sınav Süresi</div>

          <div class="col-4 px-0 cell big-font">11:12:11</div>
          <div class="col-4 px-0 cell big-font">14.:12:11</div>
          <div class="col-4 px-0 cell big-font">180 dk</div>

          <div class="col-4 px-0 head">Geçen Süre</div>
          <div class="col-4 px-0 head">Kalan Süre</div>
          <div class="col-4 px-0 head">Soru Başına Ort. Süre</div>

          <div class="col-4 px-0 cell big-font">88 dk 29 sn</div>
          <div class="col-4 px-0 cell big-font">91 dk 30sn</div>
          <div class="col-4 px-0 cell big-font">0 dk</div>
        </div>
      </div>
    </div>

    <div class="review-grid" :class="{ active: reviewIsOpen }">
      <div class="left">
        <div
          class="live-status-header d-flex align-items-center justify-content-between mb-3"
        >
          <span class="h4 mb-0">GÖZDEN GEÇİR</span>
          <button
            class="btn btn-danger rounded shadow-sm px-3"
            @click="reviewIsOpen = false"
          >
            KAPAT
          </button>
        </div>
        <div class="live-status-content">
          <ul class="q-filter-tab mb-3">
            <li
              :class="{ active: isActiveTab('all') }"
              @click="setActiveTab('all')"
            >
              Tüm Sorular
            </li>
            <li
              class="dot answered"
              :class="{ active: isActiveTab('answered') }"
              @click="setActiveTab('answered')"
            >
              Cevapladıklarım
            </li>
            <li
              :class="{ active: isActiveTab('marked') }"
              @click="setActiveTab('marked')"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.72494 17.3985C4.63081 17.4614 4.52134 17.4977 4.40822 17.5032C4.29511 17.5088 4.18261 17.4836 4.08274 17.4301C3.98286 17.3767 3.89938 17.2972 3.84121 17.2C3.78303 17.1029 3.75235 16.9917 3.75244 16.8785V5.01473C3.75244 4.35256 4.01515 3.71743 4.48291 3.24873C4.95067 2.78003 5.58527 2.51606 6.24744 2.51473L13.7474 2.50098C14.0757 2.50032 14.401 2.56434 14.7045 2.68937C15.0081 2.8144 15.2841 2.998 15.5167 3.22968C15.7493 3.46136 15.934 3.73659 16.0602 4.03965C16.1865 4.34272 16.2518 4.66768 16.2524 4.99598V16.8785C16.2525 16.9917 16.2219 17.1029 16.1637 17.2C16.1055 17.2972 16.022 17.3767 15.9221 17.4301C15.8223 17.4836 15.7098 17.5088 15.5967 17.5032C15.4835 17.4977 15.3741 17.4614 15.2799 17.3985L10.0024 13.8735L4.72369 17.3985H4.72494Z"
                  fill="#FE9A38"
                />
              </svg>

              İşaretlediklerim
            </li>
            <li
              class="dot empty"
              :class="{ active: isActiveTab('empty') }"
              @click="setActiveTab('empty')"
            >
              Boş Bıraktıklarım
            </li>
          </ul>
          <ul class="review-nums">
            <li
              class="review-num"
              v-for="(question, ind) in questions"
              :key="ind"
              :class="{
                answered: answersArr[ind] != undefined,
                marked: ind % 2 == 0,
                active: ind == index,
              }"
              @click="goQuestion(ind)"
              @mouseover="showRight(ind)"
            >
              {{ ind + 1 }}
            </li>
          </ul>
        </div>
      </div>
      <div class="right" v-if="showRightReview">
        <div class="exam-container">
          <div class="q-alert q-alert__info mb-3">
            7-16 sorularda, cümlede boş bırakılan yerlere uygun düşen sözcük ya
            da ifadeyi bulunuz.
          </div>
          <p class="text">
            {{ questions[index].text }}
          </p>
          <div class="exam-options">
            <label
              class="exam-option"
              v-for="(answer, key) in questions[index].answers"
              :key="key"
              :for="key"
              :class="{
                selected: answersArr[index] == key,
              }"
            >
              <span class="opt-key text-uppercase">{{ key }}</span>
              <span class="opt-text">{{ answer }}</span>
              <input
                type="radio"
                :id="key"
                class="hidden"
                :value="key"
                @change="answered($event)"
                v-model="selectedAnswer"
              />
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="bla-card">
      <div class="bla-card__header">
        <div class="first">
          Elektronik Sınav Uygulaması - (EYDS - 2014 - 99 - 1)
        </div>
        <div class="second">
          <div class="left">
            <div
              class="user-img"
              style="background-image:url('https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80')"
            ></div>
            <span class="text ml-5">Mustafa Özgün</span>
          </div>
          <div class="center">
            <ul class="quiz-cta-btns">
              <li @click="liveStatusIsOpen = true">
                <i class="icon-time"></i>

                <span class="txt ml-5">Anlık Durum</span>
              </li>
              <li @click="reviewIsOpen = true">
                <i class="icon-book"></i>
                <span class="txt ml-5">Gözden Geçir</span>
              </li>
              <li>
                <i class="icon-check-stroke"></i>
                <span class="txt ml-5">Sınavı Sonlandır</span>
              </li>
            </ul>
          </div>
          <div class="right fw-bold">
            <div class="mr-10" v-show="timeVisible">
              <span class="text-gray-soft">
                Kalan Süre:
                <span class="text-navy fw-900">19:00</span>
              </span>
              <span class="text-gray-soft ml-3">
                Saat:
                <span class="text-navy fw-900">1:55:00</span>
              </span>
            </div>
            <button
              class="btn-visible ml-auto"
              @click="timeVisible = !timeVisible"
            >
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                v-show="timeVisible"
              >
                <path
                  d="M0.854021 0.146143C0.807533 0.0996553 0.752344 0.0627792 0.691605 0.0376201C0.630865 0.012461 0.565765 -0.000488281 0.500021 -0.000488281C0.434277 -0.000488282 0.369177 0.012461 0.308438 0.0376201C0.247698 0.0627792 0.192509 0.0996553 0.146021 0.146143C0.0995332 0.192631 0.0626571 0.24782 0.037498 0.30856C0.0123389 0.369299 -0.000610352 0.434399 -0.000610352 0.500143C-0.000610351 0.565887 0.0123389 0.630987 0.037498 0.691727C0.0626571 0.752466 0.0995332 0.807655 0.146021 0.854143L3.64602 4.35214C1.91259 5.53447 0.705829 7.34355 0.280021 9.39814C0.266626 9.46242 0.266024 9.52872 0.278249 9.59323C0.290473 9.65775 0.315285 9.71922 0.351268 9.77415C0.38725 9.82907 0.433699 9.87637 0.487962 9.91335C0.542225 9.95032 0.603239 9.97625 0.667521 9.98964C0.731803 10.003 0.798094 10.0036 0.862609 9.99142C0.927124 9.97919 0.988599 9.95438 1.04352 9.9184C1.09845 9.88241 1.14575 9.83596 1.18273 9.7817C1.2197 9.72744 1.24563 9.66643 1.25902 9.60214C1.44991 8.67863 1.82311 7.80248 2.35678 7.02498C2.89045 6.24748 3.57387 5.58426 4.36702 5.07414L5.95002 6.65614C5.53745 6.95207 5.1942 7.33422 4.94409 7.77607C4.69398 8.21793 4.543 8.70891 4.50162 9.21495C4.46025 9.72099 4.52946 10.23 4.70447 10.7066C4.87948 11.1832 5.15609 11.616 5.51511 11.9751C5.87413 12.3341 6.30696 12.6107 6.78357 12.7857C7.26019 12.9607 7.76917 13.0299 8.27522 12.9885C8.78126 12.9472 9.27224 12.7962 9.71409 12.5461C10.1559 12.296 10.5381 11.9527 10.834 11.5401L15.147 15.8541C15.2409 15.948 15.3682 16.0008 15.501 16.0008C15.6338 16.0008 15.7611 15.948 15.855 15.8541C15.9489 15.7603 16.0017 15.6329 16.0017 15.5001C16.0017 15.3674 15.9489 15.24 15.855 15.1461L0.855021 0.146143H0.854021Z"
                  fill="#717B96"
                />
                <path
                  d="M8.12402 6.00293L11.487 9.36593C11.4532 8.48483 11.088 7.64897 10.4645 7.02547C9.84098 6.40198 9.00513 6.03679 8.12402 6.00293V6.00293Z"
                  fill="#717B96"
                />
                <path
                  d="M5.53101 3.41L6.33401 4.213C6.87807 4.07168 7.43789 4.00011 8.00001 4C11.206 4 14.057 6.327 14.74 9.602C14.7671 9.73196 14.8446 9.84584 14.9556 9.91861C15.0667 9.99137 15.2021 10.0171 15.332 9.99C15.462 9.96295 15.5758 9.88538 15.6486 9.77436C15.7214 9.66334 15.7471 9.52796 15.72 9.398C14.943 5.673 11.693 3 8.00001 3C7.14501 3 6.31301 3.143 5.53101 3.41V3.41Z"
                  fill="#717B96"
                />
              </svg>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                v-show="!timeVisible"
              >
                <path
                  d="M3.72998 10.7812V10.785C3.70893 10.8644 3.67243 10.9389 3.62258 11.0041C3.57273 11.0694 3.5105 11.1242 3.43946 11.1654C3.36841 11.2067 3.28993 11.2335 3.20852 11.2443C3.1271 11.2552 3.04435 11.2499 2.96498 11.2287C2.42623 11.0862 2.52123 10.465 2.52123 10.465L2.54248 10.3875C2.54248 10.3875 2.57498 10.2825 2.60123 10.2062C2.922 9.30686 3.39423 8.46895 3.99748 7.72875C5.12123 6.36125 7.00748 5 9.99998 5C12.9937 5 14.88 6.36125 16.0012 7.72875C16.6045 8.46895 17.0767 9.30686 17.3975 10.2062C17.4244 10.2835 17.4494 10.3615 17.4725 10.44L17.4762 10.4562L17.4787 10.4613V10.4638C17.5184 10.6228 17.4939 10.7911 17.4107 10.9324C17.3274 11.0736 17.1919 11.1764 17.0336 11.2187C16.8752 11.261 16.7065 11.2394 16.5639 11.1585C16.4213 11.0776 16.3162 10.9439 16.2712 10.7862L16.27 10.7812L16.26 10.75C16.189 10.5288 16.1056 10.3117 16.01 10.1C15.7551 9.53352 15.4274 9.00277 15.035 8.52125C14.1062 7.39 12.555 6.25 9.99998 6.25C7.44498 6.25 5.89248 7.39 4.96498 8.52125C4.45562 9.14686 4.0562 9.85442 3.78373 10.6138C3.76828 10.6589 3.7537 10.7043 3.73998 10.75L3.72998 10.7812V10.7812Z"
                  fill="#717B96"
                />
                <path
                  d="M6.875 11.875C6.875 11.0462 7.20424 10.2513 7.79029 9.66529C8.37634 9.07924 9.1712 8.75 10 8.75C10.8288 8.75 11.6237 9.07924 12.2097 9.66529C12.7958 10.2513 13.125 11.0462 13.125 11.875C13.125 12.7038 12.7958 13.4987 12.2097 14.0847C11.6237 14.6708 10.8288 15 10 15C9.1712 15 8.37634 14.6708 7.79029 14.0847C7.20424 13.4987 6.875 12.7038 6.875 11.875Z"
                  fill="#717B96"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="bla-card__body">
        <div class="bla-card__top">
          <span class="q-num">{{ index + 1 }}.</span>

          <div
            class="q-bookmark mb-3"
            @click="setBookmark(false)"
            v-show="bookmarkActive"
          >
            <svg
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.8674 40.0167C10.6509 40.1615 10.3991 40.2448 10.1389 40.2576C9.87875 40.2705 9.61999 40.2124 9.39029 40.0895C9.16059 39.9667 8.96858 39.7838 8.83477 39.5603C8.70097 39.3368 8.6304 39.0812 8.63062 38.8207V11.5341C8.63061 10.0111 9.23484 8.55028 10.3107 7.47228C11.3865 6.39427 12.8461 5.78712 14.3691 5.78408L31.6191 5.75245C32.3742 5.75094 33.1222 5.89817 33.8204 6.18574C34.5186 6.47331 35.1533 6.89558 35.6884 7.42845C36.2234 7.96132 36.6482 8.59435 36.9385 9.2914C37.2289 9.98844 37.3791 10.7359 37.3806 11.491V38.8207C37.3808 39.0812 37.3103 39.3368 37.1765 39.5603C37.0427 39.7838 36.8506 39.9667 36.6209 40.0895C36.3912 40.2124 36.1325 40.2705 35.8723 40.2576C35.6122 40.2448 35.3604 40.1615 35.1439 40.0167L23.0056 31.9092L10.8645 40.0167H10.8674Z"
                fill="#FE9A38"
              />
            </svg>
          </div>
          <div
            class="q-bookmark mb-3"
            @click="setBookmark(true)"
            v-show="!bookmarkActive"
          >
            <svg
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.8674 40.0172C10.6509 40.162 10.3991 40.2453 10.1389 40.2581C9.87875 40.271 9.61999 40.2129 9.39029 40.09C9.16059 39.9672 8.96858 39.7843 8.83477 39.5608C8.70097 39.3373 8.6304 39.0817 8.63062 38.8212V11.5346C8.63061 10.0116 9.23484 8.55077 10.3107 7.47276C11.3865 6.39476 12.8461 5.78761 14.3691 5.78457L31.6191 5.75294C32.3742 5.75143 33.1222 5.89866 33.8204 6.18623C34.5186 6.4738 35.1533 6.89607 35.6884 7.42894C36.2234 7.96181 36.6482 8.59484 36.9385 9.29189C37.2289 9.98893 37.3791 10.7363 37.3806 11.4914V38.8212C37.3808 39.0817 37.3103 39.3373 37.1765 39.5608C37.0427 39.7843 36.8506 39.9672 36.6209 40.09C36.3912 40.2129 36.1325 40.271 35.8723 40.2581C35.6122 40.2453 35.3604 40.162 35.1439 40.0172L23.0056 31.9097L10.8645 40.0172H10.8674ZM34.5027 11.5001L34.4826 11.1608C34.3995 10.4615 34.0626 9.817 33.5359 9.34959C33.0091 8.88218 32.3291 8.62437 31.6249 8.62507L14.3749 8.65382C13.6134 8.65534 12.8836 8.95891 12.3456 9.49792C11.8077 10.0369 11.5056 10.7673 11.5056 11.5288V36.1302L22.2064 28.9829C22.4426 28.8253 22.7202 28.7412 23.0042 28.7412C23.2882 28.7412 23.5658 28.8253 23.802 28.9829L34.5056 36.1302V11.5001H34.5027Z"
                fill="black"
              />
            </svg>
          </div>
        </div>

        <div class="exam-container">
          <div class="q-alert q-alert__info mb-3">
            7-16 sorularda, cümlede boş bırakılan yerlere uygun düşen sözcük ya
            da ifadeyi bulunuz.
          </div>
          <p class="text">{{ questions[index].text }}</p>
          <div class="exam-options">
            <label
              class="exam-option"
              v-for="(answer, key) in questions[index].answers"
              :key="key"
              :class="{
                selected: answersArr[index] == key,
              }"
            >
              <span class="opt-key text-uppercase">{{ key }}</span>
              <span class="opt-text">{{ answer }}</span>
              <input
                type="radio"
                :id="key"
                class="hidden"
                :value="key"
                @change="answered($event)"
                v-model="selectedAnswer"
              />
            </label>
          </div>
        </div>

        <div class="q-nav-btns">
          <span
            class="q-nav-btn q-nav-btn-prev"
            @click="prevQuestion"
            v-show="index != 0"
          >
            <i class="icon-left-arrow"></i>
            <span class="text">Önceki Soru</span>
          </span>
          <span
            class="q-nav-btn q-nav-btn-next"
            @click="nextQuestion"
            v-show="index + 1 != questions.length"
          >
            <i class="icon-right-arrow"></i>
            <span class="text">Sonraki Soru</span>
          </span>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../components/Layout.vue';
export default {
  components: { Layout },

  data() {
    return {
      index: 0,
      selectedAnswer: '',
      timeVisible: true,
      liveStatusIsOpen: false,
      reviewIsOpen: false,
      activeFilterTab: 'all',
      showRightReview: false,
      answersArr: [],
      bookmarkArr: [],
      bookmarkActive: false,
      questions: [
        {
          text:
            'Desertification is a local problem which does not affect distant areas.',
          answers: {
            a: 'generally',
            b: 'decisively',
            c: 'externally',
            d: 'primarly',
            e: 'respectively',
          },
          correctAnswer: 'b',
        },
        {
          text:
            ' A series of gas discoveries in recent years in the Western Desert of Egypt means that a range of new export projects must be developed the country is to make full use of its new reserves.',
          answers: {
            a: 'decisively',
            b: 'generally',
            c: 'primarly',
            d: 'externally',
            e: 'respectively',
          },
          correctAnswer: 'b',
        },

        {
          text: 'Lorem is a local problem which does not affect distant areas.',
          answers: { a: 'ev', b: 'araba', c: 'kalem', d: 'test' },
          correctAnswer: 'a',
        },
        {
          text: 'Dolor is a local problem which does not affect distant areas.',
          answers: { a: 'ev', b: 'araba', c: 'kalem', d: 'hedef' },
          correctAnswer: 'd',
        },
        {
          text: 'Sunt enim cillum commodo mollit mollit mollit irure.',
          answers: { a: 'is', b: 'was', c: 'does', d: 'did' },
          correctAnswer: 'a',
        },
      ],
    };
  },
  methods: {
    answered(e) {
      this.selectedAnswer = e.target.value;
      this.answersArr[this.index] = e.target.value;
    },

    setBookmark(status) {
      this.bookmarkActive = status;
      this.bookmarkArr[this.index] = status;
    },

    closeLeftMenus() {
      this.liveStatusIsOpen = false;
      this.reviewIsOpen = false;
    },
    isActiveTab(menuItem) {
      return this.activeFilterTab === menuItem;
    },
    setActiveTab(menuItem) {
      this.activeFilterTab = menuItem;
    },
    setIndex(n) {
      this.index = n;
    },
    nextQuestion() {
      this.index++;
    },
    prevQuestion() {
      this.index--;
    },
    showRight(num) {
      this.setIndex(num);
      this.showRightReview = true;
    },
    goQuestion(qNum) {
      this.reviewIsOpen = false;
      this.setIndex(qNum);
    },
    addActive() {
      if (this.bookmarkArr[this.index] == true) {
        this.bookmarkActive = true;
      } else {
        this.bookmarkActive = false;
      }
    },
  },
  computed: {
    // filteredQuestions() {
    //   if (!this.filter) {
    //     return this.questions;
    //   }
    //   return this.answersArr.filter((answer) => answer.status == this.filter);
    // },
  },
  created() {
    this.questions.forEach((elm, index) => {
      this.answersArr[index] = '';
      this.bookmarkArr[index] = false;
    });
  },
};
</script>

<style></style>
