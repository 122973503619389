// TR
export const locale = {
    common: {
        all: "Tümü",
        close: "Kapat",
        correct: "Doğru",
        wrong: "Yanlış",
        yes: "Evet",
        no: "Hayır",
        downloadDocument: "Dökümanı İndir"
    },
    messages: {
        warning: "Uyarı",
        success: "Başarılı",
        error: "Hata",
        sureAction: "İşlemi yapmak istediğinize emin misiniz?",
        sureConfirm: "Onaylamak istediğinize emin misiniz?",
        sureDelete: "Silme işlemi yapmak istediğinize emin misiniz?",
        sureCancel: "İptal işlemi yapmak istediğinize emin misiniz?",
        actionOk: "İşleminiz başarılı bir şekilde tamamlanmıştır.",
        deleteOk: "Silme işleminiz başarılı bir şekilde tamamlanmıştır.",
        cancelOk: "İptal işleminiz başarılı bir şekilde tamamlanmıştır.",
        selectRow: "Lütfen bir kayıt seçiniz!",
        wait: "Lütfen Bekleyiniz!",
        loading: "Yükleniyor",
        noResultFound: "Sonuç bulunamadı!",
        notFoundContent: "Görüntülenecek içerik bulunamamıştır!"
    },
    menu: {
        superead: "Süper Hızlı Okuma",
        full_paragraph_question: "Fulleten Paragraf Soruları"
    },
    ecommerce: {
        order: {
            shipping: {
                detail: {
                    title: "Kargo Detayları",
                    receivedStatus: {
                        yes: "Teslim Edildi",
                        no: "Henüz Teslim Edilmedi"
                    },
                    cols: {
                        orderNo: "Sipariş No",
                        received: "Durum",
                        receivingPerson: "Teslim Alan",
                        receivingDate: "Teslim Alınan Tarih",
                    },
                    movement: {
                        title: "Kargo Hareketleri",
                        status: {
                            accepted: "Kabul Edildi",
                            atSenderBranch: "Gönderici Şubesinde",
                            onTheRoad: "Yolda",
                            atReceiverBranch: "Alıcı Şubesinde",
                            delivered: "Teslim Edildi"
                        }
                    },
                }
            }
        }
    },
    module: {
        exam: {
            joinExam: {
                title: " TÜRKİYE GENELİ EKİM 2022 YDS DENEME IV",
                description_old: "Akın Dil Eğitiminin 23 Ekim 2022 YDS’ye yönelik uzman kadrosunun hazırlamış olduğu bu deneme sınavı ÖSYM’nin yapmış olduğu son iki sınavdaki soruların ağırlığı ve mantığına göre hazırlanmış olup mümkün olduğunca adayların gerçek bir sınav atmosferi ve mantığını kavraması için hazırlanmıştır. Sınavı bitirdiğinizde hangi bölümde ne kadar yanlışınız var, hangi bölümde süre kaybınız var bunların analizini yapıp eksik olan yerleri gözden geçirmenizi tavsiye ederiz. Ayrıca çözümleri izleyip soruya doğru bir yaklaşımla yaklaşıp yaklaşmadığınızı test edip, eksik olan yerleri de tamamlayınız.</br></br>Sınav 80 sorudan oluşmaktadır.</br></br>Süre 180 dakikadır.\nBaşarılar dileriz..</br></br></br>Sınav oturumuna katılmak istediğinize emin misiniz?",
                description: "Sınav oturumuna katılmak istediğinize emin misiniz?"
            },
            joinDescription: ""
        },
        paragraph: {
            translate: "Çevir",
            translated: "Çevrildi",
            finishRead: "Okumayı Tamamla",
            cols: {
                readingTime: "Tahmini Okuma Süresi",
                readingTimeFormat: "Okuma Süresi"
            },
            question: {
                title: "Paragraf <span class=\"text-primary\">Soruları</span>",
                previousQuestion: "Önceki Soru",
                nextQuestion: "Sonraki Soru",
                continueQuestion: "Soruları çözmeye zaten başlamıştınız, kaldığınız yerden <span class=\"text-primary fw-500\">devam</span> etmek ister misiniz ?",
                solveQuestion: {
                    title: "Hemen Başla!",
                    description: "Bu paragrafın cevaplanmayı bekleyen %d sorusu var. Hemen <span class=\"text-primary fw-500\">çözmek</span> ister misin?",
                    again: "Evet, tekrar başla!",
                    againDescription: "Bu Quiz'i tamamladın! Kendini <span class=\"text-primary fw-500\">yeniden denemek</span> ister misin?"
                },
                finishQuiz: "Quiz'i Bitir"
            },
            list: {
                tabs: {
                    easy: "Kolay",
                    medium: "Orta",
                    hard: "Zor"
                }
            }
        },
        popup: {
            dontShowAgain: "Tekrar Gösterme"
        },
        full_paragraph_question: {
            title: "Fulleten Paragraf Soruları",
            htmlTitle: "Fulleten <span class=\"text-red\">Paragraf Soruları</span>"
        }
    },
    responseErrors: {
        validate_error: "Doğrulama Hatası",
    }
};