<template>
  <div class="w-100 d-flex flex-column align-items-center">
    <span class="fw-500 ml-10">{{ progressValue }}% tamamlandı</span>

    <div class="progress w-100" :style="styleHeight">
      <div
        :class="progressBg"
        class="progress-bar"
        role="progressbar"
        :style="style"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    progressBg: {
      default: 'bg-primary',
      type: String,
    },
    progressValue: [Number, String],
    progressHeight: {
      type: String,
      default: '10',
    },
  },
  computed: {
    style() {
      return 'width: ' + this.progressValue + '%';
    },
    styleHeight() {
      return 'height: ' + this.progressHeight + 'px';
    },
  },
};
</script>

<style></style>
