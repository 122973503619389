<template>
  <LayoutUser>
    <p class="h5 mb-20">
      Adreslerim
    </p>
    <div v-if="userAddresses">
      <div class="row">
        <div class="col-md-3 mb-10">
          <router-link
            :to="{ name: 'account-address-create' }"
            class="d-flex flex-column align-items-center justify-content-center address-card"
          >
            <p class="h5 text-center">
              <i class="icon-plus h4 d-block mb-20"></i>
              Yeni Adres Oluştur
            </p>
          </router-link>
        </div>
        <div
          class="col-md-3 mb-10"
          v-for="address in userAddresses"
          :key="address.id"
        >
          <div
            class="address-card"
            :class="address.is_default ? 'active' : 'text-muted'"
            @click="activeAddressId = address.id"
          >
            <span
              class="badge badge-primary checkmark"
              v-if="address.is_default"
              >Aktif</span
            >
            <p class="fw-600 mb-5 text-primary w-75">{{ address.title }}</p>
            <p class="mb-155">{{ address.full_name }}</p>
            <p class="mb-5 address-title ">
              {{ address.district + ' ' + address.address }}
            </p>
            <div class="d-flex align-items-center address-cta-btns">
              <router-link
                :to="{ name: 'address_details', params: { id: address.id } }"
                title="Adresi Düzenlemek için Tıkla"
                type="button"
                class="mr-10"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.16667 3.33333H3.33334C2.89131 3.33333 2.46739 3.50893 2.15483 3.82149C1.84227 4.13405 1.66667 4.55797 1.66667 5V16.6667C1.66667 17.1087 1.84227 17.5326 2.15483 17.8452C2.46739 18.1577 2.89131 18.3333 3.33334 18.3333H15C15.442 18.3333 15.866 18.1577 16.1785 17.8452C16.4911 17.5326 16.6667 17.1087 16.6667 16.6667V10.8333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.4167 2.08333C15.7482 1.75181 16.1978 1.56557 16.6667 1.56557C17.1355 1.56557 17.5852 1.75181 17.9167 2.08333C18.2482 2.41485 18.4344 2.86449 18.4344 3.33333C18.4344 3.80217 18.2482 4.25181 17.9167 4.58333L10 12.5L6.66667 13.3333L7.50001 10L15.4167 2.08333Z"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                Düzenle
              </router-link>
              <button @click="deleteUserAddress(address.id)" class="ml-5">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.5 3.33331L4.41917 15.0633C4.51858 15.6711 4.78441 16.2394 5.18716 16.7052C5.58991 17.1711 6.11384 17.5163 6.70083 17.7025L6.97417 17.7891C8.94297 18.4142 11.057 18.4142 13.0258 17.7891L13.2992 17.7025C13.886 17.5163 14.4099 17.1713 14.8126 16.7056C15.2154 16.2399 15.4813 15.6717 15.5808 15.0641L17.5 3.33331"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 4.99999C14.1421 4.99999 17.5 4.2538 17.5 3.33332C17.5 2.41285 14.1421 1.66666 10 1.66666C5.85786 1.66666 2.5 2.41285 2.5 3.33332C2.5 4.2538 5.85786 4.99999 10 4.99999Z"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Sil
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </LayoutUser>
</template>

<script>
import LayoutUser from '../components/LayoutUser.vue';

export default {
  data() {
    return {
      userAddresses: '',
      is_default: '',
    };
  },

  components: { LayoutUser },

  methods: {
    fetchUserAddresses() {
      this.axios.get('/user/address').then((response) => {
        this.userAddresses = response.data.data;
      });
    },
    deleteUserAddress(id) {
      this.axios.delete(`/user/address/${id}`).then(() => {
        this.$notify({
          title:
            '<div class="d-flex align-items-center"> <i class="icon-check-stroke h4 mb-0 mr-5"></i> Başarılı</div>',
          type: 'success',
        });
      });
    },
  },
  created() {
    this.fetchUserAddresses();
  },
  // watch: {
  //   userAddresses() {
  //     this.fetchUserAddresses();
  //   },
  // },
};
</script>

<style></style>
