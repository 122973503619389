<template>
  <Layout>
    <vue-plyr ref="plyr" :options="options">
      <video controls playsinline data-poster="poster.jpg">
        <source
          size="720"
          src="https://www.w3schools.com/html/mov_bbb.mp4"
          type="video/mp4"
        />
        <source
          size="1080"
          src="https://www.w3schools.com/html/mov_bbb.mp4"
          type="video/mp4"
        />
        <track
          default
          kind="captions"
          label="English captions"
          src="/path/to/english.vtt"
          srclang="en"
        />
      </video>
    </vue-plyr>
  </Layout>
</template>

<script>
import Layout from '../components/Layout.vue';

export default {
  components: { Layout },
  data() {
    return {
      options: {
        controls: [
          'play-large',
          'play',
          'progress',
          'current-time',
          'mute',
          'volume',
          'settings',
          'pip',
          'airplay',
          'fullscreen',
        ],
        settings: ['quality', 'speed', 'loop'],
        seekTime: 7,
        tooltips: { controls: true, seek: true },
        speed: { selected: 1, options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 4] },
      },
    };
  },
  mounted() {
    console.log(this.$refs.plyr.player);
  },
};
</script>

<style scoped>
.lineThrough {
  text-decoration: line-through;
  opacity: 0.6;
}
.highlight {
  background-color: yellow;
}
.highlight::selection {
  background-color: yellow;
}

.box {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border-top-right-radius: 0;
  background-color: tomato;
  position: relative;
}
.box::before {
  content: '';
  display: inline-block;
  right: 0;
  width: 25px;
  height: 40px;
  position: absolute;
  top: -42px;
  background-color: tomato;
  border-radius: 25px;
}
.box1 {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border-top-left-radius: 0;
  background-color: wheat;
  position: relative;
}
.box1::before {
  content: '';
  display: inline-block;
  left: 0;
  width: 25px;
  height: 40px;
  position: absolute;
  top: -42px;
  background-color: wheat;
  border-radius: 25px;
}
</style>
