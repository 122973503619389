<template>
    <div class="mainWrap">
        <div class="mainWrap-left" :class="isActive ? 'active' : ''">
            <ul class="left-menu">
                <li class="left-menu__item">
                    <a :href="this.$root.getWebSiteURL()" to="/" class="left-menu__link">
                        <img src="../assets/img/logo.png" alt="" width="120"/>
                    </a>
                </li>

                <li class="left-menu__item">
                    <router-link to="/" class="left-menu__link">
                        <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M23.0029 16.0248L15.5521 9.60679C15.3957 9.48109 15.2013 9.4126 15.0009 9.4126C14.8006 9.4126 14.6063 9.48109 14.4499 9.60679L6.99908 16.0248C6.67078 16.338 6.47504 16.7659 6.45239 17.22V24.8773C6.45238 25.0683 6.52735 25.2516 6.66104 25.3875C6.79474 25.5234 6.97639 25.6009 7.16661 25.6032H12.0868C12.2785 25.6032 12.4624 25.5267 12.598 25.3907C12.7336 25.2545 12.8098 25.0699 12.8098 24.8773V19.5659C12.8098 19.3733 12.886 19.1886 13.0216 19.0526C13.1572 18.9165 13.3411 18.84 13.5329 18.84H16.5926C16.7842 18.84 16.9682 18.9165 17.1038 19.0526C17.2394 19.1886 17.3156 19.3733 17.3156 19.5659V24.8773C17.3156 25.0699 17.3918 25.2545 17.5273 25.3907C17.663 25.5267 17.8468 25.6032 18.0386 25.6032H22.8265C23.0183 25.6032 23.2022 25.5267 23.3378 25.3907C23.4734 25.2545 23.5495 25.0699 23.5495 24.8773V17.2377C23.5314 16.7773 23.3353 16.3424 23.0029 16.0248ZM14.9966 16.317C14.6966 16.317 14.4033 16.2277 14.154 16.0603C13.9046 15.893 13.7102 15.6552 13.5954 15.3771C13.4806 15.0988 13.4505 14.7926 13.5091 14.4973C13.5676 14.2019 13.712 13.9306 13.9242 13.7177C14.1362 13.5048 14.4065 13.3597 14.7006 13.301C14.9949 13.2422 15.2998 13.2724 15.5769 13.3876C15.8541 13.5029 16.091 13.698 16.2576 13.9484C16.4242 14.1988 16.5132 14.4932 16.5132 14.7944C16.5167 14.9961 16.48 15.1966 16.4053 15.3839C16.3305 15.5712 16.2192 15.7417 16.0779 15.8852C15.9366 16.0286 15.7682 16.1424 15.5825 16.2196C15.3967 16.2968 15.1975 16.3359 14.9966 16.3347V16.317Z"
                                fill="#577FE0"
                            />
                            <path
                                d="M23.6025 5.50824V9.51842L20.3136 6.66792V5.50824C20.3136 5.31572 20.3898 5.13108 20.5254 4.99495C20.661 4.85882 20.8448 4.78234 21.0366 4.78234H22.8883C23.0785 4.78466 23.2602 4.86217 23.3938 4.99805C23.5276 5.13393 23.6025 5.31724 23.6025 5.50824ZM5.43847 15.6798L14.9967 7.44694L24.5637 15.6709C24.8501 15.9315 25.228 16.0672 25.6141 16.0481C26.0002 16.029 26.363 15.8567 26.6226 15.5691C26.8821 15.2815 27.0172 14.9022 26.9982 14.5145C26.9792 14.1268 26.8076 13.7626 26.5211 13.502L15.9401 4.38397C15.6718 4.13701 15.321 4 14.957 4C14.5929 4 14.2421 4.13701 13.9738 4.38397L3.48099 13.502C3.19402 13.7631 3.02175 14.1276 3.00192 14.5158C2.98209 14.9041 3.11631 15.2844 3.37518 15.5736C3.64895 15.8373 4.00819 15.9931 4.38711 16.0126C4.76602 16.0322 5.13927 15.9139 5.43847 15.6798Z"
                                fill="#FF8084"
                            />
                        </svg>
                        Anasayfa
                    </router-link>
                </li>
                <li class="left-menu__item">
                    <router-link to="/derslerim" class="left-menu__link">
                        <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clip-path="url(#clip0_328_223)">
                                <path
                                    d="M20.2183 5.75391H10.3572C7.95079 5.75391 6 7.55042 6 9.76652V23.9867C6 26.2028 7.95079 27.9993 10.3572 27.9993H20.2183C22.6247 27.9993 24.5755 26.2028 24.5755 23.9867V9.76652C24.5755 7.55042 22.6247 5.75391 20.2183 5.75391Z"
                                    fill="#3271E6"
                                />
                                <path
                                    d="M19.6208 7.03906H10.959C8.84544 7.03906 7.13208 8.61692 7.13208 10.5633V23.05C7.13208 24.9963 8.84544 26.5742 10.959 26.5742H19.6208C21.7344 26.5742 23.4477 24.9963 23.4477 23.05V10.5633C23.4477 8.61692 21.7344 7.03906 19.6208 7.03906Z"
                                    fill="white"
                                />
                                <path
                                    d="M10.3948 6.12875H20.1794C20.1794 6.12875 15.7983 -0.910939 10.3948 6.12875Z"
                                    fill="#3271E6"
                                />
                                <path
                                    d="M15.2875 14.5496C17.2005 14.5496 18.7513 13.1215 18.7513 11.3598C18.7513 9.59807 17.2005 8.16992 15.2875 8.16992C13.3745 8.16992 11.8237 9.59807 11.8237 11.3598C11.8237 13.1215 13.3745 14.5496 15.2875 14.5496Z"
                                    fill="#FF8084"
                                />
                                <path
                                    opacity="0.3"
                                    d="M22.0285 17.3223H8.55078V18.3386H22.0285V17.3223Z"
                                    fill="#FF8084"
                                />
                                <path
                                    opacity="0.3"
                                    d="M22.0285 19.8867H8.55078V20.9031H22.0285V19.8867Z"
                                    fill="#FF8084"
                                />
                                <path
                                    opacity="0.3"
                                    d="M15.2108 22.4922H8.55078V23.5085H15.2108V22.4922Z"
                                    fill="#FF8084"
                                />
                                <path
                                    d="M15.2874 4.95408C15.7703 4.95408 16.1617 4.59359 16.1617 4.14891C16.1617 3.70423 15.7703 3.34375 15.2874 3.34375C14.8045 3.34375 14.4131 3.70423 14.4131 4.14891C14.4131 4.59359 14.8045 4.95408 15.2874 4.95408Z"
                                    fill="white"
                                />
                                <path
                                    d="M14.3844 13.0701L12.9534 10.8906L12.4275 11.1835L13.8585 13.363L14.3844 13.0701Z"
                                    fill="white"
                                />
                                <path
                                    d="M17.8275 10.3584L13.5366 12.7346L13.8518 13.2172L18.1427 10.841L17.8275 10.3584Z"
                                    fill="white"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_328_223">
                                    <rect
                                        width="19"
                                        height="25"
                                        fill="white"
                                        transform="translate(6 3)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>

                        Eğitimlerim
                    </router-link>
                </li>

                <li class="left-menu__item">
                    <router-link :to="{ name: 'exam_list' }" class="left-menu__link">
                        <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M19.9005 3.02148H10.6737C8.09247 3.02148 6 5.11396 6 7.69516V22.327C6 24.9082 8.09247 27.0007 10.6737 27.0007H19.9005C22.4817 27.0007 24.5741 24.9082 24.5741 22.327V7.69516C24.5741 5.11396 22.4817 3.02148 19.9005 3.02148Z"
                                fill="#577FE0"
                            />
                            <path
                                d="M9.55522 11.1104C10.4141 11.1104 11.1104 10.4141 11.1104 9.55522C11.1104 8.6963 10.4141 8 9.55522 8C8.69629 8 8 8.6963 8 9.55522C8 10.4141 8.69629 11.1104 9.55522 11.1104Z"
                                fill="white"
                            />
                            <path
                                d="M9.55522 16.7355C10.4141 16.7355 11.1104 16.0392 11.1104 15.1802C11.1104 14.3213 10.4141 13.625 9.55522 13.625C8.69629 13.625 8 14.3213 8 15.1802C8 16.0392 8.69629 16.7355 9.55522 16.7355Z"
                                fill="white"
                            />
                            <path
                                d="M9.55522 21.9601C10.4141 21.9601 11.1104 21.2638 11.1104 20.4049C11.1104 19.5459 10.4141 18.8496 9.55522 18.8496C8.69629 18.8496 8 19.5459 8 20.4049C8 21.2638 8.69629 21.9601 9.55522 21.9601Z"
                                fill="white"
                            />
                            <path
                                d="M21.5346 9.15234H12.8463C12.6799 9.15234 12.5449 9.28728 12.5449 9.45374C12.5449 9.62019 12.6799 9.75513 12.8463 9.75513H21.5346C21.7011 9.75513 21.836 9.62019 21.836 9.45374C21.836 9.28728 21.7011 9.15234 21.5346 9.15234Z"
                                fill="white"
                            />
                            <path
                                d="M21.5346 14.9316H12.8463C12.6799 14.9316 12.5449 15.0665 12.5449 15.233C12.5449 15.3994 12.6799 15.5344 12.8463 15.5344H21.5346C21.7011 15.5344 21.836 15.3994 21.836 15.233C21.836 15.0665 21.7011 14.9316 21.5346 14.9316Z"
                                fill="white"
                            />
                            <path
                                d="M21.5346 20.3047H12.8463C12.6799 20.3047 12.5449 20.4397 12.5449 20.6061C12.5449 20.7726 12.6799 20.9075 12.8463 20.9075H21.5346C21.7011 20.9075 21.836 20.7726 21.836 20.6061C21.836 20.4397 21.7011 20.3047 21.5346 20.3047Z"
                                fill="white"
                            />
                            <path
                                d="M11.7104 2H11.7064C11.3535 2 11.0674 2.28607 11.0674 2.63896V3.54717C11.0674 3.90006 11.3535 4.18614 11.7064 4.18614H11.7104C12.0633 4.18614 12.3493 3.90006 12.3493 3.54717V2.63896C12.3493 2.28607 12.0633 2 11.7104 2Z"
                                fill="#FF8084"
                            />
                            <path
                                d="M15.3031 2.02539H15.2991C14.9462 2.02539 14.6602 2.31146 14.6602 2.66435V3.57257C14.6602 3.92545 14.9462 4.21153 15.2991 4.21153H15.3031C15.656 4.21153 15.9421 3.92545 15.9421 3.57257V2.66435C15.9421 2.31146 15.656 2.02539 15.3031 2.02539Z"
                                fill="#FF8084"
                            />
                            <path
                                d="M18.5097 2.08789H18.5057C18.1528 2.08789 17.8667 2.37396 17.8667 2.72685V3.63507C17.8667 3.98795 18.1528 4.27403 18.5057 4.27403H18.5097C18.8626 4.27403 19.1486 3.98795 19.1486 3.63507V2.72685C19.1486 2.37396 18.8626 2.08789 18.5097 2.08789Z"
                                fill="#FF8084"
                            />
                        </svg>

                        Deneme Sınavları
                    </router-link>
                </li>
                <li class="left-menu_item">
                    <a href="https://yds.net/ucretsiz-yds-yokdil-kaynaklari" class="left-menu__link">
                        <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M18 15.75H6.75V21.75H18V15.75Z" fill="#3271E6"/>
                            <path
                                d="M24 10.0313C24.0033 10.0139 24.0033 9.99611 24 9.97877C23.9817 9.93407 23.955 9.89332 23.9213 9.85877L18.6713 4.60877C18.6367 4.57503 18.5959 4.54829 18.5513 4.53001C18.5339 4.52667 18.5161 4.52667 18.4988 4.53001C18.4606 4.50986 18.4181 4.49955 18.375 4.50002H7.875C7.77554 4.50002 7.68016 4.53952 7.60984 4.60985C7.53951 4.68018 7.5 4.77556 7.5 4.87502V15H6.375C6.27554 15 6.18016 15.0395 6.10984 15.1098C6.03951 15.1802 6 15.2756 6 15.375V22.125C6 22.2245 6.03951 22.3199 6.10984 22.3902C6.18016 22.4605 6.27554 22.5 6.375 22.5H7.5V25.125C7.5 25.2245 7.53951 25.3199 7.60984 25.3902C7.68016 25.4605 7.77554 25.5 7.875 25.5H23.625C23.7245 25.5 23.8198 25.4605 23.8902 25.3902C23.9605 25.3199 24 25.2245 24 25.125V10.125C24.0057 10.094 24.0057 10.0623 24 10.0313ZM18.75 5.77877L22.7212 9.75001H18.75V5.77877ZM6.75 15.75H18V21.75H6.75V15.75ZM8.25 24.75V22.5H18.375C18.4745 22.5 18.5698 22.4605 18.6402 22.3902C18.7105 22.3199 18.75 22.2245 18.75 22.125V15.375C18.75 15.2756 18.7105 15.1802 18.6402 15.1098C18.5698 15.0395 18.4745 15 18.375 15H8.25V5.25002H18V10.125C18 10.2245 18.0395 10.3199 18.1098 10.3902C18.1802 10.4605 18.2755 10.5 18.375 10.5H23.25V24.75H8.25Z"
                                fill="#01579B"
                            />
                            <path
                                d="M22.7213 9.75006H18.75V5.77881L22.7213 9.75006Z"
                                fill="white"
                            />
                            <path
                                d="M23.25 10.5V24.75H8.25V22.5H18.375C18.4745 22.5 18.5698 22.4605 18.6402 22.3902C18.7105 22.3198 18.75 22.2245 18.75 22.125V15.375C18.75 15.2755 18.7105 15.1802 18.6402 15.1098C18.5698 15.0395 18.4745 15 18.375 15H8.25V5.25H18V10.125C18 10.2245 18.0395 10.3198 18.1098 10.3902C18.1802 10.4605 18.2755 10.5 18.375 10.5H23.25Z"
                                fill="#D9E6FE"
                            />
                            <path
                                d="M8.625 20.2499V17.2499H9.6225C9.8786 17.2329 10.1321 17.3102 10.335 17.4674C10.4374 17.5565 10.5186 17.6674 10.5724 17.792C10.6263 17.9166 10.6515 18.0517 10.6462 18.1874C10.644 18.3627 10.5934 18.534 10.5 18.6824C10.4123 18.8286 10.2812 18.944 10.125 19.0124C9.98942 19.0727 9.84212 19.1022 9.69375 19.0986H9.24375V20.2499H8.625ZM9.25875 18.5549H9.61125C9.7223 18.5607 9.83126 18.523 9.915 18.4499C9.95208 18.4126 9.98093 18.368 9.99966 18.3189C10.0184 18.2698 10.0266 18.2173 10.0237 18.1649C10.0237 17.9349 9.88625 17.8199 9.61125 17.8199H9.25875V18.5549ZM11.2313 20.2499V17.2499H12.3562C12.6382 17.2399 12.9146 17.3303 13.1362 17.5049C13.3198 17.6542 13.4655 17.8448 13.5613 18.0612C13.6571 18.2776 13.7003 18.5136 13.6875 18.7499C13.6907 19.025 13.6264 19.2967 13.5 19.5411C13.3817 19.7697 13.1993 19.9587 12.975 20.0849C12.7159 20.2093 12.4295 20.266 12.1425 20.2499H11.2313ZM11.865 19.6799H12.27C12.414 19.6851 12.5564 19.6473 12.6787 19.5711C12.8085 19.4737 12.9111 19.3447 12.9768 19.1963C13.0424 19.0479 13.0689 18.8852 13.0537 18.7236C13.0665 18.5718 13.0405 18.4192 12.9782 18.2801C12.9159 18.1411 12.8193 18.0201 12.6975 17.9286C12.5768 17.8519 12.4354 17.814 12.2925 17.8199H11.865V19.6799ZM14.34 20.2499V17.2499H16.0575V17.8199H14.9737V18.4311H15.9675V19.0011H14.9737V20.2499H14.34Z"
                                fill="white"
                            />
                        </svg>

                        Kaynak İndir
                    </a>
                </li>
                <li class="left-menu__item">
                    <router-link class="left-menu__link" :to="{name: 'lesson_list', params: { tab: 'online' }}">
                        <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M18.5046 18.1469C22.1903 18.1469 25.1781 15.159 25.1781 11.4733C25.1781 7.78765 22.1903 4.7998 18.5046 4.7998C14.8189 4.7998 11.8311 7.78765 11.8311 11.4733C11.8311 15.159 14.8189 18.1469 18.5046 18.1469Z"
                                fill="#AECBFF"
                            />
                            <path
                                d="M7.38343 8.66699H6.79102V9.25943H7.38343V8.66699Z"
                                fill="#213550"
                            />
                            <path
                                d="M8.98306 8.66699H8.39062V9.25943H8.98306V8.66699Z"
                                fill="#213550"
                            />
                            <path
                                d="M10.5837 8.66699H9.99121V9.25943H10.5837V8.66699Z"
                                fill="#213550"
                            />
                            <path
                                d="M22.1667 18.8887H21.5742V19.4811H22.1667V18.8887Z"
                                fill="#213550"
                            />
                            <path
                                d="M22.1667 20.4893H21.5742V21.0817H22.1667V20.4893Z"
                                fill="#213550"
                            />
                            <path
                                d="M22.1667 22.0898H21.5742V22.6823H22.1667V22.0898Z"
                                fill="#213550"
                            />
                            <path
                                d="M22.0154 14.9844C21.3212 15.6785 20.4368 16.1512 19.4739 16.3427C18.5111 16.5343 17.5131 16.4359 16.6061 16.0603C15.6991 15.6845 14.9239 15.0484 14.3785 14.2321C13.8331 13.4158 13.542 12.4561 13.542 11.4744C13.542 10.4927 13.8331 9.53306 14.3785 8.71679C14.9239 7.90052 15.6991 7.26431 16.6061 6.88862C17.5131 6.5129 18.5111 6.41459 19.4739 6.60611C20.4368 6.7976 21.3212 7.27034 22.0154 7.96448C22.945 8.89607 23.4671 10.1584 23.4671 11.4744C23.4671 12.7905 22.945 14.0528 22.0154 14.9844Z"
                                fill="white"
                            />
                            <path
                                d="M18.5042 16.7326C17.2891 16.7321 16.1117 16.3104 15.1726 15.5393C14.2335 14.7681 13.5908 13.6953 13.3538 12.5036C13.1168 11.3117 13.3004 10.0747 13.8731 9.00297C14.4458 7.93128 15.3723 7.09128 16.4948 6.62598C17.6174 6.16071 18.8664 6.09891 20.0294 6.45117C21.1923 6.80343 22.1972 7.54791 22.8729 8.55786C23.5486 9.56778 23.8532 10.7807 23.7351 11.9901C23.6169 13.1995 23.0831 14.3305 22.2247 15.1905C21.7373 15.6808 21.1576 16.0696 20.519 16.3343C19.8803 16.599 19.1955 16.7344 18.5042 16.7326ZM18.5042 6.80892C17.4261 6.81012 16.3817 7.18503 15.5489 7.86978C14.7162 8.55456 14.1466 9.50685 13.9371 10.5644C13.7277 11.622 13.8913 12.7195 14.4002 13.67C14.9091 14.6205 15.7317 15.3652 16.728 15.7772C17.7243 16.1893 18.8326 16.2432 19.8642 15.9298C20.8958 15.6164 21.7868 14.9552 22.3856 14.0586C22.9844 13.162 23.2538 12.0856 23.148 11.0126C23.0422 9.93969 22.5677 8.93661 21.8054 8.17425C21.3731 7.73904 20.8586 7.39395 20.292 7.15896C19.7253 6.92397 19.1177 6.80376 18.5042 6.80526V6.80892Z"
                                fill="#213550"
                            />
                            <path
                                d="M22.0502 9.53979C22.4333 10.2411 22.5998 11.0402 22.5284 11.8361C22.4571 12.632 22.1512 13.3889 21.6495 14.0108C21.1477 14.6327 20.4727 15.0918 19.71 15.3299C18.9472 15.568 18.1309 15.5744 17.3644 15.3483C16.598 15.1222 15.9159 14.6738 15.4045 14.0598C14.8931 13.4458 14.5753 12.6939 14.4915 11.8992C14.4077 11.1045 14.5615 10.3028 14.9336 9.59565C15.3057 8.88846 15.8792 8.3076 16.5816 7.92657C17.521 7.41693 18.6242 7.30059 19.6493 7.60299C20.6744 7.90539 21.5378 8.60187 22.0502 9.53979Z"
                                fill="#E9ECF0"
                            />
                            <path
                                d="M19.2389 11.7125C19.2389 11.7125 19.2389 11.4737 18.661 11.8019C18.661 11.8019 18.5626 11.9641 18.2965 11.5613L18.1142 11.6597C18.1142 11.6597 17.8152 11.399 17.7314 11.6232C17.7314 11.6232 17.6949 11.7891 17.4251 11.6542C17.4251 11.6542 16.984 11.7581 17.2684 12.3159C17.2684 12.3159 16.9585 12.9156 17.8006 13.0232L18.198 13.0906L18.4824 13.6375C18.4824 13.6375 18.6392 13.5828 18.6939 13.8453C18.6939 13.8453 18.9764 13.9164 19.011 14.3375C19.011 14.3375 19.4941 14.7659 19.7402 14.4031L19.5579 14.3357L19.4941 14.0859L19.3282 14.1752L19.31 13.8781L19.155 13.8599C19.155 13.8599 19.7384 13.4953 19.454 12.9812C19.454 12.9812 19.0584 12.8719 19.5087 12.675C19.5087 12.675 19.7201 12.5602 19.4339 12.0899C19.4339 12.0899 19.5196 11.8183 19.4449 11.7253C19.3701 11.6323 19.2389 11.7125 19.2389 11.7125Z"
                                fill="#38A1DB"
                            />
                            <path
                                d="M20.9065 8.23438C20.9371 8.36223 20.9998 8.48019 21.0887 8.57706C21.1088 8.96352 20.4453 8.71744 20.4453 8.71744C20.4817 9.13486 19.9112 8.99086 19.9112 8.99086C18.7719 9.02185 19.0799 9.93511 19.0799 9.93511C18.8648 10.6205 19.6487 10.9796 19.6487 10.9796C20.1281 11.1983 20.2393 10.4984 20.2393 10.4984C20.6768 10.6807 20.8044 10.4984 20.8044 10.4984L20.8627 10.708C20.9192 10.9176 21.3804 10.9632 21.4296 11.0726C21.4788 11.1819 21.1744 11.6668 21.6684 11.7762C22.1624 11.8856 22.1387 12.1845 22.1387 12.1845L22.4687 12.2593C22.6156 11.5118 22.5471 10.738 22.2712 10.028C21.9952 9.31801 21.5232 8.70102 20.9101 8.24895L20.9065 8.23438Z"
                                fill="#38A1DB"
                            />
                            <path
                                d="M15.4508 12.296L15.8354 12.564C16.2201 12.8319 16.2219 12.2614 16.0505 12.1156C15.8792 11.9697 15.881 11.6015 16.3641 11.4666C16.8471 11.3317 16.5628 11.2679 16.6722 10.5898C16.7815 9.91171 17.219 10.1614 17.1698 9.81691C17.1205 9.47239 16.9273 8.90548 16.674 9.46147C16.4206 10.0174 16.3841 9.85885 16.5044 9.14974C16.6247 8.44066 16.2839 9.37762 15.6131 9.72214C15.3086 9.88072 15.1063 9.73489 14.9623 9.53983C14.7367 9.94264 14.5813 10.3809 14.5029 10.8359C14.5522 11.0692 14.6269 11.3499 14.7381 11.7656C14.9204 12.4382 15.4508 12.296 15.4508 12.296Z"
                                fill="#38A1DB"
                            />
                            <path
                                d="M5.09521 19.8628V21.9937C5.09569 22.2293 5.18962 22.4551 5.35639 22.6216C5.52316 22.788 5.74916 22.8815 5.98478 22.8815H17.8334C18.069 22.8815 18.295 22.788 18.4618 22.6216C18.6286 22.4551 18.7225 22.2293 18.723 21.9937V19.8628H5.09521Z"
                                fill="#E9ECF0"
                            />
                            <path
                                d="M17.8242 23.1767H5.98469C5.67074 23.1767 5.36966 23.0521 5.14751 22.8303C4.92536 22.6085 4.80029 22.3076 4.79981 21.9937V19.8627C4.79957 19.8238 4.80704 19.7852 4.82177 19.7491C4.8365 19.7131 4.85822 19.6803 4.88567 19.6527C4.91312 19.6251 4.94573 19.6032 4.9817 19.5882C5.01764 19.5733 5.05619 19.5656 5.09513 19.5656H18.7138C18.7535 19.5643 18.793 19.5712 18.83 19.5856C18.867 19.6 18.9007 19.6217 18.9292 19.6494C18.9576 19.6771 18.9801 19.7104 18.9954 19.747C19.0107 19.7836 19.0185 19.823 19.0182 19.8627V21.9937C19.018 22.1499 18.9868 22.3045 18.9266 22.4486C18.8664 22.5928 18.7783 22.7236 18.6673 22.8335C18.5563 22.9435 18.4247 23.0304 18.28 23.0893C18.1354 23.1482 17.9804 23.1779 17.8242 23.1767ZM5.39225 20.158V21.9937C5.39225 22.1508 5.45468 22.3015 5.56577 22.4125C5.67686 22.5237 5.82755 22.5861 5.98469 22.5861H17.8333C17.9903 22.5856 18.1407 22.523 18.2517 22.412C18.3627 22.301 18.4253 22.1506 18.4258 21.9937V20.158H5.39225Z"
                                fill="#213550"
                            />
                            <path
                                d="M17.8243 12.1758H5.98478C5.74916 12.1758 5.52316 12.2693 5.35639 12.4357C5.18962 12.6021 5.09569 12.8279 5.09521 13.0635V19.8628H18.7139V13.0635C18.7134 12.8279 18.6195 12.6021 18.4527 12.4357C18.2859 12.2693 18.0599 12.1758 17.8243 12.1758Z"
                                fill="#FF8084"
                            />
                            <path
                                d="M17.8242 11.8799H5.98468C5.67073 11.8799 5.36965 12.0045 5.14747 12.2263C4.92532 12.4481 4.80028 12.749 4.7998 13.063V19.8623C4.7998 19.9406 4.83091 20.0157 4.88629 20.0711C4.94167 20.1265 5.01679 20.1576 5.09509 20.1576H18.7138C18.7533 20.1588 18.7927 20.1521 18.8296 20.1378C18.8665 20.1235 18.9001 20.1019 18.9285 20.0744C18.9569 20.0468 18.9795 20.0139 18.9949 19.9774C19.0103 19.941 19.0182 19.9018 19.0182 19.8623V13.063C19.0179 12.9068 18.9868 12.7521 18.9266 12.608C18.8664 12.4638 18.7782 12.333 18.6673 12.2231C18.5563 12.1131 18.4247 12.0262 18.28 11.9673C18.1353 11.9084 17.9804 11.8787 17.8242 11.8799ZM18.4167 19.5651H5.39224V13.063C5.39224 12.9059 5.45464 12.7552 5.56576 12.644C5.67685 12.533 5.82754 12.4705 5.98468 12.4705H17.8333C17.9903 12.471 18.1407 12.5336 18.2517 12.6446C18.3627 12.7556 18.4253 12.906 18.4257 13.063L18.4167 19.5651Z"
                                fill="#213550"
                            />
                            <path
                                d="M17.5292 13.3604H6.28027V18.6795H17.5292V13.3604Z"
                                fill="white"
                            />
                            <path
                                d="M13.7069 22.8809H10.1031L9.50879 24.9043H14.3011L13.7069 22.8809Z"
                                fill="white"
                            />
                            <path
                                d="M14.3002 25.1994H9.50785C9.46192 25.1993 9.41668 25.1884 9.37564 25.1678C9.3346 25.1472 9.2989 25.1173 9.27136 25.0806C9.24379 25.0439 9.22513 25.0012 9.21682 24.956C9.20851 24.9109 9.21079 24.8644 9.22348 24.8203L9.81772 22.7969C9.83614 22.7358 9.87373 22.6822 9.92494 22.6441C9.97615 22.6061 10.0383 22.5855 10.1021 22.5854H13.7059C13.7697 22.5855 13.8319 22.6061 13.8831 22.6441C13.9343 22.6822 13.9719 22.7358 13.9903 22.7969L14.5845 24.8203C14.5972 24.8644 14.5995 24.9109 14.5912 24.956C14.5829 25.0012 14.5642 25.0439 14.5367 25.0806C14.5091 25.1173 14.4734 25.1472 14.4324 25.1678C14.3913 25.1884 14.3461 25.1993 14.3002 25.1994ZM9.9034 24.6088H13.9137L13.4926 23.1761H10.3245L9.9034 24.6088Z"
                                fill="#213550"
                            />
                            <path
                                d="M14.7213 25.2H9.08682C9.00849 25.2 8.9334 25.1689 8.87802 25.1135C8.82261 25.0581 8.7915 24.9831 8.7915 24.9047C8.7915 24.8264 8.82261 24.7513 8.87802 24.6959C8.9334 24.6405 9.00849 24.6094 9.08682 24.6094H14.7213C14.7996 24.6094 14.8748 24.6405 14.9301 24.6959C14.9855 24.7513 15.0166 24.8264 15.0166 24.9047C15.0166 24.9831 14.9855 25.0581 14.9301 25.1135C14.8748 25.1689 14.7996 25.2 14.7213 25.2Z"
                                fill="#213550"
                            />
                            <path
                                d="M11.9097 20.7842C11.7936 20.7842 11.6801 20.8187 11.5836 20.8832C11.4871 20.9477 11.4118 21.0393 11.3674 21.1466C11.323 21.2538 11.3114 21.3719 11.334 21.4857C11.3567 21.5996 11.4126 21.7042 11.4947 21.7862C11.5768 21.8683 11.6813 21.9242 11.7952 21.9469C11.9091 21.9695 12.0271 21.9579 12.1343 21.9135C12.2416 21.869 12.3333 21.7938 12.3978 21.6973C12.4623 21.6008 12.4967 21.4873 12.4967 21.3712C12.4967 21.2155 12.4348 21.0662 12.3248 20.9561C12.2147 20.8461 12.0654 20.7842 11.9097 20.7842Z"
                                fill="#D8D8D9"
                            />
                            <path
                                d="M10.0066 20.9878C9.93044 20.9874 9.85589 21.0097 9.79241 21.0517C9.7289 21.0938 9.67931 21.1537 9.64994 21.224C9.62054 21.2942 9.61265 21.3716 9.62729 21.4463C9.64193 21.5211 9.67841 21.5898 9.73214 21.6437C9.78587 21.6977 9.85439 21.7345 9.92906 21.7495C10.0037 21.7645 10.0811 21.757 10.1515 21.7279C10.2219 21.6989 10.2821 21.6496 10.3244 21.5863C10.3668 21.523 10.3894 21.4486 10.3894 21.3724C10.3894 21.2707 10.3491 21.1732 10.2774 21.1011C10.2056 21.029 10.1083 20.9883 10.0066 20.9878Z"
                                fill="#D8D8D9"
                            />
                            <path
                                d="M13.8036 20.9878C13.7275 20.9878 13.6531 21.0104 13.5899 21.0526C13.5266 21.0949 13.4773 21.155 13.4482 21.2252C13.4191 21.2955 13.4115 21.3728 13.4263 21.4475C13.4412 21.5221 13.4778 21.5906 13.5316 21.6444C13.5854 21.6982 13.6539 21.7348 13.7285 21.7496C13.8031 21.7645 13.8805 21.7569 13.9507 21.7278C14.021 21.6986 14.0811 21.6494 14.1234 21.5861C14.1656 21.5228 14.1882 21.4485 14.1882 21.3724C14.1882 21.2704 14.1477 21.1726 14.0755 21.1004C14.0034 21.0283 13.9056 20.9878 13.8036 20.9878Z"
                                fill="#D8D8D9"
                            />
                            <path
                                d="M16.3028 14.2461H10.6865V14.8385H16.3028V14.2461Z"
                                fill="#213550"
                            />
                            <path
                                d="M16.3028 15.7227H10.6865V16.3151H16.3028V15.7227Z"
                                fill="#213550"
                            />
                            <path
                                d="M16.3028 17.2012H10.6865V17.7936H16.3028V17.2012Z"
                                fill="#213550"
                            />
                            <path
                                d="M8.06645 16.6963L7.81673 17.2614H7.37012L8.46383 14.7769H8.91044L10.0041 17.2614H9.55757L9.30965 16.6963H8.06645ZM9.13829 16.3081L8.68805 15.2854L8.23598 16.3081H9.13829Z"
                                fill="#FF8084"
                            />
                        </svg>
                        Online Dersler
                    </router-link>
                </li>
                <li class="left-menu__item">
                    <router-link class="left-menu__link" :to="{ name: 'lesson_list', params: { tab: 'offline' }}">
                        <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M22.8091 20.1907C23.4665 17.2057 21.5796 14.2529 18.5946 13.5955C15.6095 12.9381 12.6567 14.8251 11.9994 17.8101C11.3419 20.7951 13.2289 23.7479 16.2139 24.4053C19.1989 25.0627 22.1517 23.1758 22.8091 20.1907Z"
                                fill="#FF8084"
                            />
                            <path
                                d="M22.8472 4.47949H7.124C7.01315 4.47928 6.90332 4.50094 6.80084 4.54327C6.69839 4.5856 6.60527 4.64773 6.52688 4.72612C6.44849 4.80451 6.38636 4.89763 6.34403 5.00008C6.3017 5.10256 6.28004 5.21236 6.28028 5.32324V8.59096H23.6892V5.32324C23.6894 5.21251 23.6678 5.10283 23.6256 5.0005C23.5834 4.89814 23.5214 4.80511 23.4432 4.72672C23.365 4.64836 23.2721 4.5862 23.1698 4.54375C23.0675 4.50133 22.9579 4.47949 22.8472 4.47949Z"
                                fill="#E9ECF0"
                            />
                            <path
                                d="M23.689 8.86841H6.28008C6.20655 8.86796 6.13608 8.83877 6.08373 8.78708C6.03141 8.73539 6.00135 8.66531 6 8.59175V5.32403C6 5.02598 6.11841 4.74011 6.32916 4.52936C6.53991 4.31861 6.82578 4.2002 7.12383 4.2002H22.847C23.1448 4.20065 23.4302 4.31927 23.6405 4.52996C23.8509 4.74068 23.9691 5.02628 23.9691 5.32403V8.59175C23.9678 8.66531 23.9377 8.73539 23.8854 8.78708C23.833 8.83877 23.7626 8.86796 23.689 8.86841ZM6.5619 8.31167H23.4072V5.32403C23.4074 5.25032 23.3931 5.1773 23.3651 5.10911C23.337 5.04095 23.2958 4.979 23.2437 4.9268C23.1917 4.8746 23.1298 4.83317 23.0618 4.80491C22.9937 4.77665 22.9207 4.7621 22.847 4.7621H7.12383C7.04997 4.76189 6.9768 4.77626 6.90852 4.80443C6.84024 4.83257 6.7782 4.87397 6.72597 4.92617C6.67377 4.9784 6.63237 5.04044 6.60423 5.10872C6.57606 5.177 6.56169 5.25017 6.5619 5.32403V8.31167Z"
                                fill="#213550"
                            />
                            <path
                                d="M22.5651 5.60352H7.40381V7.46907H22.5651V5.60352Z"
                                fill="white"
                            />
                            <path
                                d="M6.28027 8.5918V18.6198C6.28027 18.7305 6.30211 18.8401 6.34453 18.9424C6.38695 19.0447 6.44911 19.1375 6.5275 19.2158C6.60586 19.294 6.69889 19.3559 6.80125 19.3982C6.90361 19.4404 7.01329 19.462 7.12399 19.4618H22.8472C22.9578 19.4618 23.0672 19.44 23.1694 19.3977C23.2716 19.3553 23.3644 19.2933 23.4426 19.2152C23.5207 19.137 23.5828 19.0441 23.6251 18.942C23.6674 18.8398 23.6892 18.7303 23.6892 18.6198V8.5918H6.28027Z"
                                fill="#BCD3FF"
                            />
                            <path
                                d="M23.689 8.31152H6.28008C6.2058 8.31152 6.13455 8.34101 6.08205 8.39354C6.02952 8.44607 6 8.51732 6 8.5916V18.6196C6.00045 18.9175 6.11901 19.2031 6.32967 19.4137C6.54033 19.6244 6.8259 19.743 7.12383 19.7434H22.847C23.1448 19.743 23.4302 19.6243 23.6405 19.4136C23.8509 19.2029 23.9691 18.9173 23.9691 18.6196V8.5916C23.9691 8.55482 23.9618 8.5184 23.9478 8.48441C23.9337 8.45045 23.9131 8.41955 23.887 8.39354C23.861 8.36753 23.8302 8.34692 23.7962 8.33282C23.7622 8.31875 23.7258 8.31152 23.689 8.31152ZM23.4072 18.6196C23.4072 18.7683 23.3482 18.911 23.2432 19.0163C23.1382 19.1216 22.9957 19.181 22.847 19.1815H7.12383C6.97479 19.1815 6.83187 19.1223 6.72648 19.0169C6.62112 18.9115 6.5619 18.7686 6.5619 18.6196V8.86823H23.4072V18.6196Z"
                                fill="#213550"
                            />
                            <path
                                d="M22.5651 9.71582H7.40381V18.3399H22.5651V9.71582Z"
                                fill="white"
                            />
                            <path
                                d="M18.7821 6.25684H18.2202V6.81874H18.7821V6.25684Z"
                                fill="#213550"
                            />
                            <path
                                d="M20.2987 6.25684H19.7368V6.81874H20.2987V6.25684Z"
                                fill="#213550"
                            />
                            <path
                                d="M21.8163 6.25684H21.2544V6.81874H21.8163V6.25684Z"
                                fill="#213550"
                            />
                            <path
                                d="M10.8599 16.0674C10.2871 16.0674 9.72718 15.8976 9.25093 15.5793C8.77468 15.2611 8.40352 14.8088 8.18431 14.2797C7.96513 13.7505 7.90777 13.1682 8.01952 12.6064C8.13127 12.0447 8.40706 11.5286 8.81209 11.1236C9.21709 10.7186 9.73312 10.4428 10.2949 10.331C10.8567 10.2193 11.4389 10.2766 11.9681 10.4958C12.4973 10.715 12.9496 11.0862 13.2678 11.5625C13.586 12.0387 13.7559 12.5986 13.7559 13.1714C13.7554 13.9393 13.4502 14.6757 12.9071 15.2187C12.3641 15.7617 11.6278 16.067 10.8599 16.0674Z"
                                fill="#E9ECF0"
                            />
                            <path
                                d="M12.0412 12.1539C12.0412 12.7487 11.5035 13.6771 10.8413 13.6771C10.1791 13.6771 9.63965 12.7487 9.63965 12.1539C9.65534 11.8469 9.791 11.5584 10.0174 11.3505C10.2437 11.1425 10.5427 11.0318 10.8499 11.0422C11.1539 11.0368 11.448 11.1498 11.6703 11.3572C11.8926 11.5647 12.0255 11.8503 12.0412 12.1539Z"
                                fill="#FF8084"
                            />
                            <path
                                d="M11.0775 10.9437C10.5588 10.8383 10.322 11.0924 10.2926 11.1685L10.2494 11.191C9.48861 11.0354 9.63903 12.154 9.63903 12.154C9.64593 12.3636 9.69411 12.5699 9.78081 12.7608C9.78081 12.5983 9.79809 11.867 10.2165 11.9794C10.3962 12.0852 10.601 12.141 10.8095 12.141C11.0181 12.141 11.2229 12.0852 11.4026 11.9794C11.4026 11.9794 11.8331 11.8272 11.9074 12.7349C11.9921 12.5378 12.5869 11.2532 11.0775 10.9437Z"
                                fill="#213550"
                            />
                            <path
                                d="M11.7672 13.6763H9.95347C9.65956 13.6763 9.37766 13.793 9.16982 14.0009C8.96198 14.2087 8.84521 14.4906 8.84521 14.7845V15.2133C9.10669 15.4835 9.41983 15.6983 9.76603 15.845C10.1122 15.9917 10.4844 16.0673 10.8603 16.0673C11.2363 16.0673 11.6085 15.9917 11.9546 15.845C12.3008 15.6983 12.6139 15.4835 12.8754 15.2133V14.7845C12.8754 14.4906 12.7587 14.2087 12.5508 14.0009C12.343 13.793 12.0611 13.6763 11.7672 13.6763Z"
                                fill="#BCD3FF"
                            />
                            <path
                                d="M10.0288 13.6763L10.5475 14.5771C10.5787 14.6313 10.6237 14.6763 10.6779 14.7076C10.7321 14.739 10.7935 14.7554 10.8561 14.7554C10.9187 14.7554 10.9802 14.739 11.0343 14.7076C11.0885 14.6763 11.1335 14.6313 11.1647 14.5771L11.6834 13.6763H10.0288Z"
                                fill="#FF8084"
                            />
                            <path
                                d="M21.5361 11.1719H16.5342V11.7338H21.5361V11.1719Z"
                                fill="#213550"
                            />
                            <path
                                d="M21.5361 12.9028H16.5342V13.4647H21.5361V12.9028Z"
                                fill="#213550"
                            />
                            <path
                                d="M19.036 14.6362H16.5342V15.1982H19.036V14.6362Z"
                                fill="#213550"
                            />
                            <path
                                d="M21.3553 16.5171H13.4521V20.8257H18.2725L20.3023 21.889V20.8257H21.3553V16.5171Z"
                                fill="#BCD3FF"
                            />
                            <path
                                d="M20.1777 22.1382L18.2101 21.1009H13.452C13.4152 21.1009 13.3788 21.0936 13.3448 21.0795C13.3108 21.0655 13.2799 21.0449 13.2539 21.0189C13.2279 20.9928 13.2073 20.9619 13.1932 20.928C13.1791 20.894 13.1719 20.8576 13.1719 20.8208V16.5174C13.1719 16.4431 13.2014 16.3719 13.2539 16.3194C13.3065 16.2668 13.3777 16.2373 13.452 16.2373H21.3551C21.392 16.2371 21.4286 16.2441 21.4628 16.2581C21.497 16.2721 21.528 16.2927 21.5542 16.3187C21.5804 16.3448 21.6012 16.3757 21.6154 16.4098C21.6296 16.4439 21.6369 16.4805 21.6369 16.5174V20.826C21.6369 20.8629 21.6296 20.8995 21.6154 20.9336C21.6012 20.9676 21.5804 20.9986 21.5542 21.0246C21.528 21.0507 21.497 21.0713 21.4628 21.0852C21.4286 21.0992 21.392 21.1063 21.3551 21.1061H20.5822V21.8893C20.5819 21.9368 20.5695 21.9834 20.5462 22.0248C20.5228 22.0662 20.4894 22.101 20.4489 22.1259C20.4085 22.1508 20.3624 22.165 20.3149 22.1672C20.2674 22.1693 20.2202 22.1594 20.1777 22.1382ZM13.7338 20.5442H18.2758C18.321 20.5446 18.3655 20.5559 18.4055 20.577L20.0238 21.4242V20.826C20.0238 20.7515 20.0532 20.6801 20.1057 20.6273C20.1582 20.5745 20.2294 20.5446 20.3039 20.5442H21.0784V16.7975H13.7338V20.5442Z"
                                fill="#213550"
                            />
                            <path
                                d="M20.2756 17.6421H14.5303V19.6978H20.2756V17.6421Z"
                                fill="white"
                            />
                            <path
                                d="M16.2195 18.6716C16.2216 18.7429 16.2021 18.8132 16.1638 18.8733C16.1255 18.9334 16.07 18.9807 16.0045 19.0089C15.939 19.0372 15.8665 19.0451 15.7965 19.0317C15.7265 19.0183 15.6621 18.9841 15.6116 18.9337C15.5612 18.8833 15.5271 18.8189 15.5137 18.7488C15.5003 18.6788 15.5082 18.6063 15.5364 18.5408C15.5646 18.4753 15.6119 18.4198 15.672 18.3815C15.7322 18.3432 15.8024 18.3238 15.8737 18.3258C15.9654 18.3258 16.0534 18.3623 16.1182 18.4271C16.1831 18.492 16.2195 18.5799 16.2195 18.6716Z"
                                fill="#213550"
                            />
                            <path
                                d="M19.2571 18.6716C19.2588 18.742 19.2395 18.8114 19.2016 18.8708C19.1637 18.9302 19.109 18.977 19.0444 19.0051C18.9798 19.0333 18.9083 19.0416 18.839 19.0289C18.7697 19.0162 18.7057 18.9831 18.6553 18.9339C18.6048 18.8847 18.5702 18.8216 18.5558 18.7526C18.5414 18.6836 18.5479 18.6119 18.5745 18.5466C18.601 18.4814 18.6464 18.4255 18.7049 18.3862C18.7634 18.3468 18.8322 18.3258 18.9027 18.3258C18.9488 18.3246 18.9947 18.3327 19.0377 18.3496C19.0806 18.3664 19.1198 18.3917 19.1528 18.424C19.1858 18.4562 19.2121 18.4947 19.23 18.5372C19.2479 18.5797 19.2571 18.6254 19.2571 18.6716Z"
                                fill="#213550"
                            />
                        </svg>

                        Offline Dersler
                    </router-link>
                </li>
                <li class="left-menu__item">
                    <router-link class="left-menu__link" :to="{name: 'lesson_list', params: { tab: 'video' } }">
                        <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M25.2754 14.076C26.0621 9.03519 22.6134 4.31106 17.5726 3.5244C12.5318 2.73772 7.80767 6.18639 7.02098 11.2272C6.23432 16.268 9.683 20.9922 14.7238 21.7788C19.7646 22.5655 24.4887 19.1168 25.2754 14.076Z"
                                fill="#FF8084"
                            />
                            <path
                                d="M20.6879 25.7913H3.63037L5.1337 20.9863H19.1866L20.6879 25.7913Z"
                                fill="white"
                            />
                            <path
                                d="M20.6934 26.1229H3.62984C3.57806 26.1226 3.52706 26.1103 3.48095 26.0868C3.43481 26.0633 3.39482 26.0293 3.36416 25.9876C3.33353 25.9459 3.31307 25.8975 3.30443 25.8465C3.29582 25.7954 3.29927 25.7431 3.31451 25.6936L4.81784 20.8886C4.83863 20.8217 4.88018 20.7631 4.93652 20.7214C4.99286 20.6797 5.06105 20.657 5.13113 20.6567H19.186C19.2564 20.6566 19.3251 20.6791 19.3818 20.7208C19.4386 20.7625 19.4805 20.8213 19.5013 20.8886L20.9843 25.6346C21.0113 25.6824 21.0253 25.7364 21.025 25.7913C21.025 25.8348 21.0164 25.8779 20.9998 25.9182C20.9831 25.9584 20.9587 25.9949 20.9279 26.0257C20.8971 26.0565 20.8605 26.0809 20.8203 26.0976C20.7801 26.1143 20.737 26.1229 20.6934 26.1229ZM4.07942 25.4617H20.2377L18.9439 21.3158H5.37524L4.07942 25.4617Z"
                                fill="#213550"
                            />
                            <path
                                d="M18.5839 23.4727L18.1425 21.9673H6.17476L5.73535 23.4727H18.5839Z"
                                fill="#D8D8D9"
                            />
                            <path
                                d="M10.4203 23.9326L10.1904 24.9152H14.1288L13.8969 23.9326H10.4203Z"
                                fill="#E9ECF0"
                            />
                            <path
                                d="M19.1857 11.5117H5.13281V20.9854H19.1857V11.5117Z"
                                fill="#E9ECF0"
                            />
                            <path
                                d="M19.1863 21.3165H5.13139C5.08795 21.3167 5.04487 21.3084 5.00467 21.2919C4.96444 21.2755 4.92787 21.2513 4.89706 21.2207C4.86622 21.19 4.84177 21.1536 4.82509 21.1135C4.80841 21.0734 4.7998 21.0303 4.7998 20.9869V11.5132C4.7998 11.4253 4.83475 11.3409 4.89694 11.2788C4.9591 11.2166 5.04346 11.1816 5.13139 11.1816H19.1863C19.2297 11.1816 19.2728 11.1902 19.3129 11.2069C19.353 11.2236 19.3894 11.2481 19.42 11.2789C19.4507 11.3097 19.4749 11.3463 19.4913 11.3865C19.5078 11.4267 19.5161 11.4698 19.5159 11.5132V20.9869C19.5161 21.0303 19.5078 21.0732 19.4913 21.1133C19.4748 21.1534 19.4506 21.1899 19.4199 21.2205C19.3893 21.2512 19.3528 21.2754 19.3127 21.2919C19.2726 21.3084 19.2296 21.3167 19.1863 21.3165ZM5.46298 20.6574H18.8567V11.8428H5.46298V20.6574Z"
                                fill="#213550"
                            />
                            <path
                                d="M17.7031 12.5767H6.61621V19.9204H17.7031V12.5767Z"
                                fill="white"
                            />
                            <path
                                d="M21.9648 6.84863H10.3368C10.175 6.84863 10.0198 6.91295 9.9053 7.0274C9.79085 7.14185 9.72656 7.29707 9.72656 7.45892V9.54815H22.5751V7.45892C22.5751 7.29707 22.5108 7.14185 22.3963 7.0274C22.2819 6.91295 22.1267 6.84863 21.9648 6.84863Z"
                                fill="white"
                            />
                            <path
                                d="M21.965 6.51611H10.337C10.0886 6.51827 9.85116 6.61844 9.67629 6.79484C9.50142 6.97124 9.40332 7.20959 9.40332 7.45799V9.87674H22.9069V7.45799C22.9064 7.20836 22.807 6.96908 22.6304 6.79256C22.4539 6.61604 22.2147 6.51665 21.965 6.51611ZM22.2458 9.2156H10.0563V7.45799C10.0563 7.42112 10.0636 7.38461 10.0777 7.35056C10.0918 7.31648 10.1125 7.28555 10.1385 7.25948C10.1646 7.23341 10.1956 7.21274 10.2296 7.19861C10.2637 7.18451 10.3002 7.17725 10.337 7.17725H21.965C22.0395 7.17725 22.1109 7.20683 22.1636 7.25948C22.2162 7.31213 22.2458 7.38353 22.2458 7.45799V9.2156Z"
                                fill="#213550"
                            />
                            <path
                                d="M9.72412 9.54688V17.8569C9.72412 18.0188 9.78844 18.174 9.90289 18.2885C10.0173 18.4029 10.1726 18.4672 10.3344 18.4672H21.9644C22.1263 18.4672 22.2815 18.4029 22.396 18.2885C22.5104 18.174 22.5747 18.0188 22.5747 17.8569V9.54688H9.72412Z"
                                fill="#BCD3FF"
                            />
                            <path
                                d="M21.965 18.7897H10.337C10.0904 18.7875 9.85443 18.6888 9.67983 18.5146C9.50526 18.3403 9.40596 18.1046 9.40332 17.858V9.21631H22.9069V17.858C22.9037 18.1058 22.8031 18.3425 22.6269 18.5168C22.4507 18.6911 22.2129 18.7892 21.965 18.7897ZM10.0563 9.87745V17.858C10.0563 17.9324 10.0859 18.0038 10.1385 18.0565C10.1912 18.1091 10.2626 18.1387 10.337 18.1387H21.965C22.0395 18.1387 22.1109 18.1091 22.1636 18.0565C22.2162 18.0038 22.2458 17.9324 22.2458 17.858V9.87745H10.0563Z"
                                fill="#213550"
                            />
                            <path
                                d="M21.3531 10.7676H10.9478V17.2346H21.3531V10.7676Z"
                                fill="white"
                            />
                            <path
                                d="M20.8418 8.19706C20.8418 8.27107 20.8638 8.34346 20.9049 8.40502C20.946 8.46655 21.0045 8.51455 21.0729 8.54287C21.1413 8.57119 21.2165 8.5786 21.2891 8.56417C21.3618 8.54971 21.4284 8.51407 21.4808 8.46172C21.5331 8.40937 21.5688 8.34268 21.5832 8.27008C21.5977 8.19748 21.5903 8.12221 21.5619 8.05381C21.5336 7.98541 21.4856 7.92694 21.4241 7.88581C21.3625 7.84468 21.2901 7.82275 21.2161 7.82275C21.1168 7.82275 21.0216 7.86217 20.9514 7.93237C20.8812 8.00257 20.8418 8.09779 20.8418 8.19706Z"
                                fill="#EA5648"
                            />
                            <path
                                d="M19.6055 8.19707C19.6055 8.27102 19.6274 8.34329 19.6684 8.40482C19.7094 8.46632 19.7678 8.51429 19.836 8.54267C19.9043 8.57108 19.9795 8.57861 20.052 8.56433C20.1246 8.55005 20.1913 8.51459 20.2437 8.46245C20.2962 8.41031 20.332 8.3438 20.3466 8.27132C20.3613 8.19884 20.3542 8.12366 20.3262 8.05523C20.2981 7.9868 20.2505 7.92821 20.1892 7.88684C20.1279 7.84544 20.0557 7.82315 19.9818 7.82276C19.9325 7.82249 19.8836 7.83197 19.8379 7.85066C19.7923 7.86935 19.7508 7.89689 19.7158 7.93166C19.6808 7.96646 19.6531 8.0078 19.6341 8.05334C19.6152 8.09891 19.6055 8.14775 19.6055 8.19707Z"
                                fill="#EA5648"
                            />
                            <path
                                d="M18.3667 8.19706C18.3667 8.27107 18.3887 8.34346 18.4298 8.40502C18.4709 8.46655 18.5294 8.51455 18.5978 8.54287C18.6662 8.57119 18.7414 8.5786 18.814 8.56417C18.8866 8.54971 18.9533 8.51407 19.0057 8.46172C19.058 8.40937 19.0937 8.34268 19.1081 8.27008C19.1225 8.19748 19.1151 8.12221 19.0868 8.05381C19.0585 7.98541 19.0105 7.92694 18.949 7.88581C18.8874 7.84468 18.815 7.82275 18.741 7.82275C18.6417 7.82275 18.5465 7.86217 18.4763 7.93237C18.4061 8.00257 18.3667 8.09779 18.3667 8.19706Z"
                                fill="#EA5648"
                            />
                            <path
                                d="M19.8638 11.7266H12.4346V12.3877H19.8638V11.7266Z"
                                fill="#213550"
                            />
                            <path
                                d="M19.8638 13.5181H12.4346V14.1792H19.8638V13.5181Z"
                                fill="#213550"
                            />
                            <path
                                d="M16.5869 15.3369H15.9258V15.9492H16.5869V15.3369Z"
                                fill="#213550"
                            />
                            <path
                                d="M18.2383 15.3369H17.5771V15.9492H18.2383V15.3369Z"
                                fill="#213550"
                            />
                            <path
                                d="M19.8906 15.3369H19.2295V15.9492H19.8906V15.3369Z"
                                fill="#213550"
                            />
                        </svg>

                        Video Dersler
                    </router-link>
                </li>
                <li class="left-menu__item">
                    <router-link class="left-menu__link" :to="{ name: 'lesson_detail', params: { id: 106 } }">
                        <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M13.9603 17.467C17.121 17.467 19.6833 14.9047 19.6833 11.744C19.6833 8.58327 17.121 6.021 13.9603 6.021C10.7996 6.021 8.2373 8.58327 8.2373 11.744C8.2373 14.9047 10.7996 17.467 13.9603 17.467Z"
                                fill="#FF8084"
                            />
                            <path
                                d="M22.253 12.0962H7.53857V22.3993H22.253V12.0962Z"
                                fill="#AECBFF"
                            />
                            <path
                                d="M22.2531 22.6698H7.53207C7.46112 22.668 7.39374 22.6384 7.34451 22.5873C7.29528 22.5362 7.26819 22.4678 7.26906 22.3968V12.0971C7.26951 12.0258 7.2981 11.9577 7.34862 11.9075C7.39914 11.8573 7.46745 11.8291 7.53867 11.8291H22.2597C22.3307 11.8295 22.3986 11.8579 22.4487 11.9081C22.4989 11.9582 22.5272 12.0261 22.5277 12.0971V22.3968C22.5281 22.4329 22.5213 22.4688 22.5077 22.5022C22.494 22.5356 22.4738 22.566 22.4482 22.5914C22.4225 22.6169 22.3921 22.6369 22.3586 22.6504C22.3251 22.6638 22.2892 22.6704 22.2531 22.6698ZM7.80663 22.1322H21.9835V12.3733H7.80663V22.1322Z"
                                fill="#213550"
                            />
                            <path
                                d="M21.1781 22.3993V13.1714H8.6123V22.3993H21.1781Z"
                                fill="white"
                            />
                            <path
                                d="M17.017 21.5693V22.3964H12.7743V21.5693H6.56836V22.6825C6.56836 22.8589 6.6031 23.0335 6.67057 23.1965C6.73807 23.3595 6.83701 23.5075 6.96172 23.6322C7.08646 23.7569 7.23451 23.8559 7.39747 23.9234C7.5604 23.9909 7.73506 24.0256 7.91143 24.0256H21.8799C22.0563 24.0256 22.2309 23.9909 22.3939 23.9234C22.5568 23.8559 22.7049 23.7569 22.8296 23.6322C22.9543 23.5075 23.0532 23.3595 23.1207 23.1965C23.1882 23.0335 23.223 22.8589 23.223 22.6825V21.5693H17.017Z"
                                fill="white"
                            />
                            <path
                                d="M21.8793 24.2996H7.91083C7.48342 24.2991 7.07365 24.129 6.77155 23.8266C6.46948 23.5242 6.2998 23.1143 6.2998 22.6869V21.5687C6.30022 21.4978 6.3286 21.4299 6.37876 21.3797C6.42892 21.3296 6.49681 21.3012 6.56776 21.3008H12.7737C12.845 21.3008 12.9133 21.329 12.9638 21.3791C13.0143 21.4294 13.0429 21.4975 13.0434 21.5687V22.1311H16.7468V21.5687C16.7472 21.4975 16.7758 21.4294 16.8263 21.3791C16.8768 21.329 16.9452 21.3008 17.0164 21.3008H23.2224C23.2576 21.3008 23.2924 21.3077 23.3249 21.3212C23.3574 21.3346 23.387 21.3544 23.4119 21.3793C23.4367 21.4041 23.4565 21.4337 23.4699 21.4662C23.4834 21.4987 23.4903 21.5335 23.4903 21.5687V22.6819C23.491 22.894 23.4498 23.1041 23.3692 23.3002C23.2885 23.4963 23.17 23.6746 23.0203 23.8249C22.8707 23.9751 22.6929 24.0944 22.4971 24.1759C22.3013 24.2573 22.0914 24.2993 21.8793 24.2996ZM6.83737 21.8433V22.6869C6.83737 22.9717 6.95041 23.2449 7.15168 23.4465C7.35295 23.6481 7.62598 23.7616 7.91083 23.762H21.8793C22.1642 23.7616 22.4372 23.6481 22.6385 23.4465C22.8397 23.2449 22.9528 22.9717 22.9528 22.6869V21.8433H17.2844V22.3958C17.2844 22.467 17.2562 22.5353 17.206 22.5858C17.1558 22.6363 17.0876 22.6649 17.0164 22.6654H12.7737C12.7025 22.6649 12.6344 22.6363 12.5842 22.5858C12.534 22.5353 12.5058 22.467 12.5058 22.3958V21.8433H6.83737Z"
                                fill="#213550"
                            />
                            <path
                                d="M8.64597 22.5332H8.1084V23.0708H8.64597V22.5332Z"
                                fill="#213550"
                            />
                            <path
                                d="M10.0366 22.5332H9.49902V23.0708H10.0366V22.5332Z"
                                fill="#213550"
                            />
                            <path
                                d="M11.4277 22.5332H10.8901V23.0708H11.4277V22.5332Z"
                                fill="#213550"
                            />
                            <path
                                d="M18.9018 22.5332H18.3643V23.0708H18.9018V22.5332Z"
                                fill="#213550"
                            />
                            <path
                                d="M20.292 22.5332H19.7544V23.0708H20.292V22.5332Z"
                                fill="#213550"
                            />
                            <path
                                d="M21.6831 22.5332H21.1455V23.0708H21.6831V22.5332Z"
                                fill="#213550"
                            />
                            <path
                                d="M20.8554 12.3665H13.26C13.2229 12.3694 13.1856 12.3646 13.1505 12.3525C13.1154 12.3403 13.0831 12.321 13.0558 12.2957C13.0285 12.2705 13.0067 12.2398 12.9918 12.2058C12.9769 12.1717 12.9692 12.1349 12.9692 12.0977C12.9692 12.0606 12.9769 12.0238 12.9918 11.9897C13.0067 11.9556 13.0285 11.925 13.0558 11.8998C13.0831 11.8745 13.1154 11.8552 13.1505 11.843C13.1856 11.8308 13.2229 11.826 13.26 11.829H20.8554C20.923 11.8343 20.9862 11.8649 21.0322 11.9147C21.0783 11.9645 21.1039 12.0299 21.1039 12.0977C21.1039 12.1656 21.0783 12.231 21.0322 12.2808C20.9862 12.3306 20.923 12.3612 20.8554 12.3665Z"
                                fill="#213550"
                            />
                            <path
                                d="M13.9365 20.1187H9.7666V20.5107H13.9365V20.1187Z"
                                fill="#213550"
                            />
                            <path
                                d="M13.9365 18.9526H9.7666V19.3446H13.9365V18.9526Z"
                                fill="#213550"
                            />
                            <path
                                d="M13.9365 17.7861H9.7666V18.1781H13.9365V17.7861Z"
                                fill="#213550"
                            />
                            <path
                                d="M13.9365 16.6182H9.7666V17.0102H13.9365V16.6182Z"
                                fill="#213550"
                            />
                            <path
                                d="M13.9365 15.4521H9.7666V15.8442H13.9365V15.4521Z"
                                fill="#213550"
                            />
                            <path
                                d="M13.9365 14.2861H9.7666V14.6781H13.9365V14.2861Z"
                                fill="#213550"
                            />
                            <path
                                d="M20.0229 20.1187H15.853V20.5107H20.0229V20.1187Z"
                                fill="#213550"
                            />
                            <path
                                d="M20.0229 18.9526H15.853V19.3446H20.0229V18.9526Z"
                                fill="#213550"
                            />
                            <path
                                d="M20.0229 17.7861H15.853V18.1781H20.0229V17.7861Z"
                                fill="#213550"
                            />
                            <path
                                d="M20.0229 16.6182H15.853V17.0102H20.0229V16.6182Z"
                                fill="#213550"
                            />
                            <path
                                d="M20.0229 15.4521H15.853V15.8442H20.0229V15.4521Z"
                                fill="#213550"
                            />
                            <path
                                d="M20.0229 14.2861H15.853V14.6781H20.0229V14.2861Z"
                                fill="#213550"
                            />
                            <path
                                d="M16.1097 13.1465C16.0062 13.0582 15.8732 13.0121 15.7373 13.0175C15.6013 13.0229 15.4724 13.0793 15.3762 13.1755C15.28 13.2717 15.2236 13.4006 15.2182 13.5366C15.2128 13.6725 15.2589 13.8055 15.3472 13.909L19.0158 18.1285C19.0994 18.2255 19.2022 18.3043 19.3177 18.3599C19.4331 18.4154 19.5588 18.4466 19.6868 18.4514C19.8149 18.4561 19.9425 18.4344 20.0618 18.3876C20.181 18.3408 20.2893 18.2698 20.3799 18.1793C20.4705 18.0887 20.5415 17.9803 20.5883 17.8611C20.6351 17.7418 20.6568 17.6142 20.652 17.4861C20.6473 17.3581 20.6161 17.2324 20.5606 17.117C20.505 17.0015 20.4262 16.8988 20.3291 16.8152L16.1097 13.1465Z"
                                fill="#E9ECF0"
                            />
                            <path
                                d="M19.7176 18.7163C19.5461 18.7166 19.3765 18.6799 19.2204 18.6089C19.0643 18.5378 18.9253 18.434 18.8129 18.3045L15.1442 14.0867C15.0102 13.9315 14.9397 13.7316 14.9468 13.5267C14.9538 13.3219 15.0379 13.1273 15.1822 12.9817C15.3292 12.8402 15.5234 12.758 15.7274 12.751C15.9313 12.744 16.1307 12.8126 16.2871 12.9437L20.505 16.6074C20.6292 16.7154 20.7298 16.8477 20.8007 16.9962C20.8716 17.1448 20.9111 17.3063 20.9168 17.4708C20.9226 17.6352 20.8944 17.7991 20.8341 17.9522C20.7737 18.1053 20.6825 18.2444 20.5662 18.3607C20.4554 18.4733 20.3233 18.5627 20.1776 18.6238C20.032 18.6848 19.8756 18.7163 19.7176 18.7163ZM15.7479 13.2828C15.6956 13.2827 15.6443 13.2977 15.6002 13.3259C15.5562 13.3542 15.5211 13.3945 15.4993 13.4421C15.4776 13.4897 15.4699 13.5425 15.4773 13.5944C15.4847 13.6462 15.5069 13.6948 15.5412 13.7343L19.2181 17.9522C19.2793 18.0185 19.353 18.072 19.435 18.1096C19.517 18.1472 19.6057 18.1682 19.6958 18.1713C19.786 18.1743 19.8759 18.1595 19.9603 18.1275C20.0446 18.0956 20.1218 18.0472 20.1874 17.9853C20.2516 17.921 20.3019 17.8442 20.3351 17.7597C20.3683 17.6751 20.3838 17.5847 20.3805 17.4939C20.3773 17.4031 20.3554 17.314 20.3161 17.232C20.2769 17.1501 20.2213 17.0771 20.1526 17.0176L15.9348 13.3489C15.8851 13.3063 15.8217 13.2828 15.7562 13.2828H15.7479Z"
                                fill="#213550"
                            />
                            <path
                                d="M21.8863 18.1833L17.0515 13.9853C16.9336 13.8805 16.7801 13.8247 16.6224 13.8294C16.4647 13.834 16.3147 13.8988 16.2031 14.0103C16.0916 14.1219 16.0269 14.2718 16.0222 14.4295C16.0176 14.5872 16.0734 14.7407 16.1782 14.8587L20.3828 19.6852C20.4786 19.7956 20.5961 19.8852 20.728 19.9482C20.8598 20.0113 21.0033 20.0465 21.1494 20.0518C21.2955 20.057 21.4411 20.0321 21.5772 19.9786C21.7132 19.9251 21.8368 19.8442 21.9403 19.7409C22.0437 19.6375 22.1248 19.514 22.1784 19.378C22.232 19.242 22.2571 19.0965 22.252 18.9503C22.247 18.8042 22.2119 18.6607 22.149 18.5288C22.0861 18.3968 21.9966 18.2792 21.8863 18.1833Z"
                                fill="#FF8084"
                            />
                            <path
                                d="M21.1865 20.3199C20.9958 20.3199 20.8074 20.279 20.6339 20.1999C20.4604 20.1209 20.3059 20.0055 20.1808 19.8617L15.9828 15.0335C15.8356 14.8638 15.758 14.6446 15.7657 14.42C15.7733 14.1955 15.8657 13.9821 16.0242 13.8228C16.1859 13.6687 16.3985 13.5794 16.6217 13.5718C16.8449 13.5642 17.0631 13.6388 17.2349 13.7814L22.0631 17.9794C22.2687 18.1576 22.4148 18.3944 22.4818 18.6581C22.5489 18.9217 22.5337 19.1996 22.4383 19.4543C22.3429 19.7091 22.1718 19.9286 21.948 20.0833C21.7243 20.238 21.4585 20.3206 21.1865 20.3199ZM16.6527 14.1007C16.5856 14.101 16.52 14.1206 16.4637 14.1571C16.4074 14.1936 16.3628 14.2455 16.3351 14.3066C16.3074 14.3678 16.2978 14.4355 16.3075 14.5019C16.3172 14.5683 16.3457 14.6306 16.3897 14.6812L20.5861 19.5094C20.6578 19.5919 20.7458 19.6588 20.8445 19.7059C20.9432 19.7529 21.0506 19.7792 21.1599 19.783C21.2692 19.7868 21.3781 19.7681 21.4799 19.728C21.5816 19.6879 21.674 19.6273 21.7514 19.55C21.8287 19.4726 21.8893 19.3802 21.9294 19.2785C21.9695 19.1767 21.9882 19.0678 21.9844 18.9585C21.9806 18.8492 21.9544 18.7418 21.9073 18.6431C21.8602 18.5444 21.7933 18.4564 21.7108 18.3846L16.8859 14.1867C16.821 14.131 16.7383 14.1005 16.6527 14.1007Z"
                                fill="#213550"
                            />
                            <path
                                d="M15.6231 15.821C17.8798 14.9005 18.9647 12.329 18.0463 10.0773C17.1279 7.82569 14.5539 6.74656 12.2972 7.66708C10.0405 8.58757 8.95553 11.1591 9.87395 13.4107C10.7924 15.6624 13.3663 16.7415 15.6231 15.821Z"
                                fill="white"
                            />
                            <path
                                d="M13.9521 16.4169C12.7101 16.4158 11.5194 15.9215 10.6417 15.0427C9.76409 14.1639 9.27143 12.9725 9.27197 11.7306C9.27251 10.4886 9.76628 9.29765 10.6447 8.41961C11.5231 7.5416 12.7143 7.04834 13.9562 7.04834C15.1982 7.04834 16.3894 7.5416 17.2678 8.41961C18.1462 9.29765 18.64 10.4886 18.6405 11.7306C18.6411 12.9725 18.1483 14.1639 17.2707 15.0427C16.3931 15.9215 15.2024 16.4158 13.9604 16.4169H13.9521ZM13.967 7.60907C13.4239 7.60811 12.886 7.7141 12.384 7.92098C11.8819 8.12789 11.4255 8.43167 11.0408 8.81495C10.6561 9.19826 10.3507 9.65357 10.142 10.1549C9.93332 10.6562 9.82541 11.1937 9.82442 11.7368C9.82343 12.2798 9.92942 12.8177 10.1363 13.3198C10.3432 13.8218 10.647 14.2782 11.0303 14.6629C11.4136 15.0476 11.8689 15.353 12.3702 15.5617C12.8716 15.7704 13.4091 15.8784 13.9521 15.8793V16.1473V15.8793C15.0488 15.8804 16.101 15.4458 16.8773 14.6711C17.6536 13.8964 18.0903 12.845 18.0914 11.7483C18.0925 10.6516 17.6578 9.59942 16.8831 8.82314C16.1084 8.04689 15.0571 7.61018 13.9604 7.60907H13.967Z"
                                fill="#213550"
                            />
                            <path
                                d="M13.9521 15.2693C13.256 15.266 12.5765 15.0567 11.9992 14.6678C11.4219 14.2789 10.9727 13.7277 10.7082 13.0838C10.4437 12.44 10.3758 11.7322 10.513 11.0498C10.6502 10.3674 10.9864 9.74084 11.4791 9.2492C11.9719 8.75759 12.5992 8.42285 13.282 8.28725C13.9647 8.15165 14.6723 8.22125 15.3156 8.48723C15.9588 8.75324 16.5089 9.20372 16.8965 9.78194C17.2841 10.3601 17.4918 11.0401 17.4934 11.7362C17.4947 12.2012 17.404 12.6619 17.2264 13.0917C17.0488 13.5215 16.7878 13.9119 16.4586 14.2403C16.1294 14.5688 15.7384 14.8288 15.3082 15.0054C14.878 15.182 14.4171 15.2717 13.9521 15.2693Z"
                                fill="#E9ECF0"
                            />
                            <path
                                d="M16.1974 11.7413C16.1633 11.7415 16.1294 11.7349 16.0979 11.7218C16.0663 11.7087 16.0378 11.6893 16.0138 11.6649C15.9899 11.6406 15.9712 11.6116 15.9586 11.5799C15.9461 11.5481 15.9402 11.5141 15.941 11.48C15.9384 11.0152 15.7517 10.5705 15.4219 10.2431C15.092 9.91571 14.6459 9.73241 14.1811 9.73328C14.1169 9.72812 14.057 9.69896 14.0133 9.65159C13.9696 9.60425 13.9453 9.54218 13.9453 9.47774C13.9453 9.4133 13.9696 9.35123 14.0133 9.30386C14.057 9.25652 14.1169 9.22736 14.1811 9.22217C14.7814 9.22172 15.3575 9.45899 15.7834 9.88208C16.2093 10.3052 16.4503 10.8797 16.4538 11.48C16.4545 11.514 16.4483 11.5479 16.4357 11.5796C16.4231 11.6113 16.4044 11.6401 16.3805 11.6645C16.3566 11.6888 16.3281 11.7081 16.2967 11.7213C16.2652 11.7345 16.2315 11.7413 16.1974 11.7413Z"
                                fill="white"
                            />
                            <path
                                d="M13.9385 14.291H11.5137C11.664 14.4356 11.8268 14.5668 12 14.683H13.9385V14.291Z"
                                fill="#213550"
                            />
                            <path
                                d="M13.9381 12.9678H10.6499C10.6997 13.1019 10.7577 13.2328 10.8236 13.3598H13.9381V12.9678Z"
                                fill="#213550"
                            />
                            <path
                                d="M13.9379 11.665H10.4346C10.4346 11.6948 10.4346 11.7229 10.4346 11.7527C10.4346 11.8553 10.4346 11.9562 10.4495 12.057H13.9379V11.665Z"
                                fill="#213550"
                            />
                            <path
                                d="M13.9389 10.3535H10.7184C10.6634 10.4813 10.6159 10.6122 10.5762 10.7455H13.9389V10.3535Z"
                                fill="#213550"
                            />
                        </svg>

                        Online Ek Dersler
                    </router-link>
                </li>
                <li class="left-menu__item">
                    <router-link :to="{ name: 'paragraphs_list' }" class="left-menu__link">
                        <svg
                            width="19"
                            height="25"
                            viewBox="0 0 19 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M17.8907 18.2012H1.17285V23.9029H17.8907V18.2012Z"
                                fill="#FE9A38"
                            />
                            <path
                                d="M15.7246 18.7539L15.7246 0.00390625L10.6408 0.00390625V18.7539H15.7246Z"
                                fill="#FE9A38"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.1452 0V10.4167L13.0028 8.83772L14.8603 10.4167V0H18.5754V25H2.78826C2.04944 25 1.34083 24.6711 0.818044 24.0856C0.295256 23.5001 0.00103621 22.7058 0 21.8772V3.12719C0 2.29781 0.293762 1.5024 0.816663 0.915934C1.33956 0.329471 2.04877 0 2.78826 0H11.1452ZM16.7178 20.8333H3.2458C3.11876 20.8241 2.99139 20.8442 2.87154 20.8923C2.7517 20.9405 2.64194 21.0157 2.54906 21.1133C2.45617 21.211 2.38213 21.3289 2.33151 21.46C2.28089 21.591 2.25478 21.7322 2.25478 21.875C2.25478 22.0178 2.28089 22.159 2.33151 22.29C2.38213 22.4211 2.45617 22.539 2.54906 22.6367C2.64194 22.7343 2.7517 22.8095 2.87154 22.8577C2.99139 22.9058 3.11876 22.9259 3.2458 22.9167H16.7178V20.8333Z"
                                fill="#3271E6"
                            />
                        </svg>
                        Paragraf Okuma
                    </router-link>
                </li>
                <!--<li class="left-menu__item">
                    <router-link :to="{ name: 'full_paragraph_question' }" class="left-menu__link">
                        <img class="svg-icon" src="../assets/icons/document-question.svg" :alt="$t('menu.full_paragraph_question')"/>
                        {{ $t('menu.full_paragraph_question') }}
                    </router-link>
                </li>-->
                <li class="left-menu__item">
                    <a href="https://yds.net/blog" class="left-menu__link">
                        <svg
                            width="26"
                            height="26"
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M22.3029 25.224H1.21852C0.633516 25.224 0.158203 24.7487 0.158203 24.1637V3.07936C0.158203 2.49436 0.633516 2.01904 1.21852 2.01904H22.3029C22.8879 2.01904 23.3632 2.49436 23.3632 3.07936V24.1637C23.3632 24.7487 22.8879 25.224 22.3029 25.224Z"
                                fill="#EFF3F5"
                            />
                            <path
                                d="M23.3635 24.168V24.9439C23.3635 25.5289 22.8923 26.0002 22.3073 26.0002H1.21885C0.633848 26.0002 0.162598 25.5289 0.162598 24.9439V24.168C0.162598 24.753 0.633848 25.2242 1.21885 25.2242H22.3032C22.8882 25.2242 23.3635 24.7489 23.3635 24.168Z"
                                fill="#C6C1C1"
                            />
                            <path
                                d="M6.45117 19.3902H14.3487L23.3634 16.3027V6.22363L6.45117 19.3902Z"
                                fill="#D3D3D3"
                            />
                            <path
                                d="M20.0689 7.12549H3.44922V8.15736H20.0689V7.12549Z"
                                fill="#BDBDBE"
                            />
                            <path
                                d="M20.0689 11.1108H3.44922V12.1427H20.0689V11.1108Z"
                                fill="#BDBDBE"
                            />
                            <path
                                d="M20.0689 15.0962H3.44922V16.1281H20.0689V15.0962Z"
                                fill="#BDBDBE"
                            />
                            <path
                                d="M20.0689 19.0854H3.44922V20.1173H20.0689V19.0854Z"
                                fill="#BDBDBE"
                            />
                            <path
                                d="M13.0205 16.9611C12.9718 16.9936 12.923 17.0221 12.8702 17.0383C12.8337 17.0586 12.7971 17.0708 12.7605 17.0789L8.44615 18.6999L7.14209 17.3958L8.75896 13.0814C8.77115 13.0449 8.78334 13.0043 8.79959 12.9677C8.8199 12.9149 8.84834 12.8661 8.88084 12.8174L13.0205 16.9611Z"
                                fill="#FCD66F"
                            />
                            <path
                                d="M7.58457 17.8427L7.1377 17.3958L8.75457 13.0814C8.76676 13.0449 8.77895 13.0043 8.7952 12.9677C8.81551 12.9149 8.84395 12.8661 8.87645 12.8174L10.4649 13.9793L7.58457 17.8427Z"
                                fill="#FBD984"
                            />
                            <path
                                d="M13.0203 16.9612C12.9715 16.9937 12.9228 17.0221 12.87 17.0383C12.8334 17.0587 12.7968 17.0708 12.7603 17.079L8.4459 18.6999L7.99902 18.253L11.8625 15.3687L13.0203 16.9612Z"
                                fill="#E6AC43"
                            />
                            <path
                                d="M11.8584 15.3723L7.99898 18.2567L6.45117 19.3901L7.58461 17.8423L10.469 13.9829L11.8584 15.3723Z"
                                fill="#FCD66F"
                            />
                            <path
                                d="M6.45117 19.3905L8.4418 18.704L7.13773 17.3999L6.45117 19.3905Z"
                                fill="#2E435A"
                            />
                            <path
                                d="M22.7623 7.25541L13.187 16.8307C13.1342 16.8835 13.0814 16.9282 13.0245 16.9607C12.9758 16.9932 12.927 17.0217 12.8742 17.0379C12.8376 17.0582 12.8011 17.0704 12.7645 17.0785C12.4314 17.172 12.0617 17.0907 11.7976 16.8267C11.5133 16.5423 11.4401 16.132 11.5742 15.7785C11.5945 15.7135 11.627 15.6526 11.6676 15.5957C11.7042 15.5388 11.7448 15.482 11.7976 15.4332L21.373 5.85791L22.7623 7.25541Z"
                                fill="#324A5E"
                            />
                            <path
                                d="M10.4002 14.0481L19.9796 4.46875L21.373 5.86219L11.7936 15.4416C11.4077 15.8275 10.7861 15.8275 10.4002 15.4416C10.0183 15.0556 10.0183 14.4341 10.4002 14.0481Z"
                                fill="#3271E6"
                            />
                            <path
                                d="M19.9795 4.46896L10.4001 14.0483C10.3473 14.1012 10.2945 14.1458 10.2376 14.1783C9.85575 14.4262 9.34387 14.3815 9.01075 14.0483C8.75075 13.7883 8.66544 13.4187 8.75887 13.0855C8.77106 13.049 8.78325 13.0083 8.7995 12.9718C8.81981 12.919 8.84825 12.8702 8.88075 12.8215C8.91325 12.7605 8.95794 12.7077 9.01075 12.659L18.5861 3.07959L19.9795 4.46896Z"
                                fill="#3271E6"
                            />
                            <path
                                d="M20.0405 4.53375L10.1768 14.3975C10.2255 14.2716 10.2986 14.1497 10.4002 14.0481L19.9796 4.46875L20.0405 4.53375Z"
                                fill="#3271E6"
                            />
                            <path
                                d="M11.5742 15.7829L21.4339 5.92324L21.373 5.8623L11.7936 15.4417L11.5742 15.7829Z"
                                fill="#3271E6"
                            />
                            <path
                                d="M22.6386 4.71779L21.4321 5.92432L22.7622 7.25432L23.9687 6.04779L22.6386 4.71779Z"
                                fill="#DEDEDF"
                            />
                            <path
                                d="M21.247 3.32912L20.0405 4.53564L21.3706 5.86565L22.5771 4.65912L21.247 3.32912Z"
                                fill="#E5E5E5"
                            />
                            <path
                                d="M19.7919 1.87014L18.5854 3.07666L19.9787 4.46986L21.1852 3.26333L19.7919 1.87014Z"
                                fill="#EFF3F5"
                            />
                            <path
                                d="M21.185 3.26467L19.9785 4.47119L20.0417 4.53439L21.2482 3.32787L21.185 3.26467Z"
                                fill="#DEDEDF"
                            />
                            <path
                                d="M22.579 4.65627L21.3726 5.86279L21.4329 5.92312L22.6394 4.71659L22.579 4.65627Z"
                                fill="#DEDEDF"
                            />
                            <path
                                d="M25.6628 4.35477L23.9687 6.04883L22.6362 4.71633L24.7569 2.5957L25.6628 3.50164C25.8984 3.73727 25.8984 4.11914 25.6628 4.35477Z"
                                fill="#A72A45"
                            />
                            <path
                                d="M23.3665 1.21151L21.2466 3.33154L22.5766 4.66154L24.6966 2.54151L23.3665 1.21151Z"
                                fill="#D1395D"
                            />
                            <path
                                d="M23.3675 1.20648L21.2469 3.32711L19.7925 1.87273L21.4865 0.178672C21.7222 -0.0569531 22.1 -0.0569531 22.3397 0.178672L23.3675 1.20648Z"
                                fill="#DF4666"
                            />
                            <path
                                d="M24.6961 2.53524L22.5762 4.65527L22.6365 4.7156L24.7565 2.59557L24.6961 2.53524Z"
                                fill="#DF4666"
                            />
                            <path
                                d="M23.3021 1.14413L21.1821 3.26416L21.2453 3.32736L23.3653 1.20732L23.3021 1.14413Z"
                                fill="#A72A45"
                            />
                            <path
                                d="M24.6961 2.53524L22.5762 4.65527L22.6365 4.7156L24.7565 2.59557L24.6961 2.53524Z"
                                fill="#621A21"
                            />
                            <path
                                d="M22.2192 5.13724L21.8486 5.50781L23.1787 6.83781L23.5492 6.46724L22.2192 5.13724Z"
                                fill="#C6C1C1"
                            />
                            <path
                                d="M20.831 3.74613L20.4604 4.1167L21.7905 5.4467L22.1611 5.07613L20.831 3.74613Z"
                                fill="#D3D3D3"
                            />
                            <path
                                d="M19.3715 2.2886L19.001 2.65918L20.3942 4.05238L20.7648 3.6818L19.3715 2.2886Z"
                                fill="#DEDEDF"
                            />
                            <path
                                d="M20.7656 3.68411L20.395 4.05469L20.4582 4.11788L20.8288 3.74731L20.7656 3.68411Z"
                                fill="#BDBDBE"
                            />
                            <path
                                d="M22.1596 5.07571L21.7891 5.44629L21.8494 5.50661L22.22 5.13604L22.1596 5.07571Z"
                                fill="#BDBDBE"
                            />
                            <path
                                d="M21.185 3.26467L19.9785 4.47119L20.0417 4.53439L21.2482 3.32787L21.185 3.26467Z"
                                fill="#C6C1C1"
                            />
                            <path
                                d="M22.579 4.65627L21.3726 5.86279L21.4329 5.92312L22.6394 4.71659L22.579 4.65627Z"
                                fill="#BDBDBE"
                            />
                        </svg>

                        Tavsiyeler
                    </a>
                </li>

                <li class="left-menu_item">
                    <router-link :to="{ name: 'voice-records' }" class="left-menu__link">
                        <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="12"
                                cy="15"
                                r="9"
                                fill="#577FE0"
                                fill-opacity="0.15"
                            />
                            <path
                                d="M15 21.375C16.2929 21.375 17.5329 20.8614 18.4471 19.9471C19.3614 19.0329 19.875 17.7929 19.875 16.5V8.25C19.875 6.95707 19.3614 5.71709 18.4471 4.80285C17.5329 3.88861 16.2929 3.375 15 3.375C13.7071 3.375 12.4671 3.88861 11.5529 4.80285C10.6386 5.71709 10.125 6.95707 10.125 8.25V16.5C10.125 17.7929 10.6386 19.0329 11.5529 19.9471C12.4671 20.8614 13.7071 21.375 15 21.375V21.375ZM11.625 8.25C11.625 7.35489 11.9806 6.49645 12.6135 5.86351C13.2465 5.23058 14.1049 4.875 15 4.875C15.8951 4.875 16.7535 5.23058 17.3865 5.86351C18.0194 6.49645 18.375 7.35489 18.375 8.25V16.5C18.375 17.3951 18.0194 18.2535 17.3865 18.8865C16.7535 19.5194 15.8951 19.875 15 19.875C14.1049 19.875 13.2465 19.5194 12.6135 18.8865C11.9806 18.2535 11.625 17.3951 11.625 16.5V8.25Z"
                                fill="#577FE0"
                            />
                            <path
                                d="M21.75 14.25C21.5511 14.25 21.3603 14.329 21.2197 14.4697C21.079 14.6103 21 14.8011 21 15V16.5C21 18.0913 20.3679 19.6174 19.2426 20.7426C18.1174 21.8679 16.5913 22.5 15 22.5C13.4087 22.5 11.8826 21.8679 10.7574 20.7426C9.63214 19.6174 9 18.0913 9 16.5V15C9 14.8011 8.92098 14.6103 8.78033 14.4697C8.63968 14.329 8.44891 14.25 8.25 14.25C8.05109 14.25 7.86032 14.329 7.71967 14.4697C7.57902 14.6103 7.5 14.8011 7.5 15V16.5C7.49998 18.3593 8.19058 20.1523 9.43782 21.5312C10.6851 22.9101 12.4 23.7766 14.25 23.9625V25.125H12.375C12.1761 25.125 11.9853 25.204 11.8447 25.3447C11.704 25.4853 11.625 25.6761 11.625 25.875C11.625 26.0739 11.704 26.2647 11.8447 26.4053C11.9853 26.546 12.1761 26.625 12.375 26.625H17.625C17.8239 26.625 18.0147 26.546 18.1553 26.4053C18.296 26.2647 18.375 26.0739 18.375 25.875C18.375 25.6761 18.296 25.4853 18.1553 25.3447C18.0147 25.204 17.8239 25.125 17.625 25.125H15.75V23.9625C17.6 23.7766 19.3149 22.9101 20.5622 21.5312C21.8094 20.1523 22.5 18.3593 22.5 16.5V15C22.5 14.8011 22.421 14.6103 22.2803 14.4697C22.1397 14.329 21.9489 14.25 21.75 14.25Z"
                                fill="#577FE0"
                            />
                        </svg>
                        Ses Kayıtları
                    </router-link>
                </li>

                <!-- <li class="left-menu__item">
                            <router-link
                                class="left-menu__link"
                                :to="{ name: 'coming-soon', params: { feature: 'kelimelik' } }"
                            >
                                <svg
                                    width="28"
                                    height="25"
                                    viewBox="0 0 28 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M18.239 0.599152L3.25694 6.24547C2.38018 6.57589 1.93729 7.5545 2.26772 8.43125L7.91403 23.4133C8.24446 24.2901 9.22307 24.733 10.0998 24.4025L25.0819 18.7562C25.9586 18.4258 26.4015 17.4472 26.0711 16.5704L20.4248 1.58837C20.0944 0.711616 19.1158 0.268727 18.239 0.599152Z"
                                        fill="#3271E6"
                                    />
                                    <path
                                        d="M13.9136 13.249L13.0444 14.1523V16.5547H12.1069V9.44531H13.0444V12.9609L16.2036 9.44531H17.3364L14.5386 12.585L17.5562 16.5547H16.4331L13.9136 13.249Z"
                                        fill="#FE9A38"
                                    />
                                    <path
                                        d="M14.2319 13.5186L13.3628 14.4219V16.8242H12.4253V9.71484H13.3628V13.2305L16.522 9.71484H17.6548L14.8569 12.8545L17.8745 16.8242H16.7515L14.2319 13.5186Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M7.45418 7.42969H0.505908C0.226503 7.42969 0 7.76719 0 8.18352V8.26787C0 8.6842 0.226503 9.02171 0.505908 9.02171H7.45418C7.73358 9.02171 7.96009 8.6842 7.96009 8.26787V8.18352C7.96009 7.76719 7.73358 7.42969 7.45418 7.42969Z"
                                        fill="#FE9A38"
                                    />
                                    <path
                                        d="M27.0889 18.043H20.1407C19.8613 18.043 19.6348 18.3805 19.6348 18.7968V18.8811C19.6348 19.2975 19.8613 19.635 20.1407 19.635H27.0889C27.3684 19.635 27.5949 19.2975 27.5949 18.8811V18.7968C27.5949 18.3805 27.3684 18.043 27.0889 18.043Z"
                                        fill="#FE9A38"
                                    />
                                </svg>
                                Kelimelik
                            </router-link>
                        </li>
                        <li class="left-menu__item">
                            <router-link
                                :to="{ name: 'coming-soon', params: { feature: 'konu-tarama' } }"
                                class="left-menu__link"
                            >
                                <svg
                                    width="21"
                                    height="25"
                                    viewBox="0 0 21 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M20.1613 0H0V25H20.1613V0Z" fill="#3271E6"/>
                                    <path
                                        d="M18.1549 8.06445H3.813C3.47806 8.06445 3.20654 8.33597 3.20654 8.6709V8.67736C3.20654 9.01229 3.47806 9.28381 3.813 9.28381H18.1549C18.4898 9.28381 18.7614 9.01229 18.7614 8.67736V8.6709C18.7614 8.33597 18.4898 8.06445 18.1549 8.06445Z"
                                        fill="#FE9A38"
                                    />
                                    <path
                                        d="M15.6839 12.457H6.75487C6.48763 12.457 6.271 12.6737 6.271 12.9409V13.1925C6.271 13.4597 6.48763 13.6764 6.75487 13.6764H15.6839C15.9511 13.6764 16.1678 13.4597 16.1678 13.1925V12.9409C16.1678 12.6737 15.9511 12.457 15.6839 12.457Z"
                                        fill="#FE9A38"
                                    />
                                    <path
                                        d="M9.14219 16.7363H1.96155C1.72282 16.7363 1.5293 16.9299 1.5293 17.1686V17.5234C1.5293 17.7622 1.72282 17.9557 1.96155 17.9557H9.14219C9.38092 17.9557 9.57445 17.7622 9.57445 17.5234V17.1686C9.57445 16.9299 9.38092 16.7363 9.14219 16.7363Z"
                                        fill="#FE9A38"
                                    />
                                </svg>
                                Konu Tarama
                            </router-link>
                        </li>
                        <li class="left-menu__item">
                            <router-link
                                :to="{ name: 'coming-soon', params: { feature: 'paragraf-okuma' } }"
                                class="left-menu__link"
                            >
                                <svg
                                    width="19"
                                    height="25"
                                    viewBox="0 0 19 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M17.8907 18.2012H1.17285V23.9029H17.8907V18.2012Z"
                                        fill="#FE9A38"
                                    />
                                    <path
                                        d="M15.7246 18.7539L15.7246 0.00390625L10.6408 0.00390625V18.7539H15.7246Z"
                                        fill="#FE9A38"
                                    />
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M11.1452 0V10.4167L13.0028 8.83772L14.8603 10.4167V0H18.5754V25H2.78826C2.04944 25 1.34083 24.6711 0.818044 24.0856C0.295256 23.5001 0.00103621 22.7058 0 21.8772V3.12719C0 2.29781 0.293762 1.5024 0.816663 0.915934C1.33956 0.329471 2.04877 0 2.78826 0H11.1452ZM16.7178 20.8333H3.2458C3.11876 20.8241 2.99139 20.8442 2.87154 20.8923C2.7517 20.9405 2.64194 21.0157 2.54906 21.1133C2.45617 21.211 2.38213 21.3289 2.33151 21.46C2.28089 21.591 2.25478 21.7322 2.25478 21.875C2.25478 22.0178 2.28089 22.159 2.33151 22.29C2.38213 22.4211 2.45617 22.539 2.54906 22.6367C2.64194 22.7343 2.7517 22.8095 2.87154 22.8577C2.99139 22.9058 3.11876 22.9259 3.2458 22.9167H16.7178V20.8333Z"
                                        fill="#3271E6"
                                    />
                                </svg>
                                Paragraf Okuma
                            </router-link>
                        </li>
                        <li class="left-menu__item">
                            <router-link
                                :to="{ name: 'coming-soon', params: { feature: 'sozluk' } }"
                                class="left-menu__link"
                            >
                                <svg
                                    width="25"
                                    height="27"
                                    viewBox="0 0 25 27"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M22.2015 13.6699H14.1818C12.8652 13.6699 11.7979 14.7373 11.7979 16.0539V24.0736C11.7979 25.3902 12.8652 26.4576 14.1818 26.4576H22.2015C23.5182 26.4576 24.5855 25.3902 24.5855 24.0736V16.0539C24.5855 14.7373 23.5182 13.6699 22.2015 13.6699Z"
                                        fill="#3271E6"
                                    />
                                    <path
                                        d="M6.49735 1.83789H6.4943C2.9076 1.83789 0 4.74549 0 8.3322V8.33524C0 11.9219 2.9076 14.8295 6.4943 14.8295H6.49735C10.0841 14.8295 12.9917 11.9219 12.9917 8.33524V8.3322C12.9917 4.74549 10.0841 1.83789 6.49735 1.83789Z"
                                        fill="#FE9A38"
                                    />
                                    <path
                                        d="M19.9067 21.5195H16.3325L15.5298 23.7461H14.3696L17.6274 15.2148H18.6118L21.8755 23.7461H20.7212L19.9067 21.5195ZM16.6724 20.5938H19.5728L18.1196 16.6035L16.6724 20.5938Z"
                                        fill="#FE9A38"
                                    />
                                    <path
                                        d="M20.0713 21.4707H16.4971L15.6943 23.6973H14.5342L17.792 15.166H18.7764L22.04 23.6973H20.8857L20.0713 21.4707ZM16.8369 20.5449H19.7373L18.2842 16.5547L16.8369 20.5449Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M7.82812 12C7.76562 11.875 7.71484 11.6523 7.67578 11.332C7.17188 11.8555 6.57031 12.1172 5.87109 12.1172C5.24609 12.1172 4.73242 11.9414 4.33008 11.5898C3.93164 11.2344 3.73242 10.7852 3.73242 10.2422C3.73242 9.58203 3.98242 9.07031 4.48242 8.70703C4.98633 8.33984 5.69336 8.15625 6.60352 8.15625H7.6582V7.6582C7.6582 7.2793 7.54492 6.97852 7.31836 6.75586C7.0918 6.5293 6.75781 6.41602 6.31641 6.41602C5.92969 6.41602 5.60547 6.51367 5.34375 6.70898C5.08203 6.9043 4.95117 7.14062 4.95117 7.41797H3.86133C3.86133 7.10156 3.97266 6.79688 4.19531 6.50391C4.42188 6.20703 4.72656 5.97266 5.10938 5.80078C5.49609 5.62891 5.91992 5.54297 6.38086 5.54297C7.11133 5.54297 7.68359 5.72656 8.09766 6.09375C8.51172 6.45703 8.72656 6.95898 8.74219 7.59961V10.5176C8.74219 11.0996 8.81641 11.5625 8.96484 11.9062V12H7.82812ZM6.0293 11.1738C6.36914 11.1738 6.69141 11.0859 6.99609 10.9102C7.30078 10.7344 7.52148 10.5059 7.6582 10.2246V8.92383H6.80859C5.48047 8.92383 4.81641 9.3125 4.81641 10.0898C4.81641 10.4297 4.92969 10.6953 5.15625 10.8867C5.38281 11.0781 5.67383 11.1738 6.0293 11.1738Z"
                                        fill="#3271E6"
                                    />
                                    <path
                                        d="M8.03223 11.1035C7.96973 10.9785 7.91895 10.7559 7.87988 10.4355C7.37598 10.959 6.77441 11.2207 6.0752 11.2207C5.4502 11.2207 4.93652 11.0449 4.53418 10.6934C4.13574 10.3379 3.93652 9.88867 3.93652 9.3457C3.93652 8.68555 4.18652 8.17383 4.68652 7.81055C5.19043 7.44336 5.89746 7.25977 6.80762 7.25977H7.8623V6.76172C7.8623 6.38281 7.74902 6.08203 7.52246 5.85938C7.2959 5.63281 6.96191 5.51953 6.52051 5.51953C6.13379 5.51953 5.80957 5.61719 5.54785 5.8125C5.28613 6.00781 5.15527 6.24414 5.15527 6.52148H4.06543C4.06543 6.20508 4.17676 5.90039 4.39941 5.60742C4.62598 5.31055 4.93066 5.07617 5.31348 4.9043C5.7002 4.73242 6.12402 4.64648 6.58496 4.64648C7.31543 4.64648 7.8877 4.83008 8.30176 5.19727C8.71582 5.56055 8.93066 6.0625 8.94629 6.70312V9.62109C8.94629 10.2031 9.02051 10.666 9.16895 11.0098V11.1035H8.03223ZM6.2334 10.2773C6.57324 10.2773 6.89551 10.1895 7.2002 10.0137C7.50488 9.83789 7.72559 9.60938 7.8623 9.32812V8.02734H7.0127C5.68457 8.02734 5.02051 8.41602 5.02051 9.19336C5.02051 9.5332 5.13379 9.79883 5.36035 9.99023C5.58691 10.1816 5.87793 10.2773 6.2334 10.2773Z"
                                        fill="white"
                                    />
                                </svg>
                                Sözlük
                            </router-link>

                            <a href="https://yds.net/AhmetAkin_Deyimler.zip" class="left-menu__link">
                                <svg
                                    width="19"
                                    height="25"
                                    viewBox="0 0 19 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M14.2183 2.75391H4.35721C1.95079 2.75391 0 4.55042 0 6.76652V20.9867C0 23.2028 1.95079 24.9993 4.35721 24.9993H14.2183C16.6247 24.9993 18.5755 23.2028 18.5755 20.9867V6.76652C18.5755 4.55042 16.6247 2.75391 14.2183 2.75391Z"
                                        fill="#3271E6"
                                    />
                                    <path
                                        d="M13.6208 4.03906H4.95897C2.84544 4.03906 1.13208 5.61692 1.13208 7.56331V20.05C1.13208 21.9963 2.84544 23.5742 4.95897 23.5742H13.6208C15.7344 23.5742 17.4477 21.9963 17.4477 20.05V7.56331C17.4477 5.61692 15.7344 4.03906 13.6208 4.03906Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M4.39478 3.12875H14.1794C14.1794 3.12875 9.79829 -3.91094 4.39478 3.12875Z"
                                        fill="#3271E6"
                                    />
                                    <path
                                        d="M9.28752 11.5496C11.2005 11.5496 12.7513 10.1215 12.7513 8.35978C12.7513 6.59807 11.2005 5.16992 9.28752 5.16992C7.37452 5.16992 5.82373 6.59807 5.82373 8.35978C5.82373 10.1215 7.37452 11.5496 9.28752 11.5496Z"
                                        fill="#FE9A38"
                                    />
                                    <path
                                        opacity="0.3"
                                        d="M16.0285 14.3223H2.55078V15.3386H16.0285V14.3223Z"
                                        fill="#FE9A38"
                                    />
                                    <path
                                        opacity="0.3"
                                        d="M16.0285 16.8867H2.55078V17.9031H16.0285V16.8867Z"
                                        fill="#FE9A38"
                                    />
                                    <path
                                        opacity="0.3"
                                        d="M9.21081 19.4922H2.55078V20.5085H9.21081V19.4922Z"
                                        fill="#FE9A38"
                                    />
                                    <path
                                        d="M9.28739 1.95408C9.77026 1.95408 10.1617 1.59359 10.1617 1.14891C10.1617 0.704234 9.77026 0.34375 9.28739 0.34375C8.80453 0.34375 8.41309 0.704234 8.41309 1.14891C8.41309 1.59359 8.80453 1.95408 9.28739 1.95408Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M8.38437 10.0701L6.95337 7.89062L6.42745 8.18348L7.85845 10.363L8.38437 10.0701Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M11.8275 7.35816L7.53662 9.73438L7.85177 10.217L12.1427 7.84079L11.8275 7.35816Z"
                                        fill="white"
                                    />
                                </svg>

                                Ses Kayıtları
                            </a>
                        </li> -->
            </ul>
        </div>
        <div class="content main-content">
            <button class="color-mode-btn" :class="colorTheme == 'dark' ? 'dark' : 'light'" @click="toggleTheme">
                <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M11.7188 1.5625H13.2812V5.46875H11.7188V1.5625Z"
                        fill="black"
                    />
                    <path
                        d="M16.943 6.95312L19.682 4.21328L20.7867 5.31875L18.0476 8.05781L16.943 6.95312Z"
                        fill="black"
                    />
                    <path
                        d="M19.5312 11.7188H23.4375V13.2812H19.5312V11.7188Z"
                        fill="black"
                    />
                    <path
                        d="M16.943 18.0469L18.0476 16.9422L20.7867 19.6812L19.682 20.7867L16.943 18.0469Z"
                        fill="black"
                    />
                    <path
                        d="M11.7188 19.5312H13.2812V23.4375H11.7188V19.5312Z"
                        fill="black"
                    />
                    <path
                        d="M4.21405 19.6812L6.95311 16.9422L8.0578 18.0477L5.31874 20.7867L4.21405 19.6812Z"
                        fill="black"
                    />
                    <path
                        d="M1.5625 11.7188H5.46875V13.2812H1.5625V11.7188Z"
                        fill="black"
                    />
                    <path
                        d="M4.21405 5.31875L5.31874 4.21328L8.0578 6.95312L6.95311 8.05781L4.21405 5.31875Z"
                        fill="black"
                    />
                    <path
                        d="M12.5 9.375C13.1181 9.375 13.7223 9.55828 14.2362 9.90166C14.7501 10.245 15.1506 10.7331 15.3871 11.3041C15.6236 11.8751 15.6855 12.5035 15.565 13.1097C15.4444 13.7158 15.1467 14.2727 14.7097 14.7097C14.2727 15.1467 13.7159 15.4444 13.1097 15.565C12.5035 15.6855 11.8751 15.6236 11.3041 15.3871C10.7331 15.1506 10.245 14.7501 9.90166 14.2362C9.55828 13.7223 9.375 13.1181 9.375 12.5C9.37604 11.6715 9.70561 10.8773 10.2914 10.2914C10.8773 9.70561 11.6715 9.37603 12.5 9.375V9.375ZM12.5 7.8125C11.5729 7.8125 10.6666 8.08742 9.89577 8.60249C9.12491 9.11756 8.5241 9.84964 8.16932 10.7062C7.81453 11.5627 7.7217 12.5052 7.90257 13.4145C8.08344 14.3238 8.52988 15.159 9.18544 15.8146C9.841 16.4701 10.6762 16.9166 11.5855 17.0974C12.4948 17.2783 13.4373 17.1855 14.2938 16.8307C15.1504 16.4759 15.8824 15.8751 16.3975 15.1042C16.9126 14.3334 17.1875 13.4271 17.1875 12.5C17.1875 11.2568 16.6936 10.0645 15.8146 9.18544C14.9355 8.30636 13.7432 7.8125 12.5 7.8125Z"
                        fill="black"
                    />
                </svg>
            </button>
            <div class="navbar-top">
                <div class="navbar-top__left">
                    <svg
                        width="18"
                        height="14"
                        viewBox="0 0 18 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="mainWrap-btn"
                        @click="toggleMenu"
                        v-if="isActive"
                        data-v-step="1"
                    >
                        <path d="M14.25 7H7.5" stroke="#4A83EB" stroke-linecap="round"/>
                        <path
                            d="M14.25 11.0834H3.75"
                            stroke="#4A83EB"
                            stroke-linecap="round"
                        />
                        <path
                            d="M14.25 2.91669H3.75"
                            stroke="#4A83EB"
                            stroke-linecap="round"
                        />
                        <path d="M5.25 8.75L3 7" stroke="#4A83EB" stroke-linecap="round"/>
                        <path d="M5.25 5.25L3 7" stroke="#4A83EB" stroke-linecap="round"/>
                    </svg>

                    <svg
                        width="18"
                        height="14"
                        viewBox="0 0 18 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="mainWrap-btn"
                        @click="toggleMenu"
                        v-if="!isActive"
                        data-v-step="1"
                    >
                        <path d="M3.75 7H10.5" stroke="#4A83EB" stroke-linecap="round"/>
                        <path
                            d="M3.75 11.0834H14.25"
                            stroke="#4A83EB"
                            stroke-linecap="round"
                        />
                        <path
                            d="M3.75 2.91669H14.25"
                            stroke="#4A83EB"
                            stroke-linecap="round"
                        />
                        <path
                            d="M12.75 8.75L15 7"
                            stroke="#4A83EB"
                            stroke-linecap="round"
                        />
                        <path
                            d="M12.75 5.25L15 7"
                            stroke="#4A83EB"
                            stroke-linecap="round"
                        />
                    </svg>

                    <router-link to="/" v-if="!isActive">
                        <img class="mr-3" width="100" src="../assets/img/logo.png"/>
                    </router-link>

                    <HeaderSwiper class="hide-less-lg" data-v-step="2"/>
                </div>
                <div class="">
                    <ul class="navbar-top__menu">
                        <!-- <li>
                          <a
                            :href="$root.getWebSiteURL()"
                            class="btn btn-white btn-with-ico shadow-sm header-btn"
                            data-v-step="3"
                          >
                            <span class="ico bg-purple">
                              <i class="icon-translate text-white"></i>
                            </span>
                            <span class="text">Anasayfa</span>
                          </a>
                        </li> -->
                        <li>
                            <div class="dropdown">
                                <button data-v-step="4" class="dropdown-btn btn p-0 auth-header-btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="user drop-button shadow-sm" data-drop-content="#profile-details">
                                        <div class="user-thumb-img small"
                                             v-bind:style="{ 'background-image': 'url(' + (this.$root.user.avatar && this.$root.user.avatar.thumb ? this.$root.user.avatar.thumb.small : this.$root.getWebSiteURL() +   '/system/icon/avatar-man.jpeg') + ')' }" >
                                        </div>
                                        <span class="user__name text ml-10">{{this.$root.user.full_name}}</span>
                                    </span>
                                </button>
                                <div class="dropdown-menu profile-drp-menu" aria-labelledby="dropdownMenuButton">
                                    <div class="drp-content" id="drop-auth-content">
                                        <div class="profile-menu__header fw-500">
                                            <div class="d-flex">
                                                <div class="user-thumb-img mr-10" v-bind:style="{'background-image': 'url(' +  (this.$root.user.avatar && this.$root.user.avatar.thumb ? this.$root.user.avatar.thumb.small : this.$root.getWebSiteURL() + '/system/icon/avatar-man.jpeg') +')' }"></div>
                                                <div class="d-flex flex-column">
                                                    <span class="text-gray-dark">{{this.$root.user.full_name}}</span>
                                                    <small class="d-block small-muted-text word-break">{{this.$root.user.email}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-menu__body">
                                            <ul class="auth-menu">
                                                <li class="auth-menu__item">
                                                    <router-link :to="{name: 'account' }" class="auth-menu__link">
                                                        <span class="d-flex align-items-center">
                                                            <i class="ico icon-user mr-10"></i>Hesabım
                                                        </span>
                                                        <i class="chevron icon-chevron-down"></i>
                                                    </router-link>
                                                </li>
                                                <li class="auth-menu__item">
                                                    <router-link :to="{ name: 'orders'}" class="auth-menu__link">
                                                        <span class="d-flex align-items-center">
                                                            <i class="ico icon-cart mr-10"></i>Siparişlerim
                                                        </span>
                                                        <i class="chevron icon-chevron-down"></i>
                                                    </router-link>
                                                </li>
                                                <!--<li class="auth-menu__item mb-3">
                                                    <router-link :to="{ name: 'helper' }" class="auth-menu__link">
                                                        <span class="d-flex align-items-center">
                                                            <i class="ico icon-details mr-10"></i>Yardım Merkezi
                                                        </span>
                                                        <i class="chevron icon-chevron-down"></i>
                                                    </router-link>
                                                </li>

                                                <li class="auth-menu__item">
                                                    <hr/>
                                                    <a href="" class="auth-menu__link py-15 mt-10">
                                                        <span class="pl-10">Geri Bildirim Gönder</span>
                                                        <i class="chevron icon-chevron-down"></i>
                                                    </a>
                                                </li>-->
                                            </ul>
                                        </div>
                                        <div class="profile-menu__footer text-center">
                                            <a href="" class="sign-up-link" v-on:click.prevent="$root.userLogout()">OTURUMU KAPAT</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li data-v-step="5">
                            <router-link to="/bildirimlerim" class="btn btn-white btn-with-ico shadow-sm header-btn notification-btn position-relative">
                                <span class="notification-item-count" v-if="$root.notSeenNotificationCount > 0">{{ $root.notSeenNotificationCount }}</span>
                                <span class="ico icon-bell fw-600"></span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="layout-box ">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script>
import HeaderSwiper from './HeaderSwiper.vue';

export default {
    name: 'Layout',
    mounted() {
        document.body.setAttribute('data-theme', localStorage.theme);
    },

    components: {
        HeaderSwiper,
    },
    data() {
        return {
            notActiveLinks: {
                deneme: false,
                kelimelik: false,
                tarama: false,
                paragraf: false,
            },
            isActive: true,
            colorTheme: localStorage.theme,
        };
    },
    methods: {
        toggleMenu: function () {
            this.isActive = !this.isActive;
        },
        toggleTheme: function () {
            if (document.body.getAttribute('data-theme') == 'dark') {
                localStorage.theme = '';
                this.colorTheme = localStorage.theme;
                document.body.setAttribute('data-theme', localStorage.theme);
            } else {
                localStorage.theme = 'dark';
                this.colorTheme = localStorage.theme;
                document.body.setAttribute('data-theme', localStorage.theme);
            }

            localStorage.setItem('theme', localStorage.theme);
        },
    },
    created() {
        if (window.innerWidth < 1024) {
            this.isActive = false;
        }
    },
};
</script>
<style>
.notification-btn{
    background-color:#fff
}
.notification-btn .notification-item-count{
    position: absolute;
    right: -2px;
    top: -6px;
    font-size: 10px;
    padding: 5px;
    background-color: #27ae60;
    border-radius: 50%;
    color: white;
    min-width: 20px;
    max-width: 20px;
    height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: 600;
    -webkit-transition: 0.2s;
    transition: 0.2s;
}
.notification-btn:hover {
    background-color: white;
    color: #717b96;
}
.notification-btn .notification-item-count {
    right: -8px;
    top: -6px;
    background-color: #dc3545;
}
.notification-btn:hover .notification-item-count {
    -webkit-box-shadow: 0 0 0 0.15rem rgb(241 101 101 / 50%);
    box-shadow: 0 0 0 0.15rem rgb(241 101 101 / 50%);
}
</style>