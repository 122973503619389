<template>
  <router-link
    :to="{
      name: 'lesson_detail',
      params: { id: lecture.id, lecture_id: lecture.lecture_id },
    }"
    class="video-card"
  >
    <div class="video-card__img">
      <img :src="bgImg" class="w-100" :alt="lecture.lesson_title" />
      <div class="video-badge">Yeni</div>
      <div class="video-card__play">
        <svg
          width="70"
          height="70"
          viewBox="0 0 70 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M70 35C70 44.2826 66.3125 53.185 59.7487 59.7487C53.185 66.3125 44.2826 70 35 70C25.7174 70 16.815 66.3125 10.2513 59.7487C3.68749 53.185 0 44.2826 0 35C0 25.7174 3.68749 16.815 10.2513 10.2513C16.815 3.68749 25.7174 0 35 0C44.2826 0 53.185 3.68749 59.7487 10.2513C66.3125 16.815 70 25.7174 70 35V35ZM29.7062 22.2819C29.3792 22.049 28.9943 21.9106 28.5938 21.8819C28.1933 21.8533 27.7926 21.9354 27.4357 22.1192C27.0787 22.3031 26.7793 22.5817 26.5701 22.9245C26.361 23.2673 26.2502 23.661 26.25 24.0625V45.9375C26.2502 46.339 26.361 46.7327 26.5701 47.0755C26.7793 47.4183 27.0787 47.6969 27.4357 47.8808C27.7926 48.0646 28.1933 48.1467 28.5938 48.1181C28.9943 48.0894 29.3792 47.951 29.7062 47.7181L45.0187 36.7806C45.3023 36.5783 45.5335 36.3111 45.6929 36.0014C45.8524 35.6917 45.9356 35.3484 45.9356 35C45.9356 34.6516 45.8524 34.3083 45.6929 33.9986C45.5335 33.6889 45.3023 33.4217 45.0187 33.2194L29.7062 22.2819V22.2819Z"
            fill="#F3F3F3"
          />
        </svg>
      </div>
    </div>
    <div class="video-card__body px-10">
      <!-- <small class="category fw-500">YDS</small> -->
      <p class="fw-600 mb-0 title mt-10">{{ lecture.lecture_title }}</p>
      <small>{{ lecture.lesson_title }}</small>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    lecture: {
      type: Object,
    },
  },
  data() {
    return {
      bgImg: '',
    };
  },
  methods: {
    setBgImage() {
      if (this.lecture.lecture_image.image === null) {
        this.bgImg = this.lecture.lecture_image.stream_image;
      } else {
        this.bgImg = this.lecture.lecture_image.image.small;
      }
    },
  },

  created() {
    this.setBgImage();
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.video-card {
  &__img {
    display: flex;
    position: relative;
    border-radius: 12px;
    //background-color: #eee;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .video-badge {
      position: absolute;
      left: 10px;
      top: 10px;
      background-color: rgba(var(--primary-color--rgb), 0.8);
      backdrop-filter: blur(3px);
      color: white;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 10px;
      z-index: 10;
    }
    img {
      width: 100%;
      object-fit: cover;
      object-position: top;
      border-radius: 8px;
      height: 13rem;
    }
  }
  &__body {
    color: var(--font-color);
    .title {
      font-size: 1.1rem;
    }
  }

  &__play {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #6b6b6b00;
    backdrop-filter: brightness(0.5);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      transition: 0.1s ease-in-out all;
    }
  }

  &:hover {
    .video-card__play {
      svg {
        transform: scale(1.1);
      }
    }
  }
}
</style>
