<template>
    <div class="popup-modal cs-modal" v-if="popup.show">
        <div class="cs-modal__body cs-modal-lg">
            <span class="cs-modal__close" v-if="!popup.options.sticky" @click="closePopup">x</span>
            <div class="cs-modal-header" v-if="!popup.data.options.hide_header && popup.data.translate && popup.data.translate.title">
                <p class="h4 text-primary">{{ popup.data.translate.title }}</p>
            </div>
            <div class="cs-modal-body" :class="{ 'cs-modal-image-body': popup.data.options.popup_type && popup.data.options.popup_type == 'image'}">
                <div class="text-gray-soft fw-500" v-if="popup.data.translate" v-html="popup.data.translate.content"></div>
                <div class="cs-modal-buttons" v-if="popup.data.buttons_count > 0 && popup.data.buttons">
                    <a v-for="(button, buttonIndex) in popup.data.buttons" :key="buttonIndex" :href="button.translate.slug" class="btn btn-danger py-1 py-sm-2" target="_blank" :class="buttonIndex != 0 && 'ml-2'">
                        <span class="d-flex align-items-center justify-content-center">
                            <i class="icon-right-arrow mr-10"></i>
                            {{ button.translate.title }}
                        </span>
                    </a>
                </div>
            </div>
            <div class="cs-modal-footer" v-if="!popup.options.hide_footer">
                <button class="btn btn-primary btn-sm mr-2 px-15" v-if="popup.options.allow_dont_show" @click="dontAllowPopup">{{ $t('module.popup.dontShowAgain')}}</button>
                <button class="btn btn-danger btn-sm px-15" v-if="!popup.options.sticky" @click="closePopup">{{ $t('common.close')}}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Popup",
    data() {
        return {
            popups: [],
            popup: {
                currentIndex: 0,
                data: {
                    options: {}
                },
                options: {},
                show: false,
                checkNewModal: false,
            }
        }
    },
    created() {
        this.loadPopup();
    },
    methods: {
        loadPopup() {
            this.axios.get(this.endpoints['module_popup_check'], {
                disableApiRequest: true,
                params: {
                    url: process.env.VUE_APP_CLIENT_URL
                }
            }).then((response) => {
                if (response.data.status) {
                    let data = response.data.data;
                    this.popups = data;
                    this.process();
                }
            });
        },
        process() {
            this.popups.forEach((popup, popupIndex) => {
                if (popup.options) {
                    if (popup.options.repeat) {
                        popup.options.repeat = parseInt(popup.options.repeat);
                    }
                }

                if (popupIndex === this.popup.currentIndex) {
                    if (this.checkDontShow(popup.id) && this.checkRepeat(popup)) {
                        this.openPopup(popup)
                    } else {
                        this.checkNewModal();
                    }
                }
            })

            this.popup.checkNewModal = true;
        },
        resetPopup(){
            this.popup.data = {options: {}};
            this.popup.options = {};
            this.popup.show = false;
            this.popup.checkNewModal = false;
        },
        openPopup(popup){
            this.resetPopup();

            if(popup.translate && popup.translate.content) {
                if (typeof (popup.options) !== 'object') {
                    popup.options = {
                        hide_header: true
                    };
                }

                let showTime = popup.options.show_timeout || 0;

                if (popup.options) {
                    if (popup.options.sticky && popup.options.sticky.enabled) {
                        setTimeout(() => {
                            this.closePopup();
                        }, (((popup.options.sticky.show_time || 5) + showTime) * 1000));
                    } else {
                        if (popup.options.allow_dont_show) {
                            this.popup.options.allow_dont_show = true;
                        }
                    }

                    if ((popup.options.hide_footer || (popup.options.sticky && popup.options.sticky.enabled)) && !popup.options.allow_dont_show) {
                        this.popup.options.hide_footer = true;
                    }
                }

                setTimeout(() => {
                    this.popup.data = popup;
                    this.popup.show = true;
                }, (showTime * 1000));
            } else {
                this.checkNewModal();
            }
        },
        closePopup(){
            this.popup.show = false;
            if(this.popup.checkNewModal) {
                this.checkNewModal(true);
            }
        },
        checkDontShow(id) {
            let current = JSON.parse(window.localStorage.getItem('hidden-popup')) || [];
            return current.indexOf(id) === -1;
        },
        dontAllowPopup(){
            let current = JSON.parse(window.localStorage.getItem('hidden-popup')) || [];

            if (current.indexOf(this.popup.data.id) === -1) {
                current.push(this.popup.data.id);
                window.localStorage.setItem('hidden-popup', JSON.stringify(current));
            }

            this.popup.show = false;
        },
        checkNewModal(withProcess = false){
            let newPosition = this.popup.currentIndex + 1;
            let currentPopup = this.popups[this.popup.currentIndex];

            if (typeof (currentPopup) === 'object' && currentPopup.id) {
                this.addRepeatData(currentPopup);
            }

            if (this.popups && newPosition !== this.popups.length && newPosition <= this.popups.length) {
                this.popup.checkNewModal = false;
                this.popup.currentIndex = newPosition;

                if (withProcess) {
                    setTimeout(() => {
                        this.process();
                    }, 500);
                }
            }
        },
        addRepeatData(popup) {
            if (popup.options.repeat || popup.options.repeat === 0 || popup.options.repeat === null) {
                let current = JSON.parse(window.localStorage.getItem('repeat-popup-data')) || {};

                let now = Math.floor(Date.now() / 1000);
                let repeatData = popup.options.repeat;

                if ((!current[popup.id]) || (current[popup.id] && (now >= current[popup.id].next || current[popup.id].repeat !== popup.options.repeat))) {
                    current[popup.id] = {
                        repeat: repeatData,
                        now: now,
                        next: (popup.options.repeat === null ? null : (now + repeatData))
                    };
                }

                window.localStorage.setItem('repeat-popup-data', JSON.stringify(current));
            }
        },
        checkRepeat(popup) {
            if ((!(popup.options && popup.options.repeat)) && (popup.options && popup.options.repeat && popup.options.repeat !== null)) {
                return true;
            }

            let now = Math.floor(Date.now() / 1000);
            let current = JSON.parse(window.localStorage.getItem('repeat-popup-data')) || {};

            if (current[popup.id] && current[popup.id].next === null && current[popup.id].repeat === popup.options.repeat) {
                return false;
            }

            if (current[popup.id] && current[popup.id].next) {
                if (current[popup.id].repeat !== popup.options.repeat && now >= (current[popup.id].now + popup.options.repeat)) {
                    return true;
                }

                return now >= current[popup.id].next;
            }

            return true;
        }
    }
}
</script>

<style>
.popup-modal.cs-modal {
    z-index: 9999 !important;
}

.popup-modal.cs-modal .cs-modal__body {
    margin: 0 !important;
    padding: 0 !important;
}

.popup-modal.cs-modal .cs-modal__body .cs-modal__close {
    z-index: 1;
}

.popup-modal.cs-modal .cs-modal-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding: 1rem 1rem;
}

.popup-modal.cs-modal .cs-modal-body{
    position: relative;
    font-size: 1rem;
}

.popup-modal.cs-modal .cs-modal-body .cs-modal-buttons{
    margin-bottom: 0.5rem;
}

.popup-modal.cs-modal .cs-modal-body:not(.cs-modal-image-body){
    margin-top: 0.5rem;
    padding: 0 1rem;
}

.popup-modal.cs-modal .cs-modal-body:not(.cs-modal-image-body) .cs-modal-buttons{
    text-align: center;
}

.popup-modal.cs-modal .cs-modal-body .cs-modal-buttons .btn{
    margin-bottom: 0.25rem;
}

.popup-modal.cs-modal .cs-modal-body:not(.cs-modal-image-body) .cs-modal-buttons .btn i{
    display: none;
}

.popup-modal.cs-modal .cs-modal-body.cs-modal-image-body .cs-modal-buttons{
    position: absolute;
    bottom: 0;
    left: 5.5%;
}

.popup-modal.cs-modal .cs-modal-body.cs-modal-image-body .cs-modal-buttons .btn{
    text-align: center;
    font-size: 0.5rem;
}

.popup-modal.cs-modal .cs-modal-body.cs-modal-image-body .cs-modal-buttons .btn{
    display: block;
    margin-left: 0 !important;
}

.popup-modal.cs-modal .cs-modal-footer{
    border-top: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
}

@media (min-width: 576px) {
    .popup-modal.cs-modal .cs-modal__body{
        max-width: 500px;
    }
}

@media (min-width: 992px) {
    .popup-modal.cs-modal .cs-modal__body{
        max-width: 800px;
    }

    .popup-modal.cs-modal .cs-modal-body.cs-modal-image-body .cs-modal-buttons .btn{
        font-size: 0.9rem;
    }

    .popup-modal.cs-modal .cs-modal-body .cs-modal-buttons{
        margin-bottom: 0.75rem;
    }
}

</style>