<template>
  <LayoutUser>
    <router-link
      :to="{ name: 'account-addresses' }"
      class="d-flex align-items-center h5 mb-10"
    >
      <span class="icon-chevron-down mr-5 rotate-90"></span>
      Adreslerim
    </router-link>

    <FormulateForm @submit="submitForm">
      <div class="row mt-40">
        <div class="col-md-12">
          <p class="h5 mb-20">
            Adres Oluştur
          </p>
        </div>
        <div class="col-md-12 mb-15">
          <div class="d-flex align-items-center">
            <label class="d-flex align-items-center mr-15">
              <input
                type="radio"
                name="invoice_type_id"
                class="mr-5"
                value="1"
                v-model="addressDetails.invoice_type_id"
              />
              Bireysel
            </label>
            <label class="d-flex align-items-center">
              <input
                type="radio"
                name="invoice_type_id"
                class="mr-5"
                value="2"
                v-model="addressDetails.invoice_type_id"
              />
              Kurumsal
            </label>
          </div>
        </div>
        <div class="col-md-12 mb-15">
          <div class="account-input-group">
            <FormulateInput
              id="baslik"
              type="text"
              validation="required|min:2"
              validation-name="Adres başlığı"
              placeholder="Adres başlığını girin"
              v-model="addressDetails.title"
            />
            <label class="text-muted" for="baslik">Adres Başlığı(*)</label>
          </div>
        </div>

        <div class="col-md-6 mb-15">
          <div class="account-input-group">
            <FormulateInput
              type="text"
              validation="required|min:2"
              validation-name="İsim"
              placeholder="İsminizi giriniz"
              v-model="addressDetails.firstname"
            />
            <label class="text-muted">Adınız(*):</label>
          </div>
        </div>
        <div class="col-md-6 mb-15">
          <div class="account-input-group">
              <FormulateInput
                  type="text"
                  validation="required|min:2"
                  validation-name="Soyadınız"
                  placeholder="Soyadınızı giriniz"
                  v-model="addressDetails.lastname"
              />
            <label class="text-muted">Soyadınız(*):</label>
          </div>
        </div>
        <div
          class="col-md-6 mb-15"
          v-show="addressDetails.invoice_type_id == 2"
        >
          <div class="account-input-group">
            <FormulateInput
              type="text"
              validation-name="İşletme Adı"
              placeholder="İşletme adını giriniz"
              v-model="addressDetails.company_name"
            />
            <label class="text-muted">İşletme Adı</label>
          </div>
        </div>
        <div
          class="col-md-6 mb-15"
          v-show="addressDetails.invoice_type_id == 2"
        >
          <div class="account-input-group">
            <input
              type="text"
              placeholder="Vergi Dairesi"
              v-model="addressDetails.company_tax_office"
            />
            <label class="text-muted">Vergi Dairesi</label>
          </div>
        </div>

        <div class="col-md-6 mb-15">
          <div class="account-input-group">
            <FormulateInput
              type="text"
              validation="^required|min:11,length|matches:/[0-9]/"
              validation-name="T.C. Kimlik Numarası"
              maxlength="11"
              placeholder="T.C. Kimlik Numaranız"
              v-model="addressDetails.identification_number"
            />
            <label class="text-muted">TC Kimlik No(*):</label>
          </div>
        </div>
        <div class="col-md-6 mb-15">
          <div class="account-input-group">
            <!--<FormulateInput
              type="text"
              maxlength="10"
              validation="^required|min:10,length|matches:/[0-9]/"
              validation-name="Telefon Numarası"
              placeholder="--- --- -- --"
              v-model="addressDetails.mobile_phone"
            />-->
              <input type="text" required placeholder="Telefon Numarası" v-model="addressDetails.mobile_phone" v-mask="{ mask:'+\\90 599 999 99 99', placeholder: '', clearIncomplete: true }"/>
              <label class="text-muted">Telefon No(*):</label>
          </div>
        </div>
        <div class="col-md-6 mb-15">
          <div class="account-input-group">
            <select v-model="addressDetails.state_id" required>
              <option value="">İl Seçiniz</option>
              <option
                :value="item.id"
                v-for="item in countryStates"
                :key="item.id"
                >{{ item.name }}</option
              >
            </select>
            <label class="text-muted">İl Seçiniz(*):</label>
          </div>
        </div>
        <div class="col-md-6 mb-15">
          <div class="account-input-group">
            <select name="" id="" v-model="addressDetails.city_id" required>
              <option value="">İlçe Seçiniz</option>
              <option :value="item.id" v-for="item in cities" :key="item.id">
                {{ item.name }}
              </option>
            </select>
            <label class="text-muted">İlçe Seçiniz(*):</label>
          </div>
        </div>
        <div class="col-md-6 mb-15">
          <div class="account-input-group">
            <FormulateInput
              type="text"
              validation-name="ZIP Kodu"
              placeholder="ZIP Kodu"
              v-model="addressDetails.zip_code"
            />
            <label class="text-muted">ZIP Kodu</label>
          </div>
        </div>
        <div class="col-md-6 mb-15">
          <div class="account-input-group">
            <FormulateInput
              type="text"
              validation="required|min:2"
              validation-name="Mahalle"
              placeholder="Mahalle"
              v-model="addressDetails.district"
            />
            <label class="text-muted">Mahalle</label>
          </div>
        </div>
        <div class="col-md-12 mb-15">
          <div class="account-input-group">
            <textarea
              type="text"
              required
              placeholder="Adres açıklamasını giriniz"
              rows="5"
              v-model="addressDetails.address"
            ></textarea>
            <label class="text-muted">Adres Detay(*):</label>
          </div>
        </div>
        <div class="col-md-12 mb-15">
          <div class="d-flex align-items-center">
            Aktif Adres:
            <label class="switch">
              <input
                type="checkbox"
                :checked="addressDetails.is_default"
                v-model="addressDetails.is_default"
              />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div class="col-md-12 mb-15 d-flex justify-content-end">
          <button class="btn btn-primary px-30" type="submit">
            Adres Oluştur
          </button>
        </div>
      </div>
    </FormulateForm>
  </LayoutUser>
</template>

<script>
import LayoutUser from '../components/LayoutUser.vue';

export default {
  components: { LayoutUser },
  data() {
    return {
      countryId: 1,
      countryStates: {},
      cities: {},
      addressDetails: {
        invoice_type_id: 1,
        title: '',
        country_id: 1,
        state_id: '',
        city_id: '',
        district: '',
        zip_code: '',
        firstname: '',
        lastname: '',
        mobile_phone: '',
        identification_number: '',
        address: '',
        is_default: true,
        company_name: '',
        company_tax_office: '',
      },
    };
  },
  methods: {
    submitForm() {
      this.axios.post('/user/address', this.addressDetails).then(() => {
        this.$notify({
          title:
            '<div class="d-flex align-items-center"> <i class="icon-check-stroke h4 mb-0 mr-5"></i> Başarılı</div>',
          type: 'success',
        });
        this.$router.push({ name: 'account-addresses' });
      });
    },

    fetchActiveCountryStates() {
      this.axios
        .get(`/localization/country/state/${this.countryId}`)
        .then((response) => {
          this.countryStates = response.data.data.states;
        });
    },
    fetchActiveCities() {
      this.axios
        .get(`/localization/country/city/${this.addressDetails.state_id}`)
        .then((response) => {
          this.cities = response.data.data.cities;
        });
    },
  },
  created() {
    this.fetchActiveCountryStates();
  },
  watch: {
    'addressDetails.state_id': {
      handler: function() {
        this.fetchActiveCities();
      },
      deep: true,
    },
  },
};
</script>

<style></style>
