<template>
  <swiper class="paragraph-swiper" :options="swiperOptions">
    <swiper-slide>
      <router-link :to="{ name: 'coming-soon', params: { feature: 'paragraf-okuma' } }" class="paragraph-card">
        <div class="paragraph-card__left">
          <div
            class="cover"
            style="background-image: url('https://images.unsplash.com/photo-1585331505473-7586f9cb0854?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Y292aWQlMjB3b3JsZHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60');
"
          ></div>
        </div>
        <div class="paragraph-card__right">
          <p class="fw-500 mb-0">THE WORLD AFTER COVID 19</p>
          <span class="d-block"
            >Süre: <span class="text-red fw-500"> 3dk</span></span
          >
          <small>#health</small>
        </div>
      </router-link>
    </swiper-slide>

    <swiper-slide>
      <router-link :to="{ name: 'coming-soon', params: { feature: 'paragraf-okuma' } }" class="paragraph-card">
        <div class="paragraph-card__left">
          <div
            class="cover"
            style="background-image: url('https://images.unsplash.com/photo-1585331505473-7586f9cb0854?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Y292aWQlMjB3b3JsZHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60');
"
          ></div>
        </div>
        <div class="paragraph-card__right">
          <p class="fw-500 mb-0">THE WORLD AFTER COVID 19</p>
          <span class="d-block"
            >Süre: <span class="text-red fw-500"> 3dk</span></span
          >
          <small>#health</small>
        </div>
      </router-link>
    </swiper-slide>
  </swiper>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';

export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        autoplay: true,
        slidesPerView: 'auto',
        spaceBetween: 10,
        loop: true,
        mousewheel: true,
        breakpoints: {
          0: {
            slidesPerView: 1.1,
          },
          992: {
            slidesPerView: 'auto',
          },
        },
      },
    };
  },
};
</script>
