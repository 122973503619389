<template>
    <a v-if="calendarEvent.stream_data && calendarEvent.stream_data.url" v-on:click="openStream(calendarEvent.stream_data)" class="calendar-event-card cursor-pointer">
        <div class="ico">
            <svg width="30" height="41" viewBox="0 0 30 41" fill="none" xmlns="http://www.w3.org/2000/svg" v-show="calendarEventType == 'ders'">
                <path
                    d="M22.963 4.44922H7.03704C3.15059 4.44922 0 7.35064 0 10.9297V33.8957C0 37.4748 3.15059 40.3762 7.03704 40.3762H22.963C26.8494 40.3762 30 37.4748 30 33.8957V10.9297C30 7.35064 26.8494 4.44922 22.963 4.44922Z"
                    fill="#3271E6"
                />
                <path
                    d="M21.9988 6.51562H8.00964C4.59622 6.51562 1.8291 9.06392 1.8291 12.2074V32.3737C1.8291 35.5172 4.59622 38.0655 8.00964 38.0655H21.9988C25.4122 38.0655 28.1794 35.5172 28.1794 32.3737V12.2074C28.1794 9.06392 25.4122 6.51562 21.9988 6.51562Z"
                    fill="white"
                />
                <path
                    d="M7.10059 5.05303H22.903C22.903 5.05303 15.8274 -6.31629 7.10059 5.05303Z"
                    fill="#3271E6"
                />
                <path
                    d="M14.9994 18.6413C18.089 18.6413 20.5935 16.3348 20.5935 13.4896C20.5935 10.6444 18.089 8.33789 14.9994 8.33789C11.9098 8.33789 9.40527 10.6444 9.40527 13.4896C9.40527 16.3348 11.9098 18.6413 14.9994 18.6413Z"
                    fill="#FE9A38"
                />
                <path
                    opacity="0.3"
                    d="M25.8851 23.1367H4.11816V24.7782H25.8851V23.1367Z"
                    fill="#FE9A38"
                />
                <path
                    opacity="0.3"
                    d="M25.8851 27.2637H4.11816V28.9051H25.8851V27.2637Z"
                    fill="#FE9A38"
                />
                <path
                    opacity="0.3"
                    d="M14.8743 31.4746H4.11816V33.1161H14.8743V31.4746Z"
                    fill="#FE9A38"
                />
                <path
                    d="M15.0019 3.14565C15.7817 3.14565 16.4139 2.56346 16.4139 1.84529C16.4139 1.12711 15.7817 0.544922 15.0019 0.544922C14.222 0.544922 13.5898 1.12711 13.5898 1.84529C13.5898 2.56346 14.222 3.14565 15.0019 3.14565Z"
                    fill="white"
                />
                <path
                    d="M13.5426 16.2524L11.2314 12.7324L10.3821 13.2054L12.6932 16.7253L13.5426 16.2524Z"
                    fill="white"
                />
                <path
                    d="M19.1023 11.8772L12.1724 15.7148L12.6813 16.4943L19.6113 12.6567L19.1023 11.8772Z"
                    fill="white"
                />
            </svg>

            <svg width="30" height="41" viewBox="0 0 30 41" fill="none" xmlns="http://www.w3.org/2000/svg" v-show="calendarEventType == 'deneme'">
                <path
                    d="M22.452 1.65039H7.54892C3.37977 1.65039 0 5.03016 0 9.19932V32.8327C0 37.0019 3.37977 40.3816 7.54892 40.3816H22.452C26.6212 40.3816 30.0009 37.0019 30.0009 32.8327V9.19932C30.0009 5.03016 26.6212 1.65039 22.452 1.65039Z"
                    fill="#3271E6"
                />
                <path
                    d="M3.96608 14.5982C5.35341 14.5982 6.47807 13.4735 6.47807 12.0862C6.47807 10.6989 5.35341 9.57422 3.96608 9.57422C2.57875 9.57422 1.4541 10.6989 1.4541 12.0862C1.4541 13.4735 2.57875 14.5982 3.96608 14.5982Z"
                    fill="white"
                />
                <path
                    d="M-nan -nanL10.5798 3.64807C10.5798 3.92922 10.4965 4.20405 10.3403 4.43782C10.1841 4.67159 9.96206 4.85378 9.70231 4.96137C9.44257 5.06896 9.15675 5.09712 8.881 5.04227C8.60526 4.98742 8.35197 4.85203 8.15317 4.65323C7.95436 4.45443 7.81898 4.20114 7.76413 3.92539C7.70928 3.64965 7.73743 3.36383 7.84502 3.10408C7.95261 2.84434 8.13481 2.62233 8.36858 2.46613C8.60234 2.30993 8.87718 2.22656 9.15832 2.22656C9.53533 2.22656 9.8969 2.37633 10.1635 2.64291C10.4301 2.9095 10.5798 3.27106 10.5798 3.64807L-nan -nanZ"
                    fill="#FE9A38"
                />
                <path
                    d="M-nan -nanL16.4216 3.64807C16.4216 3.92922 16.3383 4.20405 16.1821 4.43782C16.0259 4.67159 15.8039 4.85378 15.5441 4.96137C15.2844 5.06896 14.9985 5.09712 14.7228 5.04227C14.4471 4.98742 14.1938 4.85203 13.995 4.65323C13.7962 4.45443 13.6608 4.20114 13.6059 3.92539C13.5511 3.64965 13.5792 3.36383 13.6868 3.10408C13.7944 2.84434 13.9766 2.62233 14.2104 2.46613C14.4441 2.30993 14.719 2.22656 15.0001 2.22656C15.3771 2.22656 15.7387 2.37633 16.0053 2.64291C16.2719 2.9095 16.4216 3.27106 16.4216 3.64807L-nan -nanZ"
                    fill="#FE9A38"
                />
                <path
                    d="M-nan -nanL21.614 3.64807C21.614 3.92922 21.5306 4.20405 21.3744 4.43782C21.2182 4.67159 20.9962 4.85378 20.7365 4.96137C20.4767 5.06896 20.1909 5.09712 19.9152 5.04227C19.6394 4.98742 19.3861 4.85203 19.1873 4.65323C18.9885 4.45443 18.8532 4.20114 18.7983 3.92539C18.7435 3.64965 18.7716 3.36383 18.8792 3.10408C18.9868 2.84434 19.169 2.62233 19.4028 2.46613C19.6365 2.30993 19.9114 2.22656 20.1925 2.22656C20.5695 2.22656 20.9311 2.37633 21.1977 2.64291C21.4642 2.9095 21.614 3.27106 21.614 3.64807L-nan -nanZ"
                    fill="#FE9A38"
                />
                <path
                    d="M3.96608 23.6841C5.35341 23.6841 6.47807 22.5595 6.47807 21.1721C6.47807 19.7848 5.35341 18.6602 3.96608 18.6602C2.57875 18.6602 1.4541 19.7848 1.4541 21.1721C1.4541 22.5595 2.57875 23.6841 3.96608 23.6841Z"
                    fill="white"
                />
                <path
                    d="M3.96608 32.1236C5.35341 32.1236 6.47807 30.9989 6.47807 29.6116C6.47807 28.2243 5.35341 27.0996 3.96608 27.0996C2.57875 27.0996 1.4541 28.2243 1.4541 29.6116C1.4541 30.9989 2.57875 32.1236 3.96608 32.1236Z"
                    fill="white"
                />
                <path
                    d="M23.3156 11.4355H9.28223C9.01337 11.4355 8.79541 11.6535 8.79541 11.9224C8.79541 12.1912 9.01336 12.4092 9.28223 12.4092H23.3156C23.5844 12.4092 23.8024 12.1912 23.8024 11.9224C23.8024 11.6535 23.5844 11.4355 23.3156 11.4355Z"
                    fill="white"
                />
                <path
                    d="M23.3156 20.7695H9.28223C9.01337 20.7695 8.79541 20.9875 8.79541 21.2563C8.79541 21.5252 9.01336 21.7432 9.28223 21.7432H23.3156C23.5844 21.7432 23.8024 21.5252 23.8024 21.2563C23.8024 20.9875 23.5844 20.7695 23.3156 20.7695Z"
                    fill="white"
                />
                <path
                    d="M23.3156 29.4492H9.28223C9.01337 29.4492 8.79541 29.6672 8.79541 29.936C8.79541 30.2049 9.01336 30.4229 9.28223 30.4229H23.3156C23.5844 30.4229 23.8024 30.2049 23.8024 29.936C23.8024 29.6672 23.5844 29.4492 23.3156 29.4492Z"
                    fill="white"
                />
                <path
                    d="M9.2236 0H9.21711C8.64713 0 8.18506 0.462066 8.18506 1.03205V2.499C8.18506 3.06898 8.64713 3.53105 9.21711 3.53105H9.2236C9.79359 3.53105 10.2557 3.06898 10.2557 2.499V1.03205C10.2557 0.462066 9.79359 0 9.2236 0Z"
                    fill="#FE9A38"
                />
                <path
                    d="M15.0263 0.0410156H15.0198C14.4499 0.0410156 13.9878 0.503082 13.9878 1.07307V2.54001C13.9878 3.11 14.4499 3.57206 15.0198 3.57206H15.0263C15.5963 3.57206 16.0584 3.11 16.0584 2.54001V1.07307C16.0584 0.503082 15.5963 0.0410156 15.0263 0.0410156Z"
                    fill="#FE9A38"
                />
                <path
                    d="M20.2055 0.142578H20.199C19.6291 0.142578 19.167 0.604644 19.167 1.17463V2.64157C19.167 3.21156 19.6291 3.67363 20.199 3.67363H20.2055C20.7755 3.67363 21.2376 3.21156 21.2376 2.64157V1.17463C21.2376 0.604644 20.7755 0.142578 20.2055 0.142578Z"
                    fill="#FE9A38"
                />
            </svg>
        </div>
        <div class="right">
            <div class="title mb-2 fw-600">{{ calendarEvent.lecture_title }}</div>
            <p class="mb-5 text-gray-dark fw-400">{{ calendarEvent.stream_date }}</p>
            <small>{{ calendarEvent.teacher.full_name }}</small>
            <small>{{ calendarEvent.lesson_title }}</small>
        </div>
    </a>
</template>

<script>
export default {
    props: ['calendarEvent', 'calendarEventType'],
    methods: {
        openStream(stream) {
            if(stream.api == 'ZOOM'){
                this.axios.get(this.endpoints['module_zoom_meeting_link'] + '/' + stream.meeting_id).then((response) => {
                    if (response.data.status) {
                        let data = response.data.data;
                        this.trustedWindowOpen(data.url, '_blank');
                    }
                });
            } else {
                this.trustedWindowOpen(stream.url, '_blank');
            }
        }
    }
};
</script>

<style></style>
