<template>
    <div>
        <div v-if="category.children && category.children.length" class="accordion"  :id="sprintf('accordion-%d', [category.id])">
            <div class="group-details__box p-15 mb-10" v-for="(child, childIndex) in category.children" :key="childIndex">
                <div class="accordion-item mb-0">
                    <h4 class="accordion-header mb-0" :id="sprintf('heading-%d', [child.id])">
                        <button class="accordion-button" :class="(childIndex != 0 || depth != 0)  && 'collapsed'" type="button" data-toggle="collapse" :data-target="sprintf('#collapse-%d', [child.id])" aria-expanded="true" :aria-controls="sprintf('collapse-%d', [child.id])">
                            {{ child.translate.title }}
                        </button>
                    </h4>
                    <div :id="sprintf('collapse-%d', [child.id])" class="accordion-collapse collapse" :class="(childIndex == 0 && depth == 0) && 'show'" :aria-labelledby="sprintf('heading-%d', [child.id])" :data-parent="sprintf('#accordion-%d', [category.id])">
                        <div class="accordion-body">
                            <TreeSource :category="child" :depth="depth + 1"></TreeSource>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template v-else>
            <router-link :to="sprintf('/fulleten-paragraf-sorulari/%d/detail', [source.id])" target="_blank" class="d-block mb-15" v-for="(source, sourceIndex) in category.sources" :key="sourceIndex">
                <div class="feature-card text-gray-soft py-0">
                    <span class="feature-icon shadow-sm bg-light icon-right-arrow mr-10"></span>
                    <div class="d-flex flex-column fw-500">
                        <span class="fs-14 text-gray-soft">{{ source.title }}</span>
                    </div>
                </div>
            </router-link>
        </template>
    </div>
</template>

<script>
import TreeSource from '@/components/full-paragraph-question/TreeSource.vue';

export default {
    name: "TreeSource",
    props: {
        category: {type: Object, required: true},
        depth: {type: Number, default: 0},
    },
    components: {
        TreeSource
    },
    data(){
        return {

        }
    }
}
</script>

<style scoped>

</style>