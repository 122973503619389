<template>
    <div class="daily-paragraph">
        <div class="daily-paragraph__header">
            <p class="my-10 title">{{ paragraph.translate.title }}</p>
        </div>
        <div class="daily-paragraph__body">
            <p class="sample">
                <img class="w-100 mb-3" :src="paragraph.image ? paragraph.image.public_url: 'https://images.unsplash.com/photo-1547234935-80c7145ec969?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1174&q=80'"/>
                <span v-for="(sentence, index) of paragraph.sentences" :key="'sentence' + index" v-html="sentence.translate.content + ' '"></span>
            </p>
            <!-- <a
              :href="paragraph.url"
              class="btn btn-outline-primary d-block rounded-lg"
              >Devamını Oku</a
            > -->
        </div>
    </div>
</template>

<script>
export default {
    props: {
        paragraph: {
            type: Object,
            default: function () {
                return {
                    url: '#',
                    title: 'Desertificationa',
                    text: 'The Roman Empire was the largest empire of the ancient world.Its capital.was Rome, and its empire was based on the Mediterranean.The Empire dates from 27 BC, when Octavian became the Emperor, Augustus.The empire was the third stage of Ancient Rome.Rome was first ruled by Roman kings, then by the Roman Republic, then by an emperor.Many modern lands were once part of the Roman Empire, including Britain (not Scotland), Spain, Portugal, France, Italy, Greece, Turkey, Germany, Egypt, and the north coast of Africa...',
                    translate: {},
                    image: {}
                };
            },
        },
    },
    computed: {
        bgImage() {
            return this.paragraph.image;
        },
    },
};
</script>

<style lang="scss" scoped></style>
