<template>
    <LayoutUser>
        <p class="h5 mb-20">Tüm Bildirimlerim</p>
        <!--<div class="ntf-box sponsored mb-10">
            <div>
                <p class="ntf-box__title ">KOÇ GRUBU Paketlerinde %20 İndirim</p>
                <span class="text-muted ntf-box__desc">Satın alma işlemini tamamladınız.Paket Hesabınıza tanımlandı.</span>
            </div>
            <span class="ntf-box__ico">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.25 9.45H14.6625L12 1.125L9.3375 9.45H0.75L7.6875 14.5875L5.0625 22.875L12 17.7375L18.9375 22.875L16.275 14.55L23.25 9.45Z" fill="#FFAA00"/>
                </svg>
            </span>

            <span class="ntf-box__ico">
                <svg width="19" height="25" viewBox="0 0 19 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.2183 2.75391H4.35721C1.95079 2.75391 0 4.55042 0 6.76652V20.9867C0 23.2028 1.95079 24.9993 4.35721 24.9993H14.2183C16.6247 24.9993 18.5755 23.2028 18.5755 20.9867V6.76652C18.5755 4.55042 16.6247 2.75391 14.2183 2.75391Z" fill="#3271E6"/>
                    <path d="M13.6206 4.03906H4.95872C2.84519 4.03906 1.13184 5.61692 1.13184 7.56331V20.05C1.13184 21.9963 2.84519 23.5742 4.95872 23.5742H13.6206C15.7341 23.5742 17.4475 21.9963 17.4475 20.05V7.56331C17.4475 5.61692 15.7341 4.03906 13.6206 4.03906Z" fill="white"/>
                    <path d="M4.39502 3.12875H14.1796C14.1796 3.12875 9.79853 -3.91094 4.39502 3.12875Z" fill="#3271E6"/>
                    <path d="M9.28752 11.5496C11.2005 11.5496 12.7513 10.1215 12.7513 8.35978C12.7513 6.59807 11.2005 5.16992 9.28752 5.16992C7.37452 5.16992 5.82373 6.59807 5.82373 8.35978C5.82373 10.1215 7.37452 11.5496 9.28752 11.5496Z" fill="#FE9A38"/>
                    <path opacity="0.3" d="M16.0285 14.3223H2.55078V15.3386H16.0285V14.3223Z" fill="#FE9A38"/>
                    <path opacity="0.3" d="M16.0285 16.8867H2.55078V17.9031H16.0285V16.8867Z" fill="#FE9A38"/>
                    <path opacity="0.3" d="M9.21081 19.4922H2.55078V20.5085H9.21081V19.4922Z" fill="#FE9A38"/>
                    <path d="M9.28739 1.95408C9.77026 1.95408 10.1617 1.59359 10.1617 1.14891C10.1617 0.704234 9.77026 0.34375 9.28739 0.34375C8.80453 0.34375 8.41309 0.704234 8.41309 1.14891C8.41309 1.59359 8.80453 1.95408 9.28739 1.95408Z" fill="white"/>
                    <path d="M8.38413 10.0701L6.95312 7.89062L6.4272 8.18348L7.8582 10.363L8.38413 10.0701Z" fill="white"/>
                    <path d="M11.8275 7.35816L7.53662 9.73438L7.85177 10.217L12.1427 7.84079L11.8275 7.35816Z" fill="white"/>
                </svg>
            </span>
        </div>-->

        <div class="mb-3 d-flex justify-content-between align-items-center flex-wrap">
            <div class="less-md-w100">
                <ul class="nav nav-pills tab-menu--primary my-2 less-md-w100">
                    <li class="nav-item">
                        <a class="nav-link" href="" :class="{active: tabModel === 'all'}" @click.prevent="changeTab('all')">Tümü</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="" :class="{active: tabModel === 'is_not_seen'}" @click.prevent="changeTab('is_not_seen')">Okunmamışlar</a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="notification-container" v-if="!table.loading">
            <div class="ntf-box success mb-10" :class="notification.is_seen ? 'success' : 'primary'" v-for="(notification, notificationIndex) in notifications" :key="notificationIndex">
                <div>
                    <p class="ntf-box__title">{{ resolveDatum(notification.item.sub_group.translations, $root.getLanguage('code', 'TR').id, 'language_id').name }}</p>
                    <span class="text-gray-700 ntf-box__desc text-wrap-pre">{{ notification.item.template.handled_content }}</span>
                    <div class="mt-2">
                        <span class="text-gray-700">{{ moment(notification.created_at).format('DD-MM-YYYY HH:mm') }}</span>
                    </div>
                </div>
                <div class="dropdown d-none" v-if="notification.is_seen == 0">
                    <button class="dropdown-btn btn btn-white btn-with-ico shadow-sm header-btn router-link-exact-active router-link-active" type="button" id="dropdownNotification" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="ico icon-chevron-down fw-600"></span>
                    </button>
                    <div class="dropdown-menu drp-menu" aria-labelledby="dropdownNotification">
                        <div id="drop-auth-content" class="drp-content">
                            <a class="dropdown-item text-gray-800" v-on:click="updateEvent(notification, {is_seen: 1})">Okundu olarak işaretle</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="py-30 mt-40 d-flex justify-content-center">
                <Pagination :current-page="tablePagination.current" @current-change="currentPageChange" :page-size="tablePagination.pageSize" :total="tablePagination.total"></Pagination>
            </div>
        </div>
        <div v-else class="py-30 mt-40 d-flex justify-content-center align-items-center">
            <div class="spinner-border text-secondary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </LayoutUser>
</template>

<script>
import LayoutUser from '../components/LayoutUser.vue';
import Pagination from '@/components/pagination/index.vue';

export default {
    name: 'AccountNotifications',
    components: {LayoutUser, Pagination},
    data() {
        return {
            tablePagination: {
                current: 1,
                pageSize: 10,
                total: 0,
            },
            tabModel: 'all',
        }
    },
    computed: {
        table() {
            return this.$store.state.notification.table;
        },
        notifications() {
            let pagination = this.tablePagination;

            return this.cloneData(this.table.data).slice(((pagination.current - 1) * pagination.pageSize), (pagination.current * pagination.pageSize));
        }
    },
    mounted(){
        this.loadNotifications();
    },
    methods: {
        loadNotifications(params = {}) {
            this.$store.dispatch("notification/get", {
                filterData: params
            }).then(() => {
                this.initPagination()
            });
        },
        currentPageChange(pagination) {
            this.tablePagination = pagination;
        },
        changeTab(tab) {
            this.loadNotifications((tab === 'is_not_seen') ? {'is_seen': 0} : {});
            this.tabModel = tab;
        },
        updateEvent(notification, parameters) {
            this.axios.put(this.endpoints['notification'] + '/' + notification.id, parameters).then((response) => {
                this.onResponse(response.data, () => {
                    this.refreshTable();
                });
            }).catch(error => {
                this.onResponseFailure(error.response.data);
            });
        },
        initPagination() {
            this.tablePagination = {
                current: 1,
                pageSize: 10,
                total: this.table.data.length
            }
        },
        refreshTable() {
            this.$store.dispatch("notification/refresh");
        }
    },
};
</script>

<style>
.drp-menu {
    border-radius: 16px;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    width: 250px;
}

.drp-menu .drp-content {
    right: 0;
    width: 100%;
}

.drp-menu .dropdown-item {
    text-align: center;
}

.drp-menu .dropdown-item:first-child, .drp-menu .dropdown-item:first-child {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.drp-menu .dropdown-item:last-child, .drp-menu .dropdown-item:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.text-gray-700 {
    color: #5E6278 !important;
}

.notification-container .ntf-box:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.notification-container .ntf-box:hover .dropdown {
    display: block !important;
}

.text-gray-800 {
    color: #3F4254 !important;
}

.text-wrap-pre {
    white-space: pre-line;
}
</style>
