import VueI18n from 'vue-i18n'

// Localisation language list
import {locale as tr} from "@/locales/i18n/tr";

let messages = {};
messages = {...messages, tr};

// get current selected language
const lang = localStorage.getItem("language") || "tr";

export default () => new VueI18n({
    locale: lang,
    messages
});
