<template>
  <Layout>
    <div class="">
      <h1 class="h2 text-center mt-50 mb-40">
        Ödeme ve Sipariş
      </h1>

      <div class="w-md-50 mx-auto mb-50">
        <p class="text-muted mb-30">
          Sed non dui aliquam, ullamcorper est non, aliquet mauris. Quisque
          lacus ligula, dapibus nec dignissim non, tincidunt vel quam. Etiam
          porttitor iaculis odio.
        </p>
        <p class="text-muted mb-30">
          Suspendisse eu scelerisque tellus. Curabitur non tincidunt nisl, sit
          amet interdum ex. Class aptent taciti sociosqu ad litora torquent per
          conubia nostra, per inceptos himenaeos. Cras congue eros vitae risus
          tincidunt, nec pulvinar massa bibendum. Nam sit amet convallis mauris.
        </p>
        <p class="text-muted mb-30">
          Praesent pulvinar sollicitudin lacus eu luctus. Donec eget ante et mi
          finibus imperdiet id nec quam. In rutrum, massa non porta malesuada,
          magna quam ullamcorper nunc, ut placerat erat metus et leo. Fusce sed
          dapibus libero. Nullam eleifend libero a lectus blandit, elementum
          euismod arcu elementum. Donec tempus varius mi, vitae aliquam erat
          luctus et. Aliquam in lacinia nunc.
        </p>
        <p class="text-muted mb-30">
          Fugiat eu sint ex dolore laboris officia laborum duis nulla.Voluptate
          minim adipisicing elit culpa ex.Nostrud voluptate sint consequat sint
          nisi nulla pariatur est anim veniam nostrud irure duis.Consequat
          ullamco id aliquip excepteur.Elit irure anim qui ex sint commodo
          labore.Amet labore Lorem velit voluptate.Eiusmod eu cupidatat ut
          ullamco.Consequat dolore eiusmod commodo ut.Ullamco aute in
          adipisicing ipsum aliqua non duis sint ea occaecat.
        </p>
      </div>

      <div class="w-md-50 mx-auto">
        <h2 class="h4 text-center mt-60 mb-40">
          Bu makale yardımcı oldu mu?
        </h2>
        <div class="d-flex justify-content-center">
          <a href="" class="btn btn-primary rounded-sm">Evet, teşekkürler!</a>
          <a href="" class="btn btn-outline-primary ml-15 rounded-sm">
            Tam olarak Değil
          </a>
        </div>
        <div class="text-muted text-center mt-50">
          Dilerseniz canlı destek hattımıza bağlanabilir ya da bize <br />
          <a href="tel:0850 850 50 50" class="text-primary fw-600"
            >0850 850 50 50</a
          >
          Canlı destek hattımızdan <br />
          ulaşabilirsiniz.
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../components/Layout.vue';
export default {
  components: { Layout },
};
</script>

<style></style>
