<template>
    <LayoutUser>
        <div class="w-100">
            <p class="h5 mb-5">
                Siparişler
            </p>
            <p class="text-muted mb-20">
                Toplam<span class="text-gray-dark fw-bold"> {{ order.pagination.total }}</span>
                sipariş bulundu
            </p>
            <vue-good-table :columns="columns" :rows="order.data" @on-row-click="onRowClick" :sort-options="{enabled: false}">

                <template slot="table-row" slot-scope="props">
                    <span
              v-if="props.column.field == 'status'"
              class="badge d-block py-10 h-100"
              :class="
              props.row.status.id == 1
                ? 'badge-warning'
                : props.row.status.id == 2
                ? 'badge-warning'
                : props.row.status.id == 3
                ? 'badge-info'
                : props.row.status.id == 4
                ? 'badge-success'
                : props.row.status.id == 5
                ? 'badge-success'
                : props.row.status.id == 6
                ? 'badge-danger'
                : props.row.status.id == 7
                ? 'badge-danger'
                : ''
            "
          >{{ props.row.status.name }}</span
          >
                    <span v-else-if="props.column.field == 'id'">{{
                            '#' + props.row.id
                        }}</span>
                    <span v-else-if="props.column.field == 'total'">{{
                            props.row.total + ' ₺'
                        }}</span>

                    <span v-else-if="props.column.field == 'items'">
                        <span class="d-block mb-10">
                            {{ props.row.items[0].title + '...' }}
                        </span>
                  </span>
                    <!-- <span v-else-if="props.column.field == 'productName'">
                        <router-link
                          to="/"
                          class="text-primary d-flex align-items-center"
                        >
                          <img
                            src="https://www.yds-test.com/storage/2022/01/02/middle/e-yokdil-grubu.jpg"
                            alt=""
                            width="75px"
                          />
                          <span class="w-50 ml-10">{{ props.row.productName }}</span>
                        </router-link>
                      </span> -->

                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
            <div class="py-30 mt-40 d-flex justify-content-center">
                <Pagination
                    :current-page="order.pagination.current"
                    @current-change="currentPageChange"
                    :page-size="order.pagination.pageSize"
                    :total="order.pagination.total">
                </Pagination>
            </div>
        </div>
    </LayoutUser>
</template>

<script>
import LayoutUser from '../components/LayoutUser.vue';
import Pagination from '@/components/pagination/index.vue';

export default {
    components: {
        LayoutUser,
        Pagination
    },
    data() {
        return {
            image: '',
            columns: [
                {
                    label: 'Sipariş No',
                    field: 'id',
                },

                {
                    label: 'Sipariş İçeriği',
                    field: 'items',
                },
                {
                    label: 'Tarih',
                    field: 'created_at',
                    type: 'date',
                    dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSxxx",
                    dateOutputFormat: 'dd/MM/yyyy',
                },

                {
                    label: 'Tutar',
                    field: 'total',
                },
                {
                    label: 'Sipariş Durumu',
                    field: 'status',
                },
            ]
        };
    },
    computed: {
        order() {
            return this.$store.state.ecommerce.order.table;
        }
    },
    mounted(){
        this.loadOrder();
    },
    methods: {
        loadOrder(pagination = {}){
            this.$store.dispatch('ecommerce/order/get', {
                page: pagination,
                filterData: {
                    sort: 'created_at:desc',
                    just_my_order: true
                }
            })
        },
        onRowClick(params) {
            this.$router.push({
                name: 'order-details',
                params: {id: params.row.id},
            });
        },
        currentPageChange(pagination){
            this.loadOrder(pagination);
        }
    },
};
</script>
<style></style>
