<template>
  <Layout>
    <div class="pb-40">
      <div class="py-30">
        <CustomSelectBox
          :options="['Tüm Kelimeler', 'Okudum ', 'Okumadım']"
          :default="'Tüm Kelimeler'"
          class="select light w-25 ml-auto"
        />
      </div>

      <p class="primary-title fw-500">
        Kelime <span class="text-primary">Kartları</span>
      </p>
      <hr class="my-4" />
      <div class="word-group-grid">
        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Garden</span></div>
          <div class="right">
            <a href="" class="d-block"> </a>
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-success"
              progressValue="96"
              progressHeight="7"
            />
          </div>
        </router-link>
        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Garden</span></div>
          <div class="right">
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-success"
              progressValue="69"
              progressHeight="7"
            />
          </div>
        </router-link>
        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Buildings</span></div>
          <div class="right">
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-primary"
              progressValue="56"
              progressHeight="7"
            />
          </div>
        </router-link>
        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Garden</span></div>
          <div class="right">
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-primary"
              progressValue="33"
              progressHeight="7"
            />
          </div>
        </router-link>
        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Garden</span></div>
          <div class="right">
            <a href="" class="d-block"> </a>
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-success"
              progressValue="96"
              progressHeight="7"
            />
          </div>
        </router-link>
        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Garden</span></div>
          <div class="right">
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-success"
              progressValue="69"
              progressHeight="7"
            />
          </div>
        </router-link>
        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Buildings</span></div>
          <div class="right">
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-primary"
              progressValue="56"
              progressHeight="7"
            />
          </div>
        </router-link>
        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Garden</span></div>
          <div class="right">
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-primary"
              progressValue="33"
              progressHeight="7"
            />
          </div>
        </router-link>
        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Garden</span></div>
          <div class="right">
            <a href="" class="d-block"> </a>
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-success"
              progressValue="96"
              progressHeight="7"
            />
          </div>
        </router-link>
        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Garden</span></div>
          <div class="right">
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-success"
              progressValue="69"
              progressHeight="7"
            />
          </div>
        </router-link>
        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Buildings</span></div>
          <div class="right">
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-primary"
              progressValue="56"
              progressHeight="7"
            />
          </div>
        </router-link>
        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Garden</span></div>
          <div class="right">
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-primary"
              progressValue="33"
              progressHeight="7"
            />
          </div>
        </router-link>
        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Garden</span></div>
          <div class="right">
            <a href="" class="d-block"> </a>
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-success"
              progressValue="96"
              progressHeight="7"
            />
          </div>
        </router-link>
        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Garden</span></div>
          <div class="right">
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-success"
              progressValue="69"
              progressHeight="7"
            />
          </div>
        </router-link>
      </div>
    </div>

    <div class="py-40">
      <p class="primary-title fw-500">
        Sesli <span class="text-primary">Kelime Desteleri</span>
      </p>
      <hr class="my-4" />
      <div class="word-group-grid">
        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Garden</span></div>
          <div class="right">
            <a href="" class="d-block"> </a>
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-success"
              progressValue="96"
              progressHeight="7"
            />
          </div>
        </router-link>
        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Garden</span></div>
          <div class="right">
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-success"
              progressValue="69"
              progressHeight="7"
            />
          </div>
        </router-link>
        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Buildings</span></div>
          <div class="right">
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-primary"
              progressValue="56"
              progressHeight="7"
            />
          </div>
        </router-link>
        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Garden</span></div>
          <div class="right">
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-primary"
              progressValue="33"
              progressHeight="7"
            />
          </div>
        </router-link>

        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Garden</span></div>
          <div class="right">
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-success"
              progressValue="69"
              progressHeight="7"
            />
          </div>
        </router-link>
        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Buildings</span></div>
          <div class="right">
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-primary"
              progressValue="56"
              progressHeight="7"
            />
          </div>
        </router-link>
        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Garden</span></div>
          <div class="right">
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-primary"
              progressValue="33"
              progressHeight="7"
            />
          </div>
        </router-link>
        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Garden</span></div>
          <div class="right">
            <a to="/kelime-test" class="d-block"> </a>
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-success"
              progressValue="96"
              progressHeight="7"
            />
          </div>
        </router-link>
        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Garden</span></div>
          <div class="right">
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-success"
              progressValue="69"
              progressHeight="7"
            />
          </div>
        </router-link>
        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Buildings</span></div>
          <div class="right">
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-primary"
              progressValue="56"
              progressHeight="7"
            />
          </div>
        </router-link>
        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Garden</span></div>
          <div class="right">
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-primary"
              progressValue="33"
              progressHeight="7"
            />
          </div>
        </router-link>
        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Garden</span></div>
          <div class="right">
            <a href="" class="d-block"> </a>
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-success"
              progressValue="96"
              progressHeight="7"
            />
          </div>
        </router-link>
        <router-link to="/kelime-test" class="word-group">
          <div class="left"><span class="h6 fw-700">Garden</span></div>
          <div class="right">
            <small class="mb-0 text-gray-soft group-details w-50"
              >Kelime Sayısı
              <strong class="h6 d-block mb-0">46/65</strong></small
            >
            <ProgressBar
              progressBg="bg-success"
              progressValue="69"
              progressHeight="7"
            />
          </div>
        </router-link>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../components/Layout.vue';
import ProgressBar from '../components/ProgressBar.vue';
import CustomSelectBox from '../components/CustomSelectBox.vue';

export default {
  components: { Layout, ProgressBar, CustomSelectBox },
};
</script>

<style></style>
