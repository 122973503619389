<template>
    <nav v-if="total > 0">
        <ul class="pagination yds-pagination">
            <li class="page-item" :class="(pageRange == 1 || (current == 1)) && 'disabled'">
                <a class="page-link cursor-pointer" @click="changePage((current - 1))"></a>
            </li>
            <li class="page-item" v-for="page in pageRange" :key="page" :class="current == page && 'active'" >
                <a class="page-link cursor-pointer" @click="changePage(page)">
                    {{ page }}
                </a>
            </li>
            <li class="page-item" :class="(pageRange == 1 || (current == pageRange)) && 'disabled'">
                <a class="page-link cursor-pointer" @click="changePage((current + 1))"></a>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: "index",
    emit: ["current-change"],
    props: {
        currentPage: {type: Number, required: true},
        pageSize: {type: Number, required: true},
        total: {type: Number, required: true},
    },
    data(){
        return {
            current: undefined,
        }
    },
    computed: {
        pageRange(){
            return Math.ceil(this.total / this.pageSize);
        }
    },
    created(){
        this.current = this.currentPage;
    },
    methods: {
        changePage(page) {
            if(page == this.current) {
                return;
            }

            this.$emit('current-change', {
                current: page,
                pageSize: this.pageSize,
                total: this.total,
            })
        }
    },
    watch: {
        currentPage: function (newValue) {
            this.current = newValue;
        },
    }
}
</script>

<style scoped>

</style>