<template>
  <Layout>
    <div class="">
      <h1 class="h2 text-center mt-50 mb-40">
        Merhaba, size yardım edebilir miyim?
      </h1>
      <div class="helper-search-box">
        <div class="left">
          <select class="form-control category-select" name="" id="">
            <option value="">Tüm Kategoriler</option>
          </select>
        </div>
        <div class="center">
          <input type="search" placeholder="Sorunuzu yazın..." />
          <button class="btn btn-primary">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.6829 17.6371C1.1057 14.0599 1.1057 8.2601 4.6829 4.6829C8.2601 1.1057 14.0599 1.1057 17.6371 4.6829C20.9069 7.95265 21.1879 13.0794 18.4802 16.6683L22.0943 20.2825C23.0371 21.2253 21.6229 22.6395 20.6801 21.6967L17.1092 18.1257C13.5103 21.2061 8.08905 21.0432 4.6829 17.6371ZM16.2229 6.09712C13.4267 3.30096 8.89327 3.30096 6.09712 6.09712C3.30096 8.89327 3.30096 13.4267 6.09712 16.2229C8.89327 19.019 13.4267 19.019 16.2229 16.2229C19.019 13.4267 19.019 8.89327 16.2229 6.09712Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
      <div class="suggestions">
        <span class="text-muted mr-10 mb-5">Öneri:</span>
        <ul class="search-tips">
          <li>
            <router-link :to="{ name: 'helper-details' }">Öneri</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'helper-details' }">Sipariş</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'helper-details' }">Kargo</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'helper-details' }"
              >İndirim Kodu</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'helper-details' }"
              >Canlı Ders</router-link
            >
          </li>
        </ul>
      </div>

      <div class="w-md-85 mx-auto">
        <div class="row mt-50">
          <div class="col-sm-6 col-md-4 col-xl-3 mb-15">
            <router-link :to="{ name: 'helper-details' }" class="helper-box">
              <div class="helper-box__img">
                <img src="../assets/img/helpbox-1.png" class="" alt="" />
              </div>
              <div class="helper-box__body">
                <h2 class="helper-box__title  mb-15">
                  Canlı Dersler
                  <svg
                    width="5"
                    height="8"
                    viewBox="0 0 5 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.05719 4L0.195262 1.13807C-0.0650874 0.877721 -0.0650874 0.455612 0.195262 0.195262C0.455612 -0.0650873 0.877722 -0.0650873 1.13807 0.195262L4.4714 3.5286C4.73175 3.78895 4.73175 4.21106 4.4714 4.4714L1.13807 7.80474C0.877722 8.06509 0.455612 8.06509 0.195262 7.80474C-0.0650874 7.54439 -0.0650874 7.12228 0.195262 6.86193L3.05719 4Z"
                      fill="#44A5FF"
                    />
                  </svg>
                </h2>
                <div class="helper-box__subtitle mb-20">
                  <span class="text-muted">
                    Bu bölümde canlı dersler hakkında teknik bilgi
                    alabilirsiniz.
                  </span>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3 mb-15">
            <router-link :to="{ name: 'helper-details' }" class="helper-box">
              <div class="helper-box__img">
                <img src="../assets/img/helpbox-2.png" class="" alt="" />
              </div>
              <div class="helper-box__body">
                <h2 class="helper-box__title  mb-15">
                  Kargo
                  <svg
                    width="5"
                    height="8"
                    viewBox="0 0 5 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.05719 4L0.195262 1.13807C-0.0650874 0.877721 -0.0650874 0.455612 0.195262 0.195262C0.455612 -0.0650873 0.877722 -0.0650873 1.13807 0.195262L4.4714 3.5286C4.73175 3.78895 4.73175 4.21106 4.4714 4.4714L1.13807 7.80474C0.877722 8.06509 0.455612 8.06509 0.195262 7.80474C-0.0650874 7.54439 -0.0650874 7.12228 0.195262 6.86193L3.05719 4Z"
                      fill="#44A5FF"
                    />
                  </svg>
                </h2>
                <div class="helper-box__subtitle mb-20">
                  <span class="text-muted">
                    Kargolar durumu hakkında yardım bu bölümden yapabilirsiniz.
                  </span>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3 mb-15">
            <router-link :to="{ name: 'helper-details' }" class="helper-box">
              <div class="helper-box__img">
                <img src="../assets/img/helpbox-3.png" class="" alt="" />
              </div>
              <div class="helper-box__body">
                <h2 class="helper-box__title  mb-15">
                  Ödeme & Sipariş
                  <svg
                    width="5"
                    height="8"
                    viewBox="0 0 5 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.05719 4L0.195262 1.13807C-0.0650874 0.877721 -0.0650874 0.455612 0.195262 0.195262C0.455612 -0.0650873 0.877722 -0.0650873 1.13807 0.195262L4.4714 3.5286C4.73175 3.78895 4.73175 4.21106 4.4714 4.4714L1.13807 7.80474C0.877722 8.06509 0.455612 8.06509 0.195262 7.80474C-0.0650874 7.54439 -0.0650874 7.12228 0.195262 6.86193L3.05719 4Z"
                      fill="#44A5FF"
                    />
                  </svg>
                </h2>
                <div class="helper-box__subtitle mb-20">
                  <span class="text-muted">
                    Ödeme yöntemleri ve sipariş ile ilgili konularda yardım
                    alabilirsiniz.
                  </span>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3 mb-15">
            <router-link :to="{ name: 'helper-details' }" class="helper-box">
              <div class="helper-box__img">
                <img src="../assets/img/helpbox-4.png" class="" alt="" />
              </div>
              <div class="helper-box__body">
                <h2 class="helper-box__title  mb-15">
                  Eğitimler
                  <svg
                    width="5"
                    height="8"
                    viewBox="0 0 5 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.05719 4L0.195262 1.13807C-0.0650874 0.877721 -0.0650874 0.455612 0.195262 0.195262C0.455612 -0.0650873 0.877722 -0.0650873 1.13807 0.195262L4.4714 3.5286C4.73175 3.78895 4.73175 4.21106 4.4714 4.4714L1.13807 7.80474C0.877722 8.06509 0.455612 8.06509 0.195262 7.80474C-0.0650874 7.54439 -0.0650874 7.12228 0.195262 6.86193L3.05719 4Z"
                      fill="#44A5FF"
                    />
                  </svg>
                </h2>
                <div class="helper-box__subtitle mb-20">
                  <span class="text-muted">
                    Eğitimlerimiz hakkında detaylı bilgi alabilirsiniz.
                  </span>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3 mb-15">
            <router-link :to="{ name: 'helper-details' }" class="helper-box">
              <div class="helper-box__img">
                <img src="../assets/img/helpbox-5.png" class="" alt="" />
              </div>
              <div class="helper-box__body">
                <h2 class="helper-box__title  mb-15">
                  Eklentiler
                  <svg
                    width="5"
                    height="8"
                    viewBox="0 0 5 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.05719 4L0.195262 1.13807C-0.0650874 0.877721 -0.0650874 0.455612 0.195262 0.195262C0.455612 -0.0650873 0.877722 -0.0650873 1.13807 0.195262L4.4714 3.5286C4.73175 3.78895 4.73175 4.21106 4.4714 4.4714L1.13807 7.80474C0.877722 8.06509 0.455612 8.06509 0.195262 7.80474C-0.0650874 7.54439 -0.0650874 7.12228 0.195262 6.86193L3.05719 4Z"
                      fill="#44A5FF"
                    />
                  </svg>
                </h2>
                <div class="helper-box__subtitle mb-20">
                  <span class="text-muted">
                    Dersler için gerekli uygulama ve teknik bilgilendirme
                  </span>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3 mb-15">
            <router-link :to="{ name: 'helper-details' }" class="helper-box">
              <div class="helper-box__img">
                <img src="../assets/img/helpbox-6.png" class="" alt="" />
              </div>
              <div class="helper-box__body">
                <h2 class="helper-box__title  mb-15">
                  Uygulamalar
                  <svg
                    width="5"
                    height="8"
                    viewBox="0 0 5 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.05719 4L0.195262 1.13807C-0.0650874 0.877721 -0.0650874 0.455612 0.195262 0.195262C0.455612 -0.0650873 0.877722 -0.0650873 1.13807 0.195262L4.4714 3.5286C4.73175 3.78895 4.73175 4.21106 4.4714 4.4714L1.13807 7.80474C0.877722 8.06509 0.455612 8.06509 0.195262 7.80474C-0.0650874 7.54439 -0.0650874 7.12228 0.195262 6.86193L3.05719 4Z"
                      fill="#44A5FF"
                    />
                  </svg>
                </h2>
                <div class="helper-box__subtitle mb-20">
                  <span class="text-muted">
                    Akındil ve YDSnet uygulamaları hakkında bilgiler
                  </span>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3 mb-15">
            <router-link :to="{ name: 'helper-details' }" class="helper-box">
              <div class="helper-box__img">
                <img src="../assets/img/helpbox-7.png" class="" alt="" />
              </div>
              <div class="helper-box__body">
                <h2 class="helper-box__title  mb-15">
                  Deneme Sınavları
                  <svg
                    width="5"
                    height="8"
                    viewBox="0 0 5 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.05719 4L0.195262 1.13807C-0.0650874 0.877721 -0.0650874 0.455612 0.195262 0.195262C0.455612 -0.0650873 0.877722 -0.0650873 1.13807 0.195262L4.4714 3.5286C4.73175 3.78895 4.73175 4.21106 4.4714 4.4714L1.13807 7.80474C0.877722 8.06509 0.455612 8.06509 0.195262 7.80474C-0.0650874 7.54439 -0.0650874 7.12228 0.195262 6.86193L3.05719 4Z"
                      fill="#44A5FF"
                    />
                  </svg>
                </h2>
                <div class="helper-box__subtitle mb-20">
                  <span class="text-muted">
                    Deneme sınavları hakkında detaylı bilgilendirme.
                  </span>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3 mb-15">
            <router-link :to="{ name: 'helper-details' }" class="helper-box">
              <div class="helper-box__img">
                <img src="../assets/img/helpbox-8.png" class="" alt="" />
              </div>
              <div class="helper-box__body">
                <h2 class="helper-box__title  mb-15">
                  Öğreticiler
                  <svg
                    width="5"
                    height="8"
                    viewBox="0 0 5 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.05719 4L0.195262 1.13807C-0.0650874 0.877721 -0.0650874 0.455612 0.195262 0.195262C0.455612 -0.0650873 0.877722 -0.0650873 1.13807 0.195262L4.4714 3.5286C4.73175 3.78895 4.73175 4.21106 4.4714 4.4714L1.13807 7.80474C0.877722 8.06509 0.455612 8.06509 0.195262 7.80474C-0.0650874 7.54439 -0.0650874 7.12228 0.195262 6.86193L3.05719 4Z"
                      fill="#44A5FF"
                    />
                  </svg>
                </h2>
                <div class="helper-box__subtitle mb-20">
                  <span class="text-muted">
                    Yds.net çalışma odası hakkında bilgilendirmeler.
                  </span>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../components/Layout.vue';
export default {
  components: { Layout },
};
</script>

<style></style>
