<template>
    <swiper class="sb-swiper" :options="swiperOptions">
        <swiper-slide v-for="slider of sliderData" :key="slider.id">
            <a :href="slider.translate.slug ? slider.translate.slug : 'https://yds.net/'" class="d-block" target="__blank" title="Ayrıntılar için tıklayınız.">
                <img :src="slider.image.public_url" :alt="slider.translate.short_title" class="w-100 rounded-sm "/>
            </a>
        </swiper-slide>
    </swiper>
</template>

<script>
import {swiper, swiperSlide} from 'vue-awesome-swiper';

export default {
    props: ['sliderData'],
    components: {
        swiper,
        swiperSlide,
    },
    data() {
        return {
            swiperOptions: {
                speed: 2000,
                autoplay: {
                    delay: 4000,
                },
                slidesPerView: 'auto',
                loop: true,

                mousewheel: true,
            },
        };
    },
};
</script>