import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import EcommerceOrder from "@/store/ecommerce/order";
import LocalizationLanguage from "@/store/localization/language";
import LocalizationCurrency from "@/store/localization/currency";
import ModuleParagraph from "@/store/module/paragraph";
import ModuleSource from "@/store/module/source";
import ModuleSourceCategory from "@/store/module/source/category";
import Notification from "@/store/notification";

export default new Vuex.Store({
    state: () => ({}),
    getters: {},
    actions: {},
    mutations: {},
    modules: {
        ecommerce: {
            namespaced: true,
            modules: {
                order: EcommerceOrder,
            }
        },
        localization: {
            namespaced: true,
            modules: {
                language: LocalizationLanguage,
                currency: LocalizationCurrency
            }
        },
        module: {
            namespaced: true,
            modules: {
                paragraph: ModuleParagraph,
                source: Object.assign(ModuleSource,{
                    modules: {
                        category: ModuleSourceCategory,
                    }
                }),
            }
        },
        notification: Notification
    }
});