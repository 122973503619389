<template>
    <Layout>
        <div class="d-flex justify-content-center">
            <nav class="breadcrumb-nav" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a href="/">
                            <i class="icon-home"></i>
                        </a>
                    </li>
                    <li class="breadcrumb-item" aria-current="page">{{ $t('module.full_paragraph_question.title')}}</li>
                    <li class="breadcrumb-item active" aria-current="page">{{ source.title }}</li>
                </ol>
            </nav>
        </div>

        <div class="container">
            <div class="page-info mb-30">
                <h1 class="page-info__title">{{ source.title }}</h1>
            </div>
            <div v-if="source.upload">
                <div class="general-box list-detail-card">
                    <div class="content-card__body p-20 position-relative">
                        <iframe :src="source.upload.public_url + ((source.options && source.options.show_toolbar) ? '' : '#toolbar=0')" width="100%" height="800px"></iframe>

                        <div class="d-flex justify-content-center mt-20" v-if="source.options && source.options.show_download">
                            <a :href="source.upload.public_url + '?download=1'" class="btn-cta btn-cta--white bg-light text-gray-soft btn-with-icon shadow-none" download>
                                <i class="icon-down btn-icon bg-white shadow-sm"></i> {{ $t('common.downloadDocument') }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from '@/components/Layout';
export default {
    name: "index",
    components: {
        Layout
    },
    data(){
        return {
            source: {}
        }
    },
    computed:{
        sourceID(){
            return this.$route.params.id;
        }
    },
    created() {
        this.loadSource();
    },
    methods: {
        loadSource(){
            this.axios.get(this.endpoints['module_source'] + '/' + this.sourceID).then((response) => {
                let data = response.data.data;
                this.source = data;
            });
        }
    }
}
</script>

<style>
iframe {
    border: 0;
}
.btn-cta {
    height: 50px;
    padding: 10px 20px;
    border-radius: 0.75rem;
    outline: none;
    -webkit-transition: all ease-out .1s;
    transition: all ease-out .1s;
    font-weight: 500;
    font-size: 13px;
    position: relative;
    line-height: 2.3;
    text-align: center;
    background-color: transparent;
}
.btn-with-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px;
    border: 1px solid transparent;
}
.btn-icon {
    width: 35px;
    height: 35px;
    border-radius: 12px;
    padding: 7px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.btn-with-icon .btn-icon {
    margin-right: 7px;
}
.btn-cta--white:hover .btn-icon {
    color: #bd1a20;
    background-color: #fff!important;
}
</style>