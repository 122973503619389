<template>
    <div class="box-404 d-flex flex-column align-items-center justify-content-center mx-auto">
        <img src="@/assets/img/404.svg" alt="" width="300"/>
        <div class="d-flex flex-column align-items-center" v-if="description && description.trim().length">
            <span class="text-muted fw-600 h6">{{ description }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['description'],
    components: {},
};
</script>

<style>
.box-404 {
    height: 60vh !important;
}
</style>
