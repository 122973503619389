<template>
    <LayoutUser>
        <router-link
            :to="{ name: 'orders' }"
            class="h5 mb-20 d-flex align-items-center"
        >
            <span class="icon-chevron-down mr-5 rotate-90"></span>

            Siparişlerim
        </router-link>

        <div class="px-15 py-15">
            <div class="row" v-if="orderDetails.address">
                <div class="col-md-12">
                    <div class="text-gray-soft mb-10">
                        Sipariş No:
                        <span class="text-gray-dark fw-600">#{{ orderDetails.id }}</span>
                    </div>
                    <div class="text-gray-soft mb-10">
                        Tarih:
                        <span class="text-gray-dark fw-600">{{
                                moment.utc(orderDetails.created_at).format('DD.MM.YYYY HH:mm:ss')
                            }}</span>
                    </div>
                    <div class="text-gray-soft mb-10">
                        Ad Soyad:
                        <span class="text-gray-dark fw-600">{{
                                orderDetails.address.full_name && orderDetails.address.full_name
                            }}</span>
                    </div>
                    <div class="text-gray-soft mb-10">
                        E-posta Adresi:
                        <span class="text-gray-dark fw-600">{{
                                this.$root.user.email
                            }}</span>
                    </div>
                    <div class="text-gray-soft mb-10">
                        İletişim Numarası:
                        <span
                            class="text-gray-dark fw-600"
                            v-if="orderDetails.address.mobile_phone"
                        >+90 {{ orderDetails.address.mobile_phone }}</span
                        >
                    </div>
                    <div class="text-gray-soft mb-10">
                        Adres Detay:
                        <span class="text-gray-dark fw-600" v-if="orderDetails.address"
                        >{{ orderDetails.address.address }}
              <span class="d-block">{{
                      ((orderDetails.address.district && orderDetails.address.district.trim().length) ? orderDetails.address.district : "-") +
                      '/' +
                      orderDetails.address.city.name +
                      '/' +
                      orderDetails.address.state.name
                  }}</span>
            </span>
                    </div>
                </div>
            </div>
            <div class="text-info manuel-box" v-else>
                <svg
                    width="46"
                    height="46"
                    viewBox="0 0 46 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clip-path="url(#clip0_1105_1035)">
                        <path
                            d="M22.4753 1.73937L3.73033 11.403C3.32065 11.6437 3.20205 12.0031 3.20565 12.5242L3.24158 13.1747L23.0324 23.3234L42.7764 13.3364C42.7764 13.3364 42.798 13.2358 42.798 12.8836C42.798 12.8836 42.78 12.42 42.6758 12.2044C42.5608 11.96 42.3164 11.7408 42.1583 11.6078C41.7378 11.2592 41.3174 10.9502 40.6022 10.5477C30.3492 4.75094 23.6074 1.76453 23.6074 1.76453C23.1725 1.60281 22.8131 1.54531 22.4753 1.73937Z"
                            fill="#DEA66C"
                        />
                        <path
                            d="M3.50031 34.5575C4.59281 35.2798 6.58734 36.2286 8.60343 37.3211C13.2897 39.8583 19.2122 42.9417 22.1591 44.1528C22.558 44.3181 22.7951 44.3972 23.0719 44.4116C23.2767 44.4223 23.6145 44.2858 23.6145 44.2858C23.6145 44.2858 23.0647 24.4519 23.0323 23.3198C23 22.213 22.5364 21.8464 22.1267 21.5805C21.487 21.1708 20.0998 20.5311 20.0998 20.5311C20.0998 20.5311 15.2411 18.1161 10.3895 15.5717C8.08234 14.3606 6.13453 13.1208 4.03937 12.1001C3.65484 11.9169 3.20203 12.1469 3.20203 12.5781V34.0687C3.20203 34.2736 3.32781 34.4461 3.50031 34.5575V34.5575Z"
                            fill="#B38251"
                        />
                        <path
                            d="M42.2553 34.6833L23.6541 44.275C23.3558 44.4295 23 44.2139 23 43.8761L23.0323 23.327C23.0323 23.1294 22.9713 22.6945 22.9102 22.522C22.7413 22.0189 23.1078 21.8177 23.2731 21.735L41.7234 12.2331C42.2122 11.9816 42.798 12.3373 42.798 12.8872V33.7992C42.798 34.1694 42.5895 34.5108 42.2553 34.6833V34.6833Z"
                            fill="#966239"
                        />
                        <path
                            opacity="0.5"
                            d="M33.9466 17.1027C33.9466 17.1027 23.7511 22.3172 23.5858 22.7592C23.4205 23.1977 31.5855 19.2733 32.5414 18.7809C33.6231 18.2239 42.1188 13.6814 42.1188 13.1819C42.1223 12.7722 33.9466 17.1027 33.9466 17.1027Z"
                            fill="#212121"
                        />
                        <g opacity="0.5">
                            <path
                                opacity="0.5"
                                d="M4.60358 33.368C5.92967 34.0364 7.24498 34.7264 8.88373 35.5637V34.924C7.25217 34.0903 5.93326 33.4039 4.60358 32.7283V33.368V33.368ZM7.39592 33.659C7.76967 33.8495 7.95655 33.9465 8.30873 34.1262C8.31233 33.0122 8.31233 32.4551 8.31592 31.3411C7.96373 31.1614 7.78045 31.068 7.4103 30.8775C7.40311 31.9915 7.39951 32.545 7.39592 33.659V33.659ZM5.07436 32.4659C5.38342 32.624 5.55592 32.7139 5.90811 32.8936L5.92608 30.1192C5.5703 29.9359 5.3978 29.8497 5.08514 29.6844C5.08155 30.7984 5.07795 31.3555 5.07436 32.4659V32.4659Z"
                                fill="#212121"
                            />
                            <path
                                opacity="0.5"
                                d="M6.81014 30.9026C7.66186 31.3375 8.11467 31.5711 8.86576 31.952C8.49201 31.2728 8.28717 30.9278 7.87748 30.2306C7.45701 30.5037 7.2378 30.6367 6.81014 30.9026ZM4.60358 29.7706C5.23967 30.1012 5.66733 30.3205 6.50826 30.7517C6.09139 30.0509 5.88295 29.7023 5.49842 29.0159C5.1067 29.2998 4.92342 29.4472 4.60358 29.7706Z"
                                fill="#212121"
                            />
                        </g>
                        <g opacity="0.5">
                            <path
                                opacity="0.5"
                                d="M11.4317 36.4406C11.73 36.5952 11.8845 36.6778 12.1828 36.8323C12.1612 35.5098 12.1505 34.8486 12.1253 33.5261C11.8378 33.3752 11.6941 33.2997 11.4066 33.1523C11.4173 34.4677 11.4209 35.1253 11.4317 36.4406V36.4406Z"
                                fill="#212121"
                            />
                            <path
                                opacity="0.5"
                                d="M10.3356 36.207C11.4281 36.782 12.2763 37.2133 13.3256 37.7739C13.3185 37.3966 13.0274 36.9402 12.632 36.7281C12.0139 36.4011 11.6617 36.2214 11.0364 35.8944C10.6339 35.6895 10.3356 35.8369 10.3356 36.207V36.207ZM12.4164 32.4731L12.8764 33.447C12.7327 33.7525 12.6572 33.907 12.5063 34.2089C12.6356 34.1981 12.7003 34.1945 12.8297 34.1838C13.0022 33.9538 13.0885 33.8388 13.2538 33.6088C13.1028 33.253 13.0238 33.0733 12.8656 32.7175C13.3041 32.9583 13.5197 33.0805 13.9186 33.3141C13.9186 34.6006 13.0777 35.1684 11.8127 34.5072C10.5477 33.8495 9.71033 32.3797 9.71033 31.0895C10.6519 31.5603 11.2844 31.8694 12.4164 32.4731V32.4731Z"
                                fill="#212121"
                            />
                        </g>
                        <path
                            d="M36.2034 14.9105C36.0237 14.7775 16.7289 4.69702 16.7289 4.69702L10.7417 7.79124L30.5253 17.9939L30.5361 18.0047L36.3256 15.0398C36.3256 15.0398 36.3148 15.0111 36.2825 14.9751C36.2573 14.9536 36.2142 14.9212 36.2034 14.9105Z"
                            fill="#FFE0B2"
                        />
                        <path
                            d="M36.2969 14.9967C33.0194 16.6103 30.5253 17.9939 30.5253 17.9939C30.608 18.0765 30.6116 18.1844 30.6116 18.2383C30.6008 18.6551 30.5936 18.9858 30.5828 19.399C30.5325 21.5373 30.5038 23.6792 30.4319 25.8175C30.6727 25.6737 30.9638 25.99 31.183 25.8175C31.5208 25.5515 32.2395 24.38 32.3474 24.344C32.4911 24.2973 32.6456 24.3333 32.793 24.3261C33.4399 24.2901 34.8881 22.9712 35.1972 22.9101C35.4955 22.8526 36.4802 23.0431 36.4802 23.0431C36.4802 23.0431 36.4119 16.5205 36.3867 15.4244C36.3795 15.0362 36.2969 14.9967 36.2969 14.9967V14.9967Z"
                            fill="#BF9F85"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1105_1035">
                            <rect width="46" height="46" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>

                <p class="h6 mb-0 ml-10">
                    Bu siparişiniz manuel olarak oluşturulmuştur.
                </p>
            </div>
        </div>

        <div class=" px-15 py-30" v-if="orderDetails.shipping_detail">
            <div class="row">
                <div class="col-md-3">
                    <div class="text-gray-soft mb-10">
                        Sipariş Durumu:
                        <span
                            class="d-block text-gray-dark fw-600"
                            :class="
                orderDetails.status.id == 1
                  ? 'text-warning'
                  : orderDetails.status.id == 2
                  ? 'text-success'
                  : orderDetails.status.id == 3
                  ? 'text-info'
                  : orderDetails.status.id == 4
                  ? 'text-success'
                  : orderDetails.status.id == 5
                  ? 'text-success'
                  : orderDetails.status.id == 6
                  ? 'text-danger'
                  : orderDetails.status.id == 7
                  ? 'text-danger'
                  : ''
              "
                        >{{ orderDetails.status.name }}</span
                        >
                    </div>
                </div>
                <div
                    class="col-md-3"
                    v-if="orderDetails.shipping_detail.shipping_method"
                >
                    <div class="text-gray-soft mb-10">
                        Kargo Firması:
                        <span class="d-block text-gray-dark fw-600">
              {{ orderDetails.shipping_detail.shipping_method.name }}</span
                        >
                    </div>
                </div>
                <div
                    class="col-md-3"
                    v-if="orderDetails.shipping_detail.shipping_tracking_number"
                >
                    <div class="text-gray-soft mb-10">
                        Kargo Takip No:
                        <span class="d-block text-gray-dark fw-600">{{
                                orderDetails.shipping_detail.shipping_tracking_number
                            }}</span>
                        <a v-if="[5, 6].includes(orderDetails.shipping_detail.shipping_status_id)" v-on:click="showShippingDetail()" class="d-block text-green fw-600 cursor-pointer">Kargom Nerede?</a>
                    </div>
                </div>
                <div class="col-md-3">
                    <div
                        class="text-gray-soft mb-10"
                        v-if="orderDetails.last_invoice && orderDetails.last_invoice.provider_invoice"
                    >
                        Fatura:

                        <button
                            target="__blank"
                            class="d-block text-green fw-600"
                            :class="{ active: clickedInvoiceBtn }"
                            @click.prevent="getInvoice"
                            :disabled="clickedInvoiceBtn"
                            v-if="
                orderDetails.last_invoice.provider_invoice
                  .provider_legalize_status_id === 2
              "
                        >
                            {{ clickedInvoiceBtn ? 'Lütfen Bekleyin...' : 'Görüntüle' }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <hr/>
        <div class=" px-15 py-15">
            <div v-for="(item, index) in orderDetails.items" :key="index">
                <div class="row align-items-center">
                    <div class="col-9 mb-20">
                        <p class="h6 fw-700 text-gray-dark mb-0">
                            {{ item.title }}
                        </p>
                    </div>
                    <div class="col-3 mb-20">
                        <p class="fs-16 fw-700 mb-0">
                            {{ item.total + ' ' + orderDetails.currency.suffix }}
                        </p>
                    </div>
                </div>

                <div v-if="item.children">
                    <div
                        class="row"
                        v-for="childItem in item.children"
                        :key="childItem.id"
                    >
                        <div class="col-9 pl-30">
                            <p class="fs-16 fw-500 text-gray-soft mb-10">
                                {{ childItem.title }}
                            </p>
                        </div>
                        <div class="col-3">
                            <p class="text-gray-soft  fw-500 mb-10">
                                {{ childItem.total + ' ' + orderDetails.currency.suffix }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr/>

        <div class="px-15 py-15">
            <div class="row" v-if="orderDetails.payment_detail">
                <div class="col-md-3">
                    <div class="text-gray-soft mb-10">
                        Toplam Tutar:
                        <span class="d-block text-gray-dark fw-600">{{
                                totalPrice + ' ' + orderDetails.currency.suffix
                            }}</span>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="text-gray-soft mb-10">
                        İndirimler:
                        <span class="d-block text-gray-dark fw-600">{{
                                totalPrice -
                                orderDetails.payment_detail.payment_total +
                                ' ' +
                                orderDetails.currency.suffix
                            }}</span>
                    </div>
                </div>
                <div class="col-md-3" v-if="orderDetails.shipping_detail">
                    <div class="text-gray-soft mb-10">
                        Kargo Ücreti:
                        <span class="d-block text-gray-dark fw-600"
                        >{{
                                orderDetails.shipping_detail.shipping_total +
                                ' ' +
                                orderDetails.currency.suffix
                            }}
            </span>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="text-gray-soft mb-10">

                        Ödenen veya Ödenecek Tutar:
                        <span class="d-block text-gray-dark fw-600">{{
                                orderDetails.payment_detail.payment_total +
                                ' ' +
                                orderDetails.currency.suffix
                            }}</span>
                    </div>
                </div>
            </div>
        </div>
        <hr/>

        <div class="cs-modal" v-if="shippingDetailModal">
            <div class="cs-modal__body cs-modal-lg px-30">
                <span class="cs-modal__close" @click="shippingDetailModal = false">x</span>
                <div class="text-center mt-10">
                    <p class="h4  text-primary mb-30">{{ $t('ecommerce.order.shipping.detail.title') }}</p>
                    <div class="row mb-3" v-if="shippingDetail.details && Object.keys(shippingDetail.details).length">
                        <div class="col-md-4">
                            <div class="text-gray-soft mb-10">{{ $t('ecommerce.order.shipping.detail.cols.received') }}:
                                <span class="d-block text-gray-dark fw-600">{{ $t('ecommerce.order.shipping.detail.receivedStatus.' + (shippingDetail.details.received ? 'yes': 'no')) }}</span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="text-gray-soft mb-10">{{ $t('ecommerce.order.shipping.detail.cols.receivingPerson') }}:
                                <span class="d-block text-gray-dark fw-600">{{ shippingDetail.details.receiving_person ? shippingDetail.details.receiving_person : "-" }}</span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="text-gray-soft mb-10">{{ $t('ecommerce.order.shipping.detail.cols.receivingDate') }}:
                                <span class="d-block text-gray-dark fw-600">{{ ((shippingDetail.details.receiving_date) ? moment.unix(shippingDetail.details.receiving_date).format('DD.MM.YYYY HH:mm') : "-") }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <p class="h6 text-danger mt-3 mb-3">
                                {{ $t('ecommerce.order.shipping.detail.movement.title') }}
                            </p>
                            <div class="table-responsive" v-if="shippingDetail.movements && shippingDetail.movements.length">
                                <table class="table fw-bold">
                                    <tbody>
                                        <tr v-for="(movement, movementIndex) in shippingDetail.movements" :key="movementIndex">
                                            <td class="min-w-70px">
                                                <div class="badge" :class="movementStatus[movement.status_id].badge">
                                                    {{ $t('ecommerce.order.shipping.detail.movement.status.' + [movementStatus[movement.status_id].code]) }}
                                                </div>
                                            </td>

                                            <td>
                                                {{ movement.message }}
                                            </td>

                                            <td class="pe-0 text-end min-w-200px">
                                                {{ moment.unix(movement.date).format('DD.MM.YYYY HH:mm') }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </LayoutUser>
</template>

<script>
import LayoutUser from '../components/LayoutUser.vue';

export default {
    components: {LayoutUser},
    data() {
        return {
            clickedInvoiceBtn: false,
            pdfUrl: '',
            totalPrice: '',
            orderDetails: {
                address: {
                    full_name: 'deneme',
                    city: {
                        name: '',
                    },
                    state: {
                        name: '',
                    },
                },
            },
            shippingDetailModal: false,
            shippingDetail: {},
            movementStatus: {
                1: {
                    code: 'accepted',
                    badge: 'badge-primary'
                },
                2: {
                    code: 'atSenderBranch',
                    badge: 'badge-warning'
                },
                3: {
                    code: 'onTheRoad',
                    badge: 'badge-light'
                },
                4: {
                    code: 'atReceiverBranch',
                    badge: 'badge-info'
                },
                5: {
                    code: 'delivered',
                    badge: 'badge-success'
                }
            }
        };
    },
    computed: {
        orderID() {
            return this.$route.params.id
        }
    },
    methods: {
        fetchOrderDetails() {
            this.axios
                .get('/ecommerce/order/' + this.$route.params.id)
                .then((response) => {
                    this.orderDetails = response.data.data;
                });
        },
        getInvoice() {
            this.clickedInvoiceBtn = true;
            this.axios
                .get(
                    '/ecommerce/invoice/show-legalized-pdf/' +
                    this.orderDetails.last_invoice.id,
                )
                .then((response) => {
                    this.clickedInvoiceBtn = false;
                    this.pdfUrl = response.data.data.url;

                    var fileLink = document.createElement('a');

                    fileLink.href = response.data.data.url;
                    fileLink.setAttribute('download', 'file.pdf');
                    fileLink.setAttribute('target', '__blank');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                });
        },
        calculatePrice() {

            for (const element of [...this.orderDetails.items]) {
                this.totalPrice += element.total;
            }
        },
        showShippingDetail() {
            this.axios.get(this.endpoints['ecommerce_order_shipping_detail'] + '/' + this.orderID).then((response) => {
                this.onResponse(response.data, () => {
                    let data = response.data.data;
                    this.shippingDetail = data
                    this.shippingDetailModal = true;
                });
            }).catch(error => {
                this.onResponseFailure(error.response.data);
            });
        }
    },
    created() {
        this.fetchOrderDetails();
    },
    watch: {
        orderDetails() {
            this.calculatePrice();
        },
    },
};
</script>

<style>
.order-img {
    min-width: 4rem;
    height: 5rem;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
}

.manuel-box {
    display: flex;
    align-items: center;
    background-color: var(--bg-light);
    padding: 1rem;
    border-left: 3px solid #17a2b8;
}
</style>
