<template>
  <router-link
    :to="{ name: 'lesson_detail', params: { id: lessonId } }"
    class="grid-exam-card"
  >
    <div class="grid-exam-card__left">
      <img :src="image" :alt="title" class="w-100" width="175px" height="120px" />
    </div>

    <div class="grid-exam-card__center flex-wrap">
      <div class="mr-20 mb-5">
        <span class="mb-5 d-block span">Ders:</span>
        <p class="fw-bold mb-0">{{ title }}</p>
      </div>

      <div class="text-right  mb-5">
        <span class="d-block span"
          >Ders Sayısı: <span class="fw-bold">{{ videoCount }}</span>
        </span>
      </div>
    </div>

    <div class="grid-exam-card__right">
      <ProgressBar :progressValue="watchProgress" />
    </div>
  </router-link>
</template>

<script>
import ProgressBar from './ProgressBar.vue';

export default {
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
      default: 'KOÇ GRUBU - YDS İlkbahar 2021222',
    },
    lessonId: {
      type: Number,
    },
    videoCount: {
      type: Number,
      default: 17,
    },
    watchProgress: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      progressValue: 12,
    };
  },
  components: { ProgressBar },
};
</script>

<style></style>
