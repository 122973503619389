<template>
  <Layout>
    <div v-if="index < words.length">
      <div class="d-flex align-items-center">
        <div class="progress flex-grow-1 w-100" style="height:20px">
          <div
            class="progress-bar bg-primary"
            role="progressbar"
            :style="'width:' + progressBar + '%'"
            :aria-valuenow="progressBar"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <span class="h6 mb-0 ml-10 fw-bold">{{
          index + '/' + words.length
        }}</span>

        <button
          class="btn btn-primary px-40 ml-40"
          v-show="index != words.length"
        >
          Kapat
        </button>
      </div>

      <div class="word-quiz-wrapper py-50">
        <div class="word-quiz">
          <div class="word-quiz__header">
            <button
              class="voice-btn"
              @click="playSound"
              :class="{ active: soundActive }"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 5L6 9H2V15H6L11 19V5Z"
                  stroke="#AEB6CF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.07 4.92969C20.9447 6.80496 21.9979 9.34805 21.9979 11.9997C21.9979 14.6513 20.9447 17.1944 19.07 19.0697M15.54 8.45969C16.4774 9.39733 17.004 10.6689 17.004 11.9947C17.004 13.3205 16.4774 14.592 15.54 15.5297"
                  stroke="#AEB6CF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <audio ref="audioElm" :src="words[index].sound"></audio>

            <span class="text-primary h4 fw-bold">{{ words[index].word }}</span>
            <span class="desc">Kelimenin Türkçe karşılığını bulunuz</span>
          </div>
          <hr class="my-30" />
          <div class="word-quiz__body">
            <label
              v-for="(answer, key) in words[index]['answers']"
              class="wq-option"
              :for="key"
              :key="key"
              :class="{
                'hover-option': selectedAnswer == '',
                correct:
                  key == words[index]['correctAnswer'] && selectedAnswer != '',
                wrong: selectedAnswer == key,
              }"
              @click="playAnswerSound(key)"
            >
              <input
                type="radio"
                :id="key"
                class="hidden"
                :value="key"
                @change="answered($event)"
                v-model="selectedAnswer"
                :disabled="selectedAnswer != ''"
              />
              {{ answer }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="words-completed mx-auto py-50">
        <div class="text-center">
          <p class="text-primary h4 fw-bold">Kelimeleri tamamladın</p>
          <p class="fw-700 text-muted">Başarı Oranı</p>
          <div class="d-flex align-items-center">
            <div class="progress flex-grow-1" style="height:8px">
              <div
                class="progress-bar bg-primary"
                role="progressbar"
                :style="'width:25%'"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <span class="text-primary h6 mb-0 ml-10 fw-bold">85%</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 mb-3" v-for="(wrd, index) in words" :key="index">
          <div class="daily-word wq-word-result">
            <div class="daily-word__header">
              <div class="d-flex align-items-start">
                <span class="voice-btn"
                  ><svg
                    width="26"
                    height="22"
                    viewBox="0 0 26 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.3388 4.7207L7.13876 8.24548H2.97876V13.5326H7.13876L12.3388 17.0574V4.7207Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20.7315 4.6582C22.6812 6.31069 23.7765 8.55163 23.7765 10.8882C23.7765 13.2249 22.6812 15.4658 20.7315 17.1183M17.0603 7.76882C18.0352 8.59506 18.5828 9.71553 18.5828 10.8838C18.5828 12.0521 18.0352 13.1726 17.0603 13.9989"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <div class="word-details">
                  <span class="word">
                    {{ wrd.word }}
                    <div class="mean">kalıntı</div>
                  </span>
                </div>
                <span class="badge badge-danger ml-2">Noun</span>
                <span class="badge badge-danger ml-2">Noun</span>
              </div>
              <Rate :rate="index / 2" width="40" height="30" />
            </div>
            <hr />
            <div class="daily-word__footer">
              <details>
                <summary>Örnek Cümleler</summary>
                <p>
                  Epcot is a theme park at Walt Disney World Resort featuring
                  exciting attractions, international pavilions, award-winning
                  fireworks and seasonal special events.
                </p>
              </details>
            </div>
          </div>
        </div>
      </div>
      <div class="words-completed mx-auto py-60 mt-30">
        <div class="text-center">
          <p class="fw-700 text-muted mb-10">Sonraki Derste</p>
          <p class="text-primary h4 fw-bold mb-20">Buildings 02</p>
          <a href="" class="btn btn-primary px-60">Devam Et</a>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../components/Layout.vue';
import Rate from '../components/Rate.vue';
import successVoice from '@/assets/success.mp3';
import unsuccessVoice from '@/assets/unsuccess.mp3';

export default {
  components: { Layout, Rate },
  mounted() {
    this.$refs.audioElm.addEventListener('ended', this.soundBtnActive, false);
  },
  data() {
    return {
      progressBar: 0,
      startPoint: 1,
      index: 0,
      selectedAnswer: '',
      soundActive: false,
      alertSound: new Audio(),
      words: [
        {
          word: 'garden',
          answers: { a: 'balkon', b: 'bahce', c: 'ev', d: 'yeşil' },
          sound: 'http://steveobrienvo.com/demos/NARRATION.mp3',
          correctAnswer: 'b',
        },
        {
          word: 'home',
          answers: { a: 'ev', b: 'araba', c: 'kalem', d: 'test' },
          sound: 'http://steveobrienvo.com/demos/PROMO.mp3',
          correctAnswer: 'a',
        },
        {
          word: 'target',
          answers: { a: 'ev', b: 'araba', c: 'kalem', d: 'hedef' },
          sound: 'http://steveobrienvo.com/demos/TV.mp3',
          correctAnswer: 'd',
        },
        {
          word: 'hungry',
          answers: { a: 'doku', b: 'kızgın', c: 'susamış', d: 'aç' },
          sound: 'http://steveobrienvo.com/demos/NARRATION.mp3',
          correctAnswer: 'd',
        },
        {
          word: 'enemy',
          answers: { a: 'düşman', b: 'yorgun', c: 'kırmızı', d: 'kan' },
          sound: 'http://steveobrienvo.com/demos/PROMO.mp3',
          correctAnswer: 'a',
        },
        {
          word: 'black',
          answers: { a: 'kırmızı', b: 'sarı', c: 'siyah', d: 'yeşil' },
          sound: 'http://steveobrienvo.com/demos/TV.mp3',
          correctAnswer: 'c',
        },
      ],
    };
  },

  methods: {
    nextQuestion() {
      if (this.index < this.words.length) {
        setTimeout(() => {
          this.setProgresBar();
          this.selectedAnswer = '';
          this.index++;
          this.startPoint++;
          this.soundActive = false;
        }, 1100);
      }
    },
    answered(e) {
      this.selectedAnswer = e.target.value;
      this.nextQuestion();
    },
    setProgresBar() {
      this.progressBar = (this.startPoint / this.words.length) * 100;
    },
    playSound() {
      this.$refs.audioElm.currentTime = 0;
      this.soundActive = true;
      this.$refs.audioElm.play();
    },

    playAnswerSound(key) {
      if (key == this.words[this.index]['correctAnswer']) {
        this.$refs.audioElm.pause();
        this.alertSound.src = successVoice;
      } else {
        this.$refs.audioElm.pause();
        this.alertSound.src = unsuccessVoice;
      }
      setTimeout(() => {
        this.alertSound.play();
      }, 100);
    },
    soundBtnActive() {
      this.soundActive = false;
    },
  },
};
</script>

<style></style>
