<template>
  <Layout>
    <div
      class="box-404 d-flex flex-column align-items-center justify-content-center mx-auto"
    >
      <img src="@/assets/img/404.svg" alt="" width="350" />
      <div class="d-flex flex-column align-items-center">
        <router-link to="/" class="text-muted fw-600 h6 "
          >Ansayfaya Dön</router-link
        >
        <router-link :to="{ name: 'lesson_list' }" class="text-muted fw-600 h6"
          >Derslerim</router-link
        >
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../components/Layout.vue';
export default {
  components: { Layout },
};
</script>

<style>
.box-404 {
  height: 80vh;
}
</style>
