<template>
  <div class="daily-word">
    <div class="daily-word__header">
      <div class="d-flex align-items-start">
        <!-- <span
          class="voice-btn"
          :class="isPlay(word.sound) ? 'active' : ''"
          @click="audioPlay(word.sound)"
          ><svg
            width="26"
            height="22"
            viewBox="0 0 26 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.3388 4.7207L7.13876 8.24548H2.97876V13.5326H7.13876L12.3388 17.0574V4.7207Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20.7315 4.6582C22.6812 6.31069 23.7765 8.55163 23.7765 10.8882C23.7765 13.2249 22.6812 15.4658 20.7315 17.1183M17.0603 7.76882C18.0352 8.59506 18.5828 9.71553 18.5828 10.8838C18.5828 12.0521 18.0352 13.1726 17.0603 13.9989"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span> -->
        <div class="word-details">
          <span class="word mb-15 ">
            {{ word.text }}
          </span>
          <div class="mean">{{ word.meaning }}</div>
        </div>
        <!-- <span class="badge badge-danger ml-3">adverb</span> -->
      </div>
      <!-- <Rate rate="2" width="40" height="40" /> -->
    </div>
    <!-- <hr />

    <div class="daily-word__body">
      <p class="mb-0">
        {{ word.description }}
      </p>
    </div> -->
    <slot name="daily-word-body"></slot>
    <hr />
    <div class="daily-word__footer">
      <div class="mb-3">
        <p class="mb-0 fw-600 ">Definition:</p>
        <span class="mr-5">{{ word.definition }} </span>
      </div>
      <div class="mb-3">
        <p class="mb-0 fw-600 ">Synonyms:</p>
        <span
          class="mr-5 bg-light-blue"
          v-for="(synonym, index) of word.synonymus"
          :key="'synonym' + index"
          >{{ synonym + ',' }}
        </span>
      </div>

      <details>
        <summary class="fs-16 ">Örnek Cümleler</summary>
        <ul class="lillo">
          <li
            class="mb-5 fs-16"
            v-for="(example, index) in word.examples"
            :key="index"
            v-html="example.text"
          ></li>
        </ul>
      </details>
    </div>
  </div>
</template>

<script>
import Rate from './Rate.vue';
export default {
  components: { Rate },
  mounted() {
    this.audio.addEventListener('ended', this.audioBtnDisabled, false);
  },

  data() {
    return {
      audio: new Audio(),
      activeAudio: '',
      audioIsActive: false,
    };
  },
  props: {
    word: {
      type: Object,
      // default: function() {
      //   return {
      //     text: 'remnant',
      //     mean: 'kalıntı',
      //     description:
      //       'daha büyük bir orijinal parça veya miktardan kalan küçük bir parça veya miktar',
      //     examples: [
      //       {
      //         text:
      //           'Aliqua eiusmod sunt labore sint duis qui consequat consequat sint.',
      //         mean: 'Cillum ad reprehenderit proident aliqua.',
      //       },
      //       {
      //         text: 'Mollit commodo minim minim culpa eu laborum.',
      //         mean: 'Quis reprehenderit',
      //       },
      //     ],
      //     sound: 'http://steveobrienvo.com/demos/NARRATION.mp3',
      //   };
      // },
    },
  },
  methods: {
    isPlay(sound) {
      if (this.activeAudio == sound && this.audioIsActive) {
        return true;
      }
    },
    audioBtnDisabled() {
      this.audioIsActive = false;
    },
    audioPlay(item) {
      this.audio.pause();
      this.activeAudio = item;
      this.audioIsActive = true;
      this.audio.src = item;
      this.audio.currentTime = 0;

      setTimeout(() => {
        this.audio.play();
      }, 20);
    },
  },
};
</script>

<style>
.lillo {
  list-style-type: disc;
  list-style-position: inside;
}

</style>
