import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import LessonList from '../views/Lesson/LessonList';
import LessonShow from '../views/Lesson/LessonShow';
import LessonShowCopy from '../views/Lesson/LessonShowCopy';
import LessonShowNew from '../views/Lesson/LessonShowNew';
import QuizList from '../views/QuizList.vue';
import QuizIndex from '../views/QuizIndex.vue';
import QuizIndexOld from '../views/QuizIndexOld.vue';
import QuizIndexOldd from '../views/QuizIndexOldd.vue';
import WordGroups from '../views/WordGroups.vue';
import WordQuiz from '../views/WordQuiz.vue';
import ParagraphList from '../views/ParagraphList.vue';
import Paragraph from '../views/Paragraph.vue';
import Dictionary from '../views/Dictionary.vue';
import KonuTarama from '../views/KonuTarama.vue';
import Deneme from '../views/Deneme.vue';
import Deneme2 from '../views/Deneme2.vue';
import AccountOrders from '../views/AccountOrders.vue';
import AccountOrderDetails from '../views/AccountOrderDetails.vue';
import Account from '../views/Account.vue';
import AccountAddresses from '../views/AccountAddresses.vue';
import AccountAddressDetails from '../views/AccountAddressDetails.vue';
import AccountAddressCreate from '../views/AccountAddressCreate.vue';
import AccountPasswordReset from '../views/AccountPasswordReset.vue';
import AccountNotifications from '../views/AccountNotifications.vue';
//import AccountNotificationSettings from '../views/AccountNotificationSettings.vue';
import Helper from '../views/Helper.vue';
import HelperDetails from '../views/HelperDetails.vue';
import NotFound from '../views/NotFound.vue';
import ComingSoon from '../views/ComingSoon.vue';
import VoiceRecords from '../views/VoiceRecords.vue';
import FullParagraphQuestion from '../views/pages/full-paragraph-question/index.vue';
import FullParagraphQuestionDetail from '../views/pages/full-paragraph-question/detail/index.vue';


Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/derslerim/:tab?',
        name: 'lesson_list',
        component: LessonList,
    },
    {
        path: '/ders-detay-old/:id/:lecture_id?',
        name: 'lesson_detail_old',
        component: LessonShow,
    },
    {
        path: '/ders-detay-copy/:id/:lecture_id?',
        name: 'lesson_detail_copy',
        component: LessonShowCopy,
    },
    {
        path: '/ders-detay/:id/:lecture_id?',
        name: 'lesson_detail',
        component: LessonShowNew,
    },
    {
        path: '/sinavlar',
        name: 'exam_list',
        component: QuizList,
    },
    {
        path: '/sinav-index',
        name: 'Testi Çöz',
        component: QuizIndex,
    },
    {
        path: '/sinav-index-osym/:user_session_id',
        name: 'exam_show_osym',
        component: QuizIndexOld,
    },
    {
        path: '/sinav-index-osym-1',
        name: 'Testi Çöz ÖSYMm',
        component: QuizIndexOldd,
    },
    {
        path: '/kelime-gruplari',
        name: 'Kelime Grupları',
        component: WordGroups,
    },
    {
        path: '/kelime-test',
        name: 'Kelime Testleri',
        component: WordQuiz,
    },
    {
        path: '/paragraflar',
        name: 'paragraphs_list',
        component: ParagraphList,
    },
    {
        path: '/paragraf/:id',
        name: 'paragraph_details',
        component: Paragraph,
    },
    {
        path: '/fulleten-paragraf-sorulari',
        name: 'full_paragraph_question',
        component: FullParagraphQuestion,
    },
    {
        path: '/fulleten-paragraf-sorulari/:id/detail',
        name: 'full_paragraph_question',
        component: FullParagraphQuestionDetail,
    },
    {
        path: '/sozluk',
        name: 'Sozluk',
        component: Dictionary,
    },
    {
        path: '/konu-tarama',
        name: 'Konu Tarama',
        component: KonuTarama,
    },
    {
        path: '/destek',
        name: 'helper',
        component: Helper,
    },
    {
        path: '/destek-detay',
        name: 'helper-details',
        component: HelperDetails,
    },
    {
        path: '/ses-kayitlari',
        name: 'voice-records',
        component: VoiceRecords,
    },
    {
        path: '/deneme',
        name: 'Deneme',
        component: Deneme,
    },
    {
        path: '/deneme2',
        name: 'Deneme2',
        component: Deneme2,
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/About.vue'),
    },
    {
        path: '/hesabim',
        name: 'account',
        component: Account,
    },
    {
        path: '/adreslerim',
        name: 'account-addresses',
        component: AccountAddresses,
    },
    {
        path: '/adres-olustur',
        name: 'account-address-create',
        component: AccountAddressCreate,
    },
    {
        path: '/adres-detaylari/:id',
        name: 'address_details',
        component: AccountAddressDetails,
    },
     {
       path: '/bildirimlerim',
       name: 'notifications',
       component: AccountNotifications,
     },
    {
        path: '/siparislerim',
        name: 'orders',
        component: AccountOrders,
    },
    {
        path: '/siparis-detaylari/:id/',
        name: 'order-details',
        component: AccountOrderDetails,
    },
    /*{
        path: '/bildirim-ayarlari',
        name: 'notification-settings',
        component: AccountNotificationSettings,
    },*/
    {
        path: '/sifre-yenileme',
        name: 'password-change',
        component: AccountPasswordReset,
    },
    {
        path: '/cok-yakinda/:feature?',
        name: 'coming-soon',
        component: ComingSoon,
    },
    {path: '/:pathMatch(.*)*', component: NotFound},
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;
