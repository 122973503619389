<template>
    <Layout>
        <div class="cs-modal" v-if="nextSession.date">
            <div class="cs-modal__body cs-modal-lg px-30">
                <span class="cs-modal__close" @click="nextSession = {date: undefined}">x</span>
                <div class="text-center mt-10">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="d-flex flex-column align-items-center">
                                <svg
                                    width="320"
                                    height="253"
                                    viewBox="0 0 320 253"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M219.384 29.7605C190.64 35.4799 168.869 57.846 141.108 66.8637C98.6215 80.3082 37.2977 91.8188 29.1293 138.906C12.1516 236.778 164.975 252.263 218.546 211.463C287.298 159.101 237.106 102.679 283.544 60.5143C317.988 29.2376 239.632 24.0005 219.384 29.7605Z"
                                        fill="#E7F0FE"
                                    />
                                    <path
                                        d="M147.071 69.4206C158.012 61.3878 161.119 48.0556 166.754 57.2513C172.393 66.4461 160.857 85.6185 172.115 88.0203C183.376 90.4145 180.207 68.7746 188.403 73.0494C196.602 77.3158 185.415 96.1728 189.374 107.272C189.434 107.44 189.507 107.573 189.573 107.724C185.032 110.37 180.387 112.838 175.767 115.348C172.989 113.915 170.831 112.25 170.194 109.447C167.809 98.9929 167.634 92.6451 145.237 80.9776C130.168 73.1279 126.993 59.4136 126.522 51.2737C134.558 45.1469 136.995 76.8217 147.071 69.4206Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M126.521 51.2739C126.992 59.4138 130.168 73.1281 145.236 80.9778C167.633 92.6453 167.808 98.9923 170.193 109.447C170.83 112.251 172.989 113.915 175.766 115.349C172.887 116.914 170.021 118.501 167.204 120.169C163.862 114.646 144.867 114.719 139.576 107.229C132.979 97.8816 146.664 90.5859 141.834 86.8508C137.005 83.1165 114.446 68.0681 124.319 53.739C125.097 52.6098 125.829 51.801 126.521 51.2739Z"
                                        fill="#5D74E4"
                                    />
                                    <path
                                        d="M56.0143 199.645C58.848 187.455 53.0307 176.268 62.48 179.31C71.9326 182.348 75.3583 202.682 84.6661 197.596C93.9722 192.502 78.7899 179.28 87.0795 177.342C95.3675 175.396 98.848 195.301 108.267 200.679C108.409 200.761 108.54 200.81 108.677 200.876C107.089 205.454 105.32 209.971 103.596 214.503C100.795 215.17 98.2888 215.304 96.1633 213.727C88.2315 207.854 84.3048 203.521 61.6581 208.827C46.4202 212.397 35.9823 204.719 30.7756 199.312C32.7217 190.199 53.4055 210.875 56.0143 199.645Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M30.7756 199.312C35.9832 204.719 46.421 212.397 61.6581 208.827C84.3048 203.522 88.2316 207.853 96.1634 213.728C98.2897 215.304 100.796 215.171 103.596 214.503C102.521 217.328 101.469 220.16 100.5 223.019C94.8539 221.167 81.6202 232.635 73.4341 230.579C63.2211 228.011 68.4135 214.686 62.8008 214.978C57.1874 215.27 32.4008 218.309 30.7149 202.36C30.5802 201.102 30.6072 200.097 30.7756 199.312Z"
                                        fill="#5D74E4"
                                    />
                                    <path
                                        d="M219.68 137.1C225.85 159.806 221.625 163.933 215.991 172.166C210.477 180.213 228.019 192.521 211.314 207.139L210.57 209.694C210.57 209.694 244.372 219.763 248.854 204.557C253.34 189.354 224.499 193.647 229.586 183.931C234.666 174.212 254.608 169.971 252.345 161.662C250.091 153.35 237.312 169.181 231.838 159.956C226.372 150.729 246.749 146.527 249.469 136.86C252.193 127.196 241.125 133.484 228.733 131.061C217.317 128.83 237.412 107.167 228.153 105.529C222.89 110.99 215.529 121.823 219.68 137.1Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M198.178 149.795C195.961 160.223 215.308 177.856 202.588 179.687C189.863 181.516 192.041 196.095 192.041 196.095L211.315 207.139C228.019 192.52 210.477 180.213 215.992 172.166C221.626 163.933 225.851 159.807 219.681 137.1C215.529 121.823 222.891 110.99 228.153 105.529C227.357 105.387 226.342 105.396 225.079 105.578C209.06 107.861 213.024 132.812 213.521 138.478C214.019 144.143 200.397 139.376 198.178 149.795ZM66.3815 209.676C66.3208 209.662 63.1865 208.909 59.5891 206.871C59.5806 206.853 59.5806 206.833 59.5688 206.817C59.1192 206.205 59.1756 204.779 59.259 203.752C60.1861 202.763 62.8152 199.705 62.4421 197.203C61.9899 194.174 56.5229 195.108 56.6989 197.831C56.8632 200.379 57.5293 201.816 58.6939 203.69C58.624 204.534 58.5794 205.589 58.7865 206.4C55.6775 204.501 52.3722 201.642 50.4792 197.478C49.9739 196.366 49.4055 195.39 48.8008 194.523C49.3221 193.313 50.7907 189.484 49.5747 187.239C48.1171 184.548 43.2977 187.296 44.3916 189.795C44.5011 190.044 44.6122 190.271 44.7242 190.495C42.6602 189.119 40.6181 188.505 39.1478 188.235C38.6046 187.779 35.2952 185.115 32.8606 185.687C30.2021 186.312 31.4307 191.118 33.8392 190.762C35.979 190.446 37.195 189.798 38.6653 188.729C40.3065 188.983 42.9272 189.705 45.4358 191.726C46.1541 192.8 46.9768 193.59 48.0362 194.437C48.7352 195.373 49.3886 196.454 49.9613 197.714C50.5886 199.093 51.3684 200.328 52.2408 201.44C50.8867 201.098 48.8968 201.1 47.8004 201.135C46.6021 200.391 43.1571 198.465 40.8126 199.305C37.9343 200.336 39.9074 205.526 42.5415 204.825C45.019 204.167 46.3015 203.221 47.9242 201.7C49.8627 201.648 52.4421 201.784 53.0274 202.401C53.0358 202.41 53.0476 202.413 53.0568 202.421C58.3352 208.338 66.1592 210.208 66.2552 210.23C66.384 210.261 66.5137 210.197 66.5718 210.083C66.5819 210.063 66.5903 210.041 66.5954 210.017C66.6299 209.864 66.5339 209.712 66.3815 209.676Z"
                                        fill="#5D74E4"
                                    />
                                    <path
                                        d="M228.54 188.182C228.439 190.307 225.802 192.402 224.827 193.107C224.499 193.985 223.853 195.557 223.143 196.516C224.302 196.189 225.529 195.974 226.819 195.927C227.997 195.884 229.061 195.719 230.026 195.471C231.038 194.911 231.928 194.517 233.007 194.298C235.415 192.973 236.835 191.137 237.569 189.924C237.204 188.414 237.09 187.243 237.536 185.45C238.038 183.432 242.224 184.022 241.852 186.326C241.511 188.435 238.337 190.184 237.801 190.464C237.11 191.538 235.963 192.951 234.209 194.135C234.421 194.119 234.636 194.104 234.868 194.099C237.192 194.047 237.79 198.748 235.197 199.024C233.033 199.255 230.494 196.851 229.71 196.046C228.831 196.242 227.878 196.374 226.837 196.412C222.942 196.554 219.615 198.236 217.109 200.075C217.814 200.175 218.632 200.553 219.273 200.882C221.128 200.572 222.476 200.513 224.537 201.211C226.739 201.957 225.698 206.58 223.166 205.952C221.075 205.434 219.52 202.363 219.042 201.31C218.259 200.91 217.155 200.402 216.527 200.559C216.51 200.563 216.494 200.557 216.477 200.557C213.703 202.737 212.09 204.969 212.06 205.012C211.982 205.122 211.832 205.147 211.722 205.069C211.705 205.057 211.69 205.044 211.678 205.029C211.608 204.946 211.599 204.823 211.665 204.731C211.714 204.662 215.73 199.089 222.106 196.843C222.115 196.838 222.122 196.83 222.131 196.826C222.807 196.564 223.753 194.571 224.342 193.022C223.672 191.246 223.343 189.926 223.629 187.754C223.933 185.444 228.664 185.572 228.54 188.182Z"
                                        fill="#5D74E4"
                                    />
                                    <path
                                        d="M229.187 160.207C229.522 162.179 227.521 164.634 226.767 165.478C226.642 166.35 226.365 167.923 225.905 168.946C226.904 168.413 227.987 167.968 229.162 167.665C229.813 167.498 230.412 167.293 230.972 167.067C230.131 165.727 229.629 164.602 229.469 162.706C229.286 160.525 233.654 159.69 234.064 162.11C234.283 163.402 233.501 164.898 232.723 166.015C233.284 165.579 233.848 165.218 234.516 164.924C236.461 163.222 237.396 161.251 237.826 159.989C237.188 158.676 236.848 157.624 236.898 155.887C236.954 153.933 240.915 153.633 241.036 155.823C241.146 157.828 238.583 160.073 238.147 160.439C237.728 161.564 236.959 163.093 235.586 164.532C235.778 164.475 235.971 164.419 236.184 164.367C238.308 163.851 239.801 168.047 237.475 168.823C235.536 169.471 232.722 167.774 231.84 167.193C231.073 167.551 230.223 167.863 229.276 168.107C229.028 168.171 228.785 168.243 228.546 168.319C229.262 168.208 230.031 168.192 230.972 168.297C233.144 168.539 233.116 172.993 230.664 172.927C228.694 172.874 226.709 170.502 226.004 169.572C226.004 169.572 224.545 169.759 221.079 173.43C221.747 173.379 222.573 173.562 223.228 173.735C224.869 173.076 226.094 172.751 228.127 172.977C230.3 173.219 230.271 177.672 227.82 177.607C225.795 177.552 223.752 175.045 223.101 174.174C222.301 173.964 221.186 173.721 220.641 173.991C220.626 173.999 220.61 173.995 220.595 174C218.485 176.56 217.453 178.933 217.433 178.979C217.384 179.094 217.251 179.149 217.134 179.099C217.117 179.092 217.1 179.082 217.085 179.071C217.005 179.01 216.972 178.899 217.014 178.801C217.045 178.727 219.613 172.802 225.017 169.455C225.025 169.448 225.029 169.44 225.037 169.435C225.605 169.059 226.073 167.037 226.303 165.497C225.331 164.001 224.765 162.855 224.591 160.803C224.408 158.621 228.777 157.786 229.187 160.207Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M150.348 80.3184C150.348 80.3184 120.159 92.1512 113.629 101.462C107.098 110.774 101.808 133.311 114.681 134.479"
                                        fill="#1732AC"
                                    />
                                    <path
                                        opacity="0.25"
                                        d="M150.348 80.3184C150.348 80.3184 120.159 92.1512 113.629 101.462C107.098 110.774 101.808 133.311 114.681 134.479C127.555 135.648 150.348 80.3184 150.348 80.3184Z"
                                        fill="#000141"
                                    />
                                    <path
                                        d="M218.544 211.463C214.536 218.013 208.94 223.296 202.523 227.14C202.312 227.268 202.097 227.397 201.882 227.512C164.853 249.156 98.2409 229.814 91.5815 218.793C93.4223 81.1322 199.679 6.08487 216.401 172.017C217.596 183.906 218.338 197.033 218.544 211.463Z"
                                        fill="#1732AC"
                                    />
                                    <path
                                        opacity="0.1"
                                        d="M148.866 88.7708C148.887 94.443 154.626 97.5625 161.683 95.7384C168.741 93.9143 174.445 87.8381 174.424 82.1658C174.402 76.4935 168.664 73.374 161.606 75.1982C154.548 77.0223 148.844 83.0985 148.866 88.7708Z"
                                        fill="#201135"
                                    />
                                    <path
                                        d="M151.528 86.2831C151.546 91.1922 156.513 93.8917 162.621 92.3129C168.73 90.7342 173.667 85.4752 173.648 80.5662C173.63 75.6571 168.663 72.9576 162.554 74.5363C156.446 76.1142 151.509 81.3741 151.528 86.2831Z"
                                        fill="#000141"
                                    />
                                    <path
                                        d="M156.141 85.2389C159.341 87.4729 168.612 83.0091 170.742 79.737C171.36 78.7874 170.636 77.8134 170.48 75.21C170.169 69.7739 169.687 61.3658 169.687 61.144L156.255 67.9312L156.198 76.8882C156.199 76.8882 154.431 84.0447 156.141 85.2389Z"
                                        fill="#FECECC"
                                    />
                                    <path
                                        opacity="0.1"
                                        d="M156.199 76.8882L156.256 77.0518C156.256 77.0518 157.777 75.7666 160.809 74.7183C163.85 73.6625 170.391 75.7666 170.391 75.7666L170.481 75.21C170.17 69.7739 169.688 61.3658 169.688 61.144L156.256 67.9312L156.199 76.8882Z"
                                        fill="#201135"
                                    />
                                    <path
                                        d="M147.85 28.7502C147.85 28.7502 141.018 39.1013 145.923 47.1711C150.829 55.241 179.384 49.8023 179.384 49.8023C179.384 49.8023 192.347 32.2003 180.785 27.1124C180.785 27.1124 176.114 17.4048 168.697 18.3991C155.903 20.1145 147.85 28.7502 147.85 28.7502Z"
                                        fill="#000141"
                                    />
                                    <path
                                        d="M149.835 74.9473C153.45 77.4764 173.917 62.6439 177.399 58.1067C177.79 57.5957 178.101 56.8999 178.352 56.0633C182.336 56.0557 186.934 50.0006 184.842 45.9223C183.379 43.065 180.739 43.8198 179.195 44.605C179.003 38.8973 178.33 33.6644 178.33 33.6644C177.325 26.5728 147.943 26.5728 146.937 33.6644C146.937 33.6636 139.008 67.3733 149.835 74.9473Z"
                                        fill="#FECECC"
                                    />
                                    <path
                                        opacity="0.1"
                                        d="M181.665 45.3672C182.862 45.3613 184.862 46.7444 183.661 49.7281C182.461 52.7118 180.145 53.9591 180.145 53.9591C180.145 53.9591 183.4 51.1938 182.962 48.7869C182.525 46.3792 180.651 45.3723 181.665 45.3672Z"
                                        fill="#201135"
                                    />
                                    <path
                                        d="M179.24 44.2631C179.24 44.2631 179.048 48.57 176.464 48.5894C175.538 48.5961 175.139 34.3413 173.962 34.3387C168.017 34.3269 156.437 33.9963 147.341 33.282C134.791 32.2962 149.881 14.921 163.329 15.7044C169.476 16.062 175.868 19.2228 178.497 26.5876C180.403 31.9268 179.108 38.3488 179.24 44.2631Z"
                                        fill="#000141"
                                    />
                                    <path
                                        opacity="0.05"
                                        d="M167.357 51.687C167.514 52.6181 166.501 53.5651 165.096 53.8021C163.69 54.0391 162.423 53.4774 162.266 52.5472C162.109 51.617 163.122 50.67 164.528 50.4322C165.934 50.1952 167.2 50.7568 167.357 51.687ZM151.432 51.7756C151.589 52.7058 150.577 53.6528 149.171 53.8898C147.765 54.1276 146.498 53.5651 146.342 52.6349C146.185 51.7048 147.198 50.7577 148.604 50.5207C150.009 50.2829 151.275 50.8446 151.432 51.7756Z"
                                        fill="#000141"
                                    />
                                    <path
                                        d="M152.107 43.0267C151.971 43.0267 151.838 42.9558 151.766 42.8293C151.702 42.7197 150.181 40.204 146.454 41.8587C146.253 41.9464 146.022 41.857 145.935 41.6579C145.848 41.4598 145.936 41.227 146.135 41.1384C150.53 39.187 152.429 42.4043 152.447 42.4363C152.555 42.6252 152.489 42.8656 152.301 42.9735C152.24 43.0098 152.173 43.0267 152.107 43.0267ZM159.731 43.0967C159.682 43.0967 159.633 43.0874 159.586 43.068C159.384 42.9879 159.285 42.7593 159.366 42.5578C159.38 42.5224 160.809 39.0891 165.976 40.1964C166.189 40.2411 166.323 40.4511 166.278 40.6636C166.232 40.8753 166.026 41.0128 165.811 40.9664C161.284 39.994 160.107 42.8201 160.096 42.8487C160.035 43.0022 159.886 43.0967 159.731 43.0967ZM155.255 52.9468C155.119 52.9468 154.985 52.9274 154.854 52.8895C154.021 52.6466 153.668 51.7063 153.654 51.6675C153.611 51.5503 153.626 51.4212 153.693 51.3167C155.103 49.1493 154.728 46.2221 154.725 46.1934C154.696 45.9775 154.847 45.7793 155.062 45.7506C155.283 45.7194 155.475 45.8729 155.504 46.088C155.522 46.2162 155.9 49.1796 154.469 51.5604C154.582 51.7662 154.793 52.0529 155.077 52.1347C155.341 52.2089 155.666 52.106 156.037 51.821C156.21 51.6894 156.456 51.7223 156.588 51.8943C156.72 52.0672 156.687 52.3135 156.515 52.4459C156.078 52.7798 155.656 52.9468 155.255 52.9468Z"
                                        fill="#000141"
                                    />
                                    <path
                                        d="M148.281 60.4082C148.281 60.4082 152.2 63.3894 157.615 62.2273C158.262 64.5178 151.251 68.4966 148.281 60.4082Z"
                                        fill="#FCFAF7"
                                    />
                                    <path
                                        d="M150.915 46.1996C150.915 47.4258 150.235 48.4201 149.398 48.4201C148.56 48.4201 147.88 47.4258 147.88 46.1996C147.88 44.9743 148.56 43.98 149.398 43.98C150.235 43.98 150.915 44.9743 150.915 46.1996ZM164.563 46.1996C164.563 47.4258 163.885 48.4201 163.047 48.4201C162.209 48.4201 161.53 47.4258 161.53 46.1996C161.53 44.9743 162.209 43.98 163.047 43.98C163.885 43.98 164.563 44.9743 164.563 46.1996Z"
                                        fill="#000141"
                                    />
                                    <path
                                        opacity="0.05"
                                        d="M152.712 67.1379C152.307 67.0451 152.057 66.9253 152.031 66.9119C151.933 66.8638 151.894 66.7466 151.942 66.6487C151.99 66.5518 152.108 66.5113 152.205 66.5585C152.22 66.5669 153.816 67.3242 155.642 66.3485C155.74 66.3005 155.858 66.3359 155.908 66.4303C155.959 66.5265 155.923 66.6454 155.827 66.6968C154.557 67.3748 153.405 67.2964 152.712 67.1379Z"
                                        fill="#000141"
                                    />
                                    <path
                                        opacity="0.25"
                                        d="M218.544 211.463C214.536 218.013 208.94 223.296 202.523 227.14C202.312 227.268 202.097 227.397 201.882 227.512C200.568 225.219 199.501 223.168 199.276 222.393C198.072 218.205 201.231 214.155 202.541 210.376C206.856 197.923 169.373 208.298 166.35 217.645C155.565 219.879 110.195 226.746 105.065 215.691C113.543 212.449 122.904 214.352 131.721 214.655C141.87 214.866 153.412 214.76 161.698 208.105C168.023 202.174 170.973 188.689 168.778 180.433C166.186 174.379 158.07 175.557 152.982 173.053C164.524 167.022 163.2 162.316 177.655 158.101C185.276 154.991 178.589 145.969 177.985 140.401C177.229 130.851 175.228 120.027 179.771 111.165C183.921 131.709 198.508 147.923 209.303 165.105C210.782 168.178 213.37 170.531 216.402 172.017C217.596 183.906 218.338 197.033 218.544 211.463Z"
                                        fill="#000141"
                                    />
                                    <path
                                        d="M58.7082 77.8116C58.7082 78.2307 59.0476 78.5714 59.4669 78.5714C59.8863 78.5714 60.2257 78.2316 60.2257 77.8116C60.2257 77.3925 59.8863 77.0518 59.4669 77.0518C59.0476 77.0518 58.7082 77.3916 58.7082 77.8116ZM52.8926 77.8116C52.8926 78.2307 53.232 78.5714 53.6514 78.5714C54.0699 78.5714 54.4101 78.2316 54.4101 77.8116C54.4101 77.3925 54.0707 77.0518 53.6514 77.0518C53.232 77.0518 52.8926 77.3916 52.8926 77.8116ZM47.077 77.8116C47.077 78.2307 47.4164 78.5714 47.8358 78.5714C48.2543 78.5714 48.5945 78.2316 48.5945 77.8116C48.5945 77.3925 48.2552 77.0518 47.8358 77.0518C47.4164 77.0518 47.077 77.3916 47.077 77.8116ZM41.2615 77.8116C41.2615 78.2307 41.6008 78.5714 42.0202 78.5714C42.4387 78.5714 42.7789 78.2316 42.7789 77.8116C42.7789 77.3925 42.4396 77.0518 42.0202 77.0518C41.6008 77.0518 41.2615 77.3916 41.2615 77.8116ZM61.7474 81.0896C61.7474 81.5088 62.0867 81.8495 62.5061 81.8495C62.9246 81.8495 63.2648 81.5096 63.2648 81.0896C63.2648 80.6705 62.9255 80.3298 62.5061 80.3298C62.0876 80.3298 61.7474 80.6705 61.7474 81.0896ZM55.9326 81.0896C55.9326 81.5088 56.272 81.8495 56.6914 81.8495C57.1099 81.8495 57.4501 81.5096 57.4501 81.0896C57.4501 80.6705 57.1107 80.3298 56.6914 80.3298C56.272 80.3298 55.9326 80.6705 55.9326 81.0896ZM50.1171 81.0896C50.1171 81.5088 50.4564 81.8495 50.8758 81.8495C51.2943 81.8495 51.6345 81.5096 51.6345 81.0896C51.6345 80.6705 51.2952 80.3298 50.8758 80.3298C50.4564 80.3298 50.1171 80.6705 50.1171 81.0896ZM44.3015 81.0896C44.3015 81.5088 44.6408 81.8495 45.0602 81.8495C45.4787 81.8495 45.8189 81.5096 45.8189 81.0896C45.8189 80.6705 45.4796 80.3298 45.0602 80.3298C44.6408 80.3298 44.3015 80.6705 44.3015 81.0896Z"
                                        fill="#E7F0FE"
                                    />
                                    <path
                                        d="M234.707 221.965C235.073 221.965 235.369 221.668 235.369 221.301C235.369 220.935 235.073 220.638 234.707 220.638C234.341 220.638 234.044 220.935 234.044 221.301C234.044 221.668 234.341 221.965 234.707 221.965Z"
                                        fill="#E7F0FE"
                                    />
                                    <path
                                        d="M229.622 221.965C229.988 221.965 230.285 221.668 230.285 221.301C230.285 220.935 229.988 220.638 229.622 220.638C229.256 220.638 228.959 220.935 228.959 221.301C228.959 221.668 229.256 221.965 229.622 221.965Z"
                                        fill="#E7F0FE"
                                    />
                                    <path
                                        d="M224.538 221.965C224.904 221.965 225.201 221.668 225.201 221.301C225.201 220.935 224.904 220.638 224.538 220.638C224.172 220.638 223.875 220.935 223.875 221.301C223.875 221.668 224.172 221.965 224.538 221.965Z"
                                        fill="#E7F0FE"
                                    />
                                    <path
                                        d="M218.791 221.301C218.791 221.668 219.088 221.965 219.454 221.965C219.821 221.965 220.117 221.667 220.117 221.301C220.117 220.935 219.82 220.638 219.454 220.638C219.087 220.638 218.791 220.935 218.791 221.301ZM236.701 224.168C236.701 224.535 236.999 224.832 237.364 224.832C237.731 224.832 238.027 224.534 238.027 224.168C238.027 223.801 237.73 223.504 237.364 223.504C236.998 223.503 236.701 223.801 236.701 224.168ZM231.617 224.168C231.617 224.535 231.914 224.832 232.28 224.832C232.646 224.832 232.942 224.534 232.942 224.168C232.942 223.801 232.645 223.504 232.28 223.504C231.914 223.503 231.617 223.801 231.617 224.168ZM226.532 224.168C226.532 224.535 226.829 224.832 227.195 224.832C227.561 224.832 227.858 224.534 227.858 224.168C227.858 223.801 227.56 223.504 227.195 223.504C226.829 223.503 226.532 223.801 226.532 224.168ZM221.448 224.168C221.448 224.535 221.746 224.832 222.111 224.832C222.477 224.832 222.774 224.534 222.774 224.168C222.774 223.801 222.477 223.504 222.111 223.504C221.745 223.503 221.448 223.801 221.448 224.168Z"
                                        fill="#E7F0FE"
                                    />
                                    <path
                                        d="M242.942 106.122C243.27 106.122 243.535 105.856 243.535 105.528C243.535 105.2 243.27 104.934 242.942 104.934C242.615 104.934 242.349 105.2 242.349 105.528C242.35 105.856 242.616 106.122 242.942 106.122ZM242.942 110.672C243.27 110.672 243.535 110.407 243.535 110.079C243.535 109.751 243.27 109.485 242.942 109.485C242.615 109.485 242.349 109.751 242.349 110.079C242.35 110.407 242.616 110.672 242.942 110.672ZM242.942 115.223C243.27 115.223 243.535 114.957 243.535 114.629C243.535 114.301 243.27 114.035 242.942 114.035C242.615 114.035 242.349 114.301 242.349 114.629C242.35 114.957 242.616 115.223 242.942 115.223ZM242.942 119.774C243.27 119.774 243.535 119.508 243.535 119.18C243.535 118.852 243.27 118.586 242.942 118.586C242.615 118.586 242.349 118.852 242.349 119.18C242.35 119.508 242.616 119.774 242.942 119.774ZM245.5 103.743C245.827 103.743 246.093 103.477 246.093 103.149C246.093 102.821 245.827 102.555 245.5 102.555C245.172 102.555 244.907 102.821 244.907 103.149C244.908 103.477 245.173 103.743 245.5 103.743ZM245.5 108.293C245.827 108.293 246.093 108.028 246.093 107.7C246.093 107.371 245.827 107.106 245.5 107.106C245.172 107.106 244.907 107.371 244.907 107.7C244.908 108.028 245.173 108.293 245.5 108.293ZM245.5 112.844C245.827 112.844 246.093 112.578 246.093 112.25C246.093 111.922 245.827 111.656 245.5 111.656C245.172 111.656 244.907 111.922 244.907 112.25C244.908 112.578 245.173 112.844 245.5 112.844ZM245.5 117.394C245.827 117.394 246.093 117.129 246.093 116.801C246.093 116.473 245.827 116.207 245.5 116.207C245.172 116.207 244.907 116.473 244.907 116.801C244.908 117.129 245.173 117.394 245.5 117.394Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M43.4602 126.751C43.4602 126.751 44.3048 126.221 46.3436 125.069C48.3823 123.917 50.2391 119.888 51.2985 120.491C52.3579 121.094 51.8728 127.028 49.4156 129.022C46.9591 131.016 43.6716 128.558 43.4602 126.751Z"
                                        fill="#EAB2B2"
                                    />
                                    <path
                                        d="M169.16 196.814C169.16 196.814 169.005 195.828 168.535 193.531C168.064 191.235 169.88 187.188 168.731 186.783C167.581 186.378 163.43 190.641 163.54 193.806C163.65 196.971 167.66 197.841 169.16 196.814Z"
                                        fill="#EAB2B2"
                                    />
                                    <path
                                        d="M176.309 155.742C176.095 225.868 83.4568 248.361 50.496 188.471C39.1554 168.704 39.5697 141.133 51.6994 121.699C85.1099 64.0612 176.644 88.5802 176.309 155.742Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        opacity="0.1"
                                        d="M165.015 118.354C164.14 121.697 164.911 125.048 166.066 128.229C166.648 129.836 167.295 131.421 167.816 133.05C168.303 134.577 168.701 136.142 169.073 137.701C169.819 140.829 170.466 144.199 169.695 147.383C169.037 150.104 167.479 152.424 166.41 154.978C165.825 156.378 165.433 157.842 165.326 159.359C165.209 161.016 165.475 162.707 165.664 164.352C166.032 167.558 166.462 170.851 165.843 174.054C165.246 177.144 163.588 180.051 161.072 181.971C158.635 183.83 155.651 184.735 152.781 185.681C152.024 185.93 151.236 186.148 150.515 186.489C149.905 186.778 149.293 187.065 148.685 187.361C147.658 187.859 146.623 188.371 145.683 189.022C144.048 190.154 143.108 191.679 142.931 193.674C142.705 196.231 143.392 198.734 143.831 201.228C144.114 202.84 144.228 204.498 143.149 205.852C142.513 206.648 141.661 207.246 140.801 207.778C139.599 208.52 138.329 209.168 137.048 209.76C134.616 210.883 132.04 211.802 129.818 213.328C127.554 214.883 125.949 217.096 125.507 219.841C125.449 220.204 125.414 220.569 125.388 220.934C152.822 214.124 176.203 190.93 176.31 155.742C176.382 141.122 172.101 128.525 165.015 118.354Z"
                                        fill="#000141"
                                    />
                                    <path
                                        d="M149.482 196.413C171.694 174.167 171.694 138.101 149.481 115.856C127.268 93.6114 91.2536 93.6123 69.041 115.858C46.8283 138.104 46.8287 174.171 69.0418 196.415C91.2549 218.66 127.269 218.659 149.482 196.413Z"
                                        fill="#5D74E4"
                                    />
                                    <path
                                        d="M57.5537 156.524C57.4543 139.401 65.6615 124.175 78.3891 114.674C82.1802 111.841 86.3731 109.522 90.8606 107.814C92.8733 107.038 94.9406 106.398 97.0644 105.885C100.884 104.953 104.861 104.456 108.957 104.432C114.309 104.401 119.475 105.181 124.334 106.664C145.432 113.091 160.838 132.673 160.973 155.919C161.021 164.008 159.212 171.662 155.954 178.496C151.96 186.882 145.778 194.024 138.14 199.182C136.347 200.394 134.483 201.496 132.534 202.473C128.349 204.59 123.824 206.163 119.069 207.074C115.992 207.665 112.813 207.984 109.56 208.003C81.0063 208.17 57.7204 185.121 57.5537 156.524Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M160.973 155.917C161.02 164.01 159.213 171.661 155.952 178.494C151.958 186.88 145.776 194.022 138.141 199.184C136.349 200.393 134.481 201.498 132.535 202.47C128.35 204.588 123.822 206.162 119.068 207.072C118.878 207.108 118.688 207.144 118.493 207.175C120.109 206.321 121.671 205.375 123.18 204.357C130.819 199.195 137.001 192.053 140.995 183.667C144.25 176.833 146.063 169.183 146.012 161.095C145.878 137.844 130.471 118.265 109.374 111.838C104.512 110.357 99.3474 109.575 93.9975 109.606C90.9634 109.626 87.9958 109.9 85.1099 110.418C86.9583 109.436 88.8834 108.562 90.8606 107.812C92.8733 107.041 94.9423 106.398 97.0627 105.884C100.883 104.954 104.861 104.454 108.958 104.434C114.308 104.403 119.474 105.18 124.335 106.665C145.432 113.092 160.84 132.671 160.973 155.917Z"
                                        fill="#E7F0FE"
                                    />
                                    <path
                                        d="M62.6627 161.587C62.6526 161.497 62.64 161.41 62.6299 161.32C62.6198 161.232 62.6131 161.142 62.6038 161.054L61.3406 161.194L61.3996 161.726L62.6627 161.587ZM136.851 194.152L137.598 195.17L138.029 194.853L137.281 193.833C137.139 193.939 136.995 194.047 136.851 194.152Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M64.32 156.667L64.3166 156.131L61.0754 156.15L61.0787 156.686L64.32 156.668V156.667Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M140.668 191.056L141.517 191.99L141.912 191.629L141.061 190.693C140.931 190.815 140.8 190.936 140.668 191.056ZM63.3625 146.372L62.1255 146.116L62.0177 146.64L63.2531 146.896C63.2876 146.721 63.3255 146.547 63.3625 146.372ZM63.4863 166.435C63.467 166.348 63.4451 166.261 63.4257 166.174C63.4063 166.087 63.3903 165.999 63.3718 165.911L62.1305 166.183L62.2442 166.707L63.4863 166.435ZM70.2863 184.513L70.603 184.944L71.6202 184.195C71.5133 184.051 71.4063 183.909 71.3019 183.765L70.2863 184.513ZM80.9499 195.186L81.3853 195.498L82.1221 194.47C81.9764 194.367 81.8307 194.264 81.6859 194.16L80.9499 195.186Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M85.1545 114.353L84.693 114.624L86.3307 117.426L86.7922 117.155L85.1545 114.353Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M77.0291 192.008L77.4291 192.365L78.2686 191.419C78.1348 191.302 78.0009 191.184 77.8678 191.065L77.0291 192.008ZM73.4619 188.437L73.8223 188.834L74.7562 187.981C74.635 187.85 74.5137 187.72 74.3941 187.586L73.4619 188.437ZM144.141 187.577L145.083 188.417L145.439 188.017L144.495 187.175C144.377 187.309 144.26 187.444 144.141 187.577ZM62.5945 151.23L61.3373 151.106L61.2842 151.638L62.5398 151.763C62.5566 151.585 62.576 151.408 62.5945 151.23ZM64.4691 142.131C64.5221 141.96 64.5777 141.79 64.6333 141.621C64.6333 141.621 64.6333 141.621 64.6333 141.621L63.4299 141.237L63.2674 141.747L64.4691 142.131ZM147.231 183.753L148.256 184.49L148.568 184.055L147.542 183.317C147.438 183.463 147.336 183.609 147.231 183.753Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M70.0758 134.092L70.3411 133.626L67.5242 132.02L67.3566 132.315L67.259 132.485L70.0758 134.092Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M153.801 141.355C153.829 141.44 153.854 141.525 153.882 141.611L155.088 141.21L154.92 140.701L153.714 141.101C153.742 141.186 153.773 141.27 153.801 141.355ZM66.3924 137.029L65.2354 136.521L65.0206 137.012L66.176 137.519C66.2467 137.354 66.32 137.191 66.3924 137.029ZM155.04 145.837C155.059 145.924 155.081 146.011 155.1 146.099C155.119 146.187 155.135 146.273 155.154 146.361L156.395 146.09L156.28 145.566L155.04 145.837Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M86.1987 196.147L85.1815 197.936L85.6463 198.201L87.2505 195.38L86.7857 195.115L86.1987 196.147Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M94.7689 200.831L94.3857 202.036L94.8952 202.199L95.2783 200.996C95.1082 200.943 94.9381 200.886 94.7689 200.831ZM89.3407 112.196L89.163 112.276L89.6868 113.436C89.7684 113.399 89.8476 113.359 89.9293 113.323C90.011 113.286 90.0926 113.252 90.1743 113.216L89.6505 112.056L89.3407 112.196ZM155.864 150.686C155.874 150.775 155.887 150.863 155.896 150.952C155.907 151.041 155.913 151.129 155.923 151.219L157.186 151.078L157.127 150.545L155.864 150.686ZM81.675 118.121L80.928 117.104L80.4969 117.421L81.2455 118.44C81.3878 118.333 81.5301 118.225 81.675 118.121ZM90.1836 199.07L89.6758 200.228L90.1659 200.444V200.443L90.4472 199.802L90.6729 199.286C90.5087 199.216 90.3461 199.141 90.1836 199.07Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M70.6063 179.1L70.336 178.637L67.5385 180.277L67.808 180.74H67.8088L70.6063 179.1Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M71.2943 128.519L70.2695 127.782L69.9571 128.218L70.9836 128.955C71.088 128.81 71.1899 128.663 71.2943 128.519ZM74.3857 124.695L73.4434 123.855L73.088 124.255L74.032 125.096C74.1491 124.962 74.2661 124.828 74.3857 124.695ZM93.8509 110.412L94.2501 111.62C94.3352 111.591 94.4185 111.56 94.5036 111.532C94.5886 111.504 94.6737 111.479 94.7587 111.451L94.3587 110.244L93.8509 110.412ZM64.7251 170.917C64.6973 170.832 64.672 170.747 64.6442 170.662L63.4383 171.062L63.6067 171.571L64.8126 171.171C64.7832 171.086 64.7528 171.002 64.7251 170.917ZM66.5112 175.498C66.4741 175.416 66.4404 175.335 66.4042 175.252L65.2463 175.777L65.467 176.265L66.6248 175.74C66.587 175.66 66.5491 175.58 66.5112 175.498ZM77.8585 121.216L77.0097 120.283L76.6139 120.644L77.4653 121.579C77.595 121.457 77.7255 121.336 77.8585 121.216ZM154.057 170.141C154.004 170.313 153.948 170.481 153.893 170.652L155.096 171.035L155.259 170.525V170.524L154.057 170.141Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M148.45 178.18L148.184 178.646L151.002 180.253L151.125 180.037L151.266 179.788L148.45 178.18Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M148.24 127.76L147.923 127.328L146.905 128.078C147.012 128.221 147.119 128.363 147.224 128.509L148.24 127.76ZM128.36 111.829L128.304 111.956L127.853 112.985C128.017 113.056 128.179 113.13 128.343 113.202L128.85 112.043L128.36 111.829ZM99.2581 203.343L99.7819 203.451L100.037 202.213C99.8619 202.178 99.6876 202.14 99.5133 202.103L99.2581 203.343Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M132.188 194.854L131.725 195.125L133.363 197.927L133.825 197.656L132.188 194.854Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M136.404 117.802C136.55 117.904 136.696 118.007 136.84 118.113L137.576 117.086L137.141 116.774L136.404 117.802ZM140.257 120.853C140.391 120.971 140.525 121.088 140.658 121.208L141.496 120.264L141.096 119.907L140.257 120.853ZM155.931 161.042L157.188 161.167L157.241 160.633L155.986 160.508C155.969 160.686 155.95 160.864 155.931 161.042Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M154.206 155.605V155.606L154.209 156.141L157.451 156.122L157.448 155.586L154.206 155.605Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M155.164 165.901L156.401 166.156L156.509 165.632L155.274 165.376C155.239 165.551 155.201 165.725 155.164 165.901ZM152.133 175.244L153.29 175.752L153.505 175.261L152.349 174.754C152.28 174.917 152.206 175.081 152.133 175.244Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M150.717 131.533L147.919 133.172L148.189 133.635L150.988 131.995L150.717 131.533Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M151.901 136.531C151.939 136.613 151.978 136.692 152.014 136.774C152.051 136.856 152.085 136.938 152.121 137.02L153.279 136.495L153.059 136.007L151.901 136.531ZM143.77 124.29C143.891 124.422 144.013 124.553 144.132 124.685L145.064 123.835L144.704 123.439L143.77 124.29ZM118.744 108.821L118.489 110.058C118.664 110.094 118.839 110.132 119.013 110.169L119.268 108.93L118.744 108.821ZM114.44 202.838C114.352 202.848 114.263 202.854 114.174 202.864L114.314 204.128L114.846 204.069L114.706 202.805C114.617 202.815 114.529 202.828 114.44 202.838ZM128.84 198.837C128.759 198.874 128.679 198.914 128.598 198.95C128.516 198.987 128.434 199.021 128.352 199.057L128.876 200.217L129.063 200.131L129.363 199.996L128.84 198.837ZM104.365 202.872L104.24 204.131L104.772 204.184L104.897 202.928C104.719 202.911 104.542 202.891 104.365 202.872ZM119.287 202.04C119.199 202.059 119.112 202.075 119.024 202.094L119.295 203.337L119.818 203.222L119.547 201.979C119.459 201.999 119.374 202.02 119.287 202.04ZM123.248 111.277C123.418 111.33 123.587 111.387 123.758 111.441L124.141 110.236L123.631 110.073L123.248 111.277ZM99.2303 108.936L98.7074 109.05L98.9786 110.294C99.0653 110.273 99.1512 110.252 99.2388 110.233C99.3263 110.213 99.4131 110.197 99.5007 110.179L99.2303 108.936ZM124.275 200.652C124.19 200.68 124.108 200.712 124.023 200.739C123.938 200.767 123.853 200.792 123.768 200.82L124.167 202.028L124.675 201.859L124.275 200.652ZM103.68 108.203L103.82 109.467C103.908 109.457 103.997 109.444 104.085 109.434C104.174 109.424 104.263 109.417 104.351 109.408L104.211 108.143L103.68 108.203ZM113.629 109.345C113.806 109.362 113.984 109.381 114.161 109.4C114.161 109.4 114.161 109.4 114.162 109.4L114.286 108.141L113.754 108.087L113.629 109.345Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M131.276 116.893L131.741 117.158L132.099 116.526L133.345 114.337L132.88 114.072L131.276 116.893Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M109.796 201.147L109.261 201.15L109.279 204.397L109.814 204.394L109.796 201.147Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M109.251 107.881L108.715 107.884L108.734 111.131L109.27 111.127L109.251 107.881Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M112.699 156.117C112.709 157.942 111.24 159.429 109.418 159.44C107.596 159.451 106.11 157.981 106.099 156.156C106.088 154.331 107.557 152.842 109.38 152.832C111.203 152.821 112.688 154.292 112.699 156.117Z"
                                        fill="#5D74E4"
                                    />
                                    <path
                                        d="M131.641 127.958L109.417 154.391L111.206 156.158L137.36 133.605L138.707 126.509L131.641 127.958Z"
                                        fill="#5D74E4"
                                    />
                                    <path
                                        d="M110.005 158.638C110.888 158.459 111.637 157.798 111.871 156.863C112.197 155.566 111.411 154.25 110.116 153.923C108.821 153.597 107.507 154.384 107.181 155.681C106.941 156.632 107.304 157.591 108.024 158.165L100.135 180.93L101.888 185.924L105.834 182.402L110.005 158.638Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M215.506 143.029C215.506 143.029 234.575 186.758 215.535 190.146C196.495 193.534 187.236 154.235 187.236 154.235L215.506 143.029Z"
                                        fill="#FECECC"
                                    />
                                    <path
                                        d="M166.349 208.299C168.495 209.48 204.026 202.769 219.655 188.418C235.284 174.066 206.126 164.86 195.788 171.036C185.448 177.211 166.729 195.803 166.729 195.803C166.729 195.803 159.656 204.619 166.349 208.299Z"
                                        fill="#FECECC"
                                    />
                                    <path
                                        opacity="0.5"
                                        d="M195.211 201.163C194.591 201.109 193.966 201.225 193.364 201.362C192.557 201.546 191.768 201.766 190.943 201.863C188.968 202.096 186.974 201.903 184.995 202.057C184.013 202.133 183.069 202.321 182.13 202.611C181.345 202.853 180.573 203.134 179.791 203.384C178.236 203.882 176.548 204.335 174.908 204.041C174.126 203.901 173.341 203.584 172.75 203.042C172.266 202.598 171.987 202.01 171.915 201.36C171.768 200.04 172.287 198.718 171.908 197.414C171.521 196.081 170.447 195.144 169.283 194.475C169.029 194.329 168.769 194.195 168.506 194.067C167.381 195.158 166.731 195.804 166.731 195.804C166.731 195.804 159.656 204.62 166.35 208.3C167.683 209.032 181.867 206.727 196.176 201.442C195.88 201.288 195.554 201.194 195.211 201.163ZM219.269 155.314C218.516 158.269 216.505 160.66 213.827 162.082C211.226 163.464 208.286 163.684 205.397 163.764C204.338 163.793 203.275 163.791 202.217 163.869C201.657 163.911 201.093 163.972 200.546 164.106C200.122 164.21 199.655 164.355 199.337 164.67C198.72 165.284 198.906 166.171 199.589 166.621C200.305 167.093 201.222 167.249 202.053 167.383C203.337 167.589 204.632 167.775 205.897 168.078C207.429 168.444 209.086 169.029 210.196 170.204C211.06 171.117 211.444 172.337 211.395 173.577C211.374 174.07 211.343 174.599 211.162 175.064C211.128 175.151 211.088 175.249 211.021 175.318C210.968 175.373 210.973 175.366 210.935 175.365C210.744 175.355 210.508 175.16 210.357 175.061C209.947 174.791 209.574 174.472 209.174 174.19C208.552 173.751 207.773 173.46 207.075 173.168C205.386 172.461 203.648 171.808 201.874 171.351C200.144 170.907 198.371 170.625 196.589 170.516C195.332 170.44 194.073 170.462 192.815 170.427C189.081 162.061 187.236 154.235 187.236 154.235L215.507 143.029C215.507 143.029 217.493 147.587 219.544 153.872C219.477 154.357 219.39 154.84 219.269 155.314Z"
                                        fill="#EAB2B2"
                                    />
                                    <path
                                        d="M187.463 89.4576C187.463 89.4576 230.349 140.15 221.456 152.251C212.564 164.352 193.029 164.085 186.114 158.71C179.198 153.333 172.41 81.4442 187.463 89.4576Z"
                                        fill="#1732AC"
                                    />
                                    <path
                                        opacity="0.1"
                                        d="M181.01 116.195C182.223 119.574 183.659 122.884 185.402 126.025C186.212 127.485 187.091 128.909 188.055 130.272C188.933 131.513 189.915 132.653 190.912 133.798C191.715 134.722 192.476 135.681 192.931 136.83C193.501 138.268 193.64 139.892 193.491 141.42C193.318 143.187 192.857 144.92 192.819 146.702C192.801 147.577 192.884 148.457 193.141 149.296C193.345 149.963 193.705 150.587 194.077 151.173C195.686 153.701 198.126 155.675 200.694 157.165C201.986 157.914 203.344 158.555 204.753 159.049C205.873 159.442 207.05 159.777 208.237 159.883C209.272 159.975 210.316 159.968 211.36 159.929C202.1 163.74 190.982 162.495 186.115 158.71C182.013 155.521 178.181 128.834 178.884 109.32C179.494 111.641 180.2 113.937 181.01 116.195Z"
                                        fill="#000141"
                                    />
                                    <path
                                        d="M42.5263 125.209C42.5263 125.209 61.4223 129.039 61.1099 132.642C60.9634 134.327 54.475 133.595 49.4442 132.776C55.5587 134.002 60.7495 135.759 59.7457 137.442C58.9735 138.737 53.4518 138.045 48.4143 137.429C51.8291 138.166 58.8051 139.889 58.4185 141.366C57.7617 143.87 50.7469 142.47 46.0312 141.422C48.7958 142.238 55.9091 144.302 55.5697 145.656C55.2691 146.856 53.6211 147.572 47.696 146.232C44.2383 145.449 44.1987 146.137 42.6964 145.472C41.5368 144.959 40.2787 144.556 39.5478 143.682C36.0244 139.476 39.6076 125.947 42.5263 125.209Z"
                                        fill="#FECECC"
                                    />
                                    <path
                                        opacity="0.5"
                                        d="M55.0442 145.749C55.0939 145.094 53.2278 144.194 53.0029 144.258C53.0029 144.258 52.5625 145.885 52.6139 146.196C52.6644 146.508 54.9945 146.403 55.0442 145.749ZM57.9402 141.535C57.9907 140.881 56.1229 139.981 55.8981 140.044C55.8981 140.044 55.4585 141.671 55.5099 141.982C55.5613 142.293 57.8914 142.19 57.9402 141.535ZM59.2935 136.883C59.3432 136.228 57.4762 135.327 57.2522 135.392C57.2522 135.392 56.8118 137.018 56.864 137.33C56.9145 137.641 59.2438 137.536 59.2935 136.883ZM60.5154 132.281C60.5659 131.626 58.6989 130.726 58.4741 130.79C58.4741 130.79 58.0337 132.417 58.0851 132.728C58.1356 133.04 60.4665 132.935 60.5154 132.281Z"
                                        fill="#E7F0FE"
                                    />
                                    <path
                                        opacity="0.25"
                                        d="M46.7663 141.634L41.0518 139.133C41.0518 139.133 39.3002 140.763 40.4977 143.141C40.4977 143.141 37.7684 140.965 39.5495 134.068C41.3322 127.17 42.3444 126.481 42.3444 126.481C42.3444 126.481 41.8029 128.63 42.9591 130.209C43.2168 130.563 49.3575 132.733 48.9642 132.641C48.5718 132.549 42.8573 131.478 42.8573 131.478L42.1246 134.402L47.6463 137.135L41.7617 135.537L41.2387 138.056L46.7663 141.634Z"
                                        fill="#EAB2B2"
                                    />
                                    <path
                                        d="M168.205 194.441C168.205 194.441 149.216 191.101 148.195 194.57C147.717 196.192 154.024 197.882 159.007 198.959C152.867 197.865 147.395 197.605 147.716 199.538C147.964 201.026 153.357 202.399 158.271 203.668C154.824 203.107 147.7 202.16 147.523 203.677C147.221 206.249 154.263 207.509 159.034 208.257C156.163 208.005 148.788 207.327 148.611 208.712C148.454 209.938 149.726 211.208 155.732 212.126C159.237 212.662 159.022 213.317 160.664 213.246C161.93 213.192 163.248 213.277 164.248 212.731C169.058 210.101 170.653 196.195 168.205 194.441Z"
                                        fill="#FECECC"
                                    />
                                    <path
                                        opacity="0.5"
                                        d="M149.064 208.989C149.256 208.361 151.322 208.206 151.508 208.347C151.508 208.347 151.325 210.022 151.164 210.293C151.004 210.565 148.872 209.616 149.064 208.989ZM147.904 204.007C148.096 203.38 150.162 203.225 150.349 203.365C150.349 203.365 150.165 205.041 150.003 205.312C149.843 205.583 147.711 204.634 147.904 204.007ZM148.339 199.18C148.531 198.553 150.598 198.397 150.783 198.538C150.783 198.538 150.6 200.214 150.438 200.485C150.278 200.756 148.147 199.807 148.339 199.18ZM148.879 194.45C149.071 193.822 151.137 193.667 151.323 193.809C151.323 193.809 151.14 195.485 150.979 195.755C150.819 196.026 148.686 195.076 148.879 194.45Z"
                                        fill="#E7F0FE"
                                    />
                                    <path
                                        opacity="0.25"
                                        d="M158.271 208.183L164.503 207.944C164.503 207.944 165.539 210.101 163.558 211.877C163.558 211.877 166.893 210.849 167.747 203.776C168.602 196.703 167.91 195.691 167.91 195.691C167.91 195.691 167.63 197.889 165.979 198.937C165.61 199.171 159.102 198.948 159.501 199.006C159.9 199.064 165.611 200.156 165.611 200.156L165.227 203.147L159.091 203.673L165.151 204.336L164.72 206.872L158.271 208.183Z"
                                        fill="#EAB2B2"
                                    />
                                    <path
                                        d="M249.149 93.2726L238.104 79.0465L211.672 40.959H249.149V93.2726Z"
                                        fill="#5D74E4"
                                    />
                                    <path
                                        d="M245.153 39.8818H207.675V45.5608H245.153V39.8818Z"
                                        fill="#5D74E4"
                                    />
                                    <path
                                        d="M245.152 94.202C245.152 94.202 247.151 90.5537 249.149 93.2726C249.149 90.8699 245.152 72.0088 245.152 72.0088V94.202Z"
                                        fill="#5D74E4"
                                    />
                                    <path
                                        d="M245.153 45.561H207.675V94.202H245.153V45.561Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M245.152 39.8818L244.985 45.5608L249.149 46.4042V40.9588L245.152 39.8818Z"
                                        fill="#1732AC"
                                    />
                                    <path
                                        d="M245.152 45.561H207.674C207.674 45.561 208.205 68.0595 197.211 83.3845C203.536 89.4489 221.181 91.4721 235.459 87.7581C240.545 86.4349 245.152 45.561 245.152 45.561Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M58.5592 66.8939C54.5171 66.8939 51.2278 63.6007 51.2278 59.5519V42.2332C51.2278 38.1852 54.5162 34.8911 58.5592 34.8911H121.789C125.832 34.8911 129.121 38.1843 129.121 42.2332V59.5519C129.121 63.5999 125.832 66.8939 121.789 66.8939H58.5592Z"
                                        fill="#E7F0FE"
                                    />
                                    <path
                                        d="M58.5592 36.4321H121.789C124.989 36.4321 127.582 39.0296 127.582 42.2334V59.5521C127.582 62.7559 124.989 65.3534 121.789 65.3534H58.5592C55.36 65.3534 52.7663 62.7559 52.7663 59.5521V42.2334C52.7663 39.0296 55.36 36.4321 58.5592 36.4321Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M52.7663 46.2973V42.2325C52.7663 39.0286 55.36 36.4312 58.5592 36.4312H121.789C124.989 36.4312 127.582 39.0286 127.582 42.2325V46.2973H52.7663Z"
                                        fill="#5D74E4"
                                    />
                                    <path
                                        d="M68.0867 50.2441C65.1781 50.2441 62.8202 52.6055 62.8202 55.5183C62.8202 58.4312 65.1781 60.7925 68.0867 60.7925C70.9954 60.7925 73.3533 58.4312 73.3533 55.5183C73.3533 52.6055 70.9954 50.2441 68.0867 50.2441ZM68.0867 59.9248C65.6556 59.9248 63.6817 57.953 63.6817 55.5183C63.6817 53.0836 65.6556 51.1119 68.0867 51.1119C70.5179 51.1119 72.4867 53.0836 72.4867 55.5183C72.4867 57.953 70.5179 59.9248 68.0867 59.9248Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M68.7773 55.5183C68.7773 55.902 68.4674 56.2123 68.0842 56.2123C67.7011 56.2123 67.3903 55.902 67.3903 55.5183C67.3903 55.1346 67.7011 54.8242 68.0842 54.8242C68.4674 54.8242 68.7773 55.1346 68.7773 55.5183Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M68.0842 51.7822C67.8392 51.7822 67.6396 51.9813 67.6396 52.2275V55.32C67.6396 55.5663 67.8383 55.7653 68.0842 55.7653C68.3301 55.7653 68.5288 55.5663 68.5288 55.32V52.2275C68.5288 51.9813 68.3301 51.7822 68.0842 51.7822Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M70.683 53.0566C70.5095 52.8828 70.2282 52.8828 70.0547 53.0566L67.8711 55.2433C67.6977 55.4171 67.6977 55.6987 67.8711 55.8725C68.0446 56.0462 68.3259 56.0462 68.4994 55.8725L70.683 53.6857C70.8573 53.512 70.8573 53.2303 70.683 53.0566Z"
                                        fill="#A0C5FF"
                                    />
                                    <path
                                        d="M93.3272 51.4678H79.4712C79.1301 51.4678 78.8143 51.7014 78.7596 52.0379C78.6897 52.4671 79.0189 52.8382 79.4349 52.8382H93.328C93.7061 52.8382 94.0126 52.5312 94.0126 52.1526C94.0118 51.7747 93.7053 51.4678 93.3272 51.4678ZM119.79 51.4678H95.6261C95.248 51.4678 94.9415 51.7747 94.9415 52.1534C94.9415 52.5321 95.248 52.839 95.6261 52.839H119.827C120.243 52.839 120.572 52.468 120.503 52.0387C120.447 51.7014 120.131 51.4678 119.79 51.4678ZM99.3002 54.8327H79.4712C79.1301 54.8327 78.8143 55.0663 78.7596 55.4028C78.6897 55.832 79.0189 56.2031 79.4349 56.2031H99.301C99.6792 56.2031 99.9857 55.8961 99.9857 55.5175C99.9848 55.1396 99.6783 54.8327 99.3002 54.8327ZM99.3002 58.1976H79.4712C79.1301 58.1976 78.8143 58.4303 78.7596 58.7677C78.6897 59.1969 79.0189 59.568 79.4349 59.568H99.301C99.6792 59.568 99.9857 59.261 99.9857 58.8824C99.9848 58.5045 99.6783 58.1976 99.3002 58.1976ZM119.789 54.8327H101.598C101.22 54.8327 100.914 55.1396 100.914 55.5183C100.914 55.897 101.22 56.2039 101.598 56.2039H119.827C120.243 56.2039 120.572 55.8329 120.502 55.4036C120.447 55.0663 120.131 54.8327 119.789 54.8327Z"
                                        fill="#A0C5FF"
                                    />
                                </svg>

                                <p class="h6 text-muted mt-3">
                                    Sınavın Başlamasına
                                </p>

                                <p class="h5 text-primary">{{ remaining }} dk kaldı</p>

                                <div class="mt-3">
                                    <div class="text-navy">İstersen bu oturum için rezarvasyon oluşturabilirsin.</div>

                                    <button class="btn btn-outline-primary mt-2" @click="examReservation(nextSession.session)">
                                        Rezarvasyon Oluştur
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="col-lg-6 my-10">
                          <router-link to="/sinav-index">
                            <div class="d-flex flex-column mode-border p-15 rounded-sm ">
                              <img
                                src="../assets/img/gifmodern.gif"
                                alt=""
                                class="w-100 rounded-sm"
                              />
                              <span class="fw-bold text-primary my-2 h5">MODERN MOD</span>
                            </div>
                          </router-link>
                        </div>
                        <div class="col-lg-6 my-10">
                          <router-link to="/sinav-index-osym">
                            <div class="d-flex flex-column mode-border p-15 rounded-sm">
                              <img
                                src="../assets/img/gif.gif"
                                alt=""
                                class="w-100 rounded-sm"
                              />
                              <span class="fw-bold text-primary my-2 h5">KLASİK MOD</span>
                            </div>
                          </router-link>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="mb-3 d-flex justify-content-between align-items-center flex-wrap">
            <div class="less-md-w100">
                <ul class="nav nav-pills tab-menu--primary my-2 less-md-w100">
                    <li class="nav-item">
                        <a class="nav-link" href="" :class="{active: currentCategoryId === undefined}" @click.prevent="currentCategoryId = undefined">Tümü</a>
                    </li>

                    <li class="nav-item" v-for="category in categories" :key="category.id">
                        <a class="nav-link" href="" :class="{active: category.id === currentCategoryId}" @click.prevent="currentCategoryId = category.id">{{ category.translate.title }}</a>
                    </li>
                </ul>
            </div>

            <!--
            <CustomSelectBox :options="['Tüm İçerikler', 'Bu Hafta ', 'Bu Ay']" :default="'Tüm İçerikler'" class="select light less-md-w100"/>
            -->
        </div>

        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab">
                <QuizCard class="mb-3" v-for="exam in exams" :key="exam.id" :exam="exam">
                    <template slot="headerRight" v-if="!exam.user_result">
                        <div class="fw-500">
                            <span>
                                <span class="text-muted mr-10">Soru Sayısı:</span> {{ exam.questions_count }}
                            </span>

                            <span class="ml-20">
                                <span class="text-muted mr-10">Süre:</span> {{ exam.exam_duration }} dk
                            </span>

                            <span class="ml-20" v-if="!exam.without_session">
                                <span class="text-muted mr-10">Tarih:</span> {{ getExamDate(exam, true) }}
                            </span>
                        </div>
                    </template>

                    <template slot="quizCardRight" class="justify-content-between w-100" v-if="!exam.user_result">
                        <div class="d-flex flex-column w-100" v-if="(exam.join_status === 'reserved' || !exam.join_status) && !exam.without_session">
                            <details>
                                <summary class="fw-700">Oturum Seç</summary>

                                <div class="d-flex justify-content-between w-100 align-items-center mb-3" v-for="session in exam.sessions" :key="session.id">
                                    <div class="d-flex flex-column align-items-start">
                                        <span class="fw-600">{{ session.session_time }} Oturumu </span>
                                        <span class="text-muted fw-500">
                                            <i class="icon-user"></i>
                                            {{ session.quota - session.remaining_quota }}/<span class="text-dark">{{ session.quota }}</span>
                                        </span>
                                    </div>

                                    <button class="btn btn-primary px-30" @click="examJoin(session)" v-if="session.join_status !== 'reserved'">
                                        Katıl
                                    </button>

                                    <button class="btn btn-danger px-30" @click="examCancelReservation(session)" v-else>
                                        Rezarvasyonu İptal Et
                                    </button>
                                </div>
                            </details>
                        </div>

                        <div v-if="exam.without_session && !exam.join_status" class="w-100">
                            <button class="btn btn-white btn-with-ico shadow-sm header-btn w-100" v-if="exam.sessions[0]" @click="examJoin(exam.sessions[0], true)">
                                <span class="ico bg-gray">
                                    <i class="icon-penline"></i>
                                </span>

                                <span class="text">Sınava Katıl</span>
                            </button>

                            <div v-else>
                                Üzgünüz, bu sınav ile ilgili teknik bir sorun yaşanmakta. <br>
                                Lütfen daha sonra tekrar deneyiniz.
                            </div>
                        </div>


                        <button @click="examJoinOngoing(exam.id)" class="btn btn-white btn-with-ico shadow-sm header-btn w-100" v-if="exam.join_status === 'joined'">
                            <span class="ico bg-gray">
                                <i class="icon-penline"></i>
                            </span>

                            <span class="text">Sınava Devam Et</span>
                        </button>

                        <div v-if="exam.join_status === 'finished'">
                            Bu sınavı <b>{{ moment(exam.user_ended_date).format('DD.MM.YYYY HH:mm') }}</b> tarihinde tamamladınız.
                            <div v-if="exam.without_session">
                                Sınav sonucunuz birazdan hesaplanacak ve gösterilecektir.
                            </div>
                        </div>

                        <div v-if="exam.join_status === 'expired'">
                            {{ exam.without_session ? 'Sınavınız süre sona erdiğinden sonlandırılmıştır. Sınav sonucunuz birazdan hesaplanacak ve gösterilecektir.' : 'Sınavınız sınav süresi içerisinde sınavı bitirmediğinizden otomatik olarak sonlandırılmıştır.' }}
                        </div>
                    </template>
                </QuizCard>
            </div>
        </div>
    </Layout>
</template>

<script>
import moment from 'moment';
import CustomSelectBox from '../components/CustomSelectBox.vue';
import Layout from '../components/Layout.vue';
import QuizCard from '../components/QuizCard.vue';
import axios from 'axios';
import EventBus from "../event-bus";
//import VueHorizontal from 'vue-horizontal';

export default {
    components: {QuizCard, Layout, CustomSelectBox},
    data() {
        return {
            categories: [],
            currentCategoryId: undefined,
            exams: [],
            activeUserSessionId: undefined,
            nextSession: {
                session: undefined,
                date: undefined
            },
            remainingInterval: undefined,
            remaining: undefined
        };
    },
    mounted() {
        this.getCategoryList();
        this.getExamList();

        EventBus.$on(['exam_not_active', 'invalid_exam'], () => {
            this.$swal({
                icon: 'error',
                text: 'Üzgünüz ancak bu sınav artık aktif değil!'
            });
        });

        EventBus.$on('exam_retry_join', (session) => {
            this.examJoinRetry(session);
        });
    },
    methods: {
        getCategoryList() {
            axios.get('/module/exam/category').then((response) => {
                if (response.data.status) {
                    this.categories = response.data.data;
                }
            });
        },
        getExamList() {
            axios.get('/module/exam?category_id=' + (this.currentCategoryId ? this.currentCategoryId : '')).then((response) => {
                if (response.data.status) {
                    this.exams = response.data.data;
                }
            });
        },
        getExamDate(exam, autoDate = false) {
            if (autoDate) {
                if (exam.passing_date && Array.isArray(exam.sessions) && exam.sessions.length) {
                    let sessions = JSON.parse(JSON.stringify(exam.sessions));

                    sessions = sessions.sort(function (a, b) {
                        return moment(a.full_date).unix() - moment(b.full_date).unix()
                    });

                    return moment(sessions.at(0).session_date).format('DD.MM.YYYY');
                }
            }

            return moment(exam.exam_date).format('DD.MM.YYYY');
        },
        examJoin(session, without = false) {
            if (!(session.id && session.exam_id)) {
                return;
            }

            if (session.is_joining) {
                axios.get('/module/exam/session/check/' + session.exam_id).then(response => {
                    if (response.data.status && response.data.data.session_joined_status && response.data.data.joined_session && response.data.data.joined_session.user_session) {
                        this.redirectExamPage(response.data.data.joined_session.user_session.id);
                    } else {
                        this.$swal({
                            icon: 'error',
                            text: 'Bilinmeyen bir hata oluştu!'
                        });
                    }
                });

                return;
            }

            if (moment(session.full_date).isAfter(new Date()) && !without) {
                this.nextSession.session = session;
                this.nextSession.date = moment(session.full_date);

                this.remaining = this.getRemainingTime();

                this.remainingInterval = setInterval(() => {
                    this.remaining = this.getRemainingTime();
                }, 1000);

                return false;
            }

            this.$swal({
                icon: 'info',
                /*title: this.$t('module.exam.joinExam.title'),
                html: this.$t('module.exam.joinExam.description'),*/
                text: this.$t('module.exam.joinExam.description'),
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Evet',
                denyButtonText: `Vazgeç`,
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('/module/exam/session', {
                        exam_id: session.exam_id,
                        session_id: session.id
                    }).then((response) => {
                        if (response.data.status) {
                            this.redirectExamPage(this.activeUserSessionId = response.data.data.id);
                        }
                    });
                }
            });
        },
        examJoinOngoing(exam_id) {
            axios.get('/module/exam/session/check/' + exam_id).then(response => {
                if (response.data.status && response.data.data.session_joined_status && response.data.data.joined_session && response.data.data.joined_session.user_session) {
                    this.redirectExamPage(response.data.data.joined_session.user_session.id);
                } else {
                    this.$swal({
                        icon: 'error',
                        text: 'Bilinmeyen bir hata oluştu!'
                    });
                }
            });
        },
        examJoinRetry(session) {
            if (!(session.id && session.exam_id)) {
                return;
            }

            this.$swal({
                icon: 'info',
                html: 'Sınavı tekrar çözmek istediğinize emin misiniz?',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Evet',
                denyButtonText: `Vazgeç`,
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('/module/exam/session', {
                        exam_id: session.exam_id,
                        session_id: session.id,
                        retry: true
                    }).then((response) => {
                        if (response.data.status) {
                            this.redirectExamPage(this.activeUserSessionId = response.data.data.id);
                        }
                    });
                }
            });
        },
        examReservation(session) {
            this.$swal({
                icon: 'info',
                text: 'Saat ' + session.session_time + ' başlayacak oturum için rezarvasyon oluşturmak istediğinize emin misiniz?',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Evet',
                denyButtonText: `Vazgeç`,
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('/module/exam/session', {
                        exam_id: session.exam_id,
                        session_id: session.id,
                        reservation: true
                    }).then((response) => {
                        if (response.data.status) {
                            this.nextSession = {date: undefined};
                            this.getExamList();

                            setTimeout(() => {
                                this.$swal({
                                    icon: 'success',
                                    text: 'Rezarvasyon kaydınız başarıyla oluşturuldu!'
                                });
                            }, 250);
                        }
                    });
                }
            });
        },
        examCancelReservation(session) {
            if (session.join_status !== 'reserved') {
                return;
            }

            this.$swal({
                icon: 'info',
                text: 'Rezarvasyonunuz iptal edilecektir!\nDevam etmek istiyor musunuz ?',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Evet',
                denyButtonText: `Vazgeç`,
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete('/module/exam/session/reservation/' + session.user_session.id).then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                text: 'Rezarvasyon kaydınız iptal edilmiştir'
                            });

                            this.getExamList();
                        }
                    });
                }
            });
        },
        redirectExamPage(userSessionId) {
            this.$router.push({name: 'exam_show_osym', params: {user_session_id: userSessionId}})
        },
        handleWheel() {
            window.addEventListener('wheel', function (event) {
                this.scrollLeft += event.deltaY;
            });
        },
        getRemainingTime() {
            if (typeof (this.nextSession.date) === 'undefined') {
                return;
            }

            const dateNow = new Date();

            let a = moment(dateNow);
            let duration = moment.duration(this.nextSession.date.diff(a));

            let hours = duration._data.hours;
            let minutes = duration._data.minutes;

            return (hours + ' saat ' + minutes + ' dk ' + duration._data.seconds + ' sn');
        }
    },
    watch: {
        'currentCategoryId': function (newValue, oldValue) {
            this.getExamList();
        }
    },
    beforeDestroy() {
        if (typeof (this.remainingInterval) !== 'undefined') {
            clearInterval(this.remainingInterval);
        }
    }
};
</script>

<style>
.mode-border {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
</style>
