<template>
    <div class="d-flex align-items-center">
        <div class="header-swiper__icons" v-if="upcomingLiveEvents.length>0">
            <span class="ico-btn swp-nav swp-nav__prev"><span class="icon-chevron-down"></span></span>
            <span class="ico-btn swp-nav swp-nav__next ml-5"><span class="icon-chevron-down"></span></span>
        </div>
        <swiper class="header-swiper pl-10" :options="swiperOptions">
            <swiper-slide v-for="event in upcomingLiveEvents" :key="event.id">
                <span class="">
                    {{ event.lecture_title }}
                    <div class="d-flex">
                        <span class="fw-500 ">{{ event.stream_date ? moment(event.stream_date, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM ddd, LT') : '-' }}</span>
                        <a v-if="event.stream_data && event.stream_data.url" v-on:click="openStream(event.stream_data)" class="text-primary fw-500 ml-10 d-flex align-items-center cursor-pointer">
                            <i class="icon-right-arrow mr-5"></i>
                            Derse Katıl
                        </a>
                    </div>
                </span>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
import {swiper, swiperSlide} from 'vue-awesome-swiper';
import axios from 'axios';

export default {
    name: 'HeaderSwiper',
    components: {
        swiper,
        swiperSlide,
    },
    mounted() {
        this.fetchLiveLessons();
    },
    data() {
        return {
            swiperOptions: {
                autoplay: true,
                slidesPerView: 1,
                spaceBetween: 0,
                loop: true,
                navigation: {
                    nextEl: '.swp-nav__next',
                    prevEl: '.swp-nav__prev',
                },
            },
            upcomingLiveEvents: [],
        };
    },
    methods: {
        fetchLiveLessons() {
            axios.get('/lesson/upcoming?include_expire_today=1&last_hour=12').then((response) => {
                if (response.data.status) {
                    this.upcomingLiveEvents = response.data.data;
                }
            });
        },
        openStream(stream) {
            if(stream.api == 'ZOOM'){
                this.axios.get(this.endpoints['module_zoom_meeting_link'] + '/' + stream.meeting_id).then((response) => {
                    if (response.data.status) {
                        let data = response.data.data;
                        this.trustedWindowOpen(data.url, '_blank');
                    }
                });
            } else {
                this.trustedWindowOpen(stream.url, '_blank');
            }
        }
    },
};
</script>
