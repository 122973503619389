<template>
    <Layout>
        <div class="dictionary-wrap  w-lg-75 mx-auto">
            <!-- <button class="dictionary-close-mobile">
              <span class=""
                ><svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.69077 11.163C10.1024 11.5747 10.7611 11.5747 11.1728 11.163C11.5844 10.7513 11.5844 10.0927 11.1728 9.681L7.50895 6.0172L11.1728 2.35339C11.5844 1.94172 11.5844 1.28306 11.1728 0.871399C10.9669 0.665567 10.6788 0.542068 10.4318 0.542068C10.1848 0.542068 9.8966 0.665567 9.69077 0.830232L6.02696 4.49404L2.32199 0.830232C1.91032 0.418568 1.25166 0.418568 0.839998 0.830232C0.428334 1.2419 0.428334 1.90056 0.839998 2.31222L4.50381 5.97603L0.839998 9.63984C0.428334 10.0515 0.428334 10.7102 0.839998 11.1218C1.25166 11.5335 1.91032 11.5335 2.32199 11.1218L5.9858 7.45802L9.69077 11.163Z"
                    fill="white"
                  />
                </svg>
              </span>
            </button> -->
            <div class="d-flex align-items-center flex-wrap sticky-top p-3 rounded-lg  bg-light">
                <div class="d-flex flex-column flex-grow-1 mr-3">
                    <span class="text-muted mb-0">{{ $t('module.paragraph.cols.readingTime') }}:
                        <span class="text-gray-dark fw-bold">{{moment.duration(paragraph.reading_time, 'seconds').minutes() + ' dk'}}</span>
                    </span>
                    <span class="text-muted mb-5">{{ $t('module.paragraph.cols.readingTimeFormat') }}:
                        <span class="text-gray-dark fw-bold">{{ readingTime.text }}</span>
                    </span>
                    <!--
                    <div class="progress filled w-100" style="height:10px">
                      <div
                        class="progress-bar bg-primary"
                        role="progressbar"
                        :style="'width:' + progressBar + '%'"
                        :aria-valuenow="progressBar"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div> -->
                </div>

                <div>
                    <button class="btn translate-btn " :class="translate.status ? 'active' : ''" @click="translate.status = !translate.status">
                        <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M17.3331 9.2206L17.048 8.44853H14.6208L14.3357 9.2374C14.2244 9.54506 14.1293 9.75346 14.0507 9.86259C13.9719 9.96886 13.843 10.022 13.6637 10.022C13.5117 10.022 13.3773 9.96466 13.2605 9.84999C13.1438 9.73533 13.0854 9.6052 13.0854 9.45973C13.0854 9.37586 13.099 9.28913 13.1261 9.1996C13.1533 9.11006 13.1981 8.9856 13.2605 8.8262L14.7878 4.83158C14.8312 4.71688 14.8828 4.57982 14.9425 4.42037C15.0049 4.25812 15.0701 4.12385 15.138 4.01755C15.2086 3.91125 15.2996 3.82594 15.4108 3.7616C15.5249 3.69446 15.6648 3.66089 15.8303 3.66089C15.9987 3.66089 16.1385 3.69446 16.2498 3.7616C16.3638 3.82594 16.4548 3.90986 16.5227 4.01336C16.5933 4.11686 16.6516 4.22875 16.6978 4.34904C16.7467 4.46652 16.8078 4.62457 16.8811 4.82318L18.4409 8.7926C18.5631 9.09473 18.6241 9.31433 18.6241 9.4514C18.6241 9.59406 18.5658 9.72553 18.449 9.84579C18.335 9.96326 18.1965 10.022 18.0336 10.022C17.9386 10.022 17.8571 10.0039 17.7892 9.96746C17.7214 9.93393 17.6644 9.88773 17.6182 9.82899C17.5721 9.76746 17.5218 9.67513 17.4675 9.55206C17.416 9.4262 17.3712 9.31573 17.3331 9.2206ZM14.9384 7.5128H16.7222L15.8222 4.97424L14.9384 7.5128Z"
                                fill="currentColor"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0 10.7293C0 7.02728 2.8711 3.68274 6.84252 3.68274C10.7263 3.68274 13.6599 6.96255 13.6599 10.71C13.6599 15.0644 10.2045 17.7566 6.82996 17.7566C5.71214 17.7566 4.47104 17.449 3.46063 16.8355L3.45285 16.8307C3.29035 16.7285 3.19958 16.6794 3.12691 16.6563C3.07646 16.6404 3.04545 16.6401 2.99914 16.6557C2.99403 16.6574 2.98891 16.6591 2.98376 16.6607L1.7237 17.0462C1.38247 17.1547 1.01573 17.0798 0.755952 16.8331C0.488421 16.5791 0.388923 16.1945 0.490061 15.8189L0.493187 15.8077L0.912308 14.3618C0.915006 14.3525 0.917931 14.3433 0.921069 14.3341C0.938384 14.2836 0.929312 14.2599 0.928426 14.2577C0.923495 14.2493 0.918752 14.2409 0.914223 14.2323C0.337539 13.139 0 11.9267 0 10.7293ZM6.84252 4.86653C3.54498 4.86653 1.14897 7.63981 1.14897 10.7293C1.14897 11.6971 1.42236 12.7127 1.918 13.6561C2.11027 13.9909 2.11956 14.3795 2.00973 14.7126L1.68809 15.8222L2.64988 15.5279C3.2636 15.3249 3.74269 15.626 4.04772 15.8179C4.86332 16.312 5.89746 16.5728 6.82996 16.5728C9.66091 16.5728 12.511 14.3207 12.511 10.71C12.511 7.58835 10.0649 4.86653 6.84252 4.86653Z"
                                fill="currentColor"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M10.0486 4.50505C12.2309 5.70891 13.6592 8.09287 13.6592 10.7101C13.6592 11.3789 13.5769 12.0117 13.424 12.6037C13.9919 12.7923 14.5829 12.8901 15.1698 12.8901C16.1023 12.8901 17.1365 12.6293 17.9521 12.1352C18.2572 11.9433 18.7362 11.6423 19.3499 11.8452L20.3117 12.1395L19.9901 11.0299C19.8802 10.6968 19.8896 10.3082 20.0818 9.97333C20.5775 9.03 20.8508 8.0144 20.8508 7.0466C20.8508 3.95705 18.4548 1.18379 15.1573 1.18379C12.8553 1.18379 10.9503 2.57069 10.0486 4.50505ZM8.76772 4.58623C9.71521 1.96591 12.1523 0 15.1573 0C19.1287 0 21.9998 3.34453 21.9998 7.0466C21.9998 8.24393 21.6622 9.45633 21.0856 10.5495C21.0811 10.5581 21.0763 10.5666 21.0713 10.5749C21.0705 10.5771 21.0614 10.6009 21.0787 10.6513C21.0819 10.6605 21.0848 10.6698 21.0875 10.6791L21.5066 12.1249L21.5097 12.1361C21.6109 12.5118 21.5114 12.8963 21.2439 13.1504C20.9841 13.3971 20.6174 13.472 20.2762 13.3635L19.016 12.9779C19.0109 12.9763 19.0057 12.9747 19.0006 12.9729C18.9544 12.9573 18.9233 12.9577 18.8729 12.9737C18.8002 12.9967 18.7095 13.0458 18.547 13.148L18.5391 13.1528C17.5288 13.7663 16.2876 14.0739 15.1698 14.0739C14.2548 14.0739 13.3394 13.8777 12.4904 13.5078C12.2094 13.3854 12.0703 13.0581 12.1734 12.7624C12.3884 12.1462 12.5102 11.4605 12.5102 10.7101C12.5102 8.3688 11.1309 6.24636 9.07896 5.33655C8.79774 5.21185 8.66083 4.88183 8.76772 4.58623Z"
                                fill="currentColor"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M10.0312 7.84197C10.1451 8.1471 9.99738 8.48957 9.70122 8.6069C8.52674 9.0723 7.38837 9.3089 6.39591 9.35644C5.41567 9.40344 4.53004 9.26797 3.89191 8.93923C3.60813 8.79303 3.49311 8.4375 3.63499 8.1451C3.77689 7.8527 4.12196 7.73423 4.40574 7.88043C4.80236 8.0847 5.46885 8.21577 6.34253 8.1739C7.20396 8.13263 8.22133 7.92503 9.28879 7.50203C9.58488 7.38463 9.91727 7.5369 10.0312 7.84197Z"
                                fill="currentColor"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M6.39954 6.78226C6.70055 6.88559 6.86322 7.22079 6.76286 7.53092C6.51193 8.30652 6.27105 9.26932 6.2313 10.1908C6.19118 11.1207 6.35901 11.9162 6.82265 12.4622C7.03158 12.7082 7.00738 13.0821 6.76862 13.2974C6.52986 13.5127 6.16691 13.4877 5.95798 13.2417C5.21444 12.3662 5.03736 11.2071 5.08347 10.1382C5.12994 9.06072 5.40643 7.98012 5.67286 7.15659C5.7732 6.84646 6.09854 6.67886 6.39954 6.78226Z"
                                fill="currentColor"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M8.25502 8.90184C8.56282 8.98117 8.74995 9.30251 8.67302 9.61964C8.65684 9.68631 8.64053 9.75477 8.62384 9.82477C8.62132 9.83537 8.61879 9.84597 8.6162 9.85664C9.40613 10.0804 10.0826 10.5885 10.3781 11.4004C10.6587 12.1715 10.5485 12.9864 9.99437 13.6188C9.46314 14.2251 8.59045 14.5898 7.4735 14.6857C7.15728 14.7128 6.87963 14.4708 6.85329 14.145C6.82689 13.8192 7.0619 13.5332 7.37806 13.506C8.33053 13.4242 8.87703 13.1274 9.14155 12.8255C9.38329 12.5496 9.44547 12.2096 9.30228 11.8161C9.16808 11.4474 8.83219 11.1412 8.30652 10.9957C7.92521 12.146 7.22684 13.3668 5.53837 13.9466C5.06874 14.1079 4.59038 14.1192 4.1993 13.8724C3.80075 13.6208 3.63422 13.1912 3.61368 12.7886C3.57377 12.0065 4.04444 11.111 4.87903 10.5734C5.72169 10.0305 6.62631 9.75311 7.46328 9.72451C7.48431 9.63884 7.50508 9.55284 7.52598 9.46651C7.53672 9.42191 7.54752 9.37724 7.55839 9.33251C7.63533 9.01537 7.94721 8.82257 8.25502 8.90184ZM7.0962 10.9467C6.58263 11.0272 6.02831 11.2291 5.48817 11.5771C4.94373 11.9278 4.74677 12.4464 4.76106 12.7265C4.76431 12.7902 4.77742 12.8276 4.78617 12.8451C4.79317 12.8591 4.79766 12.8618 4.79923 12.8628C4.79916 12.8628 4.79931 12.8628 4.79923 12.8628C4.80128 12.8641 4.82249 12.8774 4.87988 12.8795C4.93974 12.8817 5.03616 12.8713 5.17504 12.8236C6.24888 12.4548 6.77002 11.767 7.0962 10.9467Z"
                                fill="currentColor"
                            />
                        </svg>

                        {{ $t('module.paragraph.' + (translate.status ? 'translated' : 'translate')) }}
                    </button>

                    <button class="btn btn-primary ml-3 text-primary py-2 close-quiz-btn" @click="closeEvent()">
                        {{ $t('module.paragraph.finishRead') }}
                    </button>
                </div>
            </div>
            <div class="paragraph-details-card mt-2 mb-30">
                <!-- <div
                  v-show="showToolTip"
                  class="tools text-white"
                  :style="{
                    top: `calc(${y}px)`,
                    left: `calc(${x}px + calc(${width}px / 2) - 40px)`,
                  }"
                >
                  {{ sentences[showToolTipIndex].mean }}
                  <span
                    class="text-white bg-primary close-tooltip"
                    @click="showToolTip = false"
                    >x</span
                  >
                </div> -->
                <div ref="paragraph" class="d-flex flex-column">
                    <img :src="paragraph.image ? paragraph.image.public_url : 'https://images.unsplash.com/photo-1657037031161-d126c02cce8c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxMnx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60'" class="img-prg w-100 mb-3" alt=""/>
                    <h1 class="text-primary h4 fw-bold text-center mb-3 mt-0 translatable-text">{{ paragraphTranslate.title }}</h1>

                    <p>
                        <span class="" :class="{ 'd-block mb-2': translate.status }" v-for="(sentence, index) of paragraphTranslate.sentences" :key="'sentence' + index">
                            <span v-if="sentence.line"><br></span>
                            <span v-else v-html="sentence.translate.content"></span>
                        </span>
                    </p>
                    <!--
                    <p>
                      <span
                        v-for="(sentence, index) in sentences"
                        :key="'sentence' + index"
                      ></span>
                    </p>

                    <p class="translate-prg order-3" v-show="!translated">
                      <span
                        class="translatable-text highlight-selected"
                        v-for="(sentence, index) in sentences"
                        :key="index"
                        :class="translated ? 'd-block mb-2' : ''"
                      >
                        {{ sentence.text }}
                      </span>
                    </p> -->
                </div>

                <!-- Sentence Translate
                <div v-if="translated">
                  <p v-for="(sentence, index) in sentences" :key="index">
                    <span
                      class="translatable-text"
                      :class="translated ? 'd-block mb-1' : ''"
                    >
                      {{ sentence.text }}
                    </span>
                    <span class="text-primary d-block fw-500">{{ sentence.mean }}</span>
                  </p>
                </div>
                Sentence Translate -->

                <!-- <p class="paragraph">
                  <span
                    class="highlight-selected"
                    :class="translated ? 'd-block mb-3' : ''"
                    v-for="(sentence, index) in sentences"
                    :key="index"
                    >{{ sentence.text + ' ' }}
                    <span
                      class="text-primary fw-500"
                      v-if="translated"
                      :class="translated ? 'd-block' : ''"
                      >{{ sentence.mean }}</span
                    ></span
                  >
                </p> -->
                <!--
                <div class="py-60">
                  <div v-if="translated">
                    <HighlightWord @share="onShare" @highlight="onHighlight">
                      <p v-for="(prg, index) in paragraphArr" :key="index">
                        {{ prg }}
                        <span class="text-primary fw-500 d-block"
                          >Fugiat qui id tempor aliquip.In veniam tempor esse ipsum
                          ipsum deserunt duis.</span
                        >
                      </p>
                    </HighlightWord>
                  </div>

                  <div v-if="!translated">
                    <p class="d-block">{{ prg.paragraph }}</p>
                  </div>
                </div> -->
            </div>

            <template v-if="questions.length">
                <div class="d-flex align-items-center justify-content-between">
                    <p class="h4 primary-title fw-500" v-html="$t('module.paragraph.question.title')"></p>
                    <button class="btn btn-danger btn-sm ml-3 text-primary py-2 px-4" v-on:click="finishQuiz" v-if="quiz.showQuestion && (quiz.questionIndex + 1) == questions.length">
                        {{ $t('module.paragraph.question.finishQuiz') }}
                    </button>
                </div>
                <hr class="my-3"/>
                <template v-if="paragraph.quiz.user_session && !paragraph.quiz.user_session.end_date">
                    <div v-if="quiz.showQuestion">
                        <div class="paragraph-question-numbers mb-3">
                            <vue-scroll class="question-numbers my-3" :ops="ops">
                                <li class="question-number" :class="questionIndex == quiz.questionIndex && 'active'" v-for="(question, questionIndex) in questions" :key="questionIndex" @click="changeQuestion(questionIndex)">
                                    {{ questionIndex + 1 }}
                                </li>
                            </vue-scroll>
                        </div>

                        <div class="question-detail-card p-3 mb-3">
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="q-number primary position-relative">{{ quiz.questionIndex + 1 }}</span>
                                <p class="mb-0 text-center flex-grow-1">
                                    <!-- <span class="desc d-block">True(T) or False(F)</span>-->
                                    <span class="question-p" v-html="currentQuestion.translate && currentQuestion.translate.content"></span>
                                </p>
                            </div>
                        </div>

                        <div class="w-lg-75 mx-auto">
                            <div class="row">
                                <div class="col-md-6" v-for="(answer, answerKey) in currentQuestion.answers" :key="answerKey">
                                    <label class="wq-option d-flex align-items-center" :for="answerKey" :class="{'hover-option': !currentQuestion.user_answer_id, correct:answer.id == currentQuestion.correct_answer_id && currentQuestion.user_answer_id, wrong: currentQuestion.user_answer_id == answer.id }">
                                        <span class="q-number primary position-relative">{{ $root.questionLetters[answerKey] }}</span>
                                        <input type="radio" :id="answerKey" class="hidden" :value="answer.id" v-model="currentQuestion.user_answer_id" @change="quizAnswerEvent($event, answer)" :disabled="currentQuestion.user_answer_id"/>
                                        <span class="flex-grow-1 question-p" v-html="answer.translate.content"></span>
                                    </label>
                                </div>
                            </div>

                            <div class="quiz-control-buttons mt-20">
                                <button class="control-btn prev mr-3" :class="quiz.questionIndex == 0 ? 'disabled' : ''" :disabled="quiz.questionIndex == 0" @click="changeQuestion(quiz.questionIndex - 1)">
                                    {{ $t('module.paragraph.question.previousQuestion') }}
                                    <span class="ico ml-10"><i class="icon-chevron-down "></i></span>
                                </button>
                                <button class="control-btn next ml-3" :class="(quiz.questionIndex + 1) == questions.length ? 'disabled' : ''" :disabled="(quiz.questionIndex + 1) == questions.length" @click="changeQuestion(quiz.questionIndex + 1)">
                                <span class="ico mr-10">
                                    <i class="icon-chevron-down"></i>
                                </span>
                                    {{ $t('module.paragraph.question.nextQuestion') }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="question-detail-card p-3 mb-3 text-center">
                            <img src="@/assets/icons/question-continue.png" height="128px">

                            <p class="mb-0 mt-3 flex-grow-1" style="font-size: 17px">
                                <span v-html="$t('module.paragraph.question.continueQuestion')"></span>
                            </p>
                        </div>

                        <div class="d-flex justify-content-center align-items-center">
                            <button class="btn btn-primary text-primary py-2 close-quiz-btn" v-on:click="continueQuestion">Evet, devam et!</button>
                            <button class="btn btn-danger text-primary py-2 ml-4 close-quiz-btn" v-on:click="createUserSession(true)">Baştan başla</button>
                        </div>
                    </div>
                </template>

                <div v-else-if="!paragraph.quiz.user_session" class="text-center">
                    <img src="@/assets/icons/solve-question.png" style="height: 196px">

                    <div>
                        <div class="mb-3" style="font-size: 18px" v-html="sprintf($t('module.paragraph.question.solveQuestion.description'), [questions.length])"></div>
                        <button class="btn btn-primary text-primary py-2 close-quiz-btn" v-on:click="createUserSession()">{{ $t('module.paragraph.question.solveQuestion.title') }}</button>
                    </div>
                </div>

                <div v-else-if="paragraph.quiz.user_session && paragraph.quiz.user_session.end_date" class="text-center">
                    <img src="@/assets/icons/retry-question.png" height="128px">

                    <div class="mt-3">
                        <div class="mb-3" style="font-size: 17px" v-html="$t('module.paragraph.question.solveQuestion.againDescription')"></div>
                        <button class="btn btn-primary text-primary py-2 close-quiz-btn" v-on:click="createUserSession(true)">{{ $t('module.paragraph.question.solveQuestion.again') }}</button>
                    </div>
                </div>
            </template>
        </div>
    </Layout>
</template>

<script>
import Layout from '../components/Layout.vue';

export default {
    components: {Layout},
    data() {
        return {
            ops: {
                rail: {
                    enable: false,
                },
                bar: {
                    enable: false,
                },
                scrollButton: {
                    enable: false,
                },
                scrollPanel: {
                    easing: 'easeInQuad',
                    speed: 800,
                },
                vuescroll: {
                    wheelScrollDuration: 0,
                    wheelDirectionReverse: true,
                },
            },
            readingTime: {
                counter: 0,
                interval: null,
                text: ''
            },
            statisticInterval: null,
            contentLine: '{{LINE}}',
            paragraph: {
                quiz: {}
            },
            translate: {
                status: false,
                languageCode: 'TR',
            },
            quiz: {
                showQuestion: false,
                questionIndex: 0,
                questionTimer: null,
                questionTimersCount: {}
            },
        };
    },
    computed: {
        paragraphID() {
            return this.$route.params.id;
        },
        translateLanguage() {
            return this.$root.getLanguage('code', this.translate.languageCode);
        },
        englishLanguage() {
            return this.$root.getLanguage('code', 'EN');
        },
        paragraphTranslate() {
            let translate = {};
            let translateLanguage = this.translate.status ? this.translateLanguage.id : this.englishLanguage.id;

            translate = this.resolveDatum(this.paragraph.translations, translateLanguage, "language_id");

            translate.sentences = [];
            if (this.paragraph.sentences && this.paragraph.sentences.length) {
                this.paragraph.sentences.forEach((sentence) => {
                    if (sentence.translations[0] && sentence.translations[0].content == this.contentLine) {
                        translate.sentences.push({line: true})
                    } else {
                        let sentenceTranslate = this.resolveDatum(sentence.translations, translateLanguage, "language_id", null);

                        if (sentenceTranslate) {
                            sentence.translate = sentenceTranslate;
                            translate.sentences.push(sentence);
                        }
                    }
                });
            }

            return translate;
        },
        questions() {
            let questions = [];
            if (this.paragraph.quiz && this.paragraph.quiz.questions) {
                questions = this.cloneData(this.paragraph.quiz.questions);
                questions.map((question) => {
                    question.translate = this.resolveDatum(question.translations, this.englishLanguage.id, "language_id");
                    if (question.question_type_id == 1) {
                        question.answers.map((answer) => {
                            answer.translate = this.resolveDatum(answer.translations, this.englishLanguage.id, "language_id");
                            return answer;
                        });
                    } else {
                        ['correct', 'wrong'].forEach((answer, answerIndex) => {
                            question.answers.push({
                                id: (answerIndex + 1),
                                translate: {content: this.$t('common.' + answer)}
                            })
                        });
                    }
                    return question;
                })
            }

            return questions;
        },
        currentQuestion() {
            return this.questions[this.quiz.questionIndex] ?? {};
        }
    },
    created() {
        this.fetchParagraph();
    },
    mounted() {
        this.setReadingTimeText();
        this.startReadTimeCounter();
        this.statisticInterval = setInterval(() => {
            this.statisticRequest();
        }, 60 * 3000);
    },
    methods: {
        fetchParagraph() {
            this.axios.get(this.endpoints['module_paragraph'] + '/' + this.paragraphID).then((response) => {
                let data = response.data.data;
                this.paragraph = data;
            });
        },
        statisticRequest() {
            return this.axios.post(this.endpoints['module_paragraph'] + '/' + this.paragraphID + '/statistic', {
                spent_time: this.readingTime.counter
            });
        },
        closeEvent() {
            this.statisticRequest().then(() => {
                this.$router.push({name: 'paragraphs_list'});
            });
        },
        startReadTimeCounter() {
            this.readingTime.interval = setInterval(() => {
                this.readingTime.counter++;
                this.setReadingTimeText();
            }, 1000);
        },
        startQuestionSpendTimer() {
            this.quiz.questionTimer = setInterval(() => {
                if (this.quiz.showQuestion && this.currentQuestion.id) {
                    if (this.quiz.questionTimersCount[this.currentQuestion.id] == undefined) {
                        this.quiz.questionTimersCount[this.currentQuestion.id] = 0;
                    }
                    this.quiz.questionTimersCount[this.currentQuestion.id]++;
                }
            }, 1000);
        },
        setReadingTimeText() {
            let time = this.moment.utc(this.readingTime.counter * 1000);
            let timeParts = {hour: {format: 'HH'}, minute: {format: 'mm'}, second: {format: 'ss'}};
            let timeText = [];

            for (let key in timeParts) {
                let value = time.format(timeParts[key].format)
                timeParts[key].value = parseInt(value);
                timeParts[key].text = this.sprintf('%s', [value]);
            }

            if (timeParts.hour.value > 0) {
                timeText = [timeParts.hour.text, timeParts.minute.text, timeParts.second.text];
            } else {
                timeText = [timeParts.minute.text, timeParts.second.text];
            }

            this.readingTime.text = timeText.join(':');
        },
        createUserSession(retry = null) {
            this.axios.post(this.endpoints['module_paragraph_question_session'], {
                paragraph_id: this.paragraphID,
                retry: retry
            }).then((response) => {
                this.onResponse(response.data, () => {
                    this.quiz.showQuestion = true;
                    this.fetchParagraph();
                    this.startQuestionSpendTimer();
                }, undefined, false);
            }).catch(error => {
                this.onResponseFailure(error.response.data, () => {
                });
            });
        },
        changeQuestion(index) {
            this.quiz.questionIndex = index;
        },
        quizAnswerEvent($event, answer) {
            this.axios.post(this.endpoints['module_paragraph_question_answer'], {
                user_session_id: this.paragraph.quiz.user_session.id,
                question_id: this.currentQuestion.id,
                answer_id: answer.id,
                spended_time: this.quiz.questionTimersCount[this.currentQuestion.id] ?? 0
            }).then((response) => {
                this.onResponse(response.data, () => {
                    this.fetchParagraph();
                }, undefined, false);
            }).catch(error => {
                this.onResponseFailure(error.response.data, () => {
                });
            });
        },
        continueQuestion() {
            let filtered = this.questions.filter((question) => {
                return question.user_answer_id;
            });
            if (filtered.length > 0) {
                let filterIndex = this.questions.findIndex((question) => question == filtered[(filtered.length - 1)]);
                if (filterIndex != -1) {
                    this.quiz.questionIndex = filterIndex == (this.questions.length - 1) ? filterIndex : (filterIndex + 1);
                }
            }

            this.quiz.showQuestion = true;
            this.startQuestionSpendTimer();
        },
        finishQuiz() {
            this.axios.post(this.endpoints['module_paragraph_question_session_finish'], {
                user_session_id: this.paragraph.quiz.user_session.id
            }).then((response) => {
                this.onResponse(response.data, () => {
                    this.fetchParagraph();
                    clearInterval(this.quiz.questionTimer);
                    this.quiz = {
                        showQuestion: false,
                        questionIndex: 0,
                        questionTimer: null,
                        questionTimersCount: {}
                    };
                });
            }).catch(error => {
                this.onResponseFailure(error.response.data, () => {
                });
            });
        }
    },
    beforeDestroy() {
        clearInterval(this.statisticInterval);
        clearInterval(this.readingTime.interval);
        clearInterval(this.quiz.questionTimer);
    }
};
</script>

<style>
.tools {
    padding: 5px 20px 5px 10px;
    background: #333;
    border-radius: 3px;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -100%);
    transition: 0.2s all;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 150px;
    width: 100%;
    max-width: 300px;
}

.close-tooltip {
    position: absolute;
    right: 5px;
    top: 5px;
    min-width: 15px;
    max-width: 15px;
    height: 15px;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.6rem;
}

.tools:after {
    content: '';
    position: absolute;
    left: 95%;
    bottom: -5px;
    transform: translateX(-95%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #333;
}

.item {
    color: #fff;
}

/* .item path {
  fill: #fff;
}
.item:hover path {
  fill: #19f;
}
.item:hover {
  color: #19f;
} */
.item + .item {
    margin-left: 10px;
}

.item .icon-plus {
    min-width: 20px;
    max-width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3271e6;
    color: white;
    font-size: 0.6rem;
    border-radius: 50%;
    cursor: pointer;
}

.question-p p:last-of-type {
    margin-bottom: 0;
}
</style>
