<template>
  <div class="c-select" :tabindex="tabindex" @blur="open = false">
    <div class="selected" :class="{ open: open }" @click="open = !open">
      {{ selected }}
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        v-for="(option, i) of options"
        :key="i"
        @click="
          selected = option;
          open = false;
          $emit('input', option);
        "
      >
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0]
        : null,
      open: false,
    };
  },
  mounted() {
    this.$emit('input', this.selected);
  },
};
</script>

<style lang="scss">
.c-select {
  position: relative;
  text-align: left;
  outline: none;
  //height: 47px;
  line-height: 47px;
  min-width: 160px;
  font-size: 0.9rem;
}

.c-select .selected {
  background-color: var(--light-blue);
  color: var(--font-color);
  border-radius: 16px;
  //border: 1px solid #666666;
  font-size: 0.8rem;
  padding-left: 1.2em;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(var(--primary-color--rgb), 0.08) !important;
}
.c-select.light .selected {
  background-color: var(--drop-bg);
  color: var(--font-color);
  border: 1px solid rgba(var(--gray-soft--rgb), 0.08) !important;
}

.c-select .selected.open {
  border-bottom: 1px solid white;
  border-radius: 16px 16px 0px 0px;
}

.c-select .selected:after {
  content: '';
  height: 40px;
  font-size: 0.8rem;
  background-image: url('../assets/icons/select-down.svg');
  background-size: 35px 35px;
  background-position: right 0.2rem center;
  border-radius: 16px;
  border: 1px solid transparent;
  color: var(--font-color) !important;
  padding-right: 1.6rem;
  padding-left: 1rem;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  margin-right: 0;
}
.c-select.light .selected::after {
  content: '';
  height: 40px;
  font-size: 0.8rem;
  background-image: url('../assets/icons/select-down.svg');
  background-size: 35px 35px;
  background-position: right 0.2rem center;
  border-radius: 16px;
  border: 1px solid transparent;
  color: var(--font-color) !important;
  padding-right: 1.6rem;
  padding-left: 1rem;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  margin-right: 0;
}
.c-select .selected.open::after {
  transform: rotate(180deg);
}
.c-select .items {
  color: var(--font-color);
  border-radius: 0px 0px 16px 16px;
  border: 1px solid rgba(var(--primary-color--rgb), 0.08) !important;
  border-top: 2px solid var(--bg-color) !important ;
  overflow: hidden;
  position: absolute;
  background-color: var(--light-blue);
  left: 0;
  right: 0;
  z-index: 1;
}
.c-select.light .items {
  background-color: var(--drop-bg);
}

.c-select .items div {
  color: var(--font-color);
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.c-select .items div:hover {
  background-color: rgba(var(--gray-soft--rgb), 0.1);
  //color: white;
}

.selectHide {
  display: none;
}
</style>
