<template>
    <Layout>
        <div class="py-30">
            <div class="d-flex justify-content-between align-items-center flex-wrap">
                <ul class="nav nav-pills mb-2 tab-menu--primary less-md-w100" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" id="pills-all-tab" data-toggle="pill" href="" role="tab" aria-controls="pills-all" aria-selected="true" v-on:click.prevent="activeLevelTabId = ''" v-bind:class="{ active: activeLevelTabId === '' }">{{ $t('common.all') }}</a>
                    </li>
                    <li class="nav-item" role="presentation" v-for="tab in paragraphLevels" :key="tab.code">
                        <a class="nav-link " id="pills-easy-tab" data-toggle="pill" href="" role="tab" aria-controls="pills-easy" aria-selected="false" v-bind:class="{ active: activeLevelTabId === tab.id }" v-on:click.prevent="activeLevelTabId = tab.id">{{ tab.code }}</a>
                    </li>
                </ul>

                <!-- <CustomSelectBox
                  :options="['Tümü', 'Okudum ', 'Okumadım']"
                  :default="'Tümü'"
                  class="select light mb-2 less-md-w100"
                /> -->
            </div>
            <div>
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab">
                        <ContentNull v-if="paragraphs.length == 0"/>
                        <div class="row py-3" v-else>
                            <div class="col-sm-6 col-xl-4  mb-3" v-for="(paragraph, index) in paragraphs" :key="'prg' + index">
                                <router-link :to="{ name: 'paragraph_details',params: { id: paragraph.id },}" class="paragraph-card" :class="{ 'is-read': paragraph.user_is_read }">
                                    <div class="row">
                                        <div class="col-xl-4">
                                            <img v-if="paragraph.image !== null" :src="paragraph.image.public_url" class="paragraph-card_img rounded-sm w-100" alt=""/>
                                            <div class="paragraph-card_placeholder w-100  text-red" v-else>
                                                <small class="text text-red fw-700">YDS.net</small>
                                            </div>
                                        </div>
                                        <div class="col-xl-8">
                                            <div class="d-flex flex-column">
                                                <div class="d-flex mb-2">
                                                    <small class="text-muted fw-500 mr-1" v-for="(tag, index) of paragraph.tags" :key="'tag' + index">#{{ tag.name }}</small>
                                                </div>

                                                <small class="fw-700 mb-10">
                                                    <span class="text-muted fw-500">Tahmini Okuma Süresi:</span>
                                                    {{ moment.duration(paragraph.reading_time, 'seconds').minutes() + ' dk' }}
                                                </small>
                                                <p class="fw-700 h6 mb-0">{{ paragraph.translate.title }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from '../components/Layout.vue';
/*import CustomSelectBox from '../components/CustomSelectBox.vue';*/
import ContentNull from '../components/ContentNull.vue';

export default {
    components: {Layout, ContentNull},
    data() {
        return {
            activeLevelTabId: '',
            paragraphLevels: [
                {
                    id: 1,
                    code: 'Easy',
                    sort: 1,
                    active: true,
                },
                {
                    id: 2,
                    code: 'Medium',
                    sort: 2,
                    active: true,
                },
                {
                    id: 3,
                    code: 'Hard',
                    sort: 3,
                    active: true,
                },
            ],
        };
    },
    computed: {
        paragraphs() {
            return this.$store.state.module.paragraph.table.data;
        }
    },
    methods: {
        fetchParagraphsLevels() {
            this.axios.get('/module/paragraph/levels').then((response) => {
                this.paragraphLevels = response.data.data;
            });
        },
        fetchParagraphsList() {
            this.$store.dispatch("module/paragraph/get", {
                page: {pageSize: 1000},
                filterData: {
                    language_id: 2,
                    level_id: this.activeLevelTabId
                }
            });
        }
    },
    watch: {
        activeLevelTabId() {
            this.fetchParagraphsList();
        },
    },
    created() {
        this.fetchParagraphsLevels();
        this.fetchParagraphsList();
    },
};
</script>
