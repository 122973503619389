<template>
  <LayoutUser>
    <div class="w-xxl-75">
      <p class="h5 mb-20">
        Şifre Yenile
      </p>

      <FormulateForm @submit="setPassword">
        <div class="row">
          <div class="col-md-12 mb-15">
            <div class="account-input-group">
              <FormulateInput
                type="password"
                name="current_password"
                validation="required"
                validation-name="Mevcut Şifre"
                placeholder="Mevcut şifrenizi giriniz"
                v-model="password.current_password"
              />
              <label class="text-muted">Mevcut Şifre</label>
            </div>
          </div>
          <div class="col-md-12 mb-15">
            <div class="account-input-group">
              <FormulateInput
                type="password"
                name="password"
                validation="required|min:6"
                validation-name="Yeni şifre"
                placeholder="Yeni şifrenizi giriniz"
                v-model="password.password"
              />
              <label class="text-muted">Yeni Şifre</label>
            </div>
          </div>
          <div class="col-md-12 mb-15">
            <div class="account-input-group">
              <FormulateInput
                type="password"
                name="password_confirm"
                validation="required|min:6|confirm"
                validation-name="Yeni şifre tekrar"
                placeholder="Yeni şifrenizi tekrar giriniz."
                v-model="password.password_confirmation"
              />
              <label class="text-muted">Yeni Şifre Tekrar</label>
            </div>
          </div>

          <div class="col-md-12 d-flex justify-content-end">
            <button type="submit" class="btn btn-primary px-30">Kaydet</button>
          </div>
        </div>
      </FormulateForm>
    </div>
  </LayoutUser>
</template>

<script>
import LayoutUser from '../components/LayoutUser.vue';

export default {
  data() {
    return {
      password: {
        current_password: '',
        password: '',
        password_confirmation: '',
      },

      errors: [],
    };
  },
  components: { LayoutUser },

  methods: {
    setPassword() {
      this.axios
        .put('/user/change-password', this.password)
        .then(() => {
          this.$notify({
            title:
              '<div class="d-flex align-items-center"> <i class="icon-check-stroke h4 mb-0 mr-5"></i> Başarıyla Güncellendi</div>',
            type: 'success',
          });
          this.$root.userLogout();
        })
        .catch(() => {
          this.$notify({
            title:
              '<div class="d-flex align-items-center"> <i class="icon-alert h4 mb-0 mr-5"></i> Bir hata oluştu</div>',
            type: 'error',
          });
        });
    },
   
  },
};
</script>

<style></style>
