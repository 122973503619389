<template>
  <swiper class="lesson-progress-swiper" :options="swiperOptions">
    <swiper-slide>
      <router-link
        :to="{ name: 'coming-soon', params: { feature: 'video-izleme' } }"
        class="lesson-progress-card"
      >
        <div class="progress-card__left">
          <CircleProgressbar
            :progress="25"
            progressColor="#3271e6"
            :progressSize="progressSize"
          ></CircleProgressbar>
        </div>
        <div class="progress-card__right">
          <p class="fw-500 mb-0">KOÇ GRUBU - YDS İlkbahar 2021</p>
          <small>Ders 01 Gramer</small>
        </div>
      </router-link>
    </swiper-slide>
    <swiper-slide>
      <router-link
        :to="{ name: 'coming-soon', params: { feature: 'video-izleme' } }"
        class="lesson-progress-card"
      >
        <div class="progress-card__left">
          <CircleProgressbar
            :progress="80"
            progressColor="#3271e6"
            :progressSize="progressSize"
          ></CircleProgressbar>
        </div>
        <div class="progress-card__right">
          <p class="fw-500 mb-0">KOÇ GRUBU - YDS İlkbahar 2021</p>
          <small>Ders 01 Gramer</small>
        </div>
      </router-link>
    </swiper-slide>
    <swiper-slide>
      <router-link
        :to="{ name: 'coming-soon', params: { feature: 'video-izleme' } }"
        class="lesson-progress-card"
      >
        <div class="progress-card__left">
          <CircleProgressbar
            :progress="90"
            progressColor="#3271e6"
            :progressSize="progressSize"
          ></CircleProgressbar>
        </div>
        <div class="progress-card__right">
          <p class="fw-500 mb-0">KOÇ GRUBU - YDS İlkbahar 2021</p>
          <small>Ders 01 Gramer</small>
        </div>
      </router-link>
    </swiper-slide>
    <swiper-slide>
      <router-link to="/video-index" class="lesson-progress-card">
        <div class="progress-card__left">
          <CircleProgressbar
            :progress="66"
            :progressSize="progressSize"
            progressColor="#6625df"
          ></CircleProgressbar>
        </div>
        <div class="progress-card__right">
          <p class="fw-500 mb-0">KOÇ GRUBU - YDS İlkbahar 2021</p>
          <small>Ders 01 Gramer</small>
        </div>
      </router-link>
    </swiper-slide>
  </swiper>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import CircleProgressbar from './CircleProgressbar.vue';

export default {
  components: {
    swiper,
    swiperSlide,
    CircleProgressbar,
  },
  data() {
    return {
      progressSize: 56,
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 10,
        loop: true,
        mousewheel: true,
        breakpoints: {
          0: {
            slidesPerView: 1.1,
          },
          992: {
            slidesPerView: 'auto',
          },
        },
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.lesson-progress-card {
  width: 310px;
  background-color: var(--bg-light);
  color: var(--font-color) !important;
  padding: 1rem 2rem 1rem 1rem;
  border-radius: 15px;
  display: flex;
  align-items: center;
  .progress-card__right {
    padding-left: 15px;
  }
}
.lesson-progress-swiper {
  .swiper-slide {
    width: 310px;
  }
}
</style>
