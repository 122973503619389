<template>
  <ul class="word-point" :style="style">
    <li :class="rate >= 1 ? 'bg-primary' : ''"></li>
    <li :class="rate >= 2 ? 'bg-primary' : ''"></li>
    <li :class="rate >= 3 ? 'bg-primary' : ''"></li>
    <li :class="rate >= 4 ? 'bg-primary' : ''"></li>
  </ul>
  <!-- <span class="word-rate">
    <svg
      width="9"
      height="16"
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.742188"
        width="8.0501"
        height="14.3752"
        rx="4.02505"
        :fill="[rate >= 1 ? '#3271E6' : '#AEB6CF']"
      />
    </svg>
    <svg
      width="9"
      height="24"
      viewBox="0 0 9 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.649414"
        y="0.695312"
        width="8.0501"
        height="22.4253"
        rx="4.02505"
        :fill="[rate >= 2 ? '#3271E6' : '#AEB6CF']"
      />
    </svg>
    <svg
      width="9"
      height="33"
      viewBox="0 0 9 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.300049"
        y="0.492188"
        width="8.0501"
        height="31.6254"
        rx="4.02505"
        :fill="[rate >= 3 ? '#3271E6' : '#AEB6CF']"
      />
    </svg>
    <svg
      width="9"
      height="42"
      viewBox="0 0 9 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.949951"
        y="0.867188"
        width="8.0501"
        height="40.2505"
        rx="4.02505"
        :fill="[rate >= 4 ? '#3271E6' : '#AEB6CF']"
      />
    </svg>
  </span> -->
</template>

<script>
export default {
  props: {
    rate: { type: [Number, String] },
    width: String,
    height: String,
  },
  computed: {
    style() {
      return (
        'width: ' +
        this.width +
        'px!important;' +
        'height:' +
        this.height +
        'px!important;'
      );
    },
  },
};
</script>

<style lang="scss">
.word-rate {
  display: flex;
  align-items: flex-end;
  svg {
    margin-left: 3px;
  }
}
</style>
