<template>
  <Layout>
    <div
      class="box-404 d-flex flex-column align-items-center justify-content-center mx-auto"
    >
      <img
        src="@/assets/img/coming-soon/coming-soon.svg"
        alt=""
        width="350"
        class="animate-pop mb-30"
      />
      <div class="d-flex flex-column align-items-center text-center">
        <span class="badge badge-primary rounded-pill mb-10">ÇOK YAKINDA</span>
        <p class="fw-500 mb-5 text-muted">
          <span class="text-gray-dark">{{ name }}</span> özelliğini çok yakında
          sizlerle buluşturuyoruz.
        </p>
        <p class="fw-500 mb-30 text-muted">Şimdilik derslerine odaklan.</p>
        <router-link to="/" class=" fw-600 h6 ">Ansayfaya Dön</router-link>
        <router-link :to="{ name: 'lesson_list' }" class=" fw-600 h6"
          >Derslerime Git</router-link
        >
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../components/Layout.vue';

export default {
  components: { Layout },
  data() {
    return {
      name: 'Deneme Sınavı',
      animation: '',
    };
  },
  methods: {
    setName() {
      this.$route.params.feature === 'sozluk'
        ? (this.name = 'Sözlük')
        : this.$route.params.feature === 'kelimelik'
        ? (this.name = 'Kelimelik')
        : this.$route.params.feature === 'konu-tarama'
        ? (this.name = 'Konu Tarama')
        : this.$route.params.feature === 'paragraf-okuma'
        ? (this.name = 'Paragraf Okuma')
        : this.$route.params.feature === 'deneme-sinavi'
        ? (this.name = 'Deneme Sınavı')
        : this.$route.params.feature === 'video-izleme'
        ? (this.name = 'Video İzleme')
        : '';
    },
  },
  created() {
    this.setName();
  },
  watch: {
    '$route.params.feature': {
      handler: function() {
        this.setName();
      },
    },
  },
};
</script>

<style>
.box-404 {
  height: 80vh;
}
.animate-pop {
  animation-name: hvr-pop;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: 2;
}
</style>
