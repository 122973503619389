<template>
  <div
    class="py-60 d-flex flex-column align-items-center justify-content-center mx-auto rounded-lg"
  >
    <div class="lds-ripple">
      <div></div>
      <div></div>
    </div>
    <p class="h5 fw-600 mt-30">Yükleniyor</p>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {},
};
</script>


