<template>
  <div class="dictionary-wrap">
    <audio ref="audioElm">
      <source :src="activeAudio" type="audio/ogg" />
      <source :src="activeAudio" type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
    <div class="d-flex align-items-center">
      <div class="dictionary-search-group">
        <input
          type="search"
          placeholder="Sözlükte arama yapın..."
          :value="searchWord"
          id="searchInput"
          ref="searchInput"
        />
        <button class="btn btn-primary"><i class="icon-search"></i></button>
      </div>
    </div>

    <div class="py-40">
      <h2 class="dictionary-sec-title mb-30">Arama Sonuçları</h2>
      <div class="row">
        <div class="col-xl-3 col-md-6 mb-3">
          <div
            class="daily-word"
            :class="{ 'outline-primary': isActiveMean('belirlemek') }"
            @click="setActiveMean('belirlemek')"
          >
            <div class="daily-word__header">
              <div class="d-flex align-items-start">
                <div class="word-details">
                  <span class="word fs-18">
                    belirlemek
                    <span class="badge badge-danger mr-5">Verb</span>
                    <span class="badge badge-danger mr-5">+that SVO</span>
                    <div class="mean">kalıntı</div>
                  </span>
                </div>
              </div>

              <Rate :rate="2" width="40" height="30" />
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-3">
          <div
            class="daily-word"
            :class="{ 'outline-primary': isActiveMean('karar') }"
            @click="setActiveMean('karar')"
          >
            <div class="daily-word__header">
              <div class="d-flex align-items-start">
                <div class="word-details">
                  <span class="word fs-18">
                    karar vermek
                    <span class="badge badge-danger mr-5">Verb</span>
                    <span class="badge badge-danger mr-5">+that SVO</span>
                    <div class="mean">kalıntı</div>
                  </span>
                </div>
              </div>

              <Rate :rate="2" width="40" height="30" />
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <transition name="fade">
            <div class="selected-results" v-if="selectedMean != ''">
              <div class="pt-20 w-md-50">
                <Accordion
                  id="accordion-example-1"
                  :content="exampleData"
                ></Accordion>
              </div>
            </div>
          </transition>
        </div>
      </div>

      <div class="pt-40">
        <h2 class="dictionary-sec-title mb-30">Diğer Hallleri</h2>
        <div class="row">
          <div class="col-md-3 col-xl-2 mb-3">
            <div class="daily-word">
              <div class="daily-word__header">
                <div class="d-flex align-items-start">
                  <div class="word-details">
                    <span class="word fs-18">
                      determine<span class="badge badge-primary ml-5">v1</span>
                      <div class="mean">kalıntı</div>
                    </span>
                  </div>
                </div>

                <Rate :rate="2" width="40" height="30" />
              </div>
            </div>
          </div>
          <div class="col-md-3 col-xl-2 mb-3">
            <div class="daily-word">
              <div class="daily-word__header">
                <div class="d-flex align-items-start">
                  <div class="word-details">
                    <span class="word fs-18">
                      determined<span class="badge badge-primary ml-5">v2</span>
                      <div class="mean">kalıntı</div>
                    </span>
                  </div>
                </div>

                <Rate :rate="3" width="40" height="30" />
              </div>
            </div>
          </div>
          <div class="col-md-3 col-xl-2 mb-3">
            <div class="daily-word">
              <div class="daily-word__header">
                <div class="d-flex align-items-start">
                  <div class="word-details">
                    <span class="word fs-18">
                      determined<span class="badge badge-primary ml-5">v3</span>
                      <div class="mean">kalıntı</div>
                    </span>
                  </div>
                </div>

                <Rate :rate="4" width="40" height="30" />
              </div>
            </div>
          </div>
          <div class="col-md-3 col-xl-2 mb-3">
            <div class="daily-word">
              <div class="daily-word__header">
                <div class="d-flex align-items-start">
                  <div class="word-details">
                    <span class="word fs-18">
                      determines<span class="badge badge-primary ml-5">Vs</span>
                      <div class="mean">kalıntı</div>
                    </span>
                  </div>
                </div>

                <Rate :rate="1" width="40" height="30" />
              </div>
            </div>
          </div>
          <div class="col-md-3 col-xl-2 mb-3">
            <div class="daily-word">
              <div class="daily-word__header">
                <div class="d-flex align-items-start">
                  <div class="word-details">
                    <span class="word fs-18">
                      determines<span class="badge badge-primary ml-5"
                        >Ving</span
                      >
                      <div class="mean">kalıntı</div>
                    </span>
                  </div>
                </div>

                <Rate :rate="1" width="40" height="30" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="py-40">
      <h2 class="dictionary-sec-title mb-30">Günün Kelimeleri</h2>
      <div class="row">
        <div
          class="col-md-4 mb-3"
          v-for="(word, index) in words"
          :key="index + 'zz'"
        >
          <DailyWord :word="word" />
        </div>
      </div>
    </div>

    <div class="py-40">
      <h2 class="dictionary-sec-title mb-30">Son Aranan Kelimeler</h2>
      <div class="row">
        <div
          class="col-md-4 mb-3"
          v-for="(word, index) in words"
          :key="index + 'zz'"
        >
          <DailyWord
            :word="word"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from './Accordion.vue';
import DailyWord from './DailyWord.vue';
import Rate from './Rate.vue';
export default {
  components: { Rate, Accordion, DailyWord },
  props: {
    searchWord: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      exampleData: [
        {
          id: 1,
          active: false,
          title: 'Örnekler',
          details: `
          <div class="mb-3">
            <p class="text-gray-soft fw-400 mb-0">The lives of most people are determined by their environment.</p>
            <p class="fw-600 mb-0">Çoğu insanın hayatı, çevreleri tarafından belirlenir.</p>
          </div> 
           <div class="mb-3">
            <p class="text-gray-soft fw-400 mb-0">The lives of most people are determined by their environment.</p>
            <p class="fw-600 mb-0">Çoğu insanın hayatı, çevreleri tarafından belirlenir.</p>
          </div> 
      
    `,
        },
        {
          id: 2,
          active: true,
          title: 'Collocations',
          details: `
      <p class="fw-600 mb-5">coroner may determine:<span class="text-gray-soft fw-400"> adli tabip belirleyebilir</span></p>
      <p class="fw-600 mb-5">factor may determine:<span class="text-gray-soft fw-400"> faktör sebep olabilir</span></p>
    `,
        },
      ],
      words: [
        {
          text: 'bla',
          mean: 'bla bla',
          description:
            'daha büyük bir orijinal parça veya miktardan kalan küçük bir parça veya miktarr',
          examples: [
            {
              text:
                'Zaliqua eiusmod sunt labore sint duis qui consequat consequat sint.',
              mean: 'Cillum ad reprehenderit proident aliqua.',
            },
            {
              text: 'Sollit commodo minim minim culpa eu laborum.',
              mean: 'Quis reprehenderit',
            },
          ],
          sound: 'https://freesound.org/data/previews/509/509235_7473420-lq.mp3',
        },
        {
          text: 'zula',
          mean: 'zula la la',
          description:
            'daha büyük bir orijinal parça veya miktardan kalan küçük bir parça veya miktarr',
          examples: [
            {
              text:
                'Xaliqua eiusmod sunt labore sint duis qui consequat consequat sint.',
              mean: 'Zillum ad reprehenderit proident aliqua.',
            },
            {
              text: 'Sollit commodo minim minim culpa eu laborum.',
              mean: 'Quis reprehenderit',
            },
          ],
          sound: 'http://steveobrienvo.com/demos/AC.mp3',
        },
      ],
    };
  },
  methods: {
    setActiveMean(mean) {
      this.selectedMean = mean;
    },
    isActiveMean(mean) {
      return this.selectedMean === mean;
    },
  },
};
</script>

<style></style>
