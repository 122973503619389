<template>
  <Layout>
    <div class="account-grid mx-auto">
      <div class="account-grid__left ">
        <div class="account-sidebar mb-30">
          <div class="img-uploader mb-20" :style="profileImgBg"></div>

          <p class="h5">{{ this.$root.user.full_name }}</p>
          <!-- <p class="h6 text-muted">Hesabım</p> -->
        </div>

        <ul class="auth-menu account-left-menu">
          <li class="auth-menu__item">
            <router-link
              :to="{
                name: 'account',
              }"
              aria-current="page"
              class="auth-menu__link "
            >
              <span class="d-flex align-items-center">
                <i class="ico icon-user mr-10"></i>
                Hesabım
              </span>
              <i class="chevron icon-chevron-down"></i>
            </router-link>
          </li>
          <li class="auth-menu__item">
            <router-link :to="{name: 'notifications'}" aria-current="page" class="auth-menu__link ">
              <span class="d-flex align-items-center">
                  <i class="ico icon-bell mr-10"></i>
                  Bildirimlerim
              </span>
              <i class="chevron icon-chevron-down"></i>
            </router-link>
          </li>
          <li class="auth-menu__item">
            <router-link
              :to="{
                name: 'account-addresses',
              }"
              class="auth-menu__link "
            >
              <span class="d-flex align-items-center">
                <i class="ico icon-map-pin mr-10"></i>
                Adreslerim
              </span>
              <i class="chevron icon-chevron-down"></i>
            </router-link>
          </li>
          <li class="auth-menu__item">
            <router-link :to="{ name: 'orders' }" class="auth-menu__link">
              <span class="d-flex align-items-center">
                <i class="ico icon-cart mr-10"></i>
                Siparişlerim
              </span>
              <i class="chevron icon-chevron-down"></i>
            </router-link>
          </li>
          <li class="auth-menu__item">
            <router-link
              :to="{
                name: 'password-change',
              }"
              aria-current="page"
              class="auth-menu__link "
            >
              <span class="d-flex align-items-center">
                <i class="ico icon-refresh mr-10"></i>
                Şifre Değiştir
              </span>
              <i class="chevron icon-chevron-down"></i>
            </router-link>
          </li>
          <!--<li class="auth-menu__item">
            <router-link
              :to="{
                name: 'notification-settings',
              }"
              aria-current="page"
              class="auth-menu__link "
              ><span class="d-flex align-items-center"
                ><i class="ico icon-filter mr-10"></i>Bildirim Ayarları </span
              ><i class="chevron icon-chevron-down"></i>
            </router-link>
          </li>-->

        </ul>
      </div>
      <div class="account-grid__right ">
        <slot></slot>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../components/Layout.vue';

export default {
  components: { Layout },
  computed: {
    profileImgBg() {
      if (this.$root.user.avatar != null) {
        return (
          'background-image:url( ' + this.$root.user.avatar.thumb.small + ')'
        );
      } else {
        if (this.$root.user?.gender_id == 2) {
          return 'background-image:url(' + this.$root.getWebSiteURL() + '/system/icon/avatar-woman.jpeg)';
        }
        return 'background-image:url(' + this.$root.getWebSiteURL() + '/system/icon/avatar-man.jpeg)';
      }
    },
  },
  data() {
    return {
      userDetails: {},
    };
  },

  methods: {},
};
</script>

<style></style>
