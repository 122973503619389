<template>
  <swiper class="lesson-progress-swiper" :options="swiperOptions">
    <swiper-slide>
      <router-link :to="{ name: 'coming-soon', params: { feature: 'deneme-sinavi' } }" class="lesson-progress-card">
        <div class="progress-card__left text-center">
          <span class="text-green fw-500 h6 mb-0">3</span>
          <span class="text-gray-soft h6 mb-0">/</span>
          <span class="text-red fw-500 h6 mb-0">8</span>
        </div>
        <div class="progress-card__right">
          <p class="fw-500 mb-0">KOÇ GRUBU - YDS İlkbahar 2021</p>
          <small>Ders 02 Gramer</small>
        </div>
      </router-link>
    </swiper-slide>
    <swiper-slide>
      <router-link :to="{ name: 'coming-soon', params: { feature: 'deneme-sinavi' } }" class="lesson-progress-card">
        <div class="progress-card__left text-center">
          <span class="text-green fw-500 h6 mb-0">3</span>
          <span class="text-gray-soft h6 mb-0">/</span>
          <span class="text-red fw-500 h6 mb-0">8</span>
        </div>
        <div class="progress-card__right">
          <p class="fw-500 mb-0">KOÇ GRUBU - YDS İlkbahar 2021</p>
          <small>Ders 02 Gramer</small>
        </div>
      </router-link>
    </swiper-slide>
  </swiper>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';

export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      progressSize: 56,
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 10,
        loop: true,
        mousewheel: true,
        breakpoints: {
          0: {
            slidesPerView: 1.1,
          },
          992: {
            slidesPerView: 'auto',
          },
        },
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.lesson-progress-card {
  width: 310px;
  background-color: var(--bg-light);
  color: var(--font-color) !important;
  padding: 1rem 2rem 1rem 1rem;
  border-radius: 15px;
  display: flex;
  align-items: center;
  .progress-card__left {
    min-width: 65px;
    max-width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  }
  .progress-card__right {
    padding-left: 10px;
  }
}
.lesson-progress-swiper {
  .swiper-slide {
    width: 310px;
  }
}
</style>
