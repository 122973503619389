<template>
  <Layout>
    <DictionaryComp/>
  </Layout>
</template>

<script>
import DictionaryComp from '../components/DictionaryComp.vue';
import Layout from '../components/Layout.vue';
export default {
  components: { Layout, DictionaryComp },
  data() {
    return {
      selectedMean: '',
      exampleData: [
        {
          id: 1,
          active: false,
          title: 'Örnekler',
          details: `
          <div class="mb-3">
            <p class="text-gray-soft fw-400 mb-0">The lives of most people are determined by their environment.</p>
            <p class="fw-600 mb-0">Çoğu insanın hayatı, çevreleri tarafından belirlenir.</p>
          </div> 
           <div class="mb-3">
            <p class="text-gray-soft fw-400 mb-0">The lives of most people are determined by their environment.</p>
            <p class="fw-600 mb-0">Çoğu insanın hayatı, çevreleri tarafından belirlenir.</p>
          </div> 
      
    `,
        },
        {
          id: 2,
          active: true,
          title: 'Collocations',
          details: `
      <p class="fw-600 mb-5">coroner may determine:<span class="text-gray-soft fw-400"> adli tabip belirleyebilir</span></p>
      <p class="fw-600 mb-5">factor may determine:<span class="text-gray-soft fw-400"> faktör sebep olabilir</span></p>
    `,
        },
      ],
    };
  },
  methods: {
    setActiveMean(mean) {
      this.selectedMean = mean;
    },
    isActiveMean(mean) {
      return this.selectedMean === mean;
    },
  },
};
</script>

<style></style>
