import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Global from "@/core/plugins/global";
import store from "./store";

import Highcharts from 'highcharts';
import Highcharts3d from 'highcharts/highcharts-3d';
import HighchartsVue from 'highcharts-vue';
import VCalendar from 'v-calendar';
import VueEllipseProgress from 'vue-ellipse-progress';
import vuescroll from 'vuescroll';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueGoodTablePlugin from 'vue-good-table';
import Notifications from 'vue-notification';
import VueFormulate from '@braid/vue-formulate';
import {tr} from '@braid/vue-formulate-i18n';
import EventBus from "./event-bus";
import VueSweetalert2 from 'vue-sweetalert2';
import VuePlyr from 'vue-plyr';
import Inputmask from 'inputmask'

Vue.use(VuePlyr, {
  plyr: {},
});
Vue.use(VueFormulate, {
    plugins: [tr],
    locale: 'tr',
});
Vue.use(Notifications);
Vue.use(VueGoodTablePlugin);

Vue.use(VueSweetalert2);

Vue.use(VueEllipseProgress);
Vue.use(vuescroll);
Vue.use(VueAxios, axios);

Highcharts3d(Highcharts);
Vue.use(HighchartsVue);
Vue.use(VCalendar, {});

Vue.config.productionTip = false;

import VueI18n from "vue-i18n";
import i18n from "@/core/plugins/vue-i18n";
Vue.use(VueI18n);

Vue.directive('math', {
    bind: function (el, binding, vnode) {
        window.renderMathInElement(el);
    },
    update(el, binding, vnode, prevVnode) {
        window.renderMathInElement(el);
    }
})

Vue.directive('mask', {
    bind(el, binding, vnode) {
        Inputmask(binding.value).mask(el);
    },
    unbind(el){
        Inputmask.remove(el);
    }
})

const app = new Vue({
    router,
    store,
    i18n: i18n(),
    data() {
        return {
            user: {},
            defaultLanguage: {},
            /*defaultCurrency: {},
            currencies:[],*/
            languages:[],
            questionLetters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'Y', 'V', 'W', 'X', 'Y', 'Z']
        };
    },
    computed: {
        notSeenNotificationCount() {
            return this.$store.state.notification.table.data.filter(notification => !notification.is_seen).length;
        }
    },
    created() {
        axios.interceptors.request.use((request) => {
            if (request.url.indexOf('http') === -1) {
                request.url = process.env.VUE_APP_API_URL + request.url;
            }

            request.headers.common.Authorization = this.getAuthToken();

            return request;
        });

        axios.interceptors.response.use((response) => {
                return response;
            }, (error) => {
                if (error.response.status === 401) {
                    this.userLogout();
                }

                if (error.response.data && error.response.data.message) {
                    switch (error.response.data.message) {
                        case 'exam_session_datetime_expired':
                            this.$swal({
                                icon: 'error',
                                text: 'Süresi geçmiş bir sınav oturumuna katılamazsınız'
                            });
                            break;
                        case 'exam_session_not_started':
                            this.$swal({
                                icon: 'error',
                                text: 'Sınav oturumunuz henüz başlamamış!'
                            });
                            break;
                        case 'exam_no_reservation_found_to_cancel':
                            this.$swal({
                                icon: 'error',
                                text: 'Rezervasyon iptali yapılabilecek kayıt bulunamadı!'
                            });
                            break;
                        case 'exam_this_session_started_cannot_cancel_reservation':
                            this.$swal({
                                icon: 'error',
                                text: 'Sınav oturumu başlamış olan rezervasyonlar iptal edilemez!'
                            });
                            break;
                        case 'already_joined_this_exam':
                            this.$swal({
                                icon: 'error',
                                text: 'Bu sınavın farklı bir oturumuna zaten katıldınız!'
                            });
                            break;
                        case 'exam_date_expired':
                            this.$swal({
                                icon: 'error',
                                text: 'Bu sınava katılabileceğiniz tarih henüz gelmemiştir!'
                            });
                            break;
                        case 'exam_invalid_user_session':
                        case 'exam_invalid_user_session_id':
                            EventBus.$emit('exam_user_session_invalid');
                            break;
                        case 'exam_already_started':
                            this.$swal({
                                icon: 'error',
                                text: 'Başlamış bir oturum için rezervasyon oluşturamazsınız!'
                            });
                            break;
                        case 'exam_is_over':
                            EventBus.$emit('exam_session_ended');
                            break;
                        case 'exam_not_active':
                            EventBus.$emit('exam_not_active');
                            break;
                        case 'invalid_exam_id':
                            EventBus.$emit('invalid_exam');
                            break;
                    }
                }

                return Promise.reject(error);
            }
        );

        let apiAuthToken = this.$route.query.apiAuthToken;
        let apiAuthTokenType = this.$route.query.apiAuthTokenType;

        if (apiAuthToken && apiAuthTokenType) {
            localStorage.setItem('auth.token', apiAuthToken);
            localStorage.setItem('auth.tokenType', apiAuthTokenType);
        }

        this.checkUser();
    },
    mounted() {
        this.$store.dispatch("notification/get", {
            filterData: {
                is_seen: 0
            }
        });
    },
    methods: {
        getWebSiteURL() {
            return process.env.VUE_APP_URL;
        },
        getAuthToken() {
            let authToken = localStorage.getItem('auth.token');
            let authTokenType = localStorage.getItem('auth.tokenType');

            if (authToken && authTokenType) {
                return `${authTokenType} ${authToken}`;
            }

            return null;
        },
        checkUser() {
            let token = this.getAuthToken();

            if (!token) {
                this.userLogout();
                return false;
            }

            axios.get('/user/check').then((response) => {
                if (response.data.status) {
                    this.user = response.data.session;
                } else {
                    this.userLogout();
                }
            });
        },
        getUserProfileImage() {
            if (this.user.avatar != null) {
                return (
                    'background-image:url( ' + this.user.avatar.thumb.small + ')'
                );
            } else {
                if (this.user?.gender_id == 2) {
                    return 'background-image:url(' + this.getWebSiteURL() + '/system/icon/avatar-woman.jpeg)';
                }

                return 'background-image:url(' + this.getWebSiteURL() + '/system/icon/avatar-man.jpeg)';
            }
        },
        userLogout() {
            this.unsetAuthToken();
            this.redirectMainWebSite('/user/logout/current?login_redirect=' + window.location.href);
        },
        unsetAuthToken() {
            localStorage.removeItem('auth.token');
            localStorage.removeItem('auth.tokenType');
        },
        redirectMainWebSite(suffix) {
            window.location.href = this.getWebSiteURL() + (suffix ? suffix : '');
        },
        getSecondFormat(second = 0, format = ':d gün :h saat :m dakika :s saniye') {
            second = Number(second);
            let d = Math.floor(second / (3600 * 24));
            let h = format.includes(':d') ? Math.floor(second % (3600 * 24) / 3600) : Math.floor(second / 3600);
            let m = format.includes(':h') ? Math.floor(second % 3600 / 60) : Math.floor(second / 60);
            let s = format.includes(':m') ? Math.floor(second % 60) : second;

            m = format.includes(':m') && ((!format.includes(':s')) && s > 0) ? m + 1 : m;

            return format.replace(':d', d).replace(':h', h).replace(':m', m).replace(':s', s);
        },
        getLanguage(column, value){
            return this.resolveDatum(this.languages, value, column);
        }
    },
    render: (h) => h(App),
}).$mount('#app');

Global.setApp(app)