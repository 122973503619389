<template>
    <div id="app">
        <notifications/>
        <transition name="fade">
            <router-view v-if="showPage"></router-view>
        </transition>
        <Popup></Popup>
    </div>
</template>
<script>
import 'jquery/src/jquery.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import Popup from '@/components/Popup.vue';

export default {
    name: "app",
    components: {
        Popup
    },
    created() {
        this.initialize();
    },
    computed: {
        showPage() {
            return this.$root.defaultLanguage.id;
        }
    },
    methods: {
        initialize() {
            this.$store.dispatch("localization/language/get", {page: {}}).then((languages) => {
                this.$root.languages = languages;
                for (let i = 0; i < languages.length; i++) {
                    if (languages[i].default) {
                        this.$root.defaultLanguage = languages[i];
                        this.afterLoadedDefaultLanguage();
                        break;
                    }
                }
            });

            /* this.$store.dispatch("localization/currency/get", {page: {}}).then((currencies) => {
                 this.$root.currencies = currencies;
                 for(let i = 0; i < currencies.length; i++){
                     if(currencies[i].default) {
                         this.$root.defaultCurrency = currencies[i];
                         break;
                     }
                 }
             });*/
        },
        afterLoadedDefaultLanguage() {
            this.moment.locale(this.$root.defaultLanguage.code);
        }
    }
};
</script>

<style lang="scss">
@import './assets/scss/main.scss';
@import './assets/swal.css';
@import './assets/vue-player.css';
@import './assets/global.css';
</style>
