<template>
    <Layout>
        <div class="row audio-plyr ">
            <div class="col-lg-8 ">
                <div class="shadow-sm">
                    <img src="@/assets/img/ses_kayitlari.jpg" class="w-100" alt=""/>

                    <vue-plyr ref="audioPlyr" :options="options">
                        <audio controls crossorigin playsinline></audio>
                    </vue-plyr>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="panel">
                    <div class="panel_header">
                        <h1 class="h5 my-0 mb-0 ">Ses Kayıtları</h1>
                    </div>
                    <div class="panel_body">
                        <ul class="records-list rounded-sm h-100">
                            <NestedMenu
                                @setAudioSourceData="setAudioSource($event)"
                                v-for="(record, index) of records"
                                :key="'recordd' + index"
                                :node="record"
                                :activeRecordId="activeRecordId"
                            />
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from '../components/Layout.vue';
import NestedMenu from '../components/NestedMenu.vue';

export default {
    components: {
        Layout,
        NestedMenu,
    },
    data() {
        return {
            records: {},
            activeRecordId: '',
            options: {
                controls: [
                    'progress',
                    'play-large',
                    'rewind',
                    'play',
                    'fast-forward',
                    'play',
                    'mute',
                    'volume',
                    'current-time',
                    'settings',
                    'airplay',
                    'download',
                ],

                seekTime: 7,
                tooltips: {controls: true, seek: true},
                speed: {selected: 1, options: [2, 1.5, 1, 0.5]},

                i18n: {
                    rewind: 'Rewind {seektime} secs',
                    fastForward: 'Forward {seektime} secs',
                },
            },
        };
    },
    methods: {
        setAudioSource(data) {
            this.activeRecordId = data.id;
            this.audioPlyr.config.urls.download = data.upload.public_url + '?download=1';
            this.audioPlyr.source = {
                type: 'audio',
                title: 'Example title',
                urls: {
                    download: data.upload.public_url,
                },
                sources: [
                    {
                        src: data.upload.public_url,
                        type: `audio/${data.upload.extension}`,
                    },
                ],
            };
            this.audioPlyr.play();
        },
        fetchAudios() {
            this.axios.get('/module/voice-recording').then((response) => {
                this.activeRecordId = response.data.data[0].recordings[0].id;

                let data = response.data.data;
                this.records = data;

                this.audioPlyr.config.urls.download = data[0].recordings[0].upload.public_url + '?download=1';
                this.audioPlyr.source = {
                    type: 'audio',
                    title: 'Example title',
                    sources: [
                        {
                            src: data[0].recordings[0].upload.public_url,
                            type: `audio/${data[0].recordings[0].upload.extension}`,
                        },
                    ],
                };
            });
        },
    },
    computed: {
        audioPlyr() {
            return this.$refs.audioPlyr.player;
        },
    },

    mounted() {
        // this.audioPlyr.source = {
        //   type: 'audio',
        //   title: 'Example title',
        //   sources: [
        //     {
        //       src: '/path/to/audio.mp3',
        //       type: 'audio/mp3',
        //     },
        //     {
        //       src:
        //         'https://upload.wikimedia.org/wikipedia/commons/b/b4/United_States_Navy_Band_-_O_Canada.ogg',
        //       type: 'audio/ogg',
        //     },
        //   ],
        // };

        this.audioPlyr.on('download', () => {
            this.axios
                .post('/module/voice-recording/' + this.activeRecordId)
                .then((response) => {
                });
        });
    },
    created() {
        this.fetchAudios();
    },
};
</script>
