<template>
  <layout>
      <TreeNode v-for="node in nodes" :key="node.id" :node="node" />
  </layout>
</template>

<script>
import Layout from '../components/Layout.vue';
import TreeNode from '@/components/TreeNode';
import lessonData from '../assets/lesson-data.json';

export default {
  name: 'Home',
  components: {
    TreeNode,
    Layout,
  },
  computed: {
    nodes() {
      return lessonData.data;
    },
  },
};
</script>
