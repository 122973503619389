<template>
  <Layout>
    <div
      class="mb-3 d-flex justify-content-between align-items-center flex-wrap"
    >
      <div>
        <ul
          class="nav nav-pills  tab-menu--primary my-2"
          id="pills-tab"
          role="tablist"
        >
          <li class="nav-item" role="presentation">
            <a
              class="nav-link active"
              id="pills-all-tab"
              data-toggle="pill"
              href="#pills-all"
              role="tab"
              aria-controls="pills-all"
              aria-selected="true"
              >Tümü</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="pills-yds-tab"
              data-toggle="pill"
              href="#pills-yds"
              role="tab"
              aria-controls="pills-yds"
              aria-selected="false"
              >Çözülmemiş</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="pills-yokdil-tab"
              data-toggle="pill"
              href="#pills-yokdil"
              role="tab"
              aria-controls="pills-yokdil"
              aria-selected="false"
              >Çözülen</a
            >
          </li>
        </ul>
      </div>
      <div class="konu-tarama-selects d-flex align-items-center">
        <CustomSelectBox
          :options="['Soru Tipi', 'Bu Hafta ', 'Bu Ay']"
          :default="'Soru Tipi'"
          class="select light flex-grow-1"
        />
        <CustomSelectBox
          :options="['Sınav', 'Bu Hafta ', 'Bu Ay']"
          :default="'Sınav'"
          class="select light flex-grow-1 ml-10"
        />
      </div>
    </div>
    <div class="tab-content" id="pills-tabContent">
      <div
        class="tab-pane fade show active"
        id="pills-all"
        role="tabpanel"
        aria-labelledby="pills-all-tab"
      >
        <QuizCard class="mb-3">
          <template slot="footer">
            <div class="left">
              <details>
                <summary>Deneme Raporu</summary>
                <div class="quiz-result-grid">
                  <div class="">
                    <span class="result-title success">Doğru</span>
                    <span class="result-text"><strong>3</strong>/80</span>
                  </div>
                  <div class="">
                    <span class="result-title unsuccess">Yanlış</span>
                    <span class="result-text"><strong>77</strong>/80</span>
                  </div>
                  <div class="">
                    <span class="result-title empty">Boş</span>
                    <span class="result-text"><strong>0</strong>/80</span>
                  </div>

                  <div class="">
                    <span class="result-title">Deneme Sonucu</span>
                    <strong class="text-gray-dark">3.75 Puan</strong>
                  </div>
                  <div class="">
                    <span class="result-title">Deneme Süresi</span>
                    <strong class="text-gray-dark">15 dk.</strong>
                  </div>
                </div>

                <details class="mb-30">
                  <summary>Açıklamalı Cevap Anahtarı</summary>
                  <ul class="nav tab-menu--primary quiz-tabMenu">
                    <li class="nav-item">
                      <button
                        class="nav-link"
                        @click="changeFilter('')"
                        :class="{ active: filter === '' }"
                      >
                        Tümü({{ questions.length }})
                      </button>
                    </li>
                    <li class="nav-item">
                      <button
                        class="nav-link"
                        @click="changeFilter('success')"
                        :class="{ active: filter === 'success' }"
                      >
                        Doğru
                        <span class="text-green fw-500"
                          >({{ getFilteredQuestionsCount('success') }})</span
                        >
                      </button>
                    </li>
                    <li class="nav-item">
                      <button
                        class="nav-link"
                        @click="changeFilter('unsuccess')"
                        :class="{ active: filter === 'unsuccess' }"
                      >
                        Yanlış
                        <span class="text-red fw-500"
                          >({{ getFilteredQuestionsCount('unsuccess') }})</span
                        >
                      </button>
                    </li>
                    <li class="nav-item">
                      <button
                        class="nav-link"
                        @click="changeFilter('empty')"
                        :class="{ active: filter === 'empty' }"
                      >
                        Boş
                        <span class="text-gray-dark fw-500"
                          >({{ getFilteredQuestionsCount('empty') }})</span
                        >
                      </button>
                    </li>
                  </ul>

                  <vue-scroll class="question-numbers mt-3" :ops="ops">
                    <li
                      class="question-number"
                      v-for="question in filteredQuestions"
                      @click.prevent="setActive(question.questionNumber)"
                      :key="question.questionNumber"
                      :class="{
                        unsuccess: question.status == 'unsuccess',
                        success: question.status == 'success',
                        empty: question.status == 'empty',
                      }"
                    >
                      {{ question.questionNumber }}
                    </li>
                  </vue-scroll>
                  <hr class="my-3" />

                  <div
                    class="question-box"
                    v-for="question in questions"
                    :key="question.questionNumber"
                    :class="{ show: isActive(question.questionNumber) }"
                  >
                    <div class="question-detail-card mb-3">
                      <div
                        class="detail-card__header d-flex align-items-center justify-content-between mb-3"
                      >
                        <span
                          class="question-number success"
                          :class="question.status"
                          >{{ question.questionNumber }}</span
                        >
                        <div class="question-category">
                          Bu bir
                          <span class="text-primary fw-500">Other Grammar</span>
                          sorusudur.
                        </div>
                      </div>

                      <div>
                        <p>
                          {{ question.title }}
                        </p>
                        <div class="question-explain-card mb-3">
                          <p class="mb-0">
                            iki cümlenin ortasında "but" kullanıldığı için
                            cümleler arasında zıtlık olması gerekir.
                            "Araştırmalar potansiyel değişiklikler aradı, ama
                            ... önemli bir etkisi olmak" ama'dan sonra olumsuz
                            bir anlam olması gerekir. Bunu sadece D seçeneği
                            karşılıyor. neither, iki şeyden hiç biri demektir.
                            Bu cümlede söz konusu iki şey "folic acid ve iron"
                            ifadeleridir.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="question-options-card">
                      <ul class="question-options">
                        <li class="option">
                          <span class="left">a</span>
                          <span class="center">little</span>
                          <span class="right">
                            <svg
                              width="21"
                              height="21"
                              viewBox="0 0 21 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="1"
                                y="1"
                                width="19"
                                height="19"
                                rx="9.5"
                                stroke="#3271E6"
                                stroke-width="2"
                              />
                              <rect
                                x="9"
                                y="8.28125"
                                width="2.625"
                                height="7.54688"
                                rx="1.3125"
                                fill="#3271E6"
                              />
                              <rect
                                x="9"
                                y="5"
                                width="2.625"
                                height="2.29688"
                                rx="1.14844"
                                fill="#3271E6"
                              />
                            </svg>
                          </span>
                        </li>
                        <li class="option success">
                          <span class="left">b</span>
                          <span class="center">each</span>
                          <span class="right">
                            <svg
                              width="21"
                              height="21"
                              viewBox="0 0 21 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="1"
                                y="1"
                                width="19"
                                height="19"
                                rx="9.5"
                                stroke="#3271E6"
                                stroke-width="2"
                              />
                              <rect
                                x="9"
                                y="8.28125"
                                width="2.625"
                                height="7.54688"
                                rx="1.3125"
                                fill="#3271E6"
                              />
                              <rect
                                x="9"
                                y="5"
                                width="2.625"
                                height="2.29688"
                                rx="1.14844"
                                fill="#3271E6"
                              />
                            </svg>
                          </span>
                        </li>
                        <li class="option">
                          <span class="left">c</span>
                          <span class="center">other</span>
                        </li>
                        <li class="option unsuccess">
                          <span class="left">d</span>
                          <span class="center">neither</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </details>
                <details class="">
                  <summary>Soru Tipi Analizi</summary>
                  <div class="question-type mt-3">
                    <div class="question-type-item first fw-700">SORU TİPİ</div>
                    <div class="question-type-item fw-700">HARCADIĞIM SÜRE</div>
                    <div class="question-type-item fw-700">SORU SAYISI</div>
                    <div class="question-type-item text-green fw-700">
                      DOĞRU SAYISI
                    </div>
                    <div class="question-type-item text-red fw-700">
                      YANLIŞ SAYISI
                    </div>
                    <div class="question-type-item last text-gray-soft fw-700">
                      BOŞ SAYISI
                    </div>

                    <div class="question-type-item first fw-500">
                      Other Grammer
                    </div>
                    <div class="question-type-item fw-500 text-green">0.00</div>
                    <div class="question-type-item fw-500">2</div>
                    <div class="question-type-item fw-500">0</div>
                    <div class="question-type-item fw-500">1</div>
                    <div class="question-type-item fw-500 last">1</div>

                    <div class="question-type-item first fw-500">
                      Preposition
                    </div>
                    <div class="question-type-item fw-500 text-green">0.00</div>
                    <div class="question-type-item fw-500 success">
                      2<small class="d-block">En iyi olduğun soru tipi</small>
                    </div>
                    <div class="question-type-item fw-500">0</div>
                    <div class="question-type-item fw-500">1</div>
                    <div class="question-type-item fw-500 last">1</div>

                    <div class="question-type-item first fw-500">
                      Other Grammer
                    </div>
                    <div class="question-type-item fw-500 text-green">0.00</div>
                    <div class="question-type-item fw-500">2</div>
                    <div class="question-type-item fw-500">0</div>
                    <div class="question-type-item fw-500">1</div>
                    <div class="question-type-item fw-500 last">1</div>

                    <div class="question-type-item first fw-500">Connector</div>
                    <div class="question-type-item fw-500 text-green">0.00</div>
                    <div class="question-type-item fw-500 unsuccess">
                      2<small class="d-block"
                        >Daha fazla pratik yapmalısın</small
                      >
                    </div>
                    <div class="question-type-item fw-500">0</div>
                    <div class="question-type-item fw-500">1</div>
                    <div class="question-type-item fw-500 last">1</div>
                  </div>
                </details>
              </details>
            </div>
          </template>
          <template slot="headerRight">
            <div class="fw-500">
              <span><span class="text-muted mr-10">Soru Sayısı:</span>80</span>
              <span class="ml-20"
                ><span class="text-muted mr-10">Süre:</span>120 dk</span
              >
            </div>
          </template>
          <template slot="quizCardRight">
            <span class="fw-600 text-gray-dark"
              >Deneme Puanı: <span class="text-primary">88</span> PUAN</span
            >
          </template>
        </QuizCard>

        <QuizCard class="mb-3">
          <template slot="headerRight">
            <div class="fw-500">
              <span><span class="text-muted mr-10">Soru Sayısı:</span>80</span>
              <span class="ml-20"
                ><span class="text-muted mr-10">Süre:</span>120 dk</span
              >
            </div>
          </template>
          <template slot="footer">
            <div class="left">
              <details class="mb-30">
                <summary>Deneme Raporu</summary>
                <div class="quiz-result-grid">
                  <div class="">
                    <span class="result-title success">Doğru</span>
                    <span class="result-text"><strong>3</strong>/80</span>
                  </div>
                  <div class="">
                    <span class="result-title unsuccess">Yanlış</span>
                    <span class="result-text"><strong>77</strong>/80</span>
                  </div>
                  <div class="">
                    <span class="result-title empty">Boş</span>
                    <span class="result-text"><strong>0</strong>/80</span>
                  </div>

                  <div class="">
                    <span class="result-title">Deneme Sonucu</span>
                    <strong class="text-gray-dark">3.75 Puan</strong>
                  </div>
                  <div class="">
                    <span class="result-title">Deneme Süresi</span>
                    <strong class="text-gray-dark">15 dk.</strong>
                  </div>
                </div>
              </details>
              <details class="mb-30">
                <summary>Açıklamalı Cevap Anahtarı</summary>
                <ul class="nav tab-menu--primary quiz-tabMenu">
                  <li class="nav-item">
                    <button
                      class="nav-link"
                      @click="changeFilter('')"
                      :class="{ active: filter === '' }"
                    >
                      Tümü({{ questions.length }})
                    </button>
                  </li>
                  <li class="nav-item">
                    <button
                      class="nav-link"
                      @click="changeFilter('success')"
                      :class="{ active: filter === 'success' }"
                    >
                      Doğru
                      <span class="text-green fw-500"
                        >({{ getFilteredQuestionsCount('success') }})</span
                      >
                    </button>
                  </li>
                  <li class="nav-item">
                    <button
                      class="nav-link"
                      @click="changeFilter('unsuccess')"
                      :class="{ active: filter === 'unsuccess' }"
                    >
                      Yanlış
                      <span class="text-red fw-500"
                        >({{ getFilteredQuestionsCount('unsuccess') }})</span
                      >
                    </button>
                  </li>
                  <li class="nav-item">
                    <button
                      class="nav-link"
                      @click="changeFilter('empty')"
                      :class="{ active: filter === 'empty' }"
                    >
                      Boş
                      <span class="text-gray-dark fw-500"
                        >({{ getFilteredQuestionsCount('empty') }})</span
                      >
                    </button>
                  </li>
                </ul>

                <vue-scroll class="question-numbers mt-3" :ops="ops">
                  <li
                    class="question-number"
                    v-for="question in filteredQuestions"
                    @click.prevent="setActive(question.questionNumber)"
                    :key="question.questionNumber"
                    :class="{
                      unsuccess: question.status == 'unsuccess',
                      success: question.status == 'success',
                      empty: question.status == 'empty',
                    }"
                  >
                    {{ question.questionNumber }}
                  </li>
                </vue-scroll>
                <hr class="my-3" />

                <div
                  class="question-box"
                  v-for="question in questions"
                  :key="question.questionNumber"
                  :class="{ show: isActive(question.questionNumber) }"
                >
                  <div class="question-detail-card mb-3">
                    <div
                      class="detail-card__header d-flex align-items-center justify-content-between mb-3"
                    >
                      <span
                        class="question-number success"
                        :class="question.status"
                        >{{ question.questionNumber }}</span
                      >
                      <div class="question-category">
                        Bu bir
                        <span class="text-primary fw-500">Other Grammar</span>
                        sorusudur.
                      </div>
                    </div>

                    <div>
                      <p>
                        {{ question.title }}
                      </p>
                      <div class="question-explain-card mb-3">
                        <p class="mb-0">
                          iki cümlenin ortasında "but" kullanıldığı için
                          cümleler arasında zıtlık olması gerekir. "Araştırmalar
                          potansiyel değişiklikler aradı, ama ... önemli bir
                          etkisi olmak" ama'dan sonra olumsuz bir anlam olması
                          gerekir. Bunu sadece D seçeneği karşılıyor. neither,
                          iki şeyden hiç biri demektir. Bu cümlede söz konusu
                          iki şey "folic acid ve iron" ifadeleridir.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="question-options-card">
                    <ul class="question-options">
                      <li class="option">
                        <span class="left">a</span>
                        <span class="center">little</span>
                        <span class="right">
                          <svg
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="1"
                              y="1"
                              width="19"
                              height="19"
                              rx="9.5"
                              stroke="#3271E6"
                              stroke-width="2"
                            />
                            <rect
                              x="9"
                              y="8.28125"
                              width="2.625"
                              height="7.54688"
                              rx="1.3125"
                              fill="#3271E6"
                            />
                            <rect
                              x="9"
                              y="5"
                              width="2.625"
                              height="2.29688"
                              rx="1.14844"
                              fill="#3271E6"
                            />
                          </svg>
                        </span>
                      </li>
                      <li class="option success">
                        <span class="left">b</span>
                        <span class="center">each</span>
                        <span class="right">
                          <svg
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="1"
                              y="1"
                              width="19"
                              height="19"
                              rx="9.5"
                              stroke="#3271E6"
                              stroke-width="2"
                            />
                            <rect
                              x="9"
                              y="8.28125"
                              width="2.625"
                              height="7.54688"
                              rx="1.3125"
                              fill="#3271E6"
                            />
                            <rect
                              x="9"
                              y="5"
                              width="2.625"
                              height="2.29688"
                              rx="1.14844"
                              fill="#3271E6"
                            />
                          </svg>
                        </span>
                      </li>
                      <li class="option">
                        <span class="left">c</span>
                        <span class="center">other</span>
                      </li>
                      <li class="option unsuccess">
                        <span class="left">d</span>
                        <span class="center">neither</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </details>
              <details class="">
                <summary>Soru Tipi Analizi</summary>
                <div class="question-type mt-3">
                  <div class="question-type-item first fw-700">SORU TİPİ</div>
                  <div class="question-type-item fw-700">HARCADIĞIM SÜRE</div>
                  <div class="question-type-item fw-700">SORU SAYISI</div>
                  <div class="question-type-item text-green fw-700">
                    DOĞRU SAYISI
                  </div>
                  <div class="question-type-item text-red fw-700">
                    YANLIŞ SAYISI
                  </div>
                  <div class="question-type-item last text-gray-soft fw-700">
                    BOŞ SAYISI
                  </div>

                  <div class="question-type-item first fw-500">
                    Other Grammer
                  </div>
                  <div class="question-type-item fw-500 text-green">0.00</div>
                  <div class="question-type-item fw-500">2</div>
                  <div class="question-type-item fw-500">0</div>
                  <div class="question-type-item fw-500">1</div>
                  <div class="question-type-item fw-500 last">1</div>

                  <div class="question-type-item first fw-500">Preposition</div>
                  <div class="question-type-item fw-500 text-green">0.00</div>
                  <div class="question-type-item fw-500 success">
                    2<small class="d-block">En iyi olduğun soru tipi</small>
                  </div>
                  <div class="question-type-item fw-500">0</div>
                  <div class="question-type-item fw-500">1</div>
                  <div class="question-type-item fw-500 last">1</div>

                  <div class="question-type-item first fw-500">
                    Other Grammer
                  </div>
                  <div class="question-type-item fw-500 text-green">0.00</div>
                  <div class="question-type-item fw-500">2</div>
                  <div class="question-type-item fw-500">0</div>
                  <div class="question-type-item fw-500">1</div>
                  <div class="question-type-item fw-500 last">1</div>

                  <div class="question-type-item first fw-500">Connector</div>
                  <div class="question-type-item fw-500 text-green">0.00</div>
                  <div class="question-type-item fw-500 unsuccess">
                    2<small class="d-block">Daha fazla pratik yapmalısın</small>
                  </div>
                  <div class="question-type-item fw-500">0</div>
                  <div class="question-type-item fw-500">1</div>
                  <div class="question-type-item fw-500 last">1</div>
                </div>
              </details>
            </div>
          </template>
          <template slot="quizCardRight">
            <span class="fw-600 text-gray-dark"
              >Deneme Puanı: <span class="text-primary">88</span> PUAN</span
            >
          </template>
        </QuizCard>
        <QuizCard>
          <template slot="headerRight">
            <div class="fw-500">
              <span><span class="text-muted mr-10">Soru Sayısı:</span>80</span>
              <span class="ml-20"
                ><span class="text-muted mr-10">Süre:</span>120 dk</span
              >
            </div>
          </template>
          <template slot="quizCardRight">
            <router-link
              to="/sinav-index"
              class="btn btn-white btn-with-ico shadow-sm header-btn w-100"
            >
              <span class="ico">
                <i class="icon-penline"></i>
              </span>
              <span class="text">Testi Çöz</span>
            </router-link>
          </template>
        </QuizCard>
      </div>
      <div
        class="tab-pane fade"
        id="pills-yds"
        role="tabpanel"
        aria-labelledby="pills-yds-tab"
      >
        Et non id duis id non dolor ut cillum elit fugiat occaecat.
      </div>
      <div
        class="tab-pane fade"
        id="pills-yokdil"
        role="tabpanel"
        aria-labelledby="pills-yokdil-tab"
      >
        Anim tempor cupidatat irure duis ut Lorem.
      </div>
    </div>
  </Layout>
</template>

<script>
import CustomSelectBox from '../components/CustomSelectBox.vue';
import Layout from '../components/Layout.vue';
import QuizCard from '../components/QuizCard.vue';
//import VueHorizontal from 'vue-horizontal';

export default {
  components: { QuizCard, Layout, CustomSelectBox },
  data() {
    return {
      activeQuestion: '',

      filter: '',
      questions: [
        {
          title:
            'Research looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'empty',
          questionNumber: 1,
        },
        {
          title:
            'Research looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'unsuccess',
          questionNumber: 2,
        },
        {
          title:
            'Qesearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'success',
          questionNumber: 3,
        },
        {
          title:
            'Wesearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'unsuccess',
          questionNumber: 4,
        },
        {
          title:
            'Esearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'success',
          questionNumber: 5,
        },
        {
          title:
            'Tesearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'empty',
          questionNumber: 6,
        },
        {
          title:
            'Yesearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'success',
          questionNumber: 7,
        },
        {
          title:
            'Wesearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'unsuccess',
          questionNumber: 8,
        },
        {
          title:
            'Esearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'empty',
          questionNumber: 9,
        },
        {
          title:
            'Tesearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'empty',
          questionNumber: 10,
        },
        {
          title:
            'Yesearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'success',
          questionNumber: 11,
        },
        {
          title:
            'Esearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'empty',
          questionNumber: 12,
        },
        {
          title:
            'Tesearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'empty',
          questionNumber: 13,
        },
        {
          title:
            'Wesearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'unsuccess',
          questionNumber: 14,
        },
        {
          title:
            'Esearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'empty',
          questionNumber: 15,
        },
        {
          title:
            'Tesearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'empty',
          questionNumber: 16,
        },
        {
          title:
            'Yesearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'success',
          questionNumber: 17,
        },
        {
          title:
            'Esearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'empty',
          questionNumber: 18,
        },
        {
          title:
            'Esearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'empty',
          questionNumber: 19,
        },
        {
          title:
            'Tesearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'empty',
          questionNumber: 20,
        },
        {
          title:
            'Yesearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'success',
          questionNumber: 21,
        },
        {
          title:
            'Yesearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'success',
          questionNumber: 22,
        },
        {
          title:
            'Esearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'empty',
          questionNumber: 23,
        },
        {
          title:
            'Tesearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'empty',
          questionNumber: 24,
        },
        {
          title:
            'Wesearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'unsuccess',
          questionNumber: 25,
        },
        {
          title:
            'Esearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'empty',
          questionNumber: 26,
        },
        {
          title:
            'Tesearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'empty',
          questionNumber: 27,
        },
        {
          title:
            'Tesearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'empty',
          questionNumber: 28,
        },
        {
          title:
            'Yesearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'success',
          questionNumber: 29,
        },
        {
          title:
            'Esearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'empty',
          questionNumber: 30,
        },
        {
          title:
            'Esearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'empty',
          questionNumber: 31,
        },
        {
          title:
            'Tesearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'empty',
          questionNumber: 32,
        },
        {
          title:
            'Yesearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'success',
          questionNumber: 33,
        },
        {
          title:
            'Yesearch looked for potential changes in autism risk related to taking supplemental folic acid and iron, but neither appeared to have a significant effect on a child’s autism development - positive or negative.',
          status: 'success',
          questionNumber: 34,
        },
      ],

      ops: {
        rail: {
          enable: false,
        },
        bar: {
          enable: false,
        },
        scrollButton: {
          enable: false,
        },
        scrollPanel: {
          easing: 'easeInQuad',
          speed: 800,
        },
        vuescroll: {
          wheelScrollDuration: 0,
          wheelDirectionReverse: true,
        },
      },
    };
  },
  computed: {
    filteredQuestions() {
      if (!this.filter) {
        return this.questions;
      }
      return this.questions.filter(
        (question) => question.status == this.filter
      );
    },
  },
  methods: {
    changeFilter(filter) {
      this.filter = filter;
    },
    handleWheel() {
      window.addEventListener('wheel', function(event) {
        this.scrollLeft += event.deltaY;
      });
    },
    getFilteredQuestionsCount(filter) {
      return this.questions.filter((question) => question.status == filter)
        .length;
    },
    isActive(menuItem) {
      return this.activeQuestion === menuItem;
    },
    setActive(menuItem) {
      this.activeQuestion = menuItem;
    },
  },
};
</script>

<style></style>
