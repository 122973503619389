var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutUser',[_c('div',{staticClass:"w-100"},[_c('p',{staticClass:"h5 mb-5"},[_vm._v(" Siparişler ")]),_c('p',{staticClass:"text-muted mb-20"},[_vm._v(" Toplam"),_c('span',{staticClass:"text-gray-dark fw-bold"},[_vm._v(" "+_vm._s(_vm.order.pagination.total))]),_vm._v(" sipariş bulundu ")]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.order.data,"sort-options":{enabled: false}},on:{"on-row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'status')?_c('span',{staticClass:"badge d-block py-10 h-100",class:props.row.status.id == 1
            ? 'badge-warning'
            : props.row.status.id == 2
            ? 'badge-warning'
            : props.row.status.id == 3
            ? 'badge-info'
            : props.row.status.id == 4
            ? 'badge-success'
            : props.row.status.id == 5
            ? 'badge-success'
            : props.row.status.id == 6
            ? 'badge-danger'
            : props.row.status.id == 7
            ? 'badge-danger'
            : ''},[_vm._v(_vm._s(props.row.status.name))]):(props.column.field == 'id')?_c('span',[_vm._v(_vm._s('#' + props.row.id))]):(props.column.field == 'total')?_c('span',[_vm._v(_vm._s(props.row.total + ' ₺'))]):(props.column.field == 'items')?_c('span',[_c('span',{staticClass:"d-block mb-10"},[_vm._v(" "+_vm._s(props.row.items[0].title + '...')+" ")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),_c('div',{staticClass:"py-30 mt-40 d-flex justify-content-center"},[_c('Pagination',{attrs:{"current-page":_vm.order.pagination.current,"page-size":_vm.order.pagination.pageSize,"total":_vm.order.pagination.total},on:{"current-change":_vm.currentPageChange}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }