<template>
    <Layout>
        <div class="d-flex justify-content-center">
            <nav class="breadcrumb-nav" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a href="/">
                            <i class="icon-home"></i>
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">{{ $t('module.full_paragraph_question.title')}}</li>
                </ol>
            </nav>
        </div>

        <div class="container">
            <div class="page-info mb-30">
                <h1 class="page-info__title" v-html="$t('module.full_paragraph_question.htmlTitle')"></h1>
            </div>

            <div>
                <div class="general-box filter-box pl-3 pr-2 py-2 mb-10" v-if="sourceCategories.length">
                    <div class="filter-box__left">
                        <!--<div class="filter-info">
                            <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.5">
                                    <path d="M15.5 0H0.863636C0.363636 0 0 0.363636 0 0.863636C0 1.31818 0.363636 1.72727 0.863636 1.72727H15.5C15.9545 1.72727 16.3636 1.36364 16.3636 0.863636C16.3636 0.363636 15.9545 0 15.5 0Z" fill="#717B96"/>
                                    <path d="M10.3182 5.90918H0.863636C0.409091 5.90918 0 6.27282 0 6.77282C0 7.27282 0.363636 7.63645 0.863636 7.63645H10.3182C10.7727 7.63645 11.1818 7.27282 11.1818 6.77282C11.1818 6.27282 10.8182 5.90918 10.3182 5.90918Z" fill="#717B96"/>
                                    <path d="M5.13636 12.2729H0.863636C0.409091 12.2729 0 12.6366 0 13.1366C0 13.5911 0.363636 14.0002 0.863636 14.0002H5.18182C5.63636 14.0002 6.04545 13.6366 6.04545 13.1366C6 12.6366 5.63636 12.2729 5.13636 12.2729Z" fill="#717B96"/>
                                </g>
                            </svg>

                            <span class="text-gray-soft ml-10">
                                <span class="text-gray-dark fw-bold">5 içerik</span> listeleniyor
                            </span>
                        </div>-->
                    </div>
                    <div class="filter-box__center">
                        <ul class="nav nav-pills tab-menu--primary">
                            <li class="nav-item">
                                <a class="nav-link cursor-pointer" @click="changedTab(undefined)" :class="categoryID == undefined && 'active'">Tümü</a>
                            </li>
                            <li class="nav-item" v-for="(category, categoryIndex) in sourceCategories" :key="categoryIndex">
                                <a class="nav-link cursor-pointer" @click="changedTab(category.id)" :class="categoryID == category.id && 'active'">{{ category.translate.title }}</a>
                            </li>
                        </ul>
                    </div>
                    <!--<div class="filter-box__right">
                        <CustomSelectBox
                            :options="['Tüm İçerikler', 'Bu Hafta ', 'Bu Ay']"
                            :default="'Tüm İçerikler'"
                            class="select light"
                        />
                        <select class="form-select select2-basic bg-light border-0" name="numberprefix" required="" data-parsley-errors-container="#numberPrefixError">
                            <option value="">Gelişmiş sıralama</option>
                        </select>
                    </div>-->
                </div>
                <div class="row">
                    <div class="col-md-4 mb-10" v-for="(category, categoryIndex) in sourceItems" :key="categoryIndex">
                        <div class="panel">
                            <div class="panel__header">
                                <h2 class="fs-18 fw-bold mb-0">{{ category.translate.title }}</h2>
                            </div>

                            <div class="panel__body">
                                <TreeSource :category="category"></TreeSource>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="py-30 mt-40 d-flex justify-content-center" v-if="sourceCategories">
                <Pagination
                    :current-page="sourcePagination.current"
                    @current-change="currentPageChange"
                    :page-size="sourcePagination.pageSize"
                    :total="sourcePagination.total">
                </Pagination>
            </div>
        </div>
    </Layout>
</template>

<script>
import TreeSource from '@/components/full-paragraph-question/TreeSource.vue';
import Layout from '@/components/Layout';
import Pagination from '@/components/pagination/index.vue';

export default {
    name: "index",
    components: {
        Layout,
        TreeSource,
        Pagination
    },
    data() {
        return {
            categoryID: undefined,
        }
    },
    computed: {
        sourceCategories() {
            return this.$store.state.module.source.category.table.data;
        },
        sources() {
            return this.$store.state.module.source.table;
        },
        sourceItems(){
            return this.sources.data;
        },
        sourcePagination() {
            return this.sources.pagination;
        }
    },
    mounted() {
        this.$store.dispatch('module/source/category/get', {
            page: {},
            filterData: {
                group_alias: 'CLIENT_FULLETEN'
            }
        }).then(() => {
            this.loadSources();
        });
    },
    methods: {
        loadSources(pagination = {}) {
            this.$store.dispatch('module/source/get', {
                page: pagination,
                filterData: {
                    category_id: this.categoryID,
                    group_alias: 'CLIENT_FULLETEN'
                }
            });
        },
        changedTab(id){
            this.categoryID = id;
            this.loadSources();
        },
        currentPageChange(pagination){
            this.loadSources(pagination);
        }
    }
}
</script>

<style>
.page-info {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.panel {
    -webkit-box-shadow: 0 4px 8px rgb(48 55 74 / 4%), 0 0 2px rgb(48 55 74 / 6%), 0 0 1px rgb(48 55 74 / 4%);
    box-shadow: 0 4px 8px rgb(48 55 74 / 4%), 0 0 2px rgb(48 55 74 / 6%), 0 0 1px rgb(48 55 74 / 4%);
    border-radius: 16px;
    background-color: #fff;
}

.panel__header {
    padding: 1.2rem 1.5rem;
    color: #3c4858;
    font-weight: 500;
    background-color: rgba(113,123,150,.03);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.panel__body {
    padding: 1.5rem 1rem;
    color: #717b96;
    border: 1px solid rgba(113,123,150,.05);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.feature-card {
    padding: 0.9rem 0;
}

.feature-icon {
    width: 38px;
    min-width: 38px;
    height: 38px;
    background-color: rgba(113,123,150,.06);
    color: rgba(113,123,150,.6);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bg-light {
    background-color: rgba(113,123,150,.05)!important;
}

.group-details__box {
    padding: 1.4rem;
    background-color: rgba(113,123,150,.05);
    border-radius: 16px;
    margin-bottom: 3rem;
}
.accordion-item, .accordion-button {
    background-color: transparent;
    border-radius: 16px;
    border: none;
}
.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 1rem;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
    color: #717b96;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0.7rem 1rem;
}
.accordion-button::after {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    content: '';
    background-color: rgba(113,123,150,.06);
    background-image: url(../../../../src/assets/icons/plus.svg);
    background-repeat: no-repeat;
    background-size: 0.8rem;
    background-position: center;
    -webkit-transition: all ease .2s;
    transition: all ease .2s;
    padding: 7px;
    border-radius: 10px;
    margin-left: auto;
}

.accordion-item, .accordion-button {
    background-color: transparent;
    border-radius: 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
}
.accordion-header button {
    font-weight: 500;
    font-size: 14px;
}

.accordion-button:not(.collapsed) {
    background-color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #bd1a20;
}
.accordion-button:not(.collapsed)::after {
    background-color: #bd1a20;
    background-image: url(../../../../src/assets/icons/minus.svg);
    transform: rotate(-180deg);
}

.accordion-body {
    background-color: #fff;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    font-size: 14px;
    color: #717b96;
    padding: 0.7rem 1rem;
}
</style>