<template>
    <div class="video-details" style="position: sticky;
    top: 70px;
    z-index: 10;">
        <div class="video-details__frame">
            <vue-plyr ref="plyr" :options="options" v-if="renderAgain && plyrProcessed && lecture.streams && lecture.streams[0] && lecture.streams[0].stream_data.api === 'VISTREAM'">
                <video controls playsinline :src="plyrStreamData.stream.url" v-if="plyrStreamData.stream.type === 'DIRECT'">
                    <!--<source size="720" :src="plyrStreamData.stream.url" type="video/mp4"/>
                    <source size="480" :src="plyrStreamData.stream.url" type="video/mp4"/>-->
                </video>
                <video controls playsinline :src="plyrStreamData.stream.url" v-else-if="plyrStreamData.stream.type === 'HLS'">
                    <source size="720" :src="plyrStreamData.stream.url" type="application/x-mpegURL"/>
                </video>
            </vue-plyr>
            <!-- <VideoPlayer
              :options="videoPlayerDetails"
              v-if="
                videoPlayerDetails.loaded &&
                  lecture.streams &&
                  lecture.streams[0] &&
                  lecture.streams[0].stream_data.api === 'VISTREAM'
              "
            ></VideoPlayer> -->
            <div class="aspect-ratio-container" v-else>
                <iframe class="aspect-ratio-item" :src="streamUrl" :key="'video_iframe' + stream.stream_data.video_id" width="100%" height="500" frameborder="0" allow="autoplay; fullscreen" allowfullscreen id="videoIframe" v-if="streamUrl && stream.stream_data && stream.stream_data.api === 'VIMEO'">
                </iframe>
            </div>
        </div>

        <div class="video-details__header">
            <div class="feature-grid">
                <div class="feature-card">
                    <span class="ico">
                        <i class="icon-user"></i>
                    </span>

                    <span class="fw-600">
                        <small class="d-block">Öğretmen</small>
                        {{ stream.teacher && stream.teacher.full_name }}
                    </span>
                </div>

                <div class="feature-card">
                    <span class="ico">
                        <i class="icon-calendar"></i>
                    </span>

                    <span class="fw-600">
                        <small class="d-block">Ders Tarihi</small>
                        {{ stream.stream_date ? moment(stream.stream_date, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY') : '-'}}
                    </span>
                </div>

                <div class="feature-card">
                    <span class="ico">
                        <i class="icon-time"></i>
                    </span>

                    <span class="fw-600">
                        <small class="d-block">Ders Süresi</small>
                        {{stream.stream_data && stream.stream_data.duration ? moment.utc(stream.stream_data.duration * 1000).format('HH [saat] mm [dk]') : '-'}}
                    </span>
                </div>
            </div>
        </div>
        <div class="video-details__footer">
            <!-- <details class="mb-3" >
                    <summary>Ders Hakkında</summary>
                    <p v-html="videoDetails.translate && videoDetails.translate.content"></p>
                  </details> -->
            <details>
                <summary>
                    Ödev Klasörü (Derse Özel)
                    <span class="badge badge-primary p-1 count-tabela ml-5">{{videoDetails.documents_count}}</span>
                </summary>
                <div class="row mt-3" v-if="videoDetails.documents">
                    <div class="col-md-4 mb-3" v-for="document in videoDetails.documents" :key="document.id">
                        <div class="video-source">
                            <div class="left fw-500">{{ document.name }}</div>
                            <div class="right">
                                <a target="__blank" :href="document.upload.public_url" class="btn btn-primary px-3">
                                    <i class="icon-download"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pt-10 pb-20" v-if="videoDetails.documents_count < 1">
                    <img width="220" src="@/assets/img/not-found-note.svg" alt=""/>
                </div>
            </details>
        </div>
    </div>
</template>
<script>
import EventBus from '../event-bus';
import Hls from 'hls.js';

export default {
    props: {
        videoDetails: Object,
        videoPlayerDetails: Object,
        lecture: Object,
        plyrStreamData: Object,
        plyrStreamDataLoaded: {default: false, type: Boolean},
    },
    data() {
        return {
            stream: this.lecture.streams[0],
            streamUrl: null,
            player: null,
            renderAgain: this.plyrStreamDataLoaded,
            plyrProcessed: false,
            plyrSelector: '',
            options: {
                autoplay: true,
                clickToPlay: true,
                controls: [
                    'progress',
                    'play-large',
                    'rewind',
                    'play',
                    'fast-forward',
                    'play',
                    'mute',
                    'volume',
                    'current-time',
                    'settings',
                    'pip',
                    'airplay',
                    'fullscreen',
                ],

                settings: ['quality', 'speed', 'loop'],
                seekTime: 7,
                tooltips: {controls: true, seek: true},
                speed: {selected: 1, options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]},

                i18n: {
                    rewind: 'Rewind {seektime} secs',
                    fastForward: 'Forward {seektime} secs',
                },
            },
            vimeoStatisticsInterval: null,
            syncDuration: null,
            videoCustomer: {
                interval: null,
                position: ['top-left', 'bottom-left', 'bottom-right', 'top-right', 'center'],
                counter: 0,
            },
            hlsInstance: {}
        };
    },
    computed: {
        plyr() {
            return this.$refs.plyr.player;
        },
    },
    created() {
        let scripts = ['//player.vimeo.com/api/player.js'];
        scripts.forEach((script) => {
            let tag = document.createElement('script');
            tag.setAttribute('src', script);
            document.body.appendChild(tag);
        });
    },
    mounted() {
        if (this.plyrStreamData.stream.type === 'HLS' && Hls.isSupported()) {
            const hls = new Hls();
            hls.loadSource(this.plyrStreamData.stream.url);

            hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
                const availableQualities = hls.levels.map((l) => l.height);

                this.options.quality = {
                    options: availableQualities,
                    // this ensures Plyr to use Hls to update quality level
                    forced: true,
                    onChange: (e) => this.updateQuality(e),
                };

                this.plyrProcessed = true;

                setTimeout(() => {
                    hls.attachMedia(this.plyr.media);
                    this.hlsInstance.hls = hls;
                });
            });
        } else {
            this.plyrProcessed = true;
        }

        setTimeout(() => {
            this.plyrSelector = this.plyr;
            this.plyrSelector.on('loadeddata', () => {
                this.setPlyrCurrentTime(((this.stream.user_watching_statistic && this.stream.user_watching_statistic.watching_complete != true) ? this.stream.user_watching_statistic.watching_duration : 0));
            });

            this.syncVideoDuration();

            if(this.plyrSelector.playing) {
                this.syncDuration = setInterval(() => {
                   this.syncDurationRequest(Math.floor(this.getPlyrCurrentTime()));
                }, 25000);
            }

            this.plyrSelector.on('ended', () => {
                this.syncDurationRequest(-1).then(() =>{
                    EventBus.$emit('video_ended', this.stream);
                });
            });

            this.showCustomerInfo();

            /*
            $(window).blur(() => {
                this.plyr.pause();
            });
            */
        }, 200);

    },
    methods: {
        videoWatcher() {
            if (typeof Vimeo === 'undefined') {
                setTimeout(() => {
                    this.videoWatcher();
                }, 500);
                return false;
            }

            let videoIframe = document.getElementById('videoIframe');
            if (!videoIframe) {
                setTimeout(() => {
                    this.videoWatcher();
                }, 500);
                return false;
            }

            if (!(this.stream.stream_type === 'RECORD' && this.stream.stream_data && this.stream.stream_data.api === 'VIMEO')) {
                return false;
            }

            let player = new Vimeo.Player(videoIframe);
            if (this.stream && this.stream.user_watching_statistic && this.stream.user_watching_statistic.watching_complete != true && this.stream.user_watching_statistic.watching_duration) {
                player.setCurrentTime(
                    this.stream.user_watching_statistic.watching_duration,
                );
            }

            player.on('play', () => {
                if (this.vimeoStatisticsInterval) {
                    clearInterval(this.vimeoStatisticsInterval);
                }
                this.vimeoStatisticsInterval = setInterval(() => {
                    player.getCurrentTime().then((second) => {
                        this.syncDurationRequest(Math.floor(second));
                    })
                }, 25000); //30 * 1000
            });

            player.on('pause', () => {
                if (this.vimeoStatisticsInterval) {
                    clearInterval(this.vimeoStatisticsInterval);
                }
            });

            player.on('ended', (data) => {
                this.syncDurationRequest(Math.ceil(data.duration)).then(() =>{
                    EventBus.$emit('video_ended', this.stream);
                });
            });
        },
        updateQuality(newQuality) {
            if (!(this.hlsInstance.hls && this.hlsInstance.hls.levels)) {
                return false;
            }

            this.hlsInstance.hls.levels.forEach((level, levelIndex) => {
                if (level.height === newQuality) {
                    this.hlsInstance.hls.currentLevel = levelIndex;
                }
            });
        },

        getPlyrCurrentTime() {
            return this.plyrSelector.currentTime;
        },

        setPlyrCurrentTime(time) {
            this.plyrSelector.currentTime = time;
        },
        syncVideoDuration() {
            this.plyrSelector.on('play', () => {
                if (this.syncDuration) {
                    clearInterval(this.syncDuration);
                }
                this.syncDuration = setInterval(() => {
                    this.syncDurationRequest(Math.floor(this.getPlyrCurrentTime()));
                }, 25000); //20 * 1000
            });
            this.plyrSelector.on('pause', () => {
                if (this.syncDuration) {
                    clearInterval(this.syncDuration);
                }
            });
        },
        syncDurationRequest(second){
            return this.axios.put(this.endpoints['lesson_stream_sync_duration'], {
                lecture_stream_id: this.stream.id,
                watching_duration: second,
            });
        },
        showCustomerInfo(){
            let wrapper = document.querySelector('.plyr__video-wrapper');

            if(wrapper) {
                wrapper.insertAdjacentHTML('beforeend',this.sprintf('<div class="customer-info top-left"><div>%s</div><div>%s</div></div>', [this.$root.user.full_name, this.$root.user.ip]));
            }

            this.videoCustomer.interval = setInterval(() => {
                let info = document.querySelector('.customer-info');
                if(info) {
                    let counter = this.videoCustomer.counter;
                    info.classList.remove(this.videoCustomer.position[counter]);

                    this.videoCustomer.counter = ((counter == (this.videoCustomer.position.length - 1)) ? 0 : (counter + 1));
                    info.classList.add(this.videoCustomer.position[this.videoCustomer.counter]);
                }
            }, 15000);
        },
    },
    watch: {
        videoDetails(details) {
            this.streamUrl = null;
            setTimeout(() => {
                details.streams.forEach((item) => {
                    if (item.stream_type === 'RECORD') {
                        this.stream = item;
                        this.streamUrl = 'https://player.vimeo.com/video/' + this.stream.stream_data.video_id;
                        this.videoWatcher();
                    }
                });
            });
        },
        plyrStreamData: {
            handler: function (x, y) {
                this.syncVideoDuration();
                this.renderAgain = true;
            },
            deep: true,
        },
    },
    beforeRouteLeave(to, from, next) {
        if (this.vimeoStatisticsInterval) {
            clearInterval(this.vimeoStatisticsInterval);
        }
        if (this.syncDuration) {
            clearInterval(this.syncDuration);
        }

        next();
    },
    beforeDestroy() {
        this.$refs.plyr.player.destroy();

        if(this.hlsInstance.hls) {
            this.hlsInstance.hls.destroy();
        }

        if (this.syncDuration) {
            clearInterval(this.syncDuration);
        }
    },
};
</script>

<style>
.aspect-ratio-container {
    position: relative;
    height: 0;
    padding-top: 56%;
}

.aspect-ratio-item {
    position: absolute;
    left: 0;
    top: 0;
    width: 100% !important;
    height: 100% !important;
}

.plyr__controls {
    flex-wrap: wrap;
}

.plyr__progress__container {
    flex-basis: 100% !important;
}

.plyr__volume {
    margin-left: auto !important;
}

.customer-info {
    position: absolute;
    color: #b3b3b3;
    opacity: 0.5;
}

.customer-info.top-left {
    top: 15px;
    left: 15px;
}

.customer-info.bottom-left {
    bottom: 15px;
    left: 15px;
}

.customer-info.top-right {
    right: 15px;
    top: 15px;
}

.customer-info.bottom-right {
    right: 15px;
    bottom: 15px;
}

.customer-info.center {
    top: calc(50% - 25.5px);
    left: calc(50% - 46.5px);
}
</style>
