<template>
  <div>
    <v-calendar :attributes="attributes" is-double-paned> </v-calendar>
  </div>
</template>

<script>
const todos = [
  {
    description: 'KOÇ GRUBU - YDS İlkbahar 2021',
    isComplete: false,
    dates: new Date('November 23, 2021 21:15:30'),
    color: '#2656fd',
  },
  {
    description: 'xxKOÇ GRUBU - YDS İlkbahar 2021xx',
    isComplete: false,
    dates: new Date('November 19, 2021 21:15:30'),
    color: '#2656fd',
  },
];
export default {
  props: ['calendarEvents'],

  data() {
    return {
      incId: todos.length,
      todos,
      events: [],
    };
  },

  watch: {
    calendarEvents() {
      this.setEvents();
    },
  },

  methods: {
    setEvents() {
      Object.values(this.calendarEvents).forEach((item) => {
        item.forEach((subItem) => {
          this.events.push(subItem);
        });
      });
    },
  },

  created() {
    this.setEvents();
  },

  computed: {
    attributes() {
      // this.events.map((item) => {
      // });

      return [
        // Today attribute
        {
          contentStyle: {
            fontWeight: '700',
            fontSize: '.9rem',
          },
          dates: new Date(),
        },
        //Attributes for todo
        ...this.events.map((todo) => ({
          dates: new Date(todo.stream_date.replace(/-/g,"/")),
          highlight: true,
          popover: {
            label: todo.lecture_title,
          },
        })),
      ];
    },
  },
};
</script>

<style>
.vc-container {
  background-color: transparent !important;
  border: none !important;
}
</style>
