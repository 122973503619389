<template>
    <Layout>
        <div class="general-box filter-box pl-3 pr-2 py-2 mb-10">
            <div class="filter-box__left d-flex">
                <span>
                    <svg @click="layoutHorizontal" class="layout-icon" :class="layoutStyle === 'horizontal' ? 'active' : ''" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M30 0H0V11.4815H30V0ZM30 18.8889H0V30H30V18.8889Z"/>
                    </svg>
                </span>
                <span class="ml-10">
                    <svg @click="layoutVertical" class="layout-icon" :class="layoutStyle === 'vertical' ? 'active' : ''" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M30 30H17V17H30V30ZM30 13H17V0H30V13ZM13 13V0H0V13H13ZM0 17V30H13V17H0Z"/>
                    </svg>
                </span>
            </div>
            <div class="filter-box__center">
                <ul class="nav nav-pills tab-menu--primary">
                    <!-- <li class="nav-item" v-for="(tab, index) in subTabs" :key="index">
                      <a
                        class="nav-link"
                        href="#"
                        v-on:click.prevent="activeSubTabId = tab.id"
                        v-bind:class="{ active: activeSubTabId === tab.id }"
                        >{{ tab.title }}</a
                      >
                    </li> -->

                    <li class="nav-item">
                        <a class="nav-link" href="#" v-on:click.prevent="activeSubTabId = ''" v-bind:class="{ active: activeSubTabId === '', disabled: (categoryLoading || contentLoading)}">Tümü</a>
                    </li>
                    <li class="nav-item" v-for="(tab, index) in categories" :key="index">
                        <a class="nav-link" href="#" v-on:click.prevent="activeSubTabId = tab.id" v-bind:class="{ active: activeSubTabId === tab.id, disabled: (categoryLoading || contentLoading) }">{{ tab.title }}</a>
                    </li>
                </ul>
            </div>
            <div class="filter-box__right">
                <CustomSelectBox :options="['Tüm İçerikler', 'Bu Hafta ', 'Bu Ay']" :default="'Tüm İçerikler'" class="select light"/>
            </div>
        </div>

        <div class="filter-box__center justify-content-start">
            <ul class="nav nav-pills tab-menu--primary tab-menu--submenu mb-3">
                <li class="nav-item" role="presentation" v-for="tab in tabs" :key="tab.code">
                    <a class="nav-link" data-toggle="pill" role="tab" aria-selected="true" href="" v-bind:class="{ active: activeTab === tab.code, disabled:(categoryLoading || contentLoading) }" v-on:click.prevent="activeTab = tab.code">{{ tab.title }}</a>
                </li>
                <li class="nav-item" role="presentation">
                    <router-link class="nav-link" :to="{ name: 'lesson_detail', params: { id: 106 } }" v-bind:class="{ disabled:(categoryLoading || contentLoading) }">
                        Online Ek Dersler
                    </router-link>
                </li>
            </ul>
        </div>

        <div class="mb-30">
            <div class="bg-light" v-if="categoryLoading || contentLoading">
                <Loading/>
            </div>

            <div class="lesson-progress-grid" :class="layoutStyle" v-else>
                <template v-if="lessons.length">
                    <LessonProgressCard
                        :title="lesson.translator.title"
                        :image="lesson.image && lesson.image.public_url"
                        :videoCount="lesson.total_record_lecture_count"
                        :watchProgress="lesson.total_user_progress"
                        :lessonId="lesson.id"
                        v-for="lesson in lessons"
                        :key="lesson.id"
                        :class="lesson.id === 106 ? 'd-none' : ''"
                    />
                </template>
                <ContentNull v-else :description="$t('messages.notFoundContent')"></ContentNull>
            </div>
            <div class="py-30 mt-40 d-flex justify-content-center" v-if="lessonMeta.total_record > 10">
                <nav>
                    <ul class="pagination yds-pagination">
                        <li class="page-item" :class="lessonMeta.current_page <= 1 ? 'disabled' : ''">
                            <a class="page-link" href="#" v-on:click.prevent="getMoreLesson(currentPage - 1)"></a>
                        </li>

                        <li class="page-item" v-for="(item, index) in lessonMeta.total_page" :key="'page-item' + index" :class="lessonMeta.current_page === index + 1 ? 'active' : ''">
                            <a class="page-link" href="#" v-on:click.prevent="getMoreLesson(index + 1)">{{ index + 1 }}</a>
                        </li>
                        <li class="page-item" :class="!lessonMeta.has_more_page ? 'disabled' : ''">
                            <a class="page-link" href="#" v-on:click.prevent="getMoreLesson(currentPage + 1)"></a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from '../../components/Layout';
import LessonProgressCard from '../../components/LessonProgressCard';
import CustomSelectBox from '../../components/CustomSelectBox';
import Loading from '../../components/Loading.vue';
import ContentNull from '../../components/ContentNull.vue';

export default {
    components: {
        Layout,
        LessonProgressCard,
        CustomSelectBox,
        ContentNull,
        Loading,
    },
    data() {
        return {
            isLoading: true,
            layoutStyle: 'horizontal',
            tabs: [
                {
                    title: 'Online Derslerim',
                    code: 'online',
                },
                {
                    title: 'Offline Derslerim',
                    code: 'offline',
                },
                {
                    title: 'Video Derslerim',
                    code: 'video',
                },
            ],
            activeTab: 'online',
            subTabs: [
                {id: 7, title: 'Tümü', code: 'tumu'},
                {id: 6, title: 'YDS', code: 'yds'},
                {id: 5, title: 'YÖKDİL', code: 'yokdil'},
                {id: 4, title: 'IELTS', code: 'ielts'},
            ],
            activeSubTab: 'yds',
            activeSubTabId: '',
            lessons: [],
            lessonMeta: {},
            currentPage: 1,
            categories: [],
            categoryLoading: false,
            contentLoading: false,
        };
    },
    created() {
        this.setTabId();
        this.fetchLesson();
        this.fetchLessonCategories();
    },

    methods: {
        layoutVertical: function () {
            this.layoutStyle = 'vertical';
        },
        layoutHorizontal: function () {
            this.layoutStyle = 'horizontal';
        },
        fetchLesson() {
            this.lessonMeta = [];
            this.lessons = [];
            this.contentLoading = true;

            this.axios.get('/lesson?type=' + this.activeTab + '&category_id=' + this.activeSubTabId + '&page=' + this.currentPage).then((response) => {
                this.lessons = response.data.data;
                this.lessonMeta = response.data.meta;
            }).finally(() => {
                this.contentLoading = false;
            });
        },
        fetchLessonCategories() {
            this.categoryLoading = true;

            this.axios.get('/lesson/categories?type=' + this.activeTab).then((response) => {
                /*if (response.data.status) {
                    this.isLoading = false;
                }*/
                this.categories = response.data.data;
            }).finally(() => {
                this.categoryLoading = false;
            });
        },
        getMoreLesson(index) {
            this.currentPage = index;
            this.fetchLesson();
        },
        setActiveSubId(id) {
            this.activeSubTabId = id;
        },
        setTabId() {
            if (this.$route.params.tab) {
                this.activeTab = this.$route.params.tab;
            }
        },
    },
    watch: {
        activeTab() {
            //this.isLoading = true;
            this.currentPage = 1;
            this.lessons = [];
            this.activeSubTabId = '';
            this.fetchLesson();
            this.fetchLessonCategories();
        },
        activeSubTabId() {
            //this.isLoading = true;

            this.fetchLesson();
            this.fetchLessonCategories();
        },
        '$route.params.tab': {
            handler: function () {
                this.setTabId();
            },
        },
    },
};
</script>

<style></style>
