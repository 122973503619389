<template>
  <ul class="px-2 mb-3">
    <li class="d-flex align-items-center fw-600 fs-16">
      {{ node.translate.title }}
    </li>
    <p
      class="record-item"
      v-for="(rec, index) in node.recordings"
      :key="'rec' + index"
      :class="rec.id === activeRecordId ? 'active' : ''"
      @click="setAudioSourceData(rec)"
    >
      {{ rec.title }}
    </p>
    <NestedMenu
      v-for="(child, index) in node.children"
      :key="'asdadx' + index"
      :node="child"
    />
  </ul>
</template>

<script>
export default {
  name: 'NestedMenu',
  data() {
    return {
      //activeRecordId: '',
    };
  },
  props: {
    node: {
      type: Object,
      required: true,
    },
    activeRecordId: {
      type: Number,
    },
  },
  computed: {
    hasChildren() {
      const { children } = this.node;
      return children && children.length > 0;
    },
    hasSubItem() {
      const { children } = this.node.recordings;
      return children && children.length > 0;
    },
  },
  methods: {
    setAudioSourceData(data) {
      this.$emit('setAudioSourceData', data);
    },
  },
  mounted() {
    //console.log(this.node);
  },
};
</script>

<style></style>
